@import "./components/components.scss";
@import "src/style/variables.scss";

/* scrollbar style for firefox */
@supports (-moz-appearance:none) {
    * {
        scrollbar-width: thin;
    }

    body * {
        scrollbar-color: transparent transparent;
    }

    body *:hover {
        scrollbar-color: auto;
    }
}

html {
    scroll-behavior: smooth;
}

body {
    * {
        /* scroll width */
        ::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }

        /* scroll Track */
        // ::-webkit-scrollbar-track {
        //     background: #eaeaea;
        // }

        /* scroll Handle */
        ::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 5px;
        }

        /* scroll Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        *:hover::-webkit-scrollbar-thumb {
            background: #888;
        }
    }
}

.infinite-scroll-component {
    overflow-y: overlay !important;
}