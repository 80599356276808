@use "sass:math";
@import "src/common.scss";

.TimelineHeaderCell {
    align-items: center;
    appearance: none;
    box-sizing: border-box;
    // border-right: 1px solid $spintrGray;
    bottom: 0;
    box-align: center;
    box-pack: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;

    .week-header {
        align-items: center;
        appearance: none;
        box-sizing: border-box;
        // border-right: 1px solid $spintrGray;
        bottom: 0;
        box-align: center;
        box-pack: center;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0;

        .month-name {
            margin-top: $spacingMedium;
            text-transform: uppercase;
        }
    }

    .month-header, .quarter-header {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        height: 100%;
        justify-content: center;
        text-transform: uppercase;
        width: 100%;
        // margin-top: $spacingTiny;
    }

    .date-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        text-transform: uppercase;
        width: 100%;

        &.weekend {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    .date-list {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
        width: 100%;

        .date-cell {
            cursor: default;
            overflow-wrap: break-word;
            text-align: center;
            width: math.div(100%, 7);

            &.weekend {
                .date-number {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            &.today {
                .date-number {
                    background-color: rgba(255, 255, 255, 0.15);
                }
            }

            &.today, &.weekend {
                .date-number {
                    border-radius: $brNormal;
                    display: inline;
                    padding: $spacingTiny;
                }
            }
        }
    }
}