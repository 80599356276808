@import "src/style/variables";

.tiny-ai-popup {
    max-width: 800px;
    min-width: 320px;
    width: 100%;
}

.TinyArtificialIntelligenceFunction {
    textarea {
        border-radius: $borderRadiusSharper;
        border: 1px solid #D0D2DA;
        box-sizing: border-box;
        height: 270px;
        width: 100%;
        resize: none;
        overflow-y: scroll;
    }

    .popup-body {
        position: relative;

        .loader-wrapper {
            position: absolute;
            bottom: $gutterTiny;
            right: $gutterTiny;

            .medium-bubble, .small-bubble {
                display: none;
            }
        }
    }

    .popup-footer {
        padding-top: $gutterLarge;
        text-align: right;

        > * {
            margin-left: $gutterMedium;
        }

        > button {
            border-radius: $borderRadius;
        }
    }
}