@import "src/common.scss";
@import "../plannerVariables.scss";

.TimelineRow {
    display: flex;
    position: absolute;
    width: 100%;

    &:hover {
        .TimelineRow-label, .TimelineRow-rail {
            background-color: $spintrBlueLighter;
        }
    }

    .TimelineRow-label {
        align-items: center;
        background-color: $spintrWhite;
        border-bottom: 1px solid $spintrBlueLighter;
        box-sizing: border-box;
        display: flex;
        left: 0;
        gap: $spacingTiny;
        justify-content: stretch;
        max-height: 50px;
        padding: 0 ($spacingLarge * 2);
        position: sticky;
        z-index: map-get($plannerStrata, rowLabel);

        .TimelineRow-editButton {
            display: none;
        }

        &:hover {
            .TimelineRow-editButton {
                display: flex;
            }
        }

        .TimelineRow-labelText {
            cursor: default;
            flex: 0 0 100%;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
        }

        .TimelineRow-nameInput {
            display: flex;
            flex: 1 1 calc(100% - 32px);
            width: 100%;
        }

        .TimelineRow-cancelButton, .TimelineRow-saveButton {
            display: flex;
            flex: 0 0 16px;
        }

        .TimelineRow-cancelIcon {
            rotate: 45deg;
        }
    }

    .TimelineRow-rail {
        align-items: center;
        border-bottom: 1px solid $spintrBlueLighter;
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
        position: relative;

        .TimelineRow-creationBox {
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            min-width: 3px;
            position: absolute;
            user-select: none;
            opacity: 0.25;
            z-index: map-get($plannerStrata, item);

            .creationBox-inner {
                background-color: rgba(0, 0, 0, 0.1);
                border: 1px dashed $spintrGray;
                border-radius: $brNormal;
                box-sizing: border-box;
                bottom: 0;
                left: 0;
                padding: $spacingTiny $spacingMedium;
                position: absolute;
                right: 0;
                top: 0;
                z-index: map-get($plannerStrata, itemBackground);
        
                .creationBox-label-top {
                    align-items: center;
                    display: flex;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: pre;
                    width: 100%;
                    z-index: map-get($plannerStrata, itemContent);
                }
        
                .creationBox-label-bottom {
                    align-items: center;
                    display: flex;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: pre;
                    width: 100%;
                    z-index: map-get($plannerStrata, itemContent);
                }
            }
        }
    }
}