@import "src/style/variables";

.unstyled-button {
    &.UserCard {
        background-color: #FFF;
        border-radius: $borderRadius;
        position: relative;
        box-sizing: border-box;
        min-width: 220px;
        width: 220px;
        max-width: 250px;
        display: flex;
        flex-direction: column;

        .coverImage-wrapper {
            width: 100%;
            height: 70px;
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
            background-size: cover;
            background-repeat: no-repeat;
        }

        // > * {
        //     display: inline-block;
        //     vertical-align: top;
        // }

        .image-wrapper {
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            padding: $gutterMedium;
            padding-bottom: 0px;
            margin-top: 16px;
            text-align: center;

            >* {
                display: inline-block;
            }

            .ms-Persona-imageArea {
                border: 2px solid #FFF;
            }
        }

        .UserCard-bottom {
            flex-grow: 1;
        }

        .UserCard-right {
            padding: $gutterLarge;
            padding-top: 40px;
            position: relative;
            text-align: center;

            .UserCard-title {
                margin-bottom: $gutterTiny;

                >* {
                    display: inline-block;
                    vertical-align: middle;
                    word-wrap: break-word;
                }

                >i {
                    margin-left: $gutterXSmall;
                    font-size: 12px;
                }
            }

            .UserCard-infoRow {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.lastSeen {
                    margin-top: $gutterTiny;
                }
            }

            .UserCard-Buttons {
                margin-top: $gutterMedium;

                >.ms-TooltipHost {
                    margin-left: $gutterXSmall;
                    margin-right: $gutterXSmall;
                }

                .contact-button {
                    border-radius: $borderRadiusSharper;
                    //width: 25%;
                    box-sizing: border-box;
                    text-align: center;
                    height: 32px;
                    width: 32px;
                    border-radius: 32px;

                    &:hover {
                        opacity: 0.5;
                    }

                    .Visage2Icon {
                        height: 20px;
                        width: 20px;
                        display: block;
                        margin-left: 6px;
                        margin-top: 6px;
                    }
                }
            }

            .actionmenu-component {
                position: absolute;
                right: $gutterMedium;
                top: $gutterSmall;
                display: none;
            }
        }

        &:hover {
            .actionmenu-component {
                display: block;
            }
        }

        &.show-border {
            .UserCard-bottom {
                border: 1px solid color(borderColor);
                border-top-width: 0px;
                border-bottom-left-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;
            }
        }

        &.shimmer {
            .UserCard-right {
                margin-top: 70px;
            }
        }
    }
}

.SpintrList-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: $gutterSmall;
    row-gap: $gutterSmall;

    .SpintrCard {
        max-width: calc(25% - (($gutterSmall * 3) / 4));
        min-width: calc(25% - (($gutterSmall * 3) / 4));
        width: calc(25% - (($gutterSmall * 3) / 4));
    }
}

@media screen and (max-width: 1200px) {
    .SpintrList-cards {
        .SpintrCard {
            max-width: calc(33.3% - (($gutterSmall * 2) / 3));
            min-width: calc(33.3% - (($gutterSmall * 2) / 3));
            width: calc(33.3% - (($gutterSmall * 2) / 3));
        }
    }
}

@media screen and (max-width: 700px) {
    .SpintrList-cards {
        .SpintrCard {
            max-width: calc(50% - ($gutterSmall / 2));
            min-width: calc(50% - ($gutterSmall / 2));
            width: calc(50% - ($gutterSmall / 2));
        }
    }
}

@media screen and (max-width: 400px) {
    .SpintrList-cards {
        .SpintrCard {
            max-width: 100%;
            min-width: 100%;
            width: 100%;
        }
    }
}

.ms-Fabric--isFocusVisible {
    .SpintrCard {
        .actionmenu-component {
            display: block !important;
        }
    }
}