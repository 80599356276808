@import "src/style/variables";

.ContentWithSubmenu{
    .ContentWithSubmenuRight{
        h1{
            margin-top:0;
        }
    }
}

.PageView{
    .ContentWithSubmenuRight{
        .contentWithInfoPanel{
            color: color("dark-grey")
        }
        .socialBlock{
            .CommentsContainer{
                .InteractionsBar{
                    margin:$gutterXSmall 0 0 0;
                }
            }
        }
    }
}

.PageView {
    table {
        border: 1px solid color("dark-grey");
        tr {
            td {
                padding:6px;
            }
        }
    }
}

.templateSvgWrapper {
    margin-top: $gutterXSmall;
    display: inline-block;
    width: 64px;
    height: 64px;
    border: 1px solid color(dusk-grey);
    margin-right: $gutterMedium;
    margin-bottom: $gutterSmall;
    vertical-align: top;
    padding: $gutterMedium;
    border-radius: $borderRadiusRound;
    cursor: pointer;
    -webkit-transition: border-color $fastTransition;
    -moz-transition:  border-color $fastTransition;
    -o-transition:  border-color $fastTransition;
    transition:  border-color $fastTransition;
    &:hover{
        border-color: color("visageMidBlue");
    }
    .templateSvg {
        width: 100%;
        height: 100%;
        fill: color("visageMidBlueBackground");
        -webkit-transition: fill $fastTransition;
        -moz-transition:  fill $fastTransition;
        -o-transition:  fill $fastTransition;
        transition:  fill $fastTransition;
        &:hover{
            fill: color("visageMidBlue");
        }
    }

    &.active {
        border-color: color("visageMidBlue");

        .templateSvg {
            fill: color("visageMidBlue")
        }
    }
}
