@import "src/style/variables.scss";

.carousel li img {
    max-height: 500px;
    object-fit: contain;
}

.BlogsPostView {
    .info-bar {
        margin-bottom: $gutterMedium;
    }
}

.blogPost {
    video {
        width: 100%;
    }

    .pageHeaderImageWrapperWrapper {
        margin-bottom: $gutterXXLarge;
        width: 100%;
    }

    .podcast {
        margin-top: 0 !important;
        margin-bottom: 20px !important;
        height: 40px !important;
    }

    .azuremediaplayer {
        margin-top: 0;
        max-width: 40em;
        margin-bottom: 20px;
    }

    .attached-images {
        display: flex;
        flex-flow: wrap;
        margin-top: $gutterLarge;
        margin-bottom: $gutterLarge;

        &:hover {
            cursor: pointer;
        }

        .ms-Image {
            margin: $gutterSmall;
            margin-left: 0;
            margin-top: 0;

            .ms-Image-image {
                object-fit: cover;
            }
        }
    }

    .infoPanel {
        width: 40%;
        display: inline-block;
        vertical-align: top;
        font-size: 13px;
        line-height: 1.2em;
        width: 38%;
        background-color: #f6f6f6;
        border-radius: 0;
        margin-bottom: 18px;
    }

    .postContent {
        overflow: auto;
    }

    .postContent.noImage {
        // margin-top: $gutterXLarge;
    }

    .postHeader.image {
        margin-top: 0px;

        img {
            display: block;
            width: 100%;
        }
    }

    .postHeader.appMode {
        width: calc(100vw + 1px) !important;
        margin-left: -$gutterMedium;
        margin-right: -$gutterMedium;
        margin-top: -12px;
        margin-bottom: 0px;
        max-width: 1300px !important;
    }

    .postHeader:hover .actionmenu-component {
        display: inline-block;
    }

    .postHeader.image {
        .actionmenu-component {
            i {
                background-color: color("light-grey");
                border-radius: $borderRadius;
                opacity: 0.6;
            }

            &:hover i {
                background-color: color("dusk-grey");
                opacity: 1;
            }
        }
    }

    .postHeader {
        .blogTitleHeader {
            margin: 0;
        }

        .blogPostTitle {
            margin-top: $gutterXSmall;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 16px - 25px); // action menu
            margin-bottom: 0.67em !important; // fixes margin bug in /edit
        }

        .InteractionsBar {
            margin: 0;
        }

        //height: 40px;
        margin-top: 13px;
        position: relative;
        margin-bottom: 30px;

        .actionmenu-component {
            // display: none;
            top: 16px;
            right: 16px;
            position: absolute;
            color: white;
        }

        .actionmenu-component.open {
            display: block;
        }

        .gradient {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.33237044817927175) 0%, rgba(0, 0, 0, 0) 100%);
        }

        .no-gradient .fs-body-1 {
            color: #000;
        }

        h2 {
            margin: 0;
            margin-top: $gutterXSmall;
        }

        h4 {
            text-transform: uppercase;
        }

        .image {
            color: #ffffff;
        }

        .gutter {
            box-sizing: border-box;
            // color: #fff;
            bottom: 0px;
            // position: absolute;
        }

        .gutter.image {
            padding-left: 24px;
            padding-bottom: 24px;
            padding-right: 24px;
            width: 100%;
            position: absolute;

            .blogPostTitle {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .blogPost {
        .postHeader {
            .gutter {
                &.image {
                    padding-left: 12px;
                    padding-right: 12px;
                    padding-bottom: 0;
                }
            }
        }
    }
}