@import "src/style/variables";

.ExpandableFormField {
    margin-top: $gutterMedium;
    padding: $gutterSmall;
    border-radius: $borderRadius;
    border: 1px solid color(borderColor);

    > .unstyled-button {
        width: 100%;

        .ExpandableFormField-line {
            border-bottom: 1px solid color(dusk-grey);
            flex-grow: 1;
            height: 0px;
        }
    }

    .ExpandableFormField-content {
        border-top: 1px solid color(borderColor);
        margin-top: $gutterSmall;
        padding-top: calc($gutterMedium - 5px);
        margin-bottom: 0;
    }
}
