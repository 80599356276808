@import "src/style/variables.scss";

#ProductsRootView {
    &.general-view {
        min-height: 100vh;
        position: relative;

        .products-view {
            border-radius: $borderRadius;
            box-sizing: border-box;
        }

        &.list-view {
            .products-view {
                max-width: 1400px;
            }
        }
    }
}