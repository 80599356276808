@import "src/style/variables";

#ChatTabContainer {
    bottom: 0;
    margin-left: -$gutterMedium;
    pointer-events: none;
    position: fixed;
    text-align: right;
    transition: width 0.5s ease-in-out;
    width: calc(100% - #{$spintrSidebarMaxWidth});
    z-index: 5;
    right: $gutterXLarge;


    &.with-formfooterbar {
        bottom: 62px;
    }

    .tab-wrapper {
        display: inline-block;
        padding-left: $gutterXSmall;
        text-align: initial;
        vertical-align: bottom;
    }

    .tab-switcher {
        border-radius: $borderRadius $borderRadius 0 0;
        box-shadow: 0 0 8px 4px rgba(67, 67, 67, 0.2);
        background-color: #FFF;
        height: 39px;
        line-height: 2.500em;
        font-size: 18px;
        pointer-events: auto;
        text-align: center;
        width: 39px;
    }
}

.HiddenTabList {
    .hidden-tab {
        border-bottom: 1px solid color("smoke");
        line-height: 1;
        
        &:last-child {
            border-bottom: 0 none;
        }
    }
}