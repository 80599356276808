@import "src/style/variables";

.InTextPageLinkButton {
    width: 100%;
    font-size: 18px;
    font-weight: 550 !important;
    border-radius: $borderRadius;
    height: 52px;
    background-color: color("visageMidBlueBackground");
    display: flex;
    justify-content: center;
    align-items: center;

    .span {
        height: auto;
    }

    i {
        margin-left: $gutterSmall;
        margin-top: $gutterXSmall;
        svg {
            margin-left: $gutterSmall;;
            width: 24px;
            height: 24px;        
        }
    }
    &:hover{
        opacity: 0.7;
    }
}