@import "src/style/variables";

.StartPageBuilderComponentSettings {
    cursor: default;

    .buttons {
        padding: 0 $gutterXXLarge $gutterXXLarge;
        text-align: right;

        .cancel {
            color: color("light-blue");
            margin-right: $gutterMedium;
        }
    }
}