@import "src/common.scss";

$cardHeightTags: 80px;
$cardHeight: 52px;
$cardWidth: 318px;

.ListContentCard {
    position: relative;
    overflow: hidden;

    &:hover {
        opacity: 0.9;
    }

    .ListContentCard-actionMenu {
        position: absolute;
        top: $spacingMedium;
        right: $spacingMedium;
    }

    .ListContentCard-link {
        background-color: $spintrWhite;
        border: 1px solid $spintrGrayLight;
        border-radius: $brRounder;
        box-sizing: border-box;
        padding: $spacingMedium;
        display: block;
        text-decoration: none;
        height: 100%;
    }

    &.shimmer {
        height: $cardHeight + ($spacingMedium * 2);
        width: $cardWidth + ($spacingMedium * 2);
    }

    .ListContentCard-wrapper {
        gap: $spacingMedium;
        width: $cardWidth;

        .ListContentCard-text {
            overflow: hidden;
        }
        
        .ListContentCard-textStack {
            min-height: $cardHeight;
            gap: $spacingSmall;
            flex-grow: 1;
            flex-shrink: 1;

            .ListContentCard-title {
                padding-right: $spacingXLarge;
            }
        }
    }

    &.tags {
        .ListContentCard-wrapper {
            .ListContentCard-textStack {
                min-height: $cardHeightTags;
            }
        }
    }
}

@media screen and (max-width: $vmTabletPortrait) {
    .ListContentCard-wrapper {
        flex-direction: column;
        max-width: 100%; 
        gap: $spacingSmall;
    }

}