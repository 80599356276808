@import "src/style/variables";

.TinyFile-MissingRights {
    background-color: color("light-grey");
    padding: $gutterSmall;
    margin-bottom: $gutterSmall;
    border-radius: $borderRadiusSharper;
}

.TinyFile-wrapper {
    background-color: color("light-grey");
    position: relative;
    border: 1px solid color("dusk-grey");
    border-bottom: none;

    &:last-of-type,
    &.single {
        border-bottom: 1px solid color("dusk-grey");
    }

    .unstyled-button {
        width: 100%;
    }

    .TinyFile {
        padding: $gutterXSmall $gutterSmall;
        width: 100%;
        box-sizing: border-box;

        div {
            display: inline-block;
            vertical-align: middle;
        }

        .icon-wrapper {
            margin-right: $gutterXSmall;
            vertical-align: -5px;
            line-height: normal;
        }

        .file-name-wrapper {
            display: inline-flex;
            width: calc(100% - 70px);

            > a:first-child {
                min-width: 0;

                > div {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .file-size-wrapper {
            position: absolute;
            right: $gutterSmall;
        }
    }
}
