@import "src/style/variables";

$headerHeight: 225px;
$responseiveHeight: 150px;

.AppPageHeader {
    background-color: #FFF;
    border-radius: $borderRadius;
    height: $headerHeight;
    margin-bottom: 30px;
    position: relative;
    width: 100%;

    .background-image {
        bottom: 0;
        border-radius: $borderRadius;
        height: $headerHeight;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;

        a {
            border-radius: $borderRadiusSharper;
            display: inline-block;
            position: absolute;
            right: $gutterXXLarge;
            top: $gutterXXLarge;
            padding: ($gutterXSmall * 1.5) $gutterSmall;

            &:hover {
                opacity: 0.9;
            }

            span, i {
                display: inline-block;
                vertical-align: middle;
                
                &:first-child {
                    margin-right: $gutterXSmall;
                }
            }

            .Visage2Icon {
                height: 20px;
            }

            &.light {
                svg path {
                    fill: #FFFFFF !important;
                }
            }

            &.dark {
                svg path {
                    fill: color("visageGray") !important;
                }
            }
        }
    }
}

@media only screen and (max-width: $vmTabletLandscape) {
    .AppPageHeader {
        height: $responseiveHeight;

        .background-image {
            height: $responseiveHeight;
        }
    }
}