@import "src/style/variables.scss";

.SystemStatusesPrioView {
    margin: $gutterSmall $gutterSmall 0px $gutterSmall;
    > .content {
        margin: 0 auto;
        position: relative;
        z-index: 20;
        width: 100%;
        box-shadow: 0px 1px 3px 0px #10182814;
        background-color: #fff;
        border-radius: $borderRadius;

        &.expanded {
            border-radius: $borderRadius $borderRadius 0 0;
        }

        .header {
            padding: $gutterSmall;
        }

        > .unstyled-button {
            width: 100%;
        }

        .current-status-label {
            padding-right: $gutterSmall;
            border-right: 1px solid color(borderColor);
            margin-right: $gutterSmall;
        }

        .expanded-items {
            position: absolute;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
            box-shadow: 0px 1px 3px 0px #10182814;
            border-radius: 0 0 $borderRadius $borderRadius;
            padding: $gutterSmall;
            padding-top: 0;

            > .item {
                margin-top: $gutterLarge;

                > a {
                    width: 100%;
                }

                > div:last-child {
                    align-self: flex-start;
                }

                .close-button {
                    padding: 1px 5px;

                    i {
                        font-size: 10px;
                    }
                }

                &:not(:last-child) {
                    padding-bottom: $gutterSmall;
                    border-bottom: 1px solid color(borderColor);
                }
            }
        }

        .view-all {
            margin-top: $gutterSmall;
        }
    }
}
