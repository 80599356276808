@import "src/style/variables";

.TinyFormattedContent {
    word-wrap: break-word;
    overflow: hidden;

    iframe,
    video,
    img {
        max-width: 100%;
    }

    img {
        height: auto;

        &[style*="float: left"] {
            margin: 10px 10px 10px 0;
        }

        &[style*="float: right"] {
            margin: 10px 0 10px 10px;
        }
    }

    /* also used in tiny.css */
    h1, h2, h3, h4, h5, h6 {
        margin: 1em 0 0.5em !important;
    }

    > span:first-child {
        > h1, > h2, > h3, > h4, > h5, > h6 {
            &:first-child {
                margin-top: 0.5em !important;
            }
        }
    }

    pre {
        white-space: pre-wrap;
    }

    .table-wrapper {
        display: block;
        overflow-x: auto;
    }
}
