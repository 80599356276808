@import "src/style/variables";

.ErrorFallback {
    box-sizing: border-box;
    margin: $gutterLarge auto;
    padding: $gutterSmall;
    text-align: center;
    width: 100%;

    .Visage2Icon {
        margin: 0 auto;
        font-size: 48px;
        margin-bottom: $gutterLarge;
    }
}