@import "src/style/variables";

.MessageContent {
    display: flex;
    flex-direction: column;
    gap: $gutterSmall;
    max-width: 600px;

    > div {
        > span {
            white-space: pre-wrap;
        }
    }
}
