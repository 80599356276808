@import "./variables.scss";

.introjs-tooltipbuttons {
  .introjs-button {
    font-family: $font;
    background-color: var(--primaryColor, "black");
    color: white;
    font-weight: 300;
    border-radius: $borderRadius;
    text-shadow: none;
  }
}

.introjs-tooltip {
  border-radius: $borderRadius;
  min-width: 350px;
  max-width: 350px;
}

.introjs-disabled {
  visibility: hidden;
}