@import "src/style/variables";

.ComposerInfo {
    .inner {
        display: flex;
        flex-direction: row;
        column-gap: $gutterSmall;
        padding: $gutterSmall;
        background-color: #F1F2FC;
        border-radius: $borderRadius;
        justify-content: space-between;
        margin: 0 40px 12px 34px;

        .left {
            max-width: calc(100% - 40px);
            display: flex;
            flex-direction: row;
            column-gap: $gutterTiny;

            > .label-component {
                white-space: nowrap;
            }

            .body-text {
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .unstyled-button {
            &:hover {
                opacity: 0.75;
            }

            > * {
                pointer-events: none;
            }
        }
    }
}
