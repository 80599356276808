@import "src/style/variables";

.MarketplaceWidget {
    border-radius: $borderRadius;
    color: #000;
    overflow: hidden;
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    background-color: #FFF;

    &.displayBorder {
        border: 1px solid color("dusk-grey");
    }

    &.autoWidth {
        width: auto;
    }

    .information {
        align-items: center;
        display: flex;
        height: 56px;
        box-sizing: border-box;

        .logo {
            flex: 0 0 24px;
            margin-right: 8px;

            img {
                border-radius: 100%;
                height: 24px;
                overflow: hidden;
                width: 24px;
            }
        }

        .name {
            flex: 1 1 auto;
            width: calc(100% - 32px);
            margin-top: 1px;

            .label-component {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .data {
        padding-top: 0px !important;
        box-sizing: border-box;
        align-content: center;
        display: flex;
        justify-content: center;
        flex-grow: 1;

        .MarketplaceWidgetData {
            width: 100%;
        }
    }

    .widget-error {
        position: absolute;
        height: 20px;
        width: 100%;
        left: 0px;
        text-align: center;
        top: calc(50% - 10px);
    }
}