@import "src/style/variables.scss";

.GroupHeader {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    position: relative;

    .bottom-row {
        display: flex;
        flex-direction: row;
        margin: $gutterSmall;
        margin-top: 0;

        .menu {
            flex-grow: 1;
            width: 0;
        }
    }
}

@media only screen and (max-width: $vmTabletLandscape) {
    .GroupHeader {
        .GroupHeaderInformation-inner {
            max-width: calc(100% - 50px);
        }

        .bottom-row {
            .buttons {
                position: absolute;
                top: $gutterSmall;
                right: $gutterSmall;
            }
        }
    }
}
