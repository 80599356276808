@import "src/style/variables";

.CalypsoContentWithSidebar {
    display: flex;
    flex-direction: row;
    position: relative;

    > .content {
        flex-grow: 1;
        flex-basis: 100%;
        padding: $gutterXXLarge;
        max-width: 100%;
        box-sizing: border-box;
    }

    .sidebar {
        width: 0px;
        min-width: 0px;
        transition: width 0.25s, min-width 0.25s;
        overflow: hidden;
        opacity: 0;
    }

    &.expanded {
        > .content {
            width: calc(100% - 300px);
        }

        .sidebar {
            overflow: auto;
            width: 300px;
            min-width: 300px;
            border-left: 1px solid color(borderColor);
            padding: $gutterXXLarge;
            opacity: 1;
        }
    }

    &.no-padding {
        .content, .sidebar {
            padding: 0px;
        }
    }
}

@media screen and (max-width: $vmTabletLandscape) {
    .CalypsoContentWithSidebar {
        &.expanded {
            .sidebar {
                background-color: #FFF;
                bottom: 0;
                box-sizing: border-box;
                position: absolute;
                right: 0;
                top: 77px;
                width: 100%;
            }
        }
    }
}

