@import "src/style/variables";

$imageSize: 32px;

.AppPageList {
    background-color: #FFF;
    border-radius: 10px;
    padding: $gutterXXLarge;
    
    .header {
        padding-bottom: $gutterMedium;

        .header-text {
            font-weight: 550;
        }
    }

    ol.list {
        list-style: none;
        margin: 0;
        padding: 0;

        li.list-item {
            display: flex;
            padding: 10px 0;
            gap: 8px;

            > div {
                flex: 1 1 auto;
                line-height: 32px;
                vertical-align: middle;
            }

            .item-image {
                border-radius: 100%;
                flex: 0 0 32px;
                height: $imageSize;
                overflow: hidden;
                width: $imageSize;
            }

            .value {
                text-align: right;
            }
        }
    }
}