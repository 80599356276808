@import "src/style/variables";
.calendar-view {
    min-height: 570px;
    height: calc(100vh - 200px);
    max-height: 900px;
    vertical-align: top;
    background-color: #fff;

    .calendarHeader {
        margin-bottom: $gutterSmall;
    }

    .calendarActionMenu {
        border: 1px solid #d0d5dd;
        border-radius: $borderRadiusRounder;
        padding-left: 12px;

        .actionmenu-component {
            height: 20px;
        }
    }

    .calendarNavigation {
        margin-left: $gutterLarge;
    }

    .calendarToolbar {
        display: flex;

        .ms-Button {
            border-radius: $borderRadiusRounder;
            margin-left: 8px;

            &:hover {
                background-color: color(visageMidBlueBackground);
                color: color(visageMidBlue);
            }

            &.is-selected {
                background-color: color(visageMidBlueBackground);
                color: color(visageMidBlue);
            }
        }

        .ms-Pivot {
            margin-bottom: 0;
        }
    }
    &.isAgendaView {
        height: auto;
        min-height: none;
        max-height: none;
    }

    &.isSmall {
        width: 40%;
        display: inline-block;
    }
    .rbc-off-range {
        color: black;
    }
    .rbc-calendar {
        .rbc-month-view {
            .rbc-row-content {
                .rbc-date-cell {
                    font-size: 0.875em;
                    z-index: auto !important;
                }

                .rbc-row {
                    .rbc-row-segment {
                        .rbc-event {
                            font-size: 0.875em;
                            background-color: color("light-blue");
                            padding: 2px 4px;
                        }
                    }
                }
            }
        }
        .rbc-time-view {
            .rbc-label {
                font-size: 0.875em;
            }
            .rbc-event {
                font-size: 0.875em;
                background-color: color("light-blue");
                padding: 2px 4px;
            }
        }
    }

    .rbc-event,
    .rbc-row-segment,
    .rbc-event-content {
        -webkit-user-select: text !important; /* Chrome all / Safari all */
        -moz-user-select: all !important; /* Firefox all */
        -ms-user-select: element !important; /* IE 10+ */
        user-select: all !important; /* Likely future */
    }

    .rbc-event > .rbc-event-content > a:focus > div {
        font-weight: 600;
    }
}
