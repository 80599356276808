@import "src/style/variables";

.AcademyLessonView {
    .pageHeader {
        margin-bottom: $gutterMedium !important;
    }

    .lesson-footer {
        display: flex;
        flex-direction: row;
        margin-top: $gutterLarge;
        justify-content: flex-end;
    }
}
