@import "src/style/variables";

.ShortcutList {
    padding: 0 $gutterSmall;

    .shortcut {
        position: relative;
        margin-bottom: 2px;

        .shortcut-inner {
            background-color: color(light-grey);
            color: #091B3D;
            font-weight: 600;
            padding-left: $gutterXSmall;

            > div {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                max-width: 80%;
                overflow: hidden;
                padding: 10px 4px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .arrow {
            font-size: 14px;
            font-weight: 400;
            pointer-events: none;
            position: absolute;
            right: 10px;
            top: 8px;
            user-select: none;
        }
    }

    .form-wrap {
        .shortcut-inner {
            pointer-events: none;
        }
    }
}