.StartPageView {
    max-width: 1575px;
    margin: 0 auto;

    &.responsive {
        max-width: 500px;

        .StartPageRow {
            .StartPageColumn {
                width: 500px;
            }
        }
    }

    .StartPageRow {
        display: flex;
        flex-direction: row;
        gap: 60px;

        .StartPageColumn {
            box-sizing: border-box;
            flex-grow: 0;
            flex-shrink: 1;
            min-height: 1px;
        }
    }
}
