@import "src/style/variables.scss";

.SocialComposerUserHeader {
    .poster-info {
        padding: $gutterXSmall 0;
    }

    .profile-image, .text-input {
        display: inline-block;
        vertical-align: middle;
    }

    .profile-image {
        margin-right: 10px;
    }

    .text-input {
        max-height: 26px;
        overflow: hidden;
        width: calc(100% - 46px);
    }

    &:not(.expanded) {
        .ms-Persona-details {
            display: none;
        }
    }

    &.expanded {
        .profile-image, .text-input {
            display: block;
            width: 100%;
        }
    }
}