@import "src/style/variables.scss";

.wiki-article-edit-view {
    .header-image {
        margin: $gutterMedium 0;
    }

    .structure {
        max-width: 500px;
    }

    .link-container {
        border: 1px solid color("dusk-grey");
        padding: $gutterSmall $gutterMedium $gutterSmall $gutterMedium;
        margin-bottom: $gutterMedium;
        border-radius: $borderRadius; 
    }
}
