@import "src/common.scss";

.SystemStatusResourceCTA {
    align-items: center;
    border: 1px solid $spintrGrayLighter;
    background-color: $spintrGrayLighter;
    border-radius: $brVeryRound * 1.33334;
    box-sizing: border-box;
    column-gap: $spacingTiny;
    display: flex;
    flex-direction: row;
    padding: $spacingTiny;
    padding-right: $spacingSmall;
    line-height: 1;
    position: relative;
    user-select: none;
    // width: 100%;
    transition: all 0.2s ease-in-out;

    .SpintrLoader {
        padding: 0;
    }

    &.SystemStatusResourceCTA--asking {
        border-color: $spintrGray;
        padding: $spacingSmall;
        width: 100%;
    }

    .SystemStatusResourceCTA-inner {
        align-items: center;
        display: flex;
        width: 100%;

        .SystemStatusResourceCTA-inputWrapper {
            flex: 1 1 100%;
        }

        .SystemStatusResourceCTA-sendButton {
            align-items: center;
            border-radius: $brCircular;
            display: flex;
            flex: 0 0 28px;
            height: 28px;
            justify-content: center;
            width: 28px;
        }

        .InteractiveTextInputs {
            min-width: 100px;
            line-height: 1.714em;
        }

        .DraftEditor-root {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
        }
    }
}

.SystemStatusResourceCTA-info {
    display: flex;
    flex-direction: row;
    column-gap: $spacingSmall;
    align-items: center;
}