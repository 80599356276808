@charset "UTF-8";
/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
.SocialPostHeader .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.SocialPostHeader .wrapper .SocialPostHeader-left {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-width: calc(100% - 24px);
}
.SocialPostHeader .wrapper .SocialPostHeader-left .ms-Persona-details {
  padding-right: 0px;
}
.SocialPostHeader .wrapper .SocialPostHeader-left .ms-Persona-details .meta {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.SocialPostHeader .wrapper .SocialPostHeader-left .ms-Persona-details .meta.system-status-meta {
  margin-top: -2px;
}
.SocialPostHeader .wrapper .SocialPostHeader-left .user-icon {
  display: inline-block;
  top: 1px;
  position: relative;
}
.SocialPostHeader .wrapper .UserHovercard-wrapper {
  max-width: calc(100% - 30px);
  flex-grow: 1;
}
.SocialPostHeader .wrapper .UserHovercard-wrapper .ms-Persona {
  max-width: 100%;
  height: auto !important;
  min-height: 36px;
}
.SocialPostHeader .wrapper .ms-Persona-primaryText {
  font-family: Eloquia, sans-serif;
  font-size: 12px;
  font-weight: 550;
  color: #22234A;
  line-height: 20px;
  white-space: normal;
  overflow: visible;
}
.SocialPostHeader .wrapper .ms-Persona-primaryText > div > div > * {
  display: inline;
  vertical-align: top;
}
.SocialPostHeader .wrapper .ms-Persona-primaryText .type-text {
  font-weight: 400;
}
.SocialPostHeader .wrapper .ms-Persona-primaryText .company-name {
  height: 14px;
  line-height: 14px;
  padding: 0 3px;
  margin-left: 3px;
  border-radius: 10px;
}
.SocialPostHeader .wrapper .ms-Persona-primaryText .company-name span {
  letter-spacing: normal;
  margin: 0 3px;
}
.SocialPostHeader .wrapper .ms-Persona-secondaryText {
  color: #475267;
  font-family: Eloquia, sans-serif;
  font-size: 12px;
}
.SocialPostHeader .wrapper .ms-Persona {
  height: auto !important;
}
.SocialPostHeader .wrapper .ms-Persona .meta > a:after {
  content: "·";
  padding: 0 3px;
}
.SocialPostHeader .wrapper .ms-Persona .meta > a:last-of-type:after {
  content: "";
  padding: 0;
}
.SocialPostHeader .wrapper .user-icon {
  margin-left: 6px;
}
.SocialPostHeader .wrapper .user-image {
  position: relative;
}
.SocialPostHeader .wrapper .user-image .system-status-indicator {
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  bottom: -6px;
  left: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SocialPostHeader .wrapper .user-image .system-status-indicator.done {
  background-color: #20e3aa;
}
.SocialPostHeader .wrapper .user-image .system-status-indicator.ongoing {
  background-color: #fc5371;
}
.SocialPostHeader .wrapper .user-image .system-status-indicator.planned {
  background-color: #f1ae15;
}
.SocialPostHeader .wrapper .user-image .system-status-indicator .Visage2Icon {
  line-height: 1;
  font-size: 16px;
  width: 16px;
  height: 16px;
}
.SocialPostHeader .wrapper .user-image .system-status-indicator .Visage2Icon svg {
  width: 16px;
  height: 16px;
}
.SocialPostHeader .wrapper .PostType {
  margin-top: 2px;
}
.SocialPostHeader .wrapper .PostType > * {
  display: inline-block;
  vertical-align: middle;
}
.SocialPostHeader .wrapper .PostType .Visage2Icon {
  margin-left: 6px;
  margin-right: 12px;
  height: 20px;
  vertical-align: -5px;
}
.SocialPostHeader .wrapper .PostType .Visage2Icon svg {
  height: 20px;
}
.SocialPostHeader .wrapper .PostType .Visage2Icon svg path {
  fill: #FFF;
}
.SocialPostHeader .wrapper .PostType .Visage2Icon svg path:first-of-type {
  fill: #787CDD;
}
.SocialPostHeader .wrapper .SocialPostHeader-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 3px;
}
.SocialPostHeader .wrapper .SocialPostHeader-right .pin-header.Visage2Icon {
  transform: rotate(135deg);
}
.SocialPostHeader .wrapper .SocialPostHeader-right .actionmenu-component {
  display: block;
}
.SocialPostHeader .wrapper .SocialPostHeader-right .actionmenu-component.open {
  display: block;
}
.SocialPostHeader .verify-question-box {
  background-color: #F8F8FF;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 12px 12px;
  display: flex;
}
.SocialPostHeader .verify-question-box > * {
  display: inline-block;
  vertical-align: middle;
}
.SocialPostHeader .verify-question-box > .label-component {
  margin-right: 12px;
  flex-grow: 1;
  line-height: 25px;
}
.SocialPostHeader .verify-question-box div {
  min-width: 123px;
  text-align: right;
}
.SocialPostHeader .verify-question-box div > button {
  background-color: #EAEAEA;
  border: none;
  font-size: 12px;
  height: auto;
  min-width: 0px;
  padding: 6px 12px;
  margin-right: 12px;
  display: inline-block;
}
.SocialPostHeader .verify-question-box div > button span {
  font-weight: 550;
}
.SocialPostHeader .verify-question-box div > button .ms-Button-label {
  margin: 0px;
}
.SocialPostHeader .verify-question-box div > button:last-of-type {
  margin-right: 0px;
}
.SocialPostHeader .verify-question-box div > button:hover {
  opacity: 0.75;
}
.SocialPostHeader .target > .Visage2Icon {
  display: inline;
  margin: 0 4px;
}
.SocialPostHeader .target > .Visage2Icon span, .SocialPostHeader .target > .Visage2Icon a {
  font-size: 14px;
}
.SocialPostHeader .target > .Visage2Icon div {
  display: inline;
}
.SocialPostHeader .target > .Visage2Icon div svg {
  position: relative;
  top: 2px;
}
.SocialPostHeader .SocialPostHeader-TargetUser > i {
  font-size: 9px;
  margin-left: 6px;
  margin-right: 6px;
}

.system-status-tooltip {
  padding: 16px;
}
.system-status-tooltip .SocialSystemStatus {
  margin-top: 16px;
}