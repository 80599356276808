@import "src/style/variables.scss";

.TagInput {
    cursor: text;
    position: relative;
    
    > .input-area {
        border: 1px solid color("dusk-grey");
        border-radius: $borderRadius;
        max-width: 500px;
        padding: $gutterXSmall 8px 0;
        width: 100%;
    }

    &.focus {
        > .input-area {
            box-shadow: #5B9DD9 0 0 1px 1px;
        }
    }

    .tags {
        margin: 0;
        padding: 0;

        > li {
            display: inline-block;
            vertical-align: middle;

            &.input-element {
                margin-bottom: $gutterXSmall;
                position: relative;
            }
        }

        .tag-input {
            background-color: transparent;
            border: 0 none;
            outline: 0 none;
        }

        .shadow {
            display: inline-block;
            top: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            white-space: pre;
            z-index: -1;
        }
    }

    .autocomplete-layer {
        width: 300px;
        
        .suggestions {
            list-style: none;
            margin: 0;
            padding: 4px;
        }
    }
}