@import "src/style/variables.scss";

.SocialComposerFooter {
    
    .post-button {
        background-color: #3F4692;
        border-radius: 12px;
        mix-blend-mode: normal;
        padding: $gutterSmall;

        color: white;
        font-size: 16px;
        font-weight: 550;
        line-height: 1.5em;
        text-align: center;
        transition: margin 0.3s;

        &:hover {
            margin: 0px -3px;
            opacity: 0.98;
        }
    }
}