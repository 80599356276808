@import "src/common.scss";

.SidebarContentListBox {
    .SidebarContentListBox-list {
        list-style: none;
        margin: 0;
        padding: 0;

        .SidebarContentListBox-item {
            align-items: center;
            border-radius: $brNormal;
            display: flex;
            gap: $spacingSmall;
            margin-bottom: $spacingTiny;
            padding: $spacingTiny;
            min-height: 32px;
            box-sizing: border-box;

            .SidebarContentListBox-actionMenu{
                margin-left: auto;
            }

            .StandardActionMenu{
                .Visage2Icon{
                    svg {
                        rotate: 90deg !important;
                    }
                }
            }

            &:hover {
                background-color: $spintrBlueLighter;

                .SidebarContentListBox-text {
                    color: $spintrContentLight;
                }
            }
        }
    }
}