@import "src/style/variables";

.pageHeader.smallViewMode {
    .pageHeader-title {
        // width: 96%;
        word-break: break-word;
        .Text {
            &.fs-headingExtraLarge {
                font-size: 24px;
            }
        }
    }
}

.pageHeader {
    position: relative;
    margin-bottom: $gutterMedium;

    >*:first-child {
        flex-grow: 1;
        // min-width: 300px;
    }

    // > * {
    //     max-width: 100%;

    //     > * {
    //         max-width: 100%;
    //     }
    // }

    .pageHeader-title {
        h1 {
            margin-top: 0;
            margin-bottom: 0;
            // display: inline-block;
            margin-right: $gutterSmall;

            .actionmenu-component {
                position: relative;
                left: $gutterXSmall;
                top: -3px;
                vertical-align: middle;
            }
        }

        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gutterSmall;

        .pageHeader-title-left {
            flex-grow: 1;
            column-gap: $gutterXSmall;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            .spacer {
                flex-grow: 1;
            }
        }

        .pageHeader-title-right {
            column-gap: $gutterSmall;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .pageHeader-subText {
        padding-top: $gutterXSmall;
    }

    .pageHeader-hits {
        margin-top: $gutterXSmall;
    }

    .SpintrHelpText {
        display: block;
        margin-top: $gutterXSmall;
        margin-bottom: 0px !important;
    }

    .StandardActionMenu {
        max-height: none;
        max-width: none;
        height: 36px;
    }

    .action-menu-wrapper {
        max-height: none;
        max-width: none;
        height: 36px;
    }
}

// @media only screen and (max-width: 1023px) {
//     .pageHeader {
//         .CalypsoButton {
//             width: 36px;
//             min-width: 36px;

//             .Text {
//                 display: none;
//             }
//         }
//     }
// }