@import "src/style/variables";

#StartPageEditView {
    padding: 0 $gutterLarge 80px (66px + $gutterLarge);

    &.editing {
        .grid-wrapper {
            // max-width + column padding + border
            max-width: 1575px + (30px * 2) + 3px;
        }
    }

    .grid-wrapper {
        padding: $gutterXXLarge * 1.25 0;
        max-width: 1575px + 56px;
        margin: 0 auto;
    }

    .FloatingFooterBar {
        transition: all 0.25s ease-in-out;
        left: 66px;
    }

    .FormFooterBar {
        left: 66px;
        //transition: none;

        .ms-Dropdown-container {
            > * {
                display: inline-block;

                &.ms-Dropdown {
                    width: 300px;
                }
            }
        }
    }

    &.drawer-open {
        .FloatingFooterBar {
            left: 476px;
        }

        .FormFooterBar {
            left: 476px;
            transition: all 0.25s ease-in-out;
        }
    }

    .StartPageView.responsive {
        .SpintrStartViewResponsivePivot {
            .ms-Pivot > button {
                width: auto;
            }
        }
    }
}
