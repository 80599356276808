@import "src/style/variables.scss";

.SplitForm {
    margin: -$gutterXXLarge;

    .ms-Stack {
        // overflow: hidden;

        .ms-StackItem {
            border-left: 1px solid color("borderColor");
            border-top: 1px solid color("borderColor");
            margin-top: -1px;
            margin-left: -1px;
        }
        .ms-Stack-inner {
            flex-flow: nowrap;
        }
    }

    .main-form {
        padding: $gutterXXLarge;
    }

    .additional-settings {
        padding: $gutterXXLarge;

        .lower-additional-settings {
            margin-top: $gutterXXLarge;
        }
    }
}

.SplitForm {
    @media screen and (max-width: $vmTabletPortrait) {
        .ms-StackItem {
            width: 100%;
            flex-basis: 100% !important;
        }
        .ms-Stack-inner {
            flex-flow: wrap !important;
        }
    }
}
