@import "src/style/variables";

.FieldCompletion {
    position: relative;

    .button-wrapper {
        position: absolute;
    }

    .FieldCompletion-button {
        &.default {
            background-color: color("light-grey");
            border-radius: $borderRadiusSharper;
            box-sizing: border-box;
            color: #000;
            height: 30px;
            padding: $gutterTiny $gutterSmall;
            text-align: center;
            
            &.loading {
                padding: 5px 3px;

                .small-bubble, .medium-bubble {
                    display: none;
                }

                .large-bubble {
                    background-color: transparent;
                    height: auto;
                    width: auto;

                    .chat-dots {
                        left: unset;
                        position: relative;
                        top: unset;
                        transform: scale(0.75);
                    }

                }
            }
        }
    }

    .ms-TextField {
        input, textarea {
            padding-right: 150px !important;
        }
    }
}