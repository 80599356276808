@import "src/style/variables.scss";

.SystemStatusResourceInfo {
    .title-row {
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;
        align-items: center;
    }
}
