@import "src/style/variables";

.AcademyCourseSidebarProgressCircleSmall {
    &.idle {
        svg {
            path {
                &:last-child {
                    display: none;
                }
            }
        }
    }
}
