@import "src/style/variables";

.MessageRead {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button {
        column-gap: $gutterXSmall;
        display: flex;
        flex-direction: row;

        .users {
            display: flex;
            flex-direction: row;
            column-gap: $gutterTiny;

            .ms-Persona-initials {
                font-size: 8px;
                font-weight: 500;
            }

            .more {
                height: 16px;
                min-width: 16px;
                border-radius: 16px;
                background-color: color(visage2LightGray);
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;

                .label-component {
                    margin: 0 $gutterTiny;
                }
            }
        }

        .ticks {
            height: 16px;

            > div {
                top: -3px;
                position: relative;
            }
        }
    }
}
