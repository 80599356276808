@import "src/style/variables";

#ResponsiveHeader {
    background-color: #FFF;
    height: 31px;
    left: 0;
    line-height: 31px;
    padding: $gutterMedium;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $spintrHeaderZIndex;

    .wrapper {
        display: flex;

        .menu-wrap,
        .search-wrap,
        .profile-wrap {
            i {
                user-select: none;
            }
        }

        .right-buttons-wrap {
            display: flex;
            flex: 0 0 16px;

        }

        .search-wrap {
            display: flex;
            align-items: center;
            height: 32px;
            padding: 0 6px 0 8px;
        }

        .menu-wrap {
            flex: 0 0 34px;
            align-self: center;
            flex-shrink: 1;

            i {
                color: color("dark-grey");
                font-size: 25px;
            }

            .menu-button {
                position: relative;

                .unread-indicator {
                    background-color: rgb(240, 40, 73);
                    border-radius: 100%;
                    bottom: 6px;
                    height: 12px;
                    position: absolute;
                    right: -6px;
                    width: 12px;
                }
            }
        }

        .logo-wrap {
            flex: 1 1 calc(100% - 128px);
            text-align: center;

            a {
                display: block;

                img {
                    max-width: 100%;
                    max-height: 32px;
                }
            }
        }

        .profile-wrap {
            cursor: pointer;
            padding: 0 8px 0 8px;
        }

        .shortcuts-wrap {
            .shortcutsButton:hover svg {
                animation-name: pulse;
                animation-duration: 0.5s;
            }

            .shortcutsButton:hover {
                opacity: 0.75;
            }

            .shortcutsButton {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
            }
        }
    }

    &.hasDarkBackground {
        .search-wrap, .menu-wrap, .shortcuts-wrap {
            .Visage2Icon {
                svg {
                    path {
                        fill: #FFF;
                    }
                }
            }
        }
    }

    .notches {
        .notch-wrapper {
            height: 16px;
            width: 16px;
            top: 63px;
            position: fixed;

            .notch-inner {
                width: 100%;
                height: 100%;
                background-color: color(backgroundColor);

                &.white {
                    background-color: #FFF;
                }
            }

            &:first-child {
                left: 0px;

                .notch-inner {
                    border-top-left-radius: 16px;
                }
            }

            &:not(:first-child) {
                right: 0px;

                .notch-inner {
                    border-top-right-radius: 16px;
                }
            }
        }
    }
}
