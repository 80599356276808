@import "src/style/variables";

.PhotoBox {
    .PhotoBox-inner {
        max-height: 90vh;
        max-width: 90vw;
        height: 90vh;
        width: 90vw;
        display: inline-block;
        vertical-align: top;

        .PhotoBox-photo-wrapper {
            background-color: #000;
            height: 100%;
            width: 100%;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            position: relative;

            .SpintrLoader {
                display: inline-block;
            }

            .helper {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }
            
            img {
                vertical-align: middle;
                max-width: 100%;
                max-height: 100%;
            }

            .PhotoBox-hover-actions {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;

                .PhotoBox-arrow-left, .PhotoBox-arrow-right {
                    height: 100%;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    width: 30px;
                    background-color: rgba(0, 0, 0, 0.5);
                    pointer-events: auto;

                    .helper {
                        display: inline-block;
                        height: 100%;
                        vertical-align: middle;
                    }

                    .unstyled-button {
                        vertical-align: middle;
                        font-size: 30px;
                    }
                }

                .PhotoBox-arrow-left {
                    left: 0;
                }

                .PhotoBox-arrow-right {
                    right: 0;
                }

                .PhotoBox-footer {
                    position: absolute;
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    left: 0;
                    bottom: 0;
                    padding: $gutterXSmall $gutterSmall;
                    box-sizing: border-box;
                    text-align: left;
                    pointer-events: auto;
                    display: flex;
                    flex-direction: row;

                    .PhotoBox-footer-left {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: row;
                        column-gap: $gutterXSmall;
                        align-items: center;
                    }
                }
            }
        }

        .PhotoBox-photo-wrapper:hover {
            .PhotoBox-hover-actions {
                display: block;
            }
        }

        .PhotoBox-social {
            width: calc(300px - #{2 * $gutterSmall});
            display: inline-block;
            vertical-align: top;
            padding: $gutterSmall;

            .PhotoBox-user {
                margin-bottom: $gutterSmall;
            }

            .PhotoBox-social-text {
                margin-bottom: $gutterSmall;
            }
        }
    }

    .PhotoBox-close-button {
        position: absolute;
        top: 0;
        right: 0;
        color: #FFF;
        padding: $gutterSmall;
        top: -4px;
    }
    .PhotoBox-download-button {
        position: absolute;
        bottom: 0;
        right: 0;
        color: #FFF;
        padding: $gutterSmall;
    }

    .PhotoBox-menu-wrapper {
        position: absolute;
        top: 7px;
        right: 34px;
    }

    .PhotoBox-EditButtons {
        margin-top: $gutterSmall;
    }

    &.PhotoBox-displaySocial {
        .PhotoBox-photo-wrapper {
            display: inline-block;
            width: calc(100% - 300px);
        }
        
        .PhotoBox-close-button {
            color: color("dark-grey");
        }
    }

    &.isSmallViewMode {
        .PhotoBox-photo-wrapper {
            width: 100%;
        }

        .PhotoBox-social {
            width: 100%;
            box-sizing: border-box;
            position: relative;
        }
    }
}
