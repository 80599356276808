@import "src/style/variables";

.ComposerMemoTodoItem {
    .top-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gutterXSmall;

        > .Visage2Icon {
            svg {
                > path {
                    &:not(:first-child) {
                        display: none;
                    }
                }
            }
        }

        .ms-TextField {
            flex-grow: 1;

            .ms-TextField-fieldGroup {
                height: 20px;
                max-height: 20px;
                min-height: 20px;
    
                .ms-TextField-field {
                    padding: 0px;
                    margin: 0px;
                    height: 20px;
                    max-height: 20px;
                    min-height: 20px;
                    border-radius: 0px;
                }
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gutterSmall;
        margin-top: $gutterXSmall;
        margin-left: $gutterLarge;

        .users {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: $gutterXSmall;

            .user {
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: $gutterTiny;
                background-color: color(visage2LightGray);
                border-radius: 12px;

                .unstyled-button {
                    margin-right: $gutterTiny;
                }
            }

            .user-add {
                background-color: color(visage2LightGray);
                border-radius: 24px;
                height: 24px;
                width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;

                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }
}

.users-selection {
    .FormSection {
        margin-bottom: 0px !important;
    }

    .ms-Callout-main {
        padding: $gutterSmall;
        max-width: 500px;
    }
}
