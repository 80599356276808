@import "src/style/variables.scss";

.GroupView {
    .GroupView-content {
        min-height: calc(100vh - #{$spintrHeaderHeight} - #{$gutterSmall} - #{$gutterSmall});
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }

    .groupWrap {
        flex-grow: 1;
        display: flex;

        .GroupContentView  {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            border-top: 1px solid color(borderColor);
        }
    }

    .expanded {
        .sidebar {
            height: calc(100vh - #{$spintrHeaderHeight} - #{$gutterSmall} - #{$gutterSmall});
            box-sizing: border-box;

            > * {
                height: 100%;
                box-sizing: border-box;
            }
        }
    }
}

.GroupPageView {
    width: 100%;
    margin: $gutterSmall;
    margin-top: 0px;
    padding-top: $gutterSmall;
    position: relative;
    border-top: 1px solid color(borderColor);

    .ms-Breadcrumb {
        margin-top: 0;
    }
}

.GroupFilesView {
    .inner{
        .content{
            margin-left: 0;
        }
    }

    .file-details {
        height: auto !important;
    }

}

.calendarActionMenu{
    margin-left: $gutterMedium;
}

.GroupMenu {
    > div {
        max-width: 100%;
    }
}

.SpintrMainContentContainer {
    &.hasPrioritySystemStatuses {
        .GroupView {
            min-height: calc(100vh - #{$spintrHeaderHeight} - #{$prioritySystemStatusesHeight});
        }
    }
}

@media screen and (max-width: $vmPhoneLandscape) {
    .GroupView {
        min-height: calc(100vh - #{$spintrResponsiveHeaderHeight} - #{$spintrResponsiveFooterHeight});
    }

    .SpintrMainContentContainer {
        &.hasPrioritySystemStatuses {
            .GroupView {
                min-height: calc(100vh - #{$spintrHeaderHeight} - #{$prioritySystemStatusesHeight} - #{$prioritySystemStatusesHeight});
            }
        }
    }
}

@media screen and (max-width: $vmTabletLandscape) {
    .GroupView {
        .CalypsoContentWithSidebar {
            &.expanded {
                .sidebar {
                    top: 63px;
                }
            }
        }
    }
}

