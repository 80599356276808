@import "src/style/variables";

.ComposerMemoHeader {
    display: flex;
    flex-direction: row;
    column-gap: $gutterXSmall;
    align-items: center;
    border-bottom: 1px solid color(borderColor);
    padding-bottom: $gutterSmall;

    .inner {
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;
        align-items: center;
        flex-grow: 1;
    }

    .unstyled-button {
        &:hover {
            opacity: 0.75;
        }

        > * {
            pointer-events: none;
        }
    }
}
