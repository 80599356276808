@import "src/style/variables.scss";

.profile-settings{
hr{
    margin-top: 16px;
    color: color("light-grey");
}

    .ms-Checkbox-label{
        margin-top:6px;
    }
    .label-component{
        margin-top:12px;
    }
 
    .profile-image img {
        max-width: 200px;
        object-fit: contain;
    }

    .coverImagePreview {
        img {
            max-width: 100%;
        }
    }

    .strength-meter {
        .meter {
            width: 300px;
        }
    }
}
