@import "src/style/variables";

$totalRigthPanelWidth: $rightPanelWidth + $gutterLarge;

.SpintrMainContentContainer {
    flex-basis: auto;
    display: flex;
    flex-direction: row;
    padding: $gutterSmall;

    &.supplier-portal {
        margin: 0 auto;
    }

    &.appMode {
        flex-direction: column;
        max-width: 1300px;
        margin: 0 auto;

        .main-content-wrapper {
            max-width: 1300px;
        }
    }

    .contentMiddle {
        max-width: 970px;
        margin: 0 auto;

        .contentMiddle2 {
            max-width: 770px;
            margin: 0 auto;
        }

        .ms-Breadcrumb {
            margin-top: 0px;
        }
    }

    .ms-Breadcrumb {
        .ms-Link {
            display: block !important;
        }
    }

    .main-content-wrapper,
    .right-column-wrapper {
        display: inline-block;
        vertical-align: top;
    }

    .main-content-wrapper {
        width: 100%;
        position: relative;
    }

    .main-content-wrapper {
        &.main-content-wrapper-background {
            background-color: #FFF;
            box-sizing: border-box;
            padding: $gutterXXLarge;
            border-radius: $borderRadius;
        }

        &.no-padding {
            padding: 0;
        }

        &.smaller-padding {
            padding: $gutterMedium;
        }
    }

    .right-column-wrapper {
        width: $rightColumnMinWidth;
        min-width: $rightColumnMinWidth;
        margin-left: $gutterLarge;

        h4 {
            text-transform: uppercase;
        }

        .RightColumn {
            [role="toolbar"] {
                [role="tablist"] {
                    margin-bottom: 1px;
                }

                .headerFavouriteBox {
                    display: none;
                }
            }
        }

        .headerFavouriteBox {
            border-bottom: none;

            h4 {
                margin-bottom: 0;
                margin-top: 0;
            }
        }

        .favouriteBox {
            .eachShortcutContainer {
                border-bottom: none;
                margin-bottom: 2px;
            }

            .eachFavourite {
                border-bottom: 0;
                margin-bottom: 2px;
                margin-top: 2px;

                .textEachFavourite {
                    font-size: 14px;
                }
            }
        }

        .teaser-box {
            min-width: 100% !important;
        }

        .birthdayBox {
            p {
                font-size: 14px;
                /*font-family: $secondaryFont;*/
            }

            .headerBirthdayBox {
                h4 {
                    margin-bottom: 0;
                }

                border-bottom: 1px solid color("dusk-grey");
            }
        }
    }

    &.full-width {
        padding: 0 !important;
        max-width: none;
    }

    &.fullscreenMode {
        padding: 0 !important;
        max-width: none;
        background-color: transparent;

        .SystemStatusesPrioView {
            margin-top: $gutterXXLarge;
            margin-bottom: 0;
        }

        &.hasFormFooterBar {
            .main-content-wrapper {
                > div {
                    padding-bottom: $formFooterBarContentPaddingBottom !important;
                }
            }
        }
    }
}

@media screen and (max-width: $vmPhoneLandscape) {
    .SpintrMainContentContainer {
        padding: $gutterMedium $gutterMedium 0;

        // .contentMiddle {
        //     padding: 0 $gutterSmall $gutterSmall $gutterSmall;
        // }

        .main-content-wrapper {
            width: 100%;
        }
    }

}

@media screen and (max-width: $vmPhoneLandscape) {
    .SpintrMainContentContainer.appMode {
        padding: $gutterSmall $gutterMedium 0;

        .main-content-wrapper {
            width: 100%;
        }

        .contentMiddle {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }
}

@media screen and (max-width: $vmPhonePortrait) {
    @supports (selector(html:has(body))) {
        .contentWrap:has(.BlogsRootView,.BlogsView,.BlogsPostView,.PageView,.NewsArticleView) {
            background-color: #fff;

            .visage-box-shadow:has(.BlogsRootView,.BlogsView,.BlogsPostView,.PageView,.NewsArticleView) {
                box-shadow: none;
            }

            &:not(.customDesign) {

                .contentWIthInfoPanelLeft,
                .contentWIthInfoPanelRight {
                    padding: 0px !important;
                }
            }

            .SpintrMainContentContainer .main-content-wrapper.main-content-wrapper-background {
                padding: 0px;
            }

            .fs-big {
                font-size: 28px !important;
            }

            .fs-h2 {
                font-size: 20px !important;
            }
        }
    }
}