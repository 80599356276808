@import "src/style/variables";

$panelWidth: 300px;

#MessagesRootView {
    height: calc(100vh - #{$spintrHeaderHeight} - #{$gutterSmall} - #{$gutterSmall});
    display: flex;
    margin: $gutterSmall;
    background-color: #FFF;
    border-radius: $borderRadius;
    box-sizing: border-box;

    > .conversation-panel {
        border-right: 1px solid color("smoke");
        flex-shrink: 0;
        width: $panelWidth;
    }

    > .conversation-main {
        flex-grow: 1;
        width: calc(100% - #{$panelWidth});
    }
}

@media screen and (max-width: $vmPhoneLandscape) {
    #MessagesRootView {
        display: block;

        > .conversation-panel {
            border-right: 0 none;
            width: 100%;
        }

        > .conversation-main {
            background-color: #FFF;
            bottom: 0;
            display: block;
            left: 100%;
            position: fixed;
            right: 0;
            top: $responsiveHeaderHeight;
            transition: left ease-in-out 250ms;
            width: 100%;

            &.active {
                left: 0;
            }
        }
    }
}