@import "src/style/variables";

.DateVariableInput {
    margin-bottom: $gutterMedium;

    .ms-Stack {
        > :not(.ms-StackItem) {
            flex: 1 1 30%;
        }
    }
}