@import "src/style/variables.scss";
.NotificationSidebarGroup{
    .header{
        color: color("dark-grey") !important;
    }
}

.NotificationItem {
    padding: $gutterXSmall $gutterLarge;
    -webkit-transition: background-color $fastTransition;
    -moz-transition:  background-color $fastTransition;
    -o-transition:  background-color $fastTransition;
    transition:  background-color $fastTransition;
    
    &:hover{
        background-color: color("light-grey");

        .item > .information > .lower > .read-toggle {
            display: inline-block;
        }
    }
    
    &:first-child {
        margin-top: $gutterSmall;
    }

    .upper {
        > * {
            display: inline-block;
            vertical-align: middle;
        }

        > p {
            width: calc(100% - 20px);
        }
    }

    .NotificationItem-image, .information, .unreadDot {
        display: inline-block;
        vertical-align: middle;
    }

    .NotificationItem-image {
        vertical-align: top;
        margin-top: 5px;
        margin-right: $gutterSmall;
    }

    .item {
        > .image,
        > .information,
        > .actions {
            display: inline-block;
        }

        > .image {
            margin-right: 6px;

            > .container {
                border-radius: 100%;
                overflow: hidden;
            }
        }

        > .information {
            width: calc(100% - 44px);
            
            > .text {
                display: block;
                color: color("dark-grey");

                .bold {
                    color: #4e4e4e;
                    font-weight: bold;
                }
            }

            .upper .unreadDot {
                margin-left: 5px;
            }

            > a {
                color: color("light-blue");
            }

            > .lower {
                height: 15px;
                margin-top: 2px;

                > * {
                    display: inline-block;
                    vertical-align: top;
                    height: 15px;
                }

                > .date {
                    color: color("dark-grey");
                    width: calc(100% - 20px);
                }
            
                .read-toggle {
                    display: none;
                    height: 15px;

                    .Visage2Icon {
                        height: 15px;
                        margin-top: -2px;
                    }
                }
            }
        }

        > .date {
            color: color("dark-grey");
            .label-component{
                font-weight: 400;
            }
        }
    }

    > .actions {
        display: none;
        text-align: right;
        width: 18px;
        margin-right: -6px;

        > .actions {
            display: none;
            text-align: right;
            width: 18px;
            margin-right: -6px;

            .markAsRead {
                color: #999;
                font-size: 12px;
                height: 18px;
                line-height: 1.125em;

                &:hover {
                    color: #333;
                }
            }
        }
    }

    .GroupInvitationNotification {
        display: flex;
        flex-direction: column;
        row-gap: $gutterXSmall;

        .actions {
            display: flex;
            flex-direction: row;
            column-gap: $gutterSmall;
        }
    }
}