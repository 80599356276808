@import "src/style/variables";

.PinnedMessage {
    background-color: color(visage2LightGray);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $gutterSmall $gutterSmall;
    column-gap: $gutterXSmall;
    margin-top: -4px;

    .left-text {
        white-space: nowrap;
    }

    .line {
        width: 1px;
        height: 16px;
        background-color: #A7A7B5;
        margin: 0 $gutterTiny;
    }

    .preview-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 1px;
    }

    .spacer {
        flex-grow: 1;
    }

    .actionmenu-component {
        opacity: 0;
    }

    &:hover {
        opacity: 0.75;

        .actionmenu-component {
            opacity: 1;
        }
    }
}