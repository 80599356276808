@use "sass:math";
@import "src/marketplace/variables";
@import "src/style/variables";

$logoSize: 48px;
$width: $catalogItemWidth - ($gutterXXLarge * 2) - 2px;

.MarketplaceListItem {
    border: 1px solid color("borderColor");
    border-radius: $borderRadius;
    display: inline-block;

    .body {
        border-bottom: 1px solid color("borderColor");
        padding: $gutterXXLarge;
        width: $width;

        .heading {
            padding-bottom: $gutterXXLarge;

            .logo, .name {
                display: inline-block;
                vertical-align: middle;
            }

            .logo {
                height: $logoSize;
                margin-right: $gutterSmall;
                width: $logoSize;
            }
        }

        .description {
            min-height: 42px;
        }
    }
    
    > .link {
        display: inline-block;
        padding: $gutterMedium $gutterXXLarge;
    }
}