@import "src/style/variables";

$faviconSize: 16px;

.SocialBookmark, .bookmark {
    border-radius: $borderRadius;

    &.bookmark {
        background-color: color(bookmarkColor);
    }

    > .text {
        margin-bottom: $gutterSmall;
    }

    .frame-wrapper {
        border-radius: $borderRadius;
        height: 0;
        overflow: hidden;
        padding-bottom: 52.75%;
        padding-top: $gutterXLarge;
        position: relative;

        &.spotify-height {
            height: 80px;
            padding: 0;
        }

        iframe {
            bottom: 0;
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
    }

    .youtube {
        iframe {
            height: 250px;
        }
    }

    .standard {
        .bookmark-image {
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
            width: 100%;
            max-height: 300px;
            overflow: hidden;
            text-align: center;

            img {
                margin: 0 auto;
                max-width: 100%;
            }
        }

        .bookmark-title {
            padding: $gutterSmall $gutterMedium $gutterMedium;
            box-sizing: border-box;

            a {
                text-decoration: none !important;
            }

            .bookmark-domain {
                text-transform: uppercase;
            }

            .icon, .url {
                display: inline-block;
                vertical-align: middle;
            }

            .icon {
                height: $faviconSize;
                line-height: $faviconSize;
                margin-right: $gutterXSmall;
                width: $faviconSize;

                img {
                    max-height: $faviconSize;
                    max-width: $faviconSize;
                }
            }

            .url-wrapper {
                margin-top: $gutterTiny;
            }

            .url {
                width: 100%;

                a {
                    display: inline-block;
                    vertical-align: middle;
                    width: 100%;
                }

                > div {
                    margin-top: $gutterXSmall;
                }
            }
        }

        &.with-image {
            .bookmark-title {
                width: 100%;
            }
        }
    }
}