@import "src/style/variables.scss";

.spintr-list {
    &.isEmpty {
        .ms-DetailsList-contentWrapper {
            .ms-FocusZone {
                // display: none; on contentWrapper displays scrollbar in IE11
                display: block;
                background-color: color(light-grey);
            }
        }
    }

    &.disableSort {
        i[data-icon-name="SortUp"],
        i[data-icon-name="SortDown"] {
            display: none;
        }
    }

    .ms-List-cell {
        border-bottom: 1px solid color(borderColor);
    }
}

.spintr-list-empty-list {
    .spintr-list-empty-list-label {
        padding: $gutterMedium;
        text-align: center;
        font-weight: 400;
        background-color: #fff;
    }
}

.ms-DetailsRow {
    &.is-selected {
        background-color: color(visage2LightGray);
    }
}

.textNoTodo {
    background-color: color(light-grey);
    padding: 16px;
    text-align: center;
}

.ms-DetailsHeader{
    padding-top:0;
    padding-bottom: 0px;
    border-bottom: 0;
    background-color: color(visage2LightGray);
    height: 32px;
    line-height: 32px;

    .ms-DetailsHeader-cell {
        height: 32px;
        background-color: color(visage2LightGray);

        &:active, &:hover {
            background-color: color(visage2LightGray);
        }
    }
}

.ms-DetailsRow-cell {
    > .ms-Link {
        color: color(light-blue) !important;
    }

    .ms-DetailsRow-check {
        height: auto;
        top: 1px;
    }
}
