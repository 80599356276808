@import "src/style/variables.scss";

.SystemStatusTypePill {
    display: flex;
    flex-direction: row;
    column-gap: $gutterXSmall;
    align-items: center;
    border-radius: $borderRadiusSharp;
    border: 1px solid color(orange);
    padding: 0px $gutterSmall;
    height: 36px;
    white-space: pre;

    &.red {
        border-color: color(red);
    }

    &.green {
        border-color: color(spintrGreen);
    }

    &.orange {
        border-color: color(orange);
    }

    &.dark-grey {
        border-color: color(borderColor);
    }

    &:not(.big) {
        padding: 0px $gutterXSmall;
        border-radius: $borderRadiusSharper;
        height: 20px;
    }
}

.SystemStatusTypePill-dot {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    min-width: 10px;

    &.red {
        background-color: color(red);
    }

    &.green {
        background-color: color(spintrGreen);
    }

    &.orange {
        background-color: color(orange);
    }
}