@import "src/common.scss";

$width: 256px;
$height: 204px;

.PrioritizedWiki {
    background-color: $spintrGrayLight;
    border-radius: $brNormal;
    overflow: hidden;
    position: relative;

    &:hover {
        opacity: 0.9;
    }

    .PrioritizedWiki-link {
        display: block;
    }

    .actions {
        position: absolute;
        right: $spacingMedium;
        top: $spacingMedium;
    }

    .PrioritizedWiki-title {
        padding-right: 28px;
    }

        .PrioritizedWiki-body {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    .PrioritizedWiki-content {
        box-sizing: border-box;
        gap: $spacingSmall;
        min-height: $height;
        padding: $spacingMedium;
        width: $width;

        &.hasImage {
            background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        }
    }
}