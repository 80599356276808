@import "src/style/variables.scss";

.draggable-demo {
  .activeItem{
    background-color: color("visageMidBlueBackground");
    border-radius: 8px;
    color: color("mid-grey");
  } 

  &.disable-drop-on-item {
    .rc-tree li.drag-over > .draggable {
      // background-color: initial !important;
      opacity: 1;
      border-left-width: 0px;
      border-color: transparent;
    }
  }

  .categoryTitle {
    pointer-events: none;
  }

  .draggable-container {
    > ul {
      display: flex;

      > li {
        flex-grow: 1;
      }
    }
  }

  &.hide-unplaced-items {
    .draggable-container {
      > ul {
        > li {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}

.rc-tree-treenode-disabled {
  .rc-tree-node-content-wrapper {
    cursor: default !important;
  }
}

.rc-tree {
  padding: 0 !important;
}

.rc-tree ul {
  background-color: #FFF;
}

.rc-tree li {
  border-top: 1px solid #FFF !important;
}

.rc-tree-switcher {
  background-image: none !important;
  line-height: 12px !important;
  margin-right: -4px !important;
  height: auto !important;
  vertical-align: middle !important;
  margin-left: 7px !important;
}

.rc-tree-switcher-noop {
  display: none !important;
}

.rc-tree li.drag-over > .draggable {
  background-color: color("visageMidBlueBackground") !important;
  border-radius: 8px !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.rc-tree-node-content-wrapper {
  padding: $gutterXSmall !important;
  height: auto !important;
  vertical-align: middle !important;
  margin-left: $gutterXSmall !important;
  display: inline-block;
  min-width: calc(100% - 50px) !important;
}

.rc-tree-node-selected {
  background-color: transparent !important;
  border: 0 !important;
}

.rc-tree li > .draggable {
  border-top: 2px transparent solid !important;
  border-bottom: 2px transparent solid !important;
}

.rc-tree li.drag-over-gap-top > .draggable {
  border-top: 2px color("visageMidBlueBackground") solid !important;
}

.rc-tree li.drag-over-gap-bottom > .draggable {
  border-bottom: 2px color("visageMidBlueBackground") solid !important;
}

.rc-tree .web-structure-title:last-of-type {
  // margin-top: $gutterMedium !important;
}

.web-structure-title > .rc-tree-switcher {
  display: none !important;
}

.web-structure-title > .rc-tree-child-tree {
  padding-left: 0 !important;
}

.web-structure-title > .rc-tree-node-content-wrapper {
  width: 100%;
  background-color: #FFF !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}
