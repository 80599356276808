@import "src/style/variables";

.NotificationSidebar {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .load-more {
        background:#fff;
        text-align: center;
        border-top: 1px solid color("dusk-grey");
        border-bottom: 1px solid color("dusk-grey");
        &:hover{
            background: color("light-grey")
        }
        > a {
            display: block;
            padding: $gutterSmall;
        }
    }
}
