@import "src/style/variables";

.OAuth2ClientCredentialsInput {
    text-align: center;

    .instructions, .variables {
        text-align: left;
    }

    .variables {
        margin-bottom: $gutterSmall;
    }
}