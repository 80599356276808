.updates {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.SpintrUserMenu {
    >.menuWrap {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 6px 8px;
        border-radius: $borderRadius;

        .ms-Persona {
            flex-grow: 1;

            .ms-Persona-details {
                padding-left: $gutterSmall;
                padding-right: $gutterMedium;
            }
        }

        > .ms-Persona {
            .ms-Persona-coin {
                display: none;
            }
        }

        #submenu-actionmenu {
            height: 20px;
            width: 20px;

            .Visage2Icon {
                position: relative;
                margin-left: -2px;
                height: 20px;
                width: 20px;
            }
        }
    }

    &:hover {
        opacity: 0.75;
    }
}

@media screen and (max-width: 1200px) {
    .SpintrUserMenu {
        >.menuWrap {
            > .ms-Persona {
                display: none;
            }
            
            .actions {
                margin-left: $gutterSmall;
            }
        }
    }
}