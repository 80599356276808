@import "src/style/variables.scss";

.MenuPage {
    .PrioItems {
        margin-bottom: $gutterXXXLarge;
    }

    .menu-items {
        ul {
            width: 100%;
            
            li {
                background-color: #ffffff;
                width: calc(25% - #{2 * $gutterXSmall});
                display: inline-block;
                margin: $gutterXSmall;
            }
        }

        ul,
        li {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        
        .list {
            li {
                a {
                    color: #000;
                    display: block;
                    padding: $gutterSmall $gutterMedium;
                    position: relative;

                    .chevron-icon {
                        position: absolute;
                        right: $gutterMedium;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $vmTabletLandscape) {
    .MenuPage {
        .menu-items {
            ul {
                li {
                    width: calc(50% - #{2 * $gutterXSmall});
                }
            }
        }
    }
}

@media screen and (max-width: $vmTabletPortrait) {
    .MenuPage {
        .menu-items {
            ul {
                li {
                    width: calc(100% - #{2 * $gutterXSmall});
                }
            }
        }
    }
}