@import "src/common.scss";

#ProductSettingsView {
    .ArticleIdPattern {
        border-bottom: 1px solid $spintrGray;
        margin-bottom: $spacingSmall;
        padding-bottom: $spacingSmall;

        .pattern-control {
            padding-right: calc(20px + #{$spacingSmall * 2});
            position: relative;

            .remove-pattern {
                position: absolute;
                right: 0;
                top: 14px;
            }
        }

        .custom-pattern {
            align-items: center;
            display: flex;
            gap: $spacingSmall;
            margin-top: $spacingSmall;
        }
    }

    .add-pattern {
        color: $spintrBlue;
    }
}
