@import "src/common.scss";

$mainMaxWidth: 1080px;

$outlineMinWidth: 280px;

$sidebarMaxWidth: 319px;
$sidebarMinWidth: 250px;

$viewMaxWidth: $mainMaxWidth + $spacingSmall + $sidebarMaxWidth;
.WikiArticleView-drawer {
    .WikiArticleView-header-content {
        margin-bottom: $spacingSmall;

        .WikiArticleView-heading {
            display: none;
        }
    }
}

.WikiArticleView {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: $viewMaxWidth;
    width: 100%;

    .WikiArticleView-stack {
        gap: $spacingSmall;

    #WikiArticleView-section {
        max-width: $mainMaxWidth;
        width: 100%;
    }

    #WikiArticleView-section, .WikiArticleView-sidebar {
        background-color: $spintrWhite;
        border-radius: $brRounder;
        box-sizing: border-box;
        padding: $mainContentBackplatePadding;
        flex: 1 1 auto;

        .open-submenu-button {
            align-items: center;
            display: flex;
            gap: $spacingSmall;
            margin-bottom: $spacingSmall;
            padding-left: $spacingMedium;
        }

        .WikiArticleView-header {
            margin-bottom: $spacingLarge;
            min-width: 320px;
            position: relative;

            .WikiArticleView-navigation {
                margin-bottom: $spacingLarge;

                .wiki-link {
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    gap: $spacingSmall;
                    padding-right: $spacingXLarge;
                }

                .WikiArticleView-breadcrumbs {
                    flex-grow: 1;
                    width: 100%;
                }

                .ms-Breadcrumb {
                    margin-bottom: 0;
                }
            }

            .WikiArticleView-header-content {
                border-bottom: 1px solid $spintrGrayLight;

                .WikiArticleView-heading {
                    margin-bottom: $spacingSmall;
                }

                .WikiArticleView-description {
                    margin-bottom: $spacingLarge;
                }
            }

            .WikiArticleView-header-actions {
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        .WikiArticleView-content {
            gap: $spacingSmall;

            .WikiArticleView-outline {
                position: sticky;
                top: $spacingSmall;

                .menu-wrapper {
                    border: 1px solid $spintrGrayLight;
                    border-radius: $brRounder;
                    box-sizing: border-box;
                    min-width: $outlineMinWidth;

                    .submenu-component {
                        .ms-Nav-groupContent {
                            margin-bottom: 0;
                            padding: $spacingSmall 0;
                        }

                        [role="listitem"] {
                            margin: $spacingSmall 0 $spacingSmall $spacingMedium;

                            .ms-Nav-compositeLink.is-selected .row,
                            .ms-Nav-compositeLink:hover .row {
                                background-color: $spintrBlueLighter;
                                border-radius: $brRounder;

                                &::after {
                                    border-color: transparent;
                                }
                            }

                            .ms-Nav-compositeLink {
                                > button {
                                    height: 32px;
                                    line-height: 34px;

                                    > i {
                                        height: 34px;
                                    }
                                }
                            }

                            .row {
                                padding-left: 0;
                                padding-right: $spacingXLarge;

                                div > span:first-of-type {
                                    color: $spintrContentDark;
                                    font-size: $fsCaption;
                                    font-weight: $fwMedium;
                                    line-height: $lhNormal;
                                    padding: $spacingSmall $spacingMedium;
                                }
                            }
                        }
                    }
                }
            }

            .WikiArticleView-sections {
                flex-grow: 1;
                padding: 0 $spacingMedium;

                .article-image-container {
                    margin-bottom: $spacingMedium;

                    .article-image {
                        display: flex;
                        justify-content: center;

                        img {
                            border-radius: $brRounder;
                            width: 100%;
                            max-width: 742px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}
}
    
.WikiArticleView-sidebar {
    max-width: $sidebarMaxWidth;
    min-width: $sidebarMinWidth;

    .sidebar-box {
        margin-bottom: $spacingMedium;

        &.pins-box svg {
            rotate: 135deg;
        }
    }
}
