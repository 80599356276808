@import "src/common.scss";

.DividingHeader {
    display: block;
    padding-bottom: $spacingLarge;
    position: relative;
    width: 100%;

    .DividingHeader-line {
        border-bottom: 1px solid $spintrGrayLight;
        left: 0;
        position: absolute;
        right: 0;
        top: 10px;
        width: 100%;
        z-index: 0;
    }

    .DividingHeader-text {
        background-color: $spintrWhite;
        display: inline-block;
        padding-right: $spacingMedium;
        position: relative;
        z-index: 1;
    }
}