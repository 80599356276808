@import "src/style/variables.scss";

.NotificationCardContainer {
    position: fixed;
    bottom: $gutterLarge;
    left: calc(#{$spintrSidebarMaxWidth} + #{$gutterXXLarge});
    z-index: 35;

    -webkit-animation: itemFadeIn 1s;
    -moz-animation: itemFadeIn 1s;
    -ms-animation: itemFadeIn 1s;
    -o-animation: itemFadeIn 1s;
    animation: itemFadeIn 1s;
    background-color: white;
    border-radius: $borderRadius;

    .NotificationCard {
        width: 300px;
        border-bottom: 1px solid color("dusk-grey");
        position: relative;

        .close-button {
            top: $gutterMedium;
            right: $gutterMedium;
            position: absolute;
            font-size: 10px;
        }

        .IconAndContent {
            display: flex;
            padding: $gutterMedium;
            height: 100%;
            align-self: center;
            align-items: center;

            .icon {
                font-size: 20px;
                margin-right: $gutterMedium;
            }

            .text {
                margin-bottom: $gutterXSmall;
            }
            .show {
                margin-top: $gutterXSmall;
            }
        }

        &:last-child {
            border: 0;
        }
    }

    .item-enter {
        opacity: 0;
    }

    .item-enter-active {
        opacity: 1;
        transition: opacity 500ms ease-in-out;
    }

    .item-exit {
        opacity: 1;
    }
}

@keyframes itemFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes itemFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes itemFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-ms-keyframes itemFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes itemFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@media screen and (max-width: 1550px) {
    .NotificationCardContainer {
        left: calc(#{$spintrSidebarMinWidth} + #{$gutterXXLarge});
    }
}