@import "src/style/variables";

.operatingInfoSupportPost {
    
    .supportPost {
        border: 1px solid color("dusk-grey");
        border-left: 3px solid;
        border-left-color: #35b986;
        margin-top: $gutterSmall;
        font-size: 10.5px;
        .allinfo {
            padding: $gutterSmall 0;
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            a{
                color: color("light-blue");
                margin-top: 4px;
                display: inline-block;
            }
                h5{
                    margin: 0 0 4px 0;
                }
                .description{
                    margin: 0 0 4px 0;
                }
                .name,
                .dates {
                    display: block;
                }
                .dates{
                    color: color("mid-grey")
                }
            }
        
    }
}
