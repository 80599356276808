@import "src/style/variables";

.ReadReceiptButton {
    margin-top: $gutterMedium;

    button {
        span {
            display: flex;
            flex-direction: row;
            column-gap: $gutterXSmall;
        }
    }

    &.read {
        span {
            color: color(dark-grey);
        }
    }
}