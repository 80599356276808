@import "src/common.scss";

$colorSize: 30px;

.PlannerColorPicker {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: $spacingSmall;
    justify-content: flex-start;

    .color {
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 0 0 2px #EEEEEF;
        display: flex;
        height: $colorSize;
        justify-content: center;
        width: $colorSize;

        i {
            color: $spintrWhite;
            font-size: $fsBodyMedium;
        }
    }
}