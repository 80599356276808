@import "src/style/variables.scss";

.GroupsStartView {
    height: calc(100vh - #{$spintrHeaderHeight} - #{$gutterSmall} - #{$gutterSmall});
    text-align: center;

    > div {
        display: inline-block;
        vertical-align: middle;
        max-width: 500px;
    }

    .GroupsStartView-align-helper {
        height: 100%;
    }
}