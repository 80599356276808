@import "src/style/variables.scss";

.SocialImagePost {
    .image-stage {
        padding-bottom: $gutterSmall;
        overflow: hidden;

        .image {
            background-color: #FFF;
            border-radius: $borderRadius;
            cursor: pointer;
            display: inline-block;
            margin-bottom: 0;
            margin-right: $gutterTiny;
            overflow: hidden;
            position: relative;
            text-align: center;
            vertical-align: top;

            .maximize-icon{
                position: absolute;
                z-index: 1;
                right: $gutterSmall;
                top: $gutterSmall;
                line-height: 0;
                pointer-events: none;
                background-color: rgba(0, 0, 0, 0.2);
                padding: 3px;
                border-radius: 6px;
            }
            &.more {
                .count {
                    background-color: rgba(0, 0, 0, 0.7);

                    .plus, .number {
                        color: #FFF;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    
                    .plus {
                        font-size: 21px;
                    }

                    .number {
                        font-size: 30px;
                    }
                }
            }

            img {
                background-color: #FFF;
                max-width: 100%;
                vertical-align: text-bottom;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &.count2 {
            .image {
                width: calc((100% - #{$gutterTiny}) / 2);
            }
        }

        &.count3 {
            .image {
                width: calc((100% - #{$gutterTiny}) / 2);

                &:first-child {
                    margin-bottom: $gutterTiny;
                    margin-right: 0;
                    width: 100%;
                }
            }
        }

        &.count4 {
            .image {
                width: calc((100% - #{$gutterTiny * 2}) / 3);

                &:first-child {
                    margin-bottom: $gutterTiny;
                    width: 100%;
                }
            }
        }

        &.count5 {
            .image {
                width: calc((100% - #{$gutterTiny * 2}) / 3);

                &:nth-child(1), &:nth-child(2) {
                    margin-bottom: $gutterTiny;
                    width: calc((100% - #{$gutterTiny}) / 2);
                }
                
                &:nth-child(2) {
                    margin-right: 0;
                }
            }
        }
    }
}