@import "src/style/variables.scss";

.TodoItemEditView{
    width: 550px;
    margin: 0 auto;
  
    .FormControl{
        width: 550px;
 
    }

    .css-40{

    }
}