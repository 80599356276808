.CommentForm {
    .InteractiveTextInputs {
        line-height: 1.714em;
    }

    .DraftEditor-root {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
    }
}