@import "src/style/variables";

$shortcutImageSize: 30px;

.contentWithInfoPanel {
    &.visagePageStyle {
        .customInfoPanelPart {
            margin-bottom: $gutterXLarge;
            border-radius: $borderRadius;
            background-color: #FFF;
            padding: $gutterXLarge;

            .customInfoPanelPart-items {
                display: flex;
                flex-direction: column;
                row-gap: $gutterXSmall;
                margin-top: $gutterXSmall;

                .customInfoPanelPart-item {
                    &.active {
                        .label-component {
                            color: color(dark-grey);
                            font-weight: bold;
                        }
                    }

                    &:hover {
                        .label-component {
                            color: color(dark-grey);
                        }
                    }
                }
            }
        }

        &:not(.customDesign) {
            .contentWIthInfoPanelLeft, .contentWIthInfoPanelRight {
                background-color: #FFF;
                border-radius: $borderRadius;
                padding: $gutterMedium;
                box-sizing: border-box;
            }
        }

        .contentWIthInfoPanelLeft {
            &.noInfoPanel {
                max-width: 1000px;
                margin: 0 auto;

                &.noMaxWidth {
                    max-width: initial;
                }
            }
        }

        .contentWIthInfoPanelRight {
            .pageInfoPanel {
                padding: 0px !important;
            }
        }

        // .contentWIthInfoPanelLeft {
        //     width: calc(100% - 300px - #{$gutterXXLarge}) !important;
        // }

        // .contentWIthInfoPanelRight {
        //     width: 300px !important;
        //     margin-left: $gutterXXLarge !important;
        //     padding-bottom: calc(#{$gutterXXLarge} - #{$gutterMedium});

        //     .pageInfoPanel {
        //         padding: 0px !important;
        //     }
        // }

        // &.isSmallViewMode {
        //     .contentWIthInfoPanelLeft {
        //         width: 100% !important;
        //     }

        //     .contentWIthInfoPanelRight {
        //         display: none !important;
        //     }
        // }
    }
}
