@import "src/style/variables";

#SocialFeedContainer {
    margin-bottom: $gutterLarge;

    // This is to make horizontal box-shadow visible
    .infinite-scroll-component {
        margin: -75px;
        padding: 75px;
    }

    .infinite-scroll-component.has-unread {
        margin-top: 0;
        padding-top: 0;
    }

    .load-more {
        background:#fff;
        text-align: center;
        &:hover{
            background: color("light-grey")
        }
        > a {
            display: block;
            padding: $gutterXSmall;
        }
    }

    .new-feed-posts {
        display: block;
        text-align: center;
        padding: $gutterSmall;
        margin-bottom: $gutterSmall;
        border-radius: $borderRadius;
        background-color: #fff;

        .label-component {
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            justify-content: center;

            .Visage2Icon {
                margin-right: $gutterXSmall;
            }
        }
    }

    .SocialPostShimmer {
        padding: $gutterLarge;
        background-color: "#FFF";
        margin-bottom: $gutterLarge;
        border-radius: $borderRadius;
    }
}