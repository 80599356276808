@import "src/style/variables";

.AppPageDataGrid {
    border-radius: $borderRadius;
    background-color: #FFF;
    padding: $gutterXXLarge;
    position: relative;

    > .header {
        margin-bottom: $gutterSmall;
    }

    > .actions {
        position: absolute;
        right: $gutterXXLarge;
        top: $gutterMedium;

        .ms-Button {
            border-color: color(borderColor);
            color: color(mid-grey);
        }
    }

    &.no-header {
        > .actions {
            top: initial;
            right: initial;
            position: relative;
            text-align: right;
            width: 100%;
            margin-bottom: $gutterSmall;
        }
    }
}