@import "src/style/variables.scss";

.ImageCropperModal {
    .ms-Dialog-main {
        max-width: 70% !important;
        height: 90% !important;
        max-height: 690px; // 10px lower than FileSelector
        display: flex;
        padding: $gutterMedium;
        position: relative;
    }

    .Cropper-Inner {
        position: relative;
        min-height: 75%;
        height: auto;
    }

    .control-stack {
        bottom: 100px;

        .ms-Slider {
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
                margin-right: 1em;
                font-weight: 400;
            }

            .ms-Slider-container {
                min-width: 200px;
                width: 30%;
            }
        }
    }

    .ok-cancel-buttons {
        margin-top: $gutterXSmall;
    }

    @media screen and (max-width: $vmPhoneLandscape) {
        .ms-Modal-scrollableContent,
        .ms-Dialog-main {
            max-width: 100% !important;
        }

        .control-wrapper {
            display: flex;
            flex-flow: inherit;
            align-items: center;

            .control-stack {
                margin-left: 0;
                justify-content: center;

                .action-buttons {
                    margin-left: 0 !important;
                    display: flex;

                    .ms-Button-flexContainer {
                        align-items: flex-end;
                    }
                }

                .rotation-buttons {
                    .ms-Button-label {
                        display: none !important;
                    }

                    margin-left: 0 !important;
                }

                .ms-Slider {
                    display: none !important;
                }
            }
        }
    }
}
