@import "src/style/variables";
.spintr-modal {
    .header {
        h2 {
            margin: 0;
        }
    }

    h2 {
        margin: 0;
    }

    .ms-Dropdown {
        width: 100% !important;
    }

    .ms-Dialog-main {
        width: 480px;
        padding: $gutterXXLarge $gutterXXLarge 0 $gutterXXLarge;
        border-radius: 20px;
        //padding-bottom: $gutterMedium;

        .ms-Modal-scrollableContent > div:last-child {
            padding-bottom: $gutterXLarge;
        }
    }
    
    &.wide {
        .ms-Dialog-main {
            width: 800px;
        }
    }

    .ms-BasePicker-text {
        min-width: 100% !important;
    }

    .SpintrDatePicker {
        .react-datepicker__close-icon{
            height: auto;
            top: 1.1em;
            right: 1em;
        }
        .react-datepicker-wrapper {
            > .react-datepicker__input-container {
                input {
                    min-width: auto !important;
                    width: 150px;
                }
    
                .react-datepicker__close-icon::after {
                    background-color: #909090;
                }
            }
        }
    } 

    .spintr-modal-margin-bottom {
        margin-bottom: $gutterMedium;
    }
}

.spintrCustomDialog{
}

.isSmallViewMode {
    .media-view {
        .items {
            .item {
                width: calc(50% - 24px);
            }
        }
    }
}
@media screen and (max-width: $vmTabletPortrait) {
    .spintr-modal {
        .ms-Dialog-main {
        width: 90vw;
        }
    }
}
