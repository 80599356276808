@import "src/style/variables.scss";

.AppDisabledBanner {
    padding: $gutterSmall;
    background-color: #fdf8e1;
    margin-bottom: $gutterSmall;
    border-radius: $borderRadius;

    .AppDisabledBanner-inner {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .AppDisabledBanner-info-circle {
        margin-right: $gutterXSmall;
    }
}