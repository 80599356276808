@import "src/style/variables";

.social-hidden {
    .actionmenu-component {
        &.open {
            opacity: 1;
        }
        opacity: 0;
    }

    &:hover {
        .actionmenu-component {
            opacity: 1;
        }
    }
}
