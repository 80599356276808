/* Primary Blue */
$spintrBlue:            #787CDD;
$spintrBlueLight:       #C9CBF1;
$spintrBlueLighter:     #F1F2FC;

/* Primary Content */
$spintrContentDark:     #22234A;
$spintrContentNormal:   #4E4F6C;
$spintrContentLight:    #727E94;

/* Gray */
$spintrGray:            #A7A7B5;
$spintrGrayDark:        #475267;
$spintrGrayLight:       #ECF0F3;
$spintrGrayLighter:     #F4F4F5;

/* Supporting/Yellow */
$spintrYellow:          #F1AE15;
$spintrYellowLight:     #F7D48B;
$spintrYellowLighter:   #FEF8EC;

/* Supporting/Green */
$spintrGreen:           #006352;
$spintrGreenLight:      #00A578;
$spintrGreenLighter:    #EAF4EF;

/* Supporting/Pink */
$spintrPink:            #FC5371;
$spintrPinkLight:       #FBB5D4;
$spintrPinkLighter:     #FCEEF5;

/* Neutral */
$spintrBlack:           #000000;
$spintrWhite:           #FFFFFF;

/* Color Object */
$spintrColors: (
    blue:               $spintrBlue,
    blueLight:          $spintrBlueLight,
    blueLighter:        $spintrBlueLighter,

    contentDark:        $spintrContentDark,
    contentNormal:      $spintrContentNormal,
    contentLight:       $spintrContentLight,
    
    gray:               $spintrGray,
    grayDark:           $spintrGrayDark,
    grayLight:          $spintrGrayLight,
    grayLighter:        $spintrGrayLighter,
    
    yellow:             $spintrYellow,
    yellowLight:        $spintrYellowLight,
    yellowLighter:      $spintrYellowLighter,
    
    green:              $spintrGreen,
    greenLight:         $spintrGreenLight,
    greenLighter:       $spintrGreenLighter,
    
    pink:               $spintrPink,
    pinkLight:          $spintrPinkLight,
    pinkLighter:        $spintrPinkLighter,

    white:              $spintrWhite,
    black:              $spintrBlack,
);