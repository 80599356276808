/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
.fs-big {
  font-family: "Eloquia", sans-serif;
  font-size: 28px !important;
  font-weight: 700 !important;
}

.fs-h1, h1 {
  font-family: "Eloquia", sans-serif;
  font-size: 28px;
  font-weight: 550;
  line-height: 1.5em;
}

.fs-h2, h2 {
  font-family: "Eloquia", sans-serif;
  font-size: 24px;
  font-weight: 550;
  line-height: 1.5em;
}

.fs-h3, h3 {
  font-family: "Eloquia", sans-serif;
  font-size: 20px;
  font-weight: 550;
  line-height: 1.5em;
}

.fs-h4, h4 {
  font-family: "Eloquia", sans-serif;
  font-size: 18px;
  font-weight: 550;
  line-height: 1.25em;
}

.fs-h5, h5 {
  font-family: "Eloquia", sans-serif;
  font-size: 16px;
  font-weight: 550;
  line-height: 1.25em;
}

.fs-h6, h6 {
  font-family: "Eloquia", sans-serif;
  font-size: 14px;
  font-weight: 550;
  line-height: 1.25em;
}

.fs-h7 {
  font-family: "Eloquia", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25em;
}

.fs-body-1, .split-auth-view .content .form-container .ms-TextField .ms-TextField-fieldGroup input {
  font-family: "Eloquia", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-body-2 {
  font-family: "Eloquia", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-body-3 {
  font-family: "Eloquia", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.25em;
}

.fs-body-4, .split-auth-view .content .form-container .ms-TextField label,
.split-auth-view .content .form-container .birthday-pickers label {
  font-family: "Eloquia", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-body-5 {
  font-family: "Eloquia", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-small-1 {
  font-family: "Eloquia", sans-serif;
  font-size: 10px;
  font-weight: 550;
  line-height: 1em;
}

.fs-small-2 {
  font-family: "Eloquia", sans-serif;
  font-size: 10px;
  font-weight: 550;
  line-height: 11px;
}

.fw-extra-bold {
  font-weight: 900;
}

.fw-bold {
  font-weight: 700;
}

.fw-semi-bold {
  font-weight: 600;
}

.fw-medium, .split-auth-view .content .form-container .ms-TextField label,
.split-auth-view .content .form-container .birthday-pickers label {
  font-weight: 550;
}

.fw-regular {
  font-weight: 400;
}

.fw-normal {
  font-weight: normal;
}

.fw-light {
  font-weight: 300;
}

.label-component {
  letter-spacing: -0.2px;
}
.label-component.uppercase {
  text-transform: uppercase;
}
.label-component.subline-letter-spacing {
  letter-spacing: 1px;
}
.label-component.centerText {
  text-align: center;
}
.label-component.italic {
  font-style: italic;
}
.label-component.underline {
  text-decoration: underline;
}
.label-component.line-through {
  text-decoration: line-through;
}

.fc-black {
  color: #000;
}

.fc-dark-grey {
  color: #22234A;
}

.fc-mid-grey {
  color: #475267;
}

.fc-grey {
  color: #727E94;
}

.fc-dusk-grey {
  color: #d5d5d5;
}

.fc-light-grey {
  color: #F8F8FF;
}

.fc-light-blue {
  color: #787CDD;
}

.fc-red {
  color: #EB0014;
}

.fc-salmon {
  color: #FC5371;
}

.fc-smoke {
  color: #EAEAEA;
}

.fc-white {
  color: #FFF;
}

.fc-office365 {
  color: #D83B01;
}

.fc-green {
  color: #1F7F35;
}

.fc-spintrGreen {
  color: #00A578;
}

.fc-outline {
  color: #488dc8;
}

.fc-yellow {
  color: #ADA000;
}

.fc-orange {
  color: #FFAE2C;
}

.fc-darkOrange {
  color: #A86800;
}

.fc-disabled {
  color: #a19f9d;
}

.fc-visageGray {
  color: #091B3D;
}

.fc-visageGray2 {
  color: #363853;
}

.fc-visageGray3 {
  color: #6D7588;
}

.fc-visageGray6 {
  color: #F7F7F9;
}

.fc-neutralBlue {
  color: #2772F0;
}

.fc-borderColor {
  color: #F4F4F5;
}

.fc-borderColor2 {
  color: #F7F8FA;
}

.fc-bookmarkColor {
  color: #F8F9FF;
}

.fc-blueHighlight {
  color: #ECE9FE;
}

.fc-visageMidBlue {
  color: #787CDD;
}

.fc-visageMidBlueBackground {
  color: #F1F2FC;
}

.fc-visage2LightGray {
  color: #F4F4F5;
}

.fc-visage2LightGray2 {
  color: #ECF0F3;
}

.fc-spintrWhite {
  color: #FFFFFF;
}

.fc-spintrGrey {
  color: #727E94;
}

.fc-primaryContent {
  color: #4E4F6C;
}

.fc-backgroundColor {
  color: #F0F0F0;
}

.fc-lightRed {
  color: #FDEBED;
}

.fc-lightYellow {
  color: #FDF7EA;
}

@media screen and (max-width: 1024px) {
  .fs-h1, h1 {
    font-size: 22px;
  }

  .fs-h2, h2 {
    font-size: 20px;
  }

  .fs-h3, h3 {
    font-size: 18px;
  }

  .fs-h4, h4 {
    font-size: 16px;
  }

  .fs-h5, h5 {
    font-size: 14px;
  }

  .fs-h6, h6 {
    font-size: 12px;
  }
}
.split-auth-view .error-bar {
  background-color: #ff5555;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}
.split-auth-view .content {
  display: flex;
  min-height: 100vh;
  flex: 1 1 100%;
}
.split-auth-view .content.has-error .left {
  margin-top: 60px;
}
.split-auth-view .content .left,
.split-auth-view .content .right {
  flex: 1 0 50%;
}
.split-auth-view .content .left .background-image,
.split-auth-view .content .right .background-image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.split-auth-view .content .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.split-auth-view .content .footer-text {
  margin-bottom: 45px;
}
.split-auth-view .content .footer-text a {
  text-decoration: underline;
}
.split-auth-view .content .form-container {
  max-width: 620px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 30px;
  box-sizing: border-box;
}
.split-auth-view .content .form-container .login-logo-wrapper {
  height: 62px;
  margin-top: 50px;
}
.split-auth-view .content .form-container .login-logo-wrapper img {
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}
.split-auth-view .content .form-container .ms-TextField label,
.split-auth-view .content .form-container .birthday-pickers label {
  padding-bottom: 8px;
}
.split-auth-view .content .form-container .ms-TextField .ms-TextField-fieldGroup {
  min-height: 50px;
  border: 1px solid #d6dce6;
  border-radius: 8px;
}
.split-auth-view .content .form-container .ms-TextField .ms-TextField-fieldGroup:after {
  border-radius: 8px;
}
@media only screen and (max-width: 1023px) {
  .split-auth-view .right {
    display: none;
  }
}