@import "src/style/variables.scss";

button.StandardButton {
    border: 0 none;
    border-radius: $borderRadius;
    cursor: pointer;
    display: inline-block;
    padding: 8px 12px;
    user-select: none;

    &.primary {
        background-color: color("light-blue");
        color: color("white");
    }

    &.disabled {
        background-color: color("light-grey");
        color: color("dark-grey")
    }
}