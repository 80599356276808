@import "src/style/variables";
@import "src/common.scss";

.AcademyTestQuestion {
    border-bottom: 1px solid color(borderColor);
    padding: $gutterMedium;

    .top-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: $gutterMedium;

        .validation-result {
            align-self: center;
        }

        .SpintrLoader {
            padding: 0px;
            height: 12px;
            width: 12px;
        }

        .ms-Stack {
            column-gap: $gutterSmall;
        }
    }

    .ms-Checkbox {
        margin-top: $gutterSmall;

        &:first-child {
            margin-top: $gutterMedium;
        }

        .ms-Checkbox-checkbox {
            border-color: $spintrGray;
        }

        .ms-Checkbox-text {
            color: $spintrContentNormal;
        }
    }

    &.is-on-lesson {
        border: 1px solid color(borderColor);
        border-radius: $borderRadius;

        .top-row {
            border-bottom: 1px solid color(borderColor);
            margin: 0 0 - $gutterMedium;
            padding: 0 $gutterMedium $gutterMedium $gutterMedium;

            .type {
                margin-bottom: $gutterTiny;
            }
        }
    }
}
