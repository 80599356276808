@import "src/style/variables";

#ResponsiveSearch {
    &.hidden {
        .overlay {
            bottom: 100%;
        }

        .search-bar {
            left: 100%;
            overflow: hidden;
        }
    }

    .overlay {
        background-color: rgba(0, 0, 0, 0.25);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $spintrHeaderZIndex+2;
    }

    .search-bar {
        background-color: #FFF;
        border-bottom: 1px solid color("smoke");
        box-sizing: border-box;
        height: 64px;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $spintrHeaderZIndex+3;
        transition: left ease-in-out 250ms;

        .inner {
            display: flex;
            flex-direction: row;
            column-gap: $gutterSmall;
            position: relative;
            height: 100%;
            align-items: center;
            margin: 0 $gutterLarge;

            .search-input {
                border: 0 none;
                box-sizing: border-box;
                display: block;
                font-size: 18px;
                line-height: 31px;
                flex-grow: 1;
            }

            .search-icon {
                i {
                    font-size: 18px;
                    transform: rotateY(180deg);
                }
            }

            .clear-button {
                i {
                    font-size: 16px;
                }
            }
        }
    }

    .search-body {
        background-color: #FFF;
        bottom: 0;
        left: 0;
        max-width: 100vw;
        overflow-x: hidden;
        overflow-y: scroll;
        position: fixed;
        right: 0;
        top: 64px;
        transition: all ease-in-out 0.25s;
        z-index: $spintrHeaderZIndex + 3;

        &.hidden {
            bottom: calc(100% - 64px);
        }

        .result-presentation {
            padding: 0 $gutterMedium;

            .heading {
                padding: $gutterMedium 0 $gutterSmall;
                text-transform: uppercase;
            }

            ol, li {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            .results {
                padding-bottom: $gutterSmall;

                .show-more {
                    .search-page-link {
                        color: color("light-blue");
                        display: block;
                        text-align: center;

                        > div {
                            line-height: 29px;
                        }
                    }
                }
            }

            .hit {
                padding-bottom: $gutterSmall;
                max-width: 100%;

                .image {
                    height: 32px;
                    width: 32px;
                }

                .text {
                    width: calc(100% - 32px - #{$gutterXSmall * 1.5});

                    .name {
                        line-height: 1;
                    }

                    .subText {
                        color: color("mid-grey");
                        line-height: 14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: pre;
                    }
                }
            }
        }
    }
}