@import "src/style/variables.scss";

.intercom-lightweight-app {
    z-index: 999999 !important;

    .intercom-lightweight-app-launcher {
        background: unset;
    }

    .intercom-launcher {
        > .intercom-lightweight-app-launcher-icon {
            background-image: url("/Style/Images/spintr-logo2.png");
            background-color: #fff;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 75px;
            border-radius: 100%;

            > svg {
                height: 0px;
                width: 0px;
                overflow: hidden;
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    .intercom-lightweight-app-launcher, .intercom-app > div {
        bottom: calc(20px + #{$spintrResponsiveFooterHeight}) !important;
    }
}