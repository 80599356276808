@import "src/style/variables.scss";

$smallExtraSize: 12px;
$smallSize: 16px;
$mediumSize: 20px;
$bigSize: 24px;
$bigExtraSize: 30px;

@each $name,
$color in $colors {
    .icon-color-#{$name} {
        svg {
            >* {
                fill: $color;
            }
        }
    }
}

.Visage2Icon {
    &.icon-size-extra-small {
        width: $smallExtraSize;
        height: $smallExtraSize;

        >div {
            width: $smallExtraSize;
            height: $smallExtraSize;

            svg {
                width: $smallExtraSize;
                height: $smallExtraSize;
            }
        }
    }

    &.icon-size-small {
        width: $smallSize;
        height: $smallSize;

        >div {
            width: $smallSize;
            height: $smallSize;

            svg {
                width: $smallSize;
                height: $smallSize;
            }
        }
    }

    &.icon-size-medium {
        width: $mediumSize;
        height: $mediumSize;

        >div {
            width: $mediumSize;
            height: $mediumSize;

            svg {
                width: $mediumSize;
                height: $mediumSize;
            }
        }
    }

    &.icon-size-big {
        width: $bigSize;
        height: $bigSize;

        >div {
            width: $bigSize;
            height: $bigSize;

            svg {
                width: $bigSize;
                height: $bigSize;
            }
        }
    }

    &.icon-size-extra-big {
        width: $bigExtraSize;
        height: $bigExtraSize;

        >div {
            width: $bigExtraSize;
            height: $bigExtraSize;

            svg {
                width: $bigExtraSize;
                height: $bigExtraSize;
            }
        }
    }
}

.spintr-list > .ms-List, .SocialPostHeader, .comment-content {
    .Visage2Icon-more-square {
        transform: rotate(90deg);
    }
}

.Visage2Icon-more-circle {
    svg {
        path {
            &:first-child {
                opacity: 0;
            }
        }
    }
}

.Visage2Icon-more-square {
    position: relative;
    top: 2px;
    left: 2px;

    svg {
        height: 30px !important;
        width: 30px !important;
        position: relative;
        top: -5px;
        left: -5px;

        path {
            &:first-child {
                opacity: 0;
            }
        }
    }
}

.Visage2Icon-add {
    margin: 0 -4px;
}

.Visage2Icon-status-type-8 {
    svg {
        margin-top: 1px;
    }
}

.Visage2Icon-status-type-7 {
    svg {
        margin-top: -1px;
    }
}