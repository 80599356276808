@import "src/style/variables";

.SocialFile {
    > .text {
        margin-bottom: $gutterSmall;
    }

    > .single-file {
        padding-left: $gutterSmall;

        .icon, .text {
            display: inline-block;
            vertical-align: middle;
        }

        .icon {
            cursor: default;
            font-size: 20px;
            margin-right: $gutterXSmall;
        }
    }

    > .file-list {
        padding-left: $gutterSmall;

        .file {
            .icon, .text {
                display: inline-block;
                vertical-align: middle;
            }

            .icon {
                cursor: default;
                font-size: 16px;
                margin-right: $gutterXSmall;
            }
        }
    }
}