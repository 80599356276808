@import "src/style/variables.scss";

.leftCalenderTimeline {
    margin: 0px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.react-calendar-timeline {
    border-top: 1px solid color("dusk-grey");
    border-right: 1px solid color("dusk-grey");
    background-color: white !important;

    .rct-item {
        margin-left: -1px;
    }

    .rct-vl {
        background-color: transparent !important;
    }

    &:not(.weekly) {
        .rct-outer {
            border-left: 1px solid color("dusk-grey");
        }
    }

    &.weekly {
        .rct-header-root {
            border-left: 0 !important;
        }

        .rct-calendar-header {
            > div:first-child {
                > div {
                    border-left: 1px solid color("dusk-grey");
                }
            }
        }
    }
}

.rct-sidebar-row.rct-sidebar-row-odd {
    background-color: #fff !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px solid color("dusk-grey") !important;
}

.react-calendar-timeline .rct-sidebar {
    border-right: 0 !important;
}

.rct-sidebar-row {
    padding: 0 $gutterSmall !important;
}

.rct-dateHeader {
    border-left: 1px solid color("dusk-grey") !important;
    background-color: #fff !important;
    border-bottom: 0 !important;
    cursor: default !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
    border-left-width: 1px !important;
}

.rct-scroll {
    overflow-x: hidden !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 1px solid color("dusk-grey") !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background-color: #fff !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    //background-color: color("dusk-grey");
    background-color: transparent;
}

.react-calendar-timeline .rct-header-root {
    border-left: 1px solid color("dusk-grey") !important;
    border-bottom: 1px solid color("dusk-grey") !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    border-bottom: 1px solid color("dusk-grey") !important;
}

.react-calendar-timeline .rct-header-root {
    background: transparent;
    background-color: white !important;
}

.rct-calendar-header {
    border: 0 !important;

    .rct-dateHeader-primary {
        color: #000 !important;
        background-color: #fff;
        border: 0 !important;
        border-left: 1px solid color("dusk-grey") !important;
        border-bottom: 1px solid color("dusk-grey") !important;

        span {
            font-size: 12px;
            font-weight: 550;
            line-height: 1em;
        }
    }
}

.ResourceBookingView {
    .custom-group {
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
    }
}
