@import "src/style/variables";

.OperatingInfoNotificationPopup {
    width: 400px;
    box-sizing: border-box;
    border-radius: $borderRadius;
    background-color: white;
    padding-top: $gutterMedium;
    padding-bottom: 1px;
    
    .OperatingInfoSidebarPost {
        margin-left: 16px;
        margin-right: 16px;
        &:last-child{
            margin-bottom: $gutterMedium;
        }
    }

    h4 {
        text-transform: uppercase;
        color:color("dark-grey");
    }

    .heading {
        margin: $gutterMedium;
        margin-bottom: $gutterXSmall;
        font-family: $secondaryFont;
    }

    p {
        //margin: 14px 14px 16px 16px;
    }

    .NoActiveOrPlanned {
        margin-left: 16px;
        margin-bottom: $gutterSmall;
        color: #000;
    }
    .OperatingInfoBorder {
        border: 1px solid color("dusk-grey");
        border-left: none;
        margin: 10px 16px 16px 16px;
    }
    .OperatingInfo {
        cursor: pointer;

        .dateText {
            color: color("mid-grey");
            font-size: 12px;
        }
        border-left: 4px solid #dc3545;
        // margin: $gutterLarge;
        padding: $gutterXSmall;

        &.planned {
            border-color: #ffdc00;
        }

        &.ongoing {
            border-color: red;
        }

        .title {
            font-size: 14px;
            font-weight: 400;
        }

        .label {
            font-weight: 400;
        }

        .icon {
            display: inline-block;
            margin-left: 10px;
            font-size: 22px;
            vertical-align: middle;

            .planned {
                color: #ffdc00;
            }

            .ongoing {
                color: red;
            }
        }

        .text {
            vertical-align: middle;
            display: inline-block;
            width: calc(100% - 69px);
        }
    }
}
