@import "../../style/variables.scss";

.UserCatalogueView {
    .userLink:focus {
        .ms-Persona-coin {
            outline: 2px solid color("outline");
        }
    }

    .coworkerCount {
        font-size: 12px;
    }

    .ms-CommandBar {
        margin-top: $gutterMedium;

        .ms-SearchBox {
            border: 0;
            align-self: center;
        }

        .divider {
            height: 70%;
            padding: 0 $gutterXSmall;
            align-self: center;
        }
    }

    .createUserWrap {
        width: 75%;
        margin: 0 auto;
    }

    .UserCatalogueView-nameColumn {
        > div {
            width: calc(100% - 24px);
        }

        .ms-Persona {
            .ms-Persona-details {
                padding-right: 0;
            }
        }

        > i {
            margin-top: -4px;
            margin-left: $gutterXSmall;
        }
    }
}
