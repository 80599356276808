@import "src/style/variables";

.AdminShortcutsEditView {
    .image {
        width: 200px;
        height: 200px;
        margin-bottom: 0;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;

        button {
            margin-right: $gutterXSmall;
        }

        .FormControl {
            margin-bottom: 0px !important;
        }
    }
}
