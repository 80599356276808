@import "src/style/variables";

.AppPageText {
    background-color: #FFF;
    border-radius: $borderRadius;
    padding: $gutterXXLarge;

    h1, h2, h3, h4, h5 {
        margin: 0;
    }

    h1 {
        margin-bottom: $gutterMedium;
    }

    figure {
        max-width: 100%;
    }

    img {
        max-width: 100%;
    }

    p {
        margin-bottom: $gutterXSmall;
    }
}