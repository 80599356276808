@import "src/style/variables.scss";

.mini-title-wrapper {
    display: inline-block;
    
    .mini-title {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: $gutterTiny;
        column-gap: $gutterTiny;
        margin-bottom: $gutterSmall;
        background-color: rgba(249,245,255 , 0.8);
        border-radius: $borderRadius;
        padding: 2px;
        align-items: center;
        column-gap: $gutterTiny;

        .mini-title-item {
            border-radius: $borderRadius;
            background-color: #FFF;
            padding: $gutterTiny calc(#{$gutterTiny} * 3);
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            column-gap: $gutterTiny;
            align-items: center;
            align-content: center;
            justify-content: center;
            height: 22px;
            box-sizing: border-box;

            svg {
                position: relative;
                top: 1px;
                display: block;
                height: 14px !important;
                width: 14px !important;
            }

            &.priority-level {
                &.compact {
                    width: 22px;
                }
                svg {
                    top: 0px;
                }
            }
        }

        > .label-component {
            margin: 0 $gutterSmall 0 $gutterXSmall;
        }

        .label-component {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 125px;
        }
    }
}
    