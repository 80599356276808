/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
#MarketplaceLibraryView .view-header {
  align-items: center;
  display: flex;
  height: 90px;
  flex-shrink: 0;
  justify-content: space-between;
}
#MarketplaceLibraryView .view-header .header-text {
  flex: 0 1 70%;
}
#MarketplaceLibraryView .view-header .header-text h2 {
  margin: 0;
  padding: 0;
}
#MarketplaceLibraryView .view-header .search-bar {
  flex: 1 0 30%;
}