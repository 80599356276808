@import "src/style/variables";

.SearchShortcutLabel {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: $gutterTiny;
    white-space: pre;
    flex-grow: 1;
    justify-content: flex-end;

    .Visage2Icon {
        height: 14px;
        width: 14px;
        > div {
            height: 14px;
            width: 14px;
            svg {
                display: block;
                height: 14px;
                width: 14px;
            }
        }
    }
}
