@import "src/marketplace/variables";
@import "src/style/variables";

#MarketplaceAppFormView {
    &.loading {
        display: none;
    }

    .view-header {
        @include headerWithImage;

        .image {
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
            min-height: 100px;
            min-width: 100px;
        }
    }

    .view-body {
        margin: $gutterXLarge auto;
        max-width: 1100px;

        .main-content {
            padding-bottom: $gutterXXXLarge;
        }

        .cover-art-image{
            max-width: 350px;
            width: 100%;
        }
    }

}