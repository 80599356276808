@import "src/style/variables.scss";

.public360-main-view {
    .ContentWithSubmenu {
        .switch-button {
            width: 100%;
            padding: $gutterLarge;
            margin-bottom: $gutterMedium;
        }
    }

    .title {
        margin-bottom: $gutterMedium;
    }

    .folder-title {
        margin: $gutterSmall 0;
    }

    .file {
        padding: $gutterXSmall $gutterSmall;
        border-bottom: 1px solid color("dusk-grey");

        i {
            margin-right: $gutterXSmall;
            img {
                display: block;
            }
        }

        i,
        a {
            display: inline-block;
            vertical-align: middle;
        }

        .related-file {
            margin: $gutterXSmall 0 $gutterXSmall $gutterMedium;
        }
    }
}
