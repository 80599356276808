@import "../style/variables.scss";

.SuggestionsView {
    .aboutForm {
        .label-component {
            margin-top: $gutterSmall;
        }
    }

    .improvementsForm {
        .MuiFormControl-root {
            width: 100% !important;
            margin-top: $gutterSmall;
        }
    }

    .attachedImages {
        margin: $gutterMedium;
    }
    
    .filesButton {
        width: auto !important;
    }

    .choiceGroups {
        > * {
            display: inline-block;
            width: 50%;
            vertical-align: top;
        }
    }

    .submitButton {
        margin-top: $gutterMedium;
    }

    .spintrLogo {
        width: 200px;
    }
}
