@import "src/style/variables";

.AcademyCourseSidebarSection {
    border-radius: $borderRadius;
    border: 1px solid color(borderColor);
    padding: $gutterMedium;

    >.label-component {
        border-bottom: 1px solid color(borderColor);
        padding-bottom: $gutterXSmall;
        margin-bottom: $gutterSmall;
    }
}