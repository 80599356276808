@import "src/style/variables";

.KeyPointIndicatorWidget {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .title, .number {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .title {
        font-size: 12px;
        margin-bottom: $gutterTiny;
    }

    .number {
        font-size: 22px;
        line-height: 26px;
    }

    .text {
        >* {
            display: inline-block;
            vertical-align: middle;
        }

        &.green {
            color: #00a578;

            .iconWrapper {
                background-color: #CEF2DA;

                .Visage2Icon {
                    svg {
                        stroke: #00a578;
                    }
                }
            }
        }

        &.red {
            color: #FC5371;

            .iconWrapper {
                background-color: #ffdfe6;
                transform: rotate(180deg);

                .Visage2Icon {
                    svg {
                        stroke: #FC5371;
                    }
                }
            }
        }

        &.difference {
            font-family: $secondaryFont;
            font-weight: 500;
            margin-top: $gutterXSmall;

            .iconWrapper {
                height: 20px;
                width: 20px;
                border-radius: 20px;
                display: inline-block;
                margin-right: $gutterXSmall;
                vertical-align: -1px;

                .Visage2Icon {
                    display: block;
                    line-height: 20px;
                    width: 20px;
                    height: 20px;
                    text-align: center;

                    svg {
                        margin-top: 4px;
                        stroke-width: 2px;
                    }
                }
            }
        }

        .label-component {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 26px);
        }
    }

    &.multiColumn {
        > div {
            .title {
                margin-bottom: 0px;
            }
        }

        .KeyPointIndicatorWidget-columns {
            display: flex;
            flex-direction: row;

            > div {
                border-right: 1px solid color(borderColor);
                padding: 0 $gutterMedium;

                &:last-child {
                    border-right: 0;
                    padding-right: 0;
                }

                &:first-child {
                    padding-left: 0px;
                }

                .title {
                    margin-bottom: -3px;
                    font-size: 8px;
                }
            }
        }
    }
}

.MultiKeyPointIndicatorWidget {
    .KeyPointIndicatorWidget {
        border-bottom: 1px solid color(borderColor);
        margin-bottom: 10px;
        padding-bottom: 10px;

        .title {
            font-size: 10px;
        }

        .number {
            font-size: 15px;
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0px;
            margin-bottom: 0px;
        }
    }
}