@import "src/style/variables";

.ResponsiveFooter {
    .spacer, .menu {
        width: 100vw;
        height: $spintrResponsiveFooterHeight;
    }

    .menu {
        position: fixed;
        bottom: 0px;
        z-index: 16;

        .SpintrHeaderMenu {
            justify-content: space-evenly;
            width: 100%;
            height: $spintrResponsiveFooterHeight;
            align-items: center;
            column-gap: 0px;

            .menu-item {
                flex-direction: column;
                height: auto;
                row-gap: $gutterTiny;

                .content {
                    display: block;

                    .label-component {
                        font-size: 8px;
                    }
                }
            }

            .indicator {
                border-radius: $borderRadius;
                height: 46px;
            }
        }
    }
}