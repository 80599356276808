.FloatingLayer {
    bottom: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    transition: bottom 0.25s ease-in-out;

    &.open {
        bottom: auto;
        overflow: initial;
    }
}