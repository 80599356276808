@import "src/style/variables";

.SocialPostView {
    width: $socialFeedWidth;
    max-width: 100%;
    margin: 0 auto;

    .socialPostDivider {
        display: none;
    }
}