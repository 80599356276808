@import "src/style/variables";
@import "../Composer";

.ExpandedComposerTypeSelector {
    .type-body {
        padding: $gutterMedium $gutterXLarge $gutterXLarge;

        .type-list {
            @include type-selector;
        }
    }
}