@import "src/style/variables.scss";

.SpintrSocialFeed {
    .social-post-feed {
        margin-top: 12px;
    }

    &.without-composer {
        .social-post-feed, .SocialFeedFilter {
            margin-top: 0;
        }
    }
}