@import "src/common.scss";

$imageSize: 128px;

.ProductImageUpload {
    cursor: default;
    position: relative;

    .options-button {
        align-items: center;
        display: flex;
        justify-content: center;
        right: 0;
        padding: $spacingTiny $spacingSmall;
        position: absolute;
        top: 0;
    }
                
    .image-wrapper {
        border-radius: $brRounder;
        height: $imageSize;
        overflow: hidden;
        position: relative;
        width: $imageSize;

        .primary-banner {
            background-color: rgba(0, 0, 0, 0.5);
            bottom: 0;
            left: 0;
            padding: $spacingTiny;
            text-align: center;
            position: absolute;
            right: 0;
        }
    }

    .product-image {
        align-items: center;
        display: flex;
        height: $imageSize;
        justify-content: center;
        width: $imageSize;

        img {
            max-width: 100%;
            object-fit: contain;
        }
    }
}