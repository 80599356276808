@import "src/style/variables";

$normalSize: 32px;
$smallSize: 26px;
$normalGutter: ($gutterSmall);
$unreadHeight: 17px;
$closeBUttonWidth: 16px;

.ChatTabHeader {
    background-color: #FFF;
    border-bottom: 1px solid color("smoke");
    cursor: pointer;
    height: $normalSize;
    padding: $gutterXSmall $normalGutter;
    position: relative;
    user-select: none;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    
    &.small {
        height: $smallSize;
        padding: $gutterXSmall $gutterSmall;

        .image {
            margin-right: $gutterXSmall;
            line-height: $smallSize;
        }

        .title {
            width: calc(100% - 50px);

            > span {
                line-height: $smallSize;
            }
        }

        .unread-count {
            right: $gutterXSmall * 2 + $closeBUttonWidth;
            top: floor(($smallSize - $unreadHeight) / 2);
        }

        .close-button {
            font-size: 14px;
            line-height: $smallSize;
            right: $gutterSmall;
            top: $gutterXSmall;
        }
    }

    .image, .title {
        display: inline-block;
        vertical-align: middle;
    }

    .image {
        margin-right: $normalGutter;
        line-height: $normalSize;
    }

    .title {
        width: calc(100% - 65px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        > span {
            line-height: $normalSize;
        }
    }

    .unread-count {
        line-height: $smallSize;
        position: absolute;
        right: $normalGutter * 2 + $closeBUttonWidth;
        top: ceil(($normalSize - $unreadHeight) / 2) + 2px;

        span {
            background-color: #f1173f;
            border: 1px solid #FFF;
            border-radius: $borderRadius;
            color: #FFF;
            display: inline-block;
            line-height: 0.938em;
            text-align: center;
            vertical-align: middle;
            width: 15px;
        }
    }

    .close-button {
        line-height: $normalSize;
        position: absolute;
        right: $normalGutter;
        top: $gutterSmall;
    }
}