@import "src/style/variables.scss";
.FeedBackInPageView {

    border: 1px solid color("dusk-grey");
    padding: 12px;
    max-width: 300px;
    border-radius: $borderRadius;
    text-align: center;
    margin: 0 auto;
    margin-bottom: $gutterMedium;
    .ms-Label{
        color: color("dark-grey")
    }
    .positive.active {
        color: #28a745;
    }
    .negative.active {
        color: #dc3545;
    }
    .voteOptions{
        margin-top: $gutterXSmall;
        .VoteBtn button{
            margin: $gutterXSmall $gutterSmall $gutterSmall 0;
        }
    }
}
