@import "src/style/variables.scss";

.NewsCatalogView {
    margin: -75px;
    padding: 75px;

    .feed {
        display: flex;
        column-gap: $gutterXXLarge;
        row-gap: $gutterXXLarge;
        width: 100%;
        flex-wrap: wrap;

        .InformationFeedEntry {
            flex-basis: calc((100% - #{$gutterXXLarge} * 2) / 3);
            flex-grow: 1;
            flex-shrink: 0;
            box-sizing: border-box;

            .entry-preamble > a .label-component {
                max-width: calc(var(--vw) * 14);
            }

            &.shimmer {
                padding: $gutterXXLarge;

                .top {
                    margin-bottom: $gutterMedium;
                }
            }
        }
    }
}
