@import "src/style/variables.scss";

.ExpandableField {
    .header.isExpanded {
        height: 52px;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .header {
        display: flex;
        width: 100%;
        border-radius: $borderRadius;
        color: color(dark-grey);
        align-items: center;
        height: 52px;
        cursor: pointer;
        box-sizing: border-box;
        background-color: color("visageMidBlueBackground");


        .label-component {
            flex-grow: 1;
            padding: $gutterSmall 0px $gutterSmall $gutterMedium;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .expandButton {
            margin: 0 $gutterSmall;
        }
    }

    .TinyFormattedContent {
        padding: $gutterSmall $gutterMedium;
        visibility: hidden;
        height: 0;
        overflow: hidden;

        &.isExpanded {
            box-sizing: border-box;
            border: 1px solid #dadada;
            font-size: 14px;
            color: color("mid-grey");
            border-top: 0;
            border-bottom-left-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
            width: 100%;

        }
    }

    .isExpanded {
        visibility: visible;
        height: auto;
    }
}