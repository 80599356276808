@import "src/style/variables";

$headerHeight: 64px;

.ConversationsPanel {
    .header-wrap {
        padding: $gutterSmall;
    }
    .ConversationsPanel-header {
        height: $headerHeight - $gutterSmall * 2;
        padding: $gutterMedium;
        padding-top: 0;
    }

    .FilterButtons {
        margin: 0 $gutterSmall;
    }

    .pageHeader {
        margin-bottom: 0px;
    }

    .SpintrStaticLinksCallout-header {
        padding-top: $gutterMedium;
        padding-left: $gutterMedium;
        padding-right: $gutterMedium;
    }

    .FillHeight {
        > div {
            display: block !important;
        }
    }

    .infinite-scroll-component {
        > .conversation {
            &:last-child {
                margin-bottom: $gutterSmall;
            }
        }
    }

    .ConversationsPanel-offline-text {
        text-align: center;
        margin-bottom: $gutterMedium;
    }

    &.empty-header {
        .SpintrStaticLinksCallout-header {
            display: none;
        }

        .ConversationsPanel-header {
            padding-top: $gutterMedium;
        }
    }

    .SpintrStaticLinksCallout-header-icons {
        // top: 0px;
    }

    .SpintrStaticLinksCallout-header-icon {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
}