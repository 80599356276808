@import "src/style/variables";

.ComposerAttachmentSelector {
    display: flex;
    flex-direction: row;
    column-gap: $gutterSmall;
    align-items: flex-start;
    // z-index: 5;

    .unstyled-button {
        padding: 4px 0;

        &:hover {
            opacity: 0.75;
        }

        &.active {
            .Visage2Icon {
                padding: 4px;
                margin: -4px;
                background-color: color(visage2LightGray);
                border-radius: 28px;
            }
        }

        > * {
            pointer-events: none;
        }
    }

    .attachments-separator {
        width: 1px;
        height: 20px;
        background-color: color(visage2LightGray2);
    }

    .actionmenu-component {
        height: 20px;
        width: 20px;
        min-width: 20px;
    }
}
