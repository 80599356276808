@charset "utf-8";

@font-face {
    font-family: "Eloquia";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("/style/Fonts/Eloquia/EloquiaText-Light.otf") format("opentype");
}

@font-face {
    font-family: "Eloquia";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("/style/Fonts/Eloquia/EloquiaText-LightItalic.otf") format("opentype");
}

@font-face {
    font-family: "Eloquia";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/style/Fonts/Eloquia/EloquiaText-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Eloquia";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("/style/Fonts/Eloquia/EloquiaText-Italic.otf") format("opentype");
}

@font-face {
    font-family: "Eloquia";
    font-style: normal;
    font-weight: 550;
    font-display: swap;
    src: url("/style/Fonts/Eloquia/EloquiaText-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Eloquia";
    font-style: italic;
    font-weight: 550;
    font-display: swap;
    src: url("/style/Fonts/Eloquia/EloquiaText-MediumItalic.otf") format("opentype");
}

@font-face {
    font-family: "Eloquia";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("/style/Fonts/Eloquia/EloquiaText-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Eloquia";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("/style/Fonts/Eloquia/EloquiaText-BoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "Eloquia";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("/style/Fonts/Eloquia/EloquiaText-Black.otf") format("opentype");
}

@font-face {
    font-family: "Eloquia";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("/style/Fonts/Eloquia/EloquiaText-BlackItalic.otf") format("opentype");
}

@import "../node_modules/draft-js/dist/Draft.css";
@import "../node_modules/draft-js-emoji-plugin/lib/plugin.css";
@import "../node_modules/draft-js-mention-plugin/lib/plugin.css";

@import "./Spintr.scss";

/* * * * * * * * * *
 * Module Imports  *
 * * * * * * * * * */
@import "./chat/chat.scss";
@import "./groups/groups.scss";
@import "./news/news.scss";
@import "./spintr/spintr.scss";
@import "./ui/ui.scss";
@import "./pages/pages.scss";
@import "./marketplace/widgets/SpintrWidget.scss";
@import "./chat/chat.scss";
@import "./spintr/components/SpintrModal.scss"