@import "src/style/variables.scss";

.CategoryButton {
    // cursor: pointer;
    display: flex;
    margin: 0 $gutterXLarge 0 0px;
    height: 37px;
    padding: 0px $gutterSmall;
    background-color: color("neutralBlue");

    .label {
        display: inline-block;
        color: white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-self: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: center;

    }
}