@import "src/style/variables";

.MessageTodoList {
    display: flex;
    flex-direction: column;
    row-gap: $gutterXSmall;

    .todo {
        .top-row {
            display: flex;
            flex-direction: row;
            column-gap: $gutterXSmall;
            align-items: center;

            .user-wrapper {
                border: 1px solid #FFF;
                border-radius: 16px;
            }
        }
    }

    .Visage2Icon-add-circle {
        svg {
            > path {
                &:not(:first-child) {
                    display: none;
                }
            }
        }
    }
}
