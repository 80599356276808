@import "src/style/variables";

.GroupSupportView {
    background-color: #FFFFFF;
    border-radius: $borderRadius;
    margin: $gutterXXXLarge $gutterXXXLarge $gutterXXLarge;
    padding: $gutterXLarge;

    .cta {
        flex-grow: 1;
        text-align: right;
    }
}