@import "src/style/variables";

.RainbowButton {
    border: 1px solid transparent !important;
    border-radius: $borderRadius;

    &.enabled {
        background: linear-gradient(white, white), repeating-linear-gradient(to right, #571ED2, #FF391E, #FFDE2F, #1EBD54, #FF357E, #571ED2);
        background-origin: border-box;
        background-clip: content-box, border-box;
        animation-name: rainbowButtonBorder;
        animation-duration: 40s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        &:hover {
            opacity: 0.75;
        }
    }

    .RainbowButton-inner {
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;
        align-items: center;
        margin: $gutterXSmall calc(#{$gutterTiny} * 3);

        .icon {
            height: 16px;
            width: 16px;
            -webkit-animation: loaderFadeIn 1s;
            -moz-animation: loaderFadeIn 1s;
            -ms-animation: loaderFadeIn 1s;
            -o-animation: loaderFadeIn 1s;
            animation: loaderFadeIn 1s;

            > div {
                height: 16px;
                width: 16px;
            }
        }

        .SpintrLoader {
            height: 12px;
            width: 12px;
            padding: 0px;
        }
    }
}

@keyframes rainbowButtonBorder {
    from {background-position: -1000px 0}
    to {background-position: 1000px 0}
}
@-moz-keyframes rainbowButtonBorder {
    from {background-position: -1000px 0}
    to {background-position: 1000px 0}
}
@-webkit-keyframes rainbowButtonBorder {
    from {background-position: -1000px 0}
    to {background-position: 1000px 0}
}
@-ms-keyframes rainbowButtonBorder {
    from {background-position: -1000px 0}
    to {background-position: 1000px 0}
}
@-o-keyframes rainbowButtonBorder {
    from {background-position: -1000px 0}
    to {background-position: 1000px 0}
}
