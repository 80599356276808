@import "src/style/variables";

.AcademyCourseSidebarStatus {
    display: flex;
    flex-direction: column;
    row-gap: $gutterSmall;

    .top-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .status-label {
            background-color: #FCEED5;
            padding: $gutterXSmall $gutterSmall;
            border-radius: $borderRadiusSharp;

            .label-component {
                color: #ED702E;
            }

            &.completed {
                background-color: #D9FFED;

                .label-component {
                    color: color(green);
                }
            }

            &.initialized {
                background-color: #DAEBFC;

                .label-component {
                    color: #2182E3;
                }
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        column-gap: $gutterMedium;

        > * {
            flex-grow: 1;

            .ms-Button {
                width: 100%;
            }
        }
    }
}