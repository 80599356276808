@import "src/style/variables";

.favouriteBox {
    margin-bottom: $gutterLarge;

    .headerFavouriteBox {
        
        max-width: 100%;
        width: 100%;

        h4 {
            margin-bottom: 0;
        }
    }

    .eachFavourite {
        background: color("light-grey");
        display: block;
        line-height: 3.000em;
        margin-bottom: 2px;
        padding: 0 $gutterMedium;
        box-sizing: border-box;
        -webkit-transition: background-color $fastTransition;
        -moz-transition:  background-color $fastTransition;
        -o-transition:  background-color $fastTransition;
        transition:  background-color $fastTransition;
        &:hover{
            background: color("dusk-grey");
        }
        a{
            color: color("dark-grey")
        }
        .textEachFavourite {
            cursor: pointer;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 24px - #{$gutterXSmall});
        }
    
        .textEachFavourite, .favIcon, .deleteButton {
            display: inline-block;
            vertical-align: middle;
        }
    
        .favIcon, .deleteButton {
            width: 20px;
        }
        
        .favIcon {
            margin-left: $gutterXSmall;

            svg {
                vertical-align: -4px;
            }
        }
    
        .deleteButton {
            display: none;

            >:first-child {
                vertical-align: -2px;
            }
        }

        &:hover {
            .deleteButton {
                display: inline-block;
            }

            .favIcon {
                display: none;
            }
        }
    }
    .textNoFavourite{
        background-color: color("light-grey");
        padding: $gutterMedium;
        text-align: center;
    }
}

.ms-Fabric--isFocusVisible {
    .favouriteBox {
        .favIcon {
            display: none !important;
        }

        .deleteButton {
            display: inline-block !important;
        }
    }
}