@import "src/style/variables";

.SpintrLoader {
  height: 30px;
  margin: 0 auto;
  padding: 24px 0;
  position: relative;
  text-align: center;
  width: 30px;
}

.SpintrLoader-wrapper {
  padding: 0 $gutterMedium;
}


@keyframes loaderFadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-moz-keyframes loaderFadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes loaderFadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-ms-keyframes loaderFadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-o-keyframes loaderFadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}
