@import "src/style/variables";

.contentWIthInfoPanelRight {
    div {
        a,
        span {
            /*font-family: $font !important;*/
        }
    }
}

.ContentWithSubmenuRight {
    h1 {
        margin-bottom: $gutterXSmall;
        margin-top: 0px;
    }

    .contentWithInfoPanel {
        .contentWIthInfoPanelLeft {
            .contentSection {
                /*font-family: $font;*/
                width: calc(100% - 0%) !important;
            }
            // .contentSection div span p {
            //     line-height: 1.5em;
            //     /*font-family: $font;*/
            // }
            // .contentSection span div:nth-child(1) {
            //     /*font-family: $font;*/
            //     margin-top: 0px !important;
            //     margin-bottom: 20px !important;
            //     font-size: 16px;
            //     line-height: 1.5em;
            //     /*font-weight: 500 !important;*/
            // }

            // .section {
            //     .sectionName {
            //         /*font-family: $font;*/
            //         font-size: 16px;
            //         line-height: 1.5em;
            //     }

            //     div,
            //     span,
            //     p {
            //         /*font-family: $font;*/
            //     }

            //     div span p {
            //         line-height: 1.5em;
            //     }
            // }
        }
    }
}

.ContentWithSubmenu {
    .responsive-submenu-button {
        > * {
            display: inline-block;
        }

        .Visage2Icon {
            margin-right: $gutterXSmall;
        }

        .Visage2Icon,
        span {
            vertical-align: middle;
        }
    }
    .responsive-submenu {
        z-index: $spintrHeaderZIndex + 1;

        &.hidden {
            .overlay {
                bottom: 100%;
            }

            .menu,
            .menu .header,
            .menu .body {
                left: -335px;
            }
        }

        .overlay {
            background-color: rgba(0, 0, 0, 0.3);
            bottom: 0;
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            z-index: $spintrHeaderZIndex + 2;
        }

        .menu,
        .menu .header,
        .menu .body {
            background-color: #fff;
            left: 0;
            max-width: 90%;
            position: fixed;
            top: 0;
            transition: left ease-in-out 0.2s;
            width: 335px;
            box-sizing: border-box;
        }

        .menu {
            bottom: 0;
            z-index: $spintrHeaderZIndex + 3;

            .header {
                border-bottom: 1px solid color("smoke");
                box-sizing: border-box;
                height: 63px;
                line-height: 23px;
                padding: $gutterLarge $gutterMedium;
            }

            .body {
                padding: $gutterMedium;
                overflow: scroll;
                top: 64px;
                bottom: 0;

                .ms-Breadcrumb {
                    margin: 0;

                    .ms-Breadcrumb-list {
                        .ms-Breadcrumb-listItem:first-child {
                            > button {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
