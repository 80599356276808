@import "src/style/variables";

.GaugeWidgetData {
    margin-left: -50px;
    margin-right: -50px;

    > * {
        margin: 0 auto;
    }

    &.with-timestamp {
        margin-bottom: 10px;
    }

    .pie {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        position: relative;

        .slice {
            height: 150px;
            width: 150px;
            border-radius: 50%;
            position: absolute;
        }
    }

    .chart-gauge {
        width: 150px;
        height: 130px;
        margin-bottom: -20px;
        margin-top: -9px;

        svg {
            overflow: visible;
        }
    }

    .needle,
    .needle-center-inner {
        fill: #475267;
    }

    .needle-center {
        fill: #ebf1fe;
    }

    .prose {
        text-align: center;
        font-family: sans-serif;
        color: #ababab;
    }
}