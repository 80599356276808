@import "src/style/variables";

#NotificationsView {
    // Nothing here yet
}

@media screen and (max-width: $vmPhoneLandscape) {
    #NotificationsView {
        margin: (-$gutterSmall) (-$gutterSmall) 0;

        > .heading {
            padding: 0 $gutterMedium;
        }

        .FillHeight {
            > div {
                display: block !important;
            }

            .sidebarHeader .actionmenu-component {
                top: 20px;
            }
        }
    }
}