@import "src/style/variables";

$logoSize: 100px;

.MarketplaceApp.InstallPopup {
    max-width: 450px;
    min-width: 320px;
    width: calc(var(--vw, 1vw) * 100);

    .header, .body, .footer {
        padding: $gutterXXXLarge;
    }

    .header {
        padding-bottom: $gutterLarge;

        .logo {
            margin: 0 auto $gutterLarge;
            width: $logoSize;

            img {
                border-radius: 100%;
                height: $logoSize;
                overflow: hidden;
                width: $logoSize;
            }
        }

        .name {
            text-align: center;
        }
    }

    .body {
        padding-bottom: $gutterLarge;
        padding-top: 0;
    }

    .footer {
        padding-top: $gutterMedium;
        text-align: center;

        .button {
            background-color: var(--primaryColor, color("light-blue"));
            color: #FFF;
            margin: 0 auto;
            max-width: 350px;
            padding: $gutterLarge 0;
            text-align: center;
            width: calc(var(--vw, 1vw) * 100);
            position: relative;

            &.disabled {
                background-color: grey;
                cursor: default;
            }

            .text, .icon {
                display: inline-block;
                vertical-align: middle;
            }

            .icon {
                position: absolute;
                right: $gutterLarge;
            }
        }
    }
}