.zoho-popup {
    height: 800px;
    min-height: 400px;
    min-width: 300px;
    max-width: 1200px;
    width: 90vw;
    overflow: visible;

    iframe {
        height: 100%;
        width: 100%;
        border: 0;
    }

    .close-button {
        background-color: #fff !important;
        position: absolute;
        right: -30px;
        top: 0;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        > i {
            color: #fff;
        }
    }
}
