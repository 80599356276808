@import "src/style/variables";

.StartPageBuilderGrid {
    position: relative;
    background-color: #F7F7F7;
    
    > .button-container {
        position: relative;

        .add-before-row {
            align-items: center;
            background-color: #FFF;
            box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            display: flex;
            height: 30px;
            justify-content: center;
            left: calc(50% - 60px);
            padding: 0 $gutterMedium;
            position: absolute;
            top: -15px;
            gap: 4px;

            &:hover {
                opacity: 0.75;

                .Visage2Icon {
                    animation-name: pulse;
                    animation-duration: .5s;
                }
            }

            i {
                color: white;
                line-height: 24px;
                font-size: 10px;
                margin-right: 4px;
            }

            > span {
                flex: 12px 0 0;
                line-height: 1;
                margin-top: 2px;
            }

            > div {
                flex: 73px 0 0;
            }
        }
    }

    .StartPageBuilderRow, .StartPageBuilderColumn {
        > .controls-container {
            position: relative;

            .controls {
                position: absolute;
                top: -15px;
                right: 8px;
                z-index: 2;

                .control {
                    background-color: #FFF;
                    border-radius: 3px;
                    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    margin-left: $gutterXSmall;
                    min-width: 30px;
                    text-align: center;

                    &.edit {
                        padding: 0 ($gutterXSmall * 1.5) 0 $gutterXSmall;

                        .Visage2Icon {
                            margin-right: $gutterTiny;
                        }
                    }

                    .Visage2Icon {
                        line-height: 0;
                    }

                    &.move {
                        display: none;
                    }

                    > div, > span {
                        display: inline-block;
                    }

                    &:hover {
                        .Visage2Icon {
                            animation-name: pulse;
                            animation-duration: 0.5s;
                        }
                    }
                }
            }

            .edit-name {
                background-color: #FFF;
                border-radius: $borderRadiusSharper;
                box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
                position: absolute;
                padding: $gutterSmall;
                right: 8px + 36px;
                top: 23px;
                z-index: 3;

                .heading {
                    text-transform: uppercase;
                    padding-bottom: $gutterXSmall;
                }

                .input {
                    width: 196px;

                    .title-input {
                        outline: none;
                        width: 196px;
                        border: 0;
                        border-bottom: 1.5px solid color("neutralBlue");
                        padding-bottom: 8px;
                    }
                }
            }
        }
    }
}