@import "src/style/variables";

.PlacementHelpPopup {
    .box-preview {
        margin-bottom: $gutterMedium;

        .heading {
            margin-bottom: $gutterSmall;
        }
    }
    
    .help-section {
        margin-bottom: $gutterSmall;

        .button {
            margin-top: $gutterSmall;

            border-radius: $borderRadius;
            color: rgb(255, 255, 255);
            display: inline-block;
            padding: 8px 16px;
        }
    }
}