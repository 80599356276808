@import "src/common.scss";

.SidebarContentBox {
    border: 1px solid $spintrGrayLighter;
    border-radius: $brRounder;
    padding: $spacingSmall;

    .SidebarContentBox-header {
        border-bottom: 1px solid $spintrGrayLighter;
        margin-bottom: $spacingSmall;
        padding-bottom: $spacingSmall;
    }
}