@import "src/style/variables.scss";

.GroupEditView {
    .obligatoryInfoText{
        margin-top: $gutterXSmall;
    }
    .draggable-header {
        padding: 0 8px;
    }

    .draggable-item {
        padding: 8px;
        background-color: color("light-grey");
        margin-bottom: 1px;

        > * {
            vertical-align: middle;
        }
    }
}
