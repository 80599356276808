@import "src/style/variables";

.Messages {
    flex: 1;
    position: relative;

    .scroll-indicator {
        position: absolute;
        bottom: $gutterSmall;
        right: $gutterSmall;
        padding: $gutterSmall;
        border-radius: $borderRadius;
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;

        &:hover {
            opacity: 0.75;
        }
    }
}
