@import "src/style/variables.scss";

.qa-view {
    .answer-drawer {
        margin-top: $gutterSmall;
    }
    .answer {
        font-weight: 500;
        white-space: normal !important;

        p {
            line-height: 22px;
        }
    }
    .q,
    .answer,
    .options {
        font-size: 14px;
    }
    .q {
        font-weight: 500;
        line-height: 22px;
        display: block;
    }
    .q:hover {
        text-decoration: none;
    }
    .q:hover > .hidden {
        display: inline-block;
        margin-left: 0.5em;
        font-size: 14px;
    }
    .hidden {
        display: none;
        font-weight: bold;
    }
    .answer-drawer {
        background-color: #ebecec;
        background-color: color(light-grey);
        padding: $gutterSmall;
        display: flex;
        flex-direction: column;
    }
    .unstyled-button {
        color: #23a0c3 !important;
    }

    .ms-Button-label,
    .ms-TextField-field {
        font-family: $font;
    }
    .root-247 {
        margin-left: $gutterXSmall;
    }
    .QAicon {
        font-size: 23px;
    }

    .closeedit {
        font-weight: bold;
    }
    .last-modified {
        padding: 5px 0 5px 0;
    }
}

.qa-info-panel {
    .unstyled-button {
        display: block;

        &:hover {
            opacity: 0.75;
        }
    }

    .label-component {
        margin-bottom: $gutterSmall;
    }
}