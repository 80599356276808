@use "sass:math";
@import "src/style/variables";
@import "src/style/common.scss";

.notes-container {
    .topList {
        .ms-DetailsHeader-checkTooltip {
            .check-148:not(:hover) {
                opacity: 1 !important;
                color: colors(dusk-grey);
            }
        }
    }

    .unstyled-button{
        color: color("light-blue");
    }

    .hidden-print-content {
        display: none;
    }

}

.list-type-switch {
    border-radius: $brRounder;
    background-color: $spintrGrayLighter;
    display: flex;
    gap: math.div($spacingTiny, 2);
    padding: math.div($spacingTiny, 2);
    align-items: center;
    height: 36px;
    box-sizing: border-box;

    .switch-button {
        align-items: center;
        background-color: $spintrGrayLighter;
        border: 1px solid $spintrGrayLighter;
        border-radius: $brNormal;
        display: flex;
        height: 32px;
        justify-content: center;
        transition: 150ms all ease-in;
        width: 32px;

        &.active {
            background-color: $spintrWhite;
            border-color: $spintrGrayLight;
        }
    }
}

.note-box {
    display: inline-block;
    width: calc(25% - 1em);
    margin-right: 1em;
    margin-bottom: 1em;
    position: relative;
    font-family: $font;

    iframe {
        width: 100% !important;
        height: 100% !important;
    }

    .box-content {
        border: 1px solid color("dusk-grey");
        height: 12em;
        background-color: #fff;
        padding-top: 15px;

        .title{
            padding-bottom: $gutterXSmall;
        }

        .title > span {
            padding-left: 1em;
            padding-right: 1em;
            display: block;
            width: calc(100% - 3em);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 14px;
            line-height: 1.2em;
            font-weight: 600;
        }

        .content {
            padding-left: 1em;
            padding-right: 0.9em;
            word-wrap: break-word;
            height: calc(100% - 6.5em);
            overflow: hidden;
            font-size: 13px;
            line-height: 0.938em;
            padding-top: 5px;
            .fs-body-3{
                line-height: 1.125em;
            }
        }

        .actionMenu {
            position: absolute;
            top: 5px;
            right: 10px;
        }

        .bottom {
            border-top: 1px solid #ededed;
            text-align: center;
            padding: 0.7em;
            font-size: 14px;
            line-height: 1.563em;
            height: 30px;
            margin-top: 1em;
            font-weight: 500;
            position: relative;

            .more-date {
                position: absolute;
                top: 2px;
                transform: translateX(-50%);
                line-height: 1.4;
                font-size: 12px;
            }

            .notesIcon{
                font-size: 14px;
                padding-right: 5px;
            }
        }
    }

    .box-content.selected {
        background-color: yellow;
    }
    
    &.selectMode {
        cursor: pointer;
    }
}