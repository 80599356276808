@import "src/common.scss";

.PrioritizedWikiList {
    .PrioritizedWikiList-heading {
        margin-bottom: $spacingLarge;
    }

    .PrioritizedWikiList-items {
        display: flex;
        flex-wrap: wrap;
        gap: $spacingSmall;
        list-style: none;
        margin: 0;
        padding: 0;
    }
}