@import "src/style/variables";

.TicketFormModal {
    textarea {
        min-height: 150px;
    }

    .ticket-information {
        .success-text {
            margin-bottom: $gutterMedium;
        }
    }
}