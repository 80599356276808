@import "src/style/variables";

#MfaWizard {
    .label-component {
        padding-bottom: $gutterSmall;
    }

    .actions {
        align-items: center;
        display: flex;
        justify-content: right;
        padding-top: $gutterSmall;
    }

    .information-view {
        .links {
            align-items: center;
            display: flex;
            gap: $gutterSmall;
            justify-content: center;
        }
    }

    .scanning-stage {
        .qr-code {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
}