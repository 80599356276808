@import "src/style/variables";

$assistantWindowWidth: 472px;

.AssistantWindow {
    border-radius: $borderRadius;
    max-width: 100%;
    padding-top: $gutterLarge;
    position: relative;

    .body {
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        margin: 0;
        padding-bottom: $gutterLarge;
    }

    .close-button {
        background-color: #FFF;
        border-radius: 100%;
        height: 32px;
        line-height: 32px;
        position: absolute;
        right: $gutterLarge;
        text-align: center;
        top: $gutterLarge;
        width: 32px;
    }

    .ai-header {
        background-color: #fceef5;
        display: flex;

        .assistant-info {
            flex: 1 1 40px;
            margin-right: $gutterLarge;
            text-align: center;
        }
        
        .assistant-greeting {
            flex: 1 1 calc(100% - 40px);
        }
    }

    &.with-introduction {
        .body {
            box-sizing: border-box;
            height: 337px;
            overflow: hidden;
            padding: $gutterMedium;
            position: relative;
        }
    }
}