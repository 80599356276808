@import "src/style/variables";

.PasswordStrengthMeter {
    .meter {
        display: flex;
        gap: $gutterXSmall;
        margin: $gutterXSmall 0;
        max-width: 300px;

        .indicator {
            background-color: color("visageGray3");
            height: 5px;
            flex: 0 0 calc(20% - 5px);
            border-radius: 5px;
        }

        &.strength1 {
            .indicator:nth-child(1) {
                background-color: color("red");
            }
        }

        &.strength2 {
            .indicator:nth-child(1),
            .indicator:nth-child(2) {
                background-color: color("red");
            }
        }

        &.strength3 {
            .indicator:nth-child(1),
            .indicator:nth-child(2),
            .indicator:nth-child(3) {
                background-color: color("yellow");
            }
        }

        &.strength4 {
            .indicator:nth-child(1),
            .indicator:nth-child(2),
            .indicator:nth-child(3),
            .indicator:nth-child(4) {
                background-color: color("spintrGreen");
            }
        }

        &.strength5 {
            .indicator {
                background-color: color("spintrGreen");
            }
        }
    }
}