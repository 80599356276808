/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
#MarketplaceAppView .view-header {
  align-items: center;
  display: flex;
  height: 90px;
  flex-shrink: 0;
  justify-content: space-between;
}
#MarketplaceAppView .view-header .header-text {
  flex: 0 1 70%;
}
#MarketplaceAppView .view-header .header-text h2 {
  margin: 0;
  padding: 0;
}
#MarketplaceAppView .view-header .search-bar {
  flex: 1 0 30%;
}
#MarketplaceAppView .view-body {
  display: flex;
}
#MarketplaceAppView .view-body .sidebar {
  box-sizing: border-box;
  border-left: 1px solid #F4F4F5;
  flex: 1 1 366px;
  min-height: 100%;
  padding: 24px;
}
#MarketplaceAppView .view-body .sidebar a.button, #MarketplaceAppView .view-body .sidebar button {
  border-radius: 30px;
  height: auto;
  padding: 16px;
  text-align: center;
  width: 100%;
}
#MarketplaceAppView .view-body .sidebar .section, #MarketplaceAppView .view-body .sidebar .actions {
  padding-bottom: 20px;
}
#MarketplaceAppView .view-body .sidebar .section .heading {
  padding-bottom: 16px;
}
#MarketplaceAppView .view-body .sidebar .installed-box .library-button {
  margin-bottom: 16px;
}
#MarketplaceAppView .view-body > .information {
  box-sizing: border-box;
  flex: 1 1 calc(100% - 366px);
  padding: 24px;
}
#MarketplaceAppView .view-body > .information .header-text {
  border-bottom: 1px solid #F4F4F5;
  display: flex;
  padding-bottom: 16px;
}
#MarketplaceAppView .view-body > .information .header-text .app-identity {
  display: flex;
  margin-right: 20px;
}
#MarketplaceAppView .view-body > .information .header-text .app-identity .image {
  margin-right: 12px;
}
#MarketplaceAppView .view-body > .information .header-text .app-identity .image img {
  height: 48px;
  width: 48px;
}
#MarketplaceAppView .view-body > .information .header-text .app-identity .app-title {
  padding: 10px 0 11px;
}
#MarketplaceAppView .view-body > .information .header-text .installed-mark {
  border-left: 1px solid #F4F4F5;
  padding-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}
#MarketplaceAppView .view-body > .information .header-text .installed-mark .Visage2Icon {
  margin-right: 6px;
}
#MarketplaceAppView .view-body > .information .description {
  padding-bottom: 24px;
}
#MarketplaceAppView .view-body .widget-examples > .heading {
  margin-bottom: 20px;
}
#MarketplaceAppView .view-body .widget-examples .widget-list {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: flex-start;
}
#MarketplaceAppView .view-body .widget-examples .widget-list .widget-list-item {
  border: 1px solid #F4F4F5;
  border-radius: 30px;
  flex: 0 0 255px;
}
#MarketplaceAppView .view-body .widget-examples .widget-list .widget-list-item .MarketplaceWidget {
  background-color: transparent;
}
#MarketplaceAppView .view-body .widget-examples .widget-list .widget-list-item .description {
  padding: 0 16px 20px;
}