@import "src/common.scss";

$imageSize: 128px;

.ProductFormDrawer {
    .actions {
        display: flex;
        gap: $spacingLarge;
        justify-content: flex-end;
        width: 100%;
    }

    .image-drop-zone {
        border: 0 none;
        padding: 0 0 $spacingMedium;

        .image-stack {
            gap: $spacingMedium;

            .product-image-container {
                flex: 0 0 $imageSize;
                position: relative;

                &.upload-button {
                    align-items: center;
                    border: 1px dashed $spintrContentLight;
                    box-sizing: border-box;
                    border-radius: $brRounder;
                    display: flex;
                    flex-direction: column;
                    gap: $spacingSmall;
                    justify-content: center;
                    padding: $spacingSmall;
                }
            }
        }
    }
}