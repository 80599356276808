@import "src/style/variables";

.AcademyCourseCategories {
    display: flex;
    flex-direction: row;
    gap: $gutterXSmall;
    margin-bottom: $gutterTiny;

    .category-name {
        display: inline-block;
        height: 16px;
        line-height: 16px;
        padding: $gutterTiny $gutterXSmall;
        border-radius: $borderRadiusSharp;
        background-color: color(visage2LightGray);

        span {
            display: block;
            letter-spacing: normal;
            line-height: 16px;
        }
    }
}
