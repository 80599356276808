@import "src/style/variables";

.Changelog {
    width: 0px;
}

#HW_badge_cont {
    display: inline-block;
    vertical-align: middle;
    margin-top: 1px;
    pointer-events: none !important;
}

.VisageSidebarMode7 {
    .VisageSidebarContainer {
        display: none;
    }

    .SpintrMainContentContainer {
        margin: 0 auto;
    }
}

.VisageSidebarMode6 {
    .FloatingFooterBar {
        left: 400px;
    }
}

.VisageSidebarContainer {
    z-index: $spintrSidebarZIndex;
    flex-grow: 0;
    flex-shrink: 0;
    width: $spintrSidebarMaxWidth;

    .admin-items {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        justify-content: flex-end;
    }

    .VisageSidebar {
        top: 12px;
        left: 12px;
        bottom: 12px;
        position: sticky;
        display: flex;
        flex-direction: column;
        z-index: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        background-color: #FFFFFF;
        margin: $gutterSmall 0px $gutterSmall $gutterSmall;
        border-radius: $borderRadius;
        transition: height 0.25s;

        .VisageSidebar-inner {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: scroll;
            overflow-x: hidden;
            overflow-y: overlay;

            > .sidebar-menu {
                > .VisageSidebar-menuItem-wrapper {
                    &:first-child {
                        margin-top: $gutterXSmall;
                    }
                }
            }
        }

        .VisageSidebar-inner.isGroupUser {
            display: none;
        }

        .logo-wrapper {
            display: block;
            padding: $gutterMedium;
            padding-left: 32px;
            box-sizing: border-box;
            height: $spintrHeaderHeight;
            transition: all 0.3s;

            >a {
                display: block;
                height: calc(#{$spintrHeaderHeight} - #{$gutterMedium} * 2);

                >* {
                    display: inline-block;
                    vertical-align: middle;
                }

                .logo-helper {
                    height: calc(#{$spintrHeaderHeight} - #{$gutterMedium} * 2);
                }

                img {
                    max-height: 58px;
                    max-width: 175px;
                }
            }
        }

        .VisageSidebar-header {
            &.SpintrStaticLinksCallout-header {
                padding: 0 $gutterMedium $gutterMedium $gutterMedium;
            }

            .VisageSidebar-menuItem-wrapper {
                .unstyled-button {
                    margin: 0;
                    padding: 0;
                }

                .Visage2Icon {
                    margin-top: 2px;
                }
            }

            .create-button {
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    opacity: 0.5;
                }
            }
        }

        .VisageSidebar-footer {
            justify-content: center;
                margin-top: $gutterXXLarge +8;
                display: flex;

            .VisageSidebar-footer-inner {
                padding-top: $gutterXSmall;
                padding-left: $gutterMedium;
                padding-right: $gutterMedium;
                position: absolute;
                bottom: 0;
                width: 100%;
                background-color: #FFF;
                box-sizing: border-box;
            }
        }
    }
}

.VisageSidebar-menuItem-wrapper-expanded {
    .VisageSidebar-menuItem-children {
        margin-left: 14px;
        position: relative;

        .VisageSidebar-menuItem-wrapper {
            .unstyled-button {
                min-height: 32px;
                flex-grow: 0;

                .VisageSidebar-menuItem-titleRow {
                    .label-component {
                        color: color(mid-grey);
                        font-weight: 400;
                    }
                }

                &.VisageSidebar-menuItem-active {
                    background-color: #FFF;

                    .label-component {
                        color: color(dark-grey) !important;
                        font-weight: 550;
                    }
                }
            }
        }
    }
}

.VisageSidebar-menuItem-wrapper {
    display: flex;
    flex-direction: column;

    >.unstyled-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 0px $gutterMedium;
        min-height: $spintrSidebarItemHeight;

        .VisageSidebar-menuItem-titleRow {
            display: flex;
            flex-direction: row;
            //align-items: center;
            flex-grow: 1;
            align-items: center;
            padding: $gutterXSmall 0px;

            .Visage2Icon,
            .ms-Image {
                margin-right: $gutterSmall;
            }

            .label-component {
                line-height: 20px;
                position: relative;
                top: 1px;
            }
        }

        .VisageSidebar-menuItem-chevron {
            position: relative;
            margin-left: $gutterXSmall;
        }

        .SpintrLoader {
            margin: 0px;
            padding: 0px;
            width: 12px;
            height: 12px;
            margin-right: 3px;
            -webkit-animation: loaderFadeIn 1s;
            -moz-animation: loaderFadeIn 1s;
            -ms-animation: loaderFadeIn 1s;
            -o-animation: loaderFadeIn 1s;
            animation: loaderFadeIn 1s;
        }

        &:not(.VisageSidebar-menuItem-active) {
            &:hover {
                .VisageSidebar-menuItem-titleRow {
                    .Visage2Icon {
                        svg {
                            >* {
                                fill: color("light-blue") !important;
                            }
                        }
                    }

                    >span {
                        color: color("light-blue") !important;
                    }
                }

                .VisageSidebar-menuItem-arrow-right {
                    svg {
                        fill: color("light-blue");
                    }
                }
            }
        }
    }

    .sidebarSearch {
        margin: $gutterSmall $gutterLarge;
        padding-left: 0px;

        .ms-SearchBox {
            border: 0;
            background-color: transparent;
            margin-left: $gutterXSmall;
        }
    }
}

.VisageSidebar-menuItem-small {
    margin: 0px $gutterSmall !important;
}

.sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;

    .sidebar-admin-items-border {
        width: 100%;
        border-top: 1px solid color("borderColor");
        margin-top: $gutterSmall;
        padding-top: $gutterSmall;
    }

    li {
        a {
            .sidebar-menu-item {
                padding: $gutterSmall;
                padding-top: $gutterXSmall;
                padding-bottom: $gutterXSmall;
                margin-bottom: $gutterTiny;

                >* {
                    display: inline;
                    vertical-align: middle;
                }

                i {
                    color: color("dark-grey");
                    margin-right: $gutterSmall;
                    font-size: 20px;
                    width: 20px;
                }
            }

            &.active {
                .sidebar-menu-item {
                    border-radius: $borderRadius;

                    >* {
                        color: color("light-blue");
                    }
                }

            }

            &:hover {
                .sidebar-menu-item {
                    border-radius: $borderRadius;
                }
            }
        }
    }
}

.VisageSidebarMode3,
.VisageSidebarMode5,
.VisageSidebarMode6 {
    .VisageSidebarContainer {
        .VisageSidebar {
            flex-direction: row;
        }

        .VisageSidebar-inner {
            width: 40px;
            border-right: 1px solid color("smoke");
            flex-basis: 40px;
            flex-grow: 0;

            .logo-wrapper {
                width: 50px;
                margin-left: 0px;
            }
        }

        .VisageSidebar-drawer {
            background-color: #FFF;
            border-radius: $borderRadius;
            width: 100%;
            box-sizing: border-box;
            height: 100%;

            > div {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            .VisageSidebarBackButton {
                padding: $gutterSmall;

                .VisageSidebar-menuItem {
                    padding-left: 0px;
                }
            }

            .ConversationsPanel, .SystemStatusesPanel {
                flex-grow: 1;
                box-sizing: border-box;
                min-height: 0;
                display: flex;
                flex-direction: column;

                .SpintrStaticLinksCallout-header {
                    padding-top: 0px !important;
                    .SpintrStaticLinksCallout-header-icons {
                        top: 0px;
                    }
                }

                .list-wrapper {
                    flex-grow: 1;
                    height: 0;

                    > div {
                        height: 100%;

                        > div {
                            height: 100% !important;
                            overflow-x: hidden !important;
                        }
                    }
                }

                .ConversationItem {

                    &.active,
                    &:hover {
                        background-color: color(light-grey);
                    }
                }
            }
        }

        .VisageSidebar-footer {
            display: none;
        }

        .spintr-info {
            display: none;
        }
    }
}

.VisageSidebarMode4,
.VisageSidebarMode5 {
    .VisageSidebarContainer {
        flex-basis: 74px;

        .VisageSidebar {
            background-color: initial;
        }
    }
}

.VisageSidebarMode3,
.VisageSidebarMode5,
.VisageSidebarMode6 {
    .VisageSidebarContainer {
        .VisageSidebar {
            flex-wrap: wrap;
        }

        .logo-wrapper {
            flex-shrink: 0;
            width: 100%;
        }

        .VisageSidebar-inner {
            display: none;
        }
    }
}

.VisageSidebar-menuItem-shimmer {
    margin: $gutterMedium 32px;

    >div {
        margin-bottom: $gutterSmall;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.VisageSidebarMode2 {
    .sidebar-menu>.VisageSidebar-menuItem-wrapper>.VisageSidebar-menuItem-children {
        > .VisageSidebarLines {
            display: none;
        }
    }
}

.VisageSidebarMode1, .VisageSidebarMode2 {
    .sidebar-menu {
        >.VisageSidebar-menuItem-wrapper {
            >.VisageSidebar-menuItem-children {
                margin-left: 32px;
            }
        }
    }
}

.sidebar-top-search {
    margin: $gutterSmall;
}


@media screen and (max-width: 1550px) {
    .VisageSidebarMode1,
    .VisageSidebarMode2 {
        .VisageSidebarContainer {
            width: $spintrSidebarMinWidth;
        }
    }

    .VisageSidebarContainer > .faker {
        width: calc(#{$spintrSidebarMinWidth} - #{$gutterSmall});
    }
}

@media screen and (min-width: 1250px) {
    .VisageSidebarContainer {
        .operating-info {
            display: none;
        }
    }
}

// @media screen and (min-width: 1850px) {
//     .VisageSidebarContainer {
//         flex-basis: calc(#{$spintrSidebarMaxWidth} - 10vw);
//     }
// }

// @media screen and (min-width: 2250px) {
//     .VisageSidebarContainer {
//         flex-basis: 0px;
//     }
// }

.VisageResponsiveSubmenu {
    // background-color: #FFF;
    // border-radius: $borderRadius;
    padding: $gutterSmall;
    padding-bottom: 0px;
    z-index: 1;
    display: block;
    position: relative;

    .VisageResponsiveSubmenu-button {
        // padding: $gutterXLarge;

        >* {
            display: inline-block;
            vertical-align: middle;

            &:first-child {
                margin-right: $gutterSmall;
                height: 20px;
            }
        }
    }

    .VisageResponsiveSubmenu-menu {
        background-color: #FFF;
        border-radius: $borderRadius;
        padding: $gutterXXLarge;
        margin-top: $gutterSmall;

        .goBack {
            display: none;
        }

        .sidebar-menu {
            margin-bottom: 0px;
        }

        .VisageSidebar-menuItem {
            margin-right: 0px;
        }

        .VisageSidebar-menuItem-titleRow {
            margin-left: 0px;
        }
    }
}

.Visage2SidebarItem {
    .unstyled-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: $gutterTiny $gutterLarge;
        padding: 9px $gutterSmall;

        .label-component {
            margin: 0 $gutterSmall;
        }
    }

    &.active {
        .unstyled-button {
            background-color: color(visage2LightGray2);
            border-radius: $borderRadius;
        }
    }
}

// .scrolled-down {
//     .VisageSidebar {
//         height: calc(100vh - #{$gutterSmall} - #{$gutterSmall});
//     }
// }
