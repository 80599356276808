@import "src/style/variables.scss";

.GroupPlanningView {
    width: 100%;
}

.Planning-AddCategory {
    background-color: #FFF;
    border: 1px solid color("dusk-grey");
    margin: -1px;
    width: 300px;
    padding: $gutterSmall;
    box-sizing: border-box;
    text-align: left;
}

.Planning-AddCategoryFooter {
    margin-top: $gutterSmall;
}

.Planning-AddCategorySaveButton {
    margin-right: $gutterSmall;
}

.Planning-Board {
    display: flex;
    overflow: auto;
    column-gap: $gutterMedium;
    margin-top: $gutterMedium;

    > * {
        &:first-child {
            margin-left: $gutterMedium !important;
        }

        &:last-child {
            margin-right: $gutterMedium !important;
        }
    }
}

.Planning-AddCategoryButton {
    width: 298px;
    height: 48px;
    background-color: #FFF;
    border-radius: $borderRadius;
    text-align: center;

    :hover{
    }
    .Planning-AddCategoryButtonLabel {
        padding: $gutterMedium;
        width: 300px;
        box-sizing: border-box;
    }
}

.Planning-Card {
    position: relative;
    cursor: pointer !important;
    background: white;
    margin: $gutterSmall;
    margin-top: 0;
    overflow-wrap: break-word;
    -webkit-transition: background-color $fastTransition;
    -moz-transition:  background-color $fastTransition;
    -o-transition:  background-color $fastTransition;
    transition:  background-color $fastTransition;
    .Planning-CardClickable {
        width: 100%;

        .Planning-CardInner {
            padding: $gutterXSmall $gutterSmall;
            border: 1px solid color("dusk-grey");
        }
    }
}

.Planning-Card-HasRightInfo {
    .Planning-CardInnerLeft {
        display: inline-block;
        vertical-align: top;
        width: 90%;
    }

    .Planning-CardInnerRight {
        display: inline-block;
        vertical-align: top;
        width: 10%;
        text-align: right;

        .Planning-CardInnerRightTargets {
            display: inline-block;
            margin-bottom: 16px;

            .Planning-CardInnerRightTarget {
                display: inline-block;
                margin-left: $gutterXSmall;
                vertical-align: top;
            }
        }

        .Planning-CardInnerRightDate {
            position: absolute;
            bottom: 10px;
            right: $gutterSmall;

            &.Overdue {
                background-color: color("red") !important;
                padding: 2px;
                border-radius: 2px;
                color: white;
            }
        }
    }
}

.Planning-Card:hover {
    background: #f5f6f7;
}

.Planning-EditCard {
    padding: $gutterSmall;
    padding-top: 0;

    .Planning-EditCardFooter {
        padding-top: $gutterSmall;
    
        .Planning-EditCardSaveButton {
            margin-left: $gutterSmall;
        }
    }
}

.Planning-Category {
    border-radius: $borderRadius;
    flex-shrink: 0;
    width: 300px;
    height: fit-content;
    margin-left: 0;
    background: white;

    .Planning-CategoryTitle {
        cursor: pointer;
        padding: $gutterSmall;
        overflow-wrap: break-word;

        .Planning-CategoryTitleButton {
            width: calc(100%);
            display: inline-block;
        }

        .Planning-CategoryActionMenu {
            display: inline-block;
            float: right;
        }
    }
}

.Planning-ToggleAddCard {
    cursor: pointer;
    padding: $gutterSmall;
    padding-top: 0;
    display: flex;

    align-items: center;
    svg{
        display: inline-block;
        vertical-align: middle;
    }

    .label-component {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gutterXSmall;
    }
}

.empty-text-wrapper {
    padding: $gutterSmall;
    padding-bottom: $gutterXLarge;
    text-align: center;
}

.Planning-CategoryTitleEdit {
    margin: $gutterSmall;
}

.Planning-CategoryTitleEdit .textField {
    vertical-align: middle;
    display: inline-block;
    width: 100%;
}

.Planning-CategoryTitleEditClose {
    vertical-align: middle;
    display: inline-block;
    margin-left: $gutterXSmall;
}

.Planning-SmallCountWrapper {
    display: inline-block;
    margin-right: $gutterSmall;
}

.Planning-CardSmallCountLeft {
    display: inline-block;
    vertical-align: 0;
}

.Planning-CardSmallCountRight {
    margin-left: $gutterXSmall;
    vertical-align: middle;
    display: inline-block;
}

.PlanningCardPopup {
    background-color: #FFF;
    max-width: 850px !important;
    width: 100% !important;

    .ms-TextField-wrapper{
        .ms-TextField-fieldGroup{
            height: auto;
        }
    }

    .taskForm {
        display: flex;
        align-items: center;
    }

    .PlanningCardPopup-Inner {
        //padding: $gutterMedium;

        .PlanningCardPopup-LoaderWrapper {
            margin: $gutterXXXLarge;
        }

        .PlanningCardPopup-Main {
            margin-bottom: $gutterMedium;

            .PlanningCardPopup-MainLeft {
                display: inline-block;
                width: 50%;
                vertical-align: top;

                .PlanningCardPopup-task {
                    margin-bottom: $gutterXSmall;
                    padding: 0 $gutterXSmall;
                    background-color: #fff;
                    -webkit-transition: background-color $fastTransition;
                    -moz-transition:  background-color $fastTransition;
                    -o-transition:  background-color $fastTransition;
                    transition:  background-color $fastTransition;
                    &:hover{
                        background-color: color("light-grey");
                    }

                    .PlanningCardPopup-taskCheckBox {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .PlanningCardPopup-taskCheckBoxLabel {
                        display: inline-block;
                        vertical-align: -1px;
                        margin-left: $gutterXSmall;
                        width: calc(100% - 42px);
                    }

                    .PlanningCardPopup-taskRemove {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }

                .PlanningCardPopup-tasksFooter {
                    
                }

                .PlanningCardPopup-Files {
                    margin-bottom: $gutterXSmall;

                    .PlanningCardPopup-File {
                        .PlanningCardPopup-fileLabel {
                            display: inline-block;
                            width: calc(100% - 12px);
                            color: color("light-blue")
                        }
                        
                        .PlanningCardPopup-fileRemove {
                            display: inline-block;
                        }
                    }
                }
            }

            .PlanningCardPopup-MainRight {
                display: inline-block;
                margin-left: $gutterSmall;
                width: calc(50% - #{$gutterSmall});
                vertical-align: top;
            }
        }
    }
}

.isSmallViewMode {
    .Planning-AddCategory, .Planning-Category, .Planning-AddCategoryButton {
        width: calc(100% - (#{$gutterLarge} * 2));
    }

    .Planning-Board {
        display: block;
        width: 100%;
    }

    .Planning-AddCategoryButton {
        .unstyled-button {
            width: 100%;

            .Planning-AddCategoryButtonLabel {
                width: 100%;
            }
        }
    }

    .PlanningCardPopup-MainLeft, .PlanningCardPopup-MainRight {
        width: 100% !important;
        margin: 0 !important;
    }
}
