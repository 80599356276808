@import "src/style/variables.scss";

.Textbox {
    > input, 
    > textarea {
        border: 1px solid color("dusk-grey");
        border-radius: $borderRadius;
        max-width: 500px;
        padding: 6px 8px;
        resize: none;
        width: 100%;
    }

    &.faulty {
        > input {
            border-color: #E45864;
        }

        > textarea {
            border-color: #E45864;
        }
    }

    .validationErrors {
        cursor: default;

        .text {
            font-style: italic;
        }
    }
}