.DrawerHeaderTitle {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .DrawerHeaderTitle-text {
        align-items: center;
        display: flex;
        flex: 1 1 0;
        margin: 0;
    }

    .DrawerHeaderTitle-action {
        align-items: center;
        display: flex;
        flex: 0 0 24px;
        justify-content: center;
    }
}