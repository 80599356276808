@import "src/style/variables.scss";

.FilesRootView {
    .inner {
        background-color: #fff;
        display: flex;
        flex: 1 1;

        > .sources {
            /*border-right: 1px solid #eaeaea;*/
            width: 170px;

            > .header {
                border-bottom: 1px solid #eaeaea;
                font-size: 14px;
                font-weight: bold;
                line-height: 1em;
                padding: 18px;
                vertical-align: middle;
            }
        }

        .ms-DetailsList {
            .ms-FocusZone {
                padding-top: 0;
            }
        }

        > .content {
            width: 100%;

            > .FileBrowser {
                display: inline-block;
                width: 100%;
            }

            > .file-details {
                font-family: $secondaryFont;
                display: inline-block;
                width: 350px;
                box-sizing: border-box;
                vertical-align: top;
                padding: $gutterXLarge;
                border: 1px solid color(borderColor);
                border-radius: $borderRadius;

                .office-365 {
                    padding: $gutterSmall;

                    .preview-img {
                        max-height: 350px;
                        max-width: 100%;
                    }

                    .title {
                        margin-top: $gutterSmall;
                    }
                }

                .header {
                    font-weight: 400 !important;
                    padding: $gutterSmall 0;
                    position: relative;

                    .header-text {
                        max-width: calc(100% - 50px);
                    }

                    .actionmenu-component {
                        position: absolute;
                        top: $gutterSmall;
                        right: $gutterSmall;
                    }
                }

                .description {
                    padding: $gutterTiny $gutterSmall;
                }

                .preview {
                    display: flex;
                    width: 100%;
                    min-height: 100px;
                    max-height: 200px;
                    border-radius: $borderRadius;
                    margin-bottom: $gutterMedium;
                    background-color: color(light-grey);
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    overflow: hidden;

                    > img {
                        max-height: 200px;
                        max-width: 100%;
                    }

                    .icon-wrapper {
                        padding: $gutterMedium;
                        text-align: center;
                    }
                }

                .data-box {
                    margin-bottom: $gutterXXLarge;
                    .FileDetailsView-InfoPart {
                        margin-bottom: $gutterSmall;

                        .tags {
                            > a {
                                &:after {
                                    content: ", ";
                                }

                                &:last-child {
                                    &:after {
                                        content: "";
                                    }
                                }
                            }
                        }

                        .FileDetailsView-InfoPartContent {
                            margin-top: $gutterTiny;
                        }

                        .FileDetailsView-InfoPartContent-inner {
                            margin-top: $gutterTiny;
                        }
                    }
                }

                &.edit {
                    padding: $gutterLarge;
                }
            }

            &.isFile {
                > .FileBrowser {
                    width: calc(100% - 350px - calc(#{$gutterXLarge}));
                    padding-right: $gutterXLarge;
                }
            }

            &.isSmallViewMode.isFile {
                > .FileBrowser {
                    display: none;
                }

                > .file-details {
                    width: 100%;
                    border-left: 0;
                    padding: 0;
                }
            }

            &.noSourceList,
            &.isSmallViewMode {
                width: 100%;
                border-left: 0;
                margin-left: 0;
            }
        }
    }
}
