@import "src/style/variables.scss";

.LoadMoreButtonWrapper {
  text-align: center;

  button {
    color: color("light-blue");

    &:hover {
      opacity: 0.75;
      color: color("light-blue");
    }
  }

  &.visageStyle {
    background-color: #FFFFFF;
    border: 1px solid color(borderColor);
    border-radius: $borderRadius;
    position: relative;

    button {
      font-weight: 400 !important;
    }

    &:hover {
      background-color: color("light-grey");
    }

    .Visage2Icon {
      margin-left: $gutterXSmall;
    }
  }
}