@import "src/style/variables.scss";
@import "./fonts";

.popup-inner{
    //padding: $gutterMedium;
}

.textDanger{
    color:#a94442;
}
.obligatoryInfoText{
    margin-top: $gutterXSmall;
}

.ms-Modal > .ms-Dialog-main > .ms-Modal-scrollableContent {
    overflow-y: visible;
}

.ms-ContextualMenu {
    i {
        margin: 0;
        color: rgb(86, 97, 118);
    }

    &.hasSpintrIcons {
        .ms-ContextualMenu-header {
            padding-left: 4px;
        }

        .ms-ContextualMenu-link {
            padding-left: $gutterSmall;
        }

        .ms-ContextualMenu-linkContent {
            .Visage2Icon {
                height: 16px;
                margin-top: -2px;

                svg {
                    display: block;
                }
            }

            .ms-ContextualMenu-itemText {
                margin-left: $gutterSmall;
            }
        }
    }
}

.ms-ContextualMenu-link{
    -webkit-transition: background-color $fastTransition;
    -moz-transition:  background-color $fastTransition;
    -o-transition:  background-color $fastTransition;
    transition:  background-color $fastTransition;
}

.ms-CommandBar-primaryCommand {
    .ms-OverflowSet-item {
        .actionmenu-component {
            border: none;
        }

        border: 1px solid #d5d5d5;
        margin-right: $gutterSmall;
    }
}

.ms-Button {
    font-family: $secondaryFont;
}

.ms-Button--primary {
    background-color: color("visageMidBlue");
    border: 1px solid color("visageMidBlue");
    border-radius: $borderRadiusSharp !important;
    &:hover {
        background-color: color("visageMidBlue");
        opacity: 0.75;
        border: 1px solid color("visageMidBlue");
    }

    &.is-disabled {
        opacity: 0.5;
        color: #FFF;
    }
}
.ms-Button--default{
    border: 1px solid color("borderColor");
    border-radius: $borderRadiusSharp !important;
}

.ms-Persona-primaryText {
    font-weight: 550;
    font-size: 12px;
}

.ms-Persona-details {
    padding-left: $gutterSmall;
}

.ms-DetailsHeader-cellName {
    font-family: $secondaryFont;
    font-size: 12px;
}

.ms-DetailsRow-fields {
    div:last-child {
        text-overflow: clip;
    }
}

.ms-DetailsRow-cell {
    font-family: $font;
    // text-overflow: clip;
    padding-top: $gutterSmall;
    padding-bottom: $gutterSmall;
    color: color(dark-grey);
    display: flex;
    align-items: center;

    .actionmenu-component {
        display: block;
    }

    > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.spintr-list{
    .ms-DetailsList {
        .ms-DetailsRow {
            &:hover {
                background-color: color(visage2LightGray);
            }
        }
    }
}

.ms-DetailsList-contentWrapper .ms-DetailsRow-fields {
    line-height: 24px !important;
}

.ms-DetailsHeader-cell {
    &:first-child {
        border-top-left-radius: $borderRadius;
        .ms-TooltipHost {
            border-top-left-radius: $borderRadius;
        }
    }

    &:last-child {
        border-top-right-radius: $borderRadius;
        .ms-TooltipHost {
            border-top-right-radius: $borderRadius;
        }
    }

    .ms-TooltipHost {
        &:hover {
            background-color: color(light-grey);
        }
    }

    .ms-DetailsHeader-cellName {
        color: color(mid-grey);
        font-weight: 550;
        font-size: 12px;
    }
}

.ms-Pivot-link{
    -webkit-transition: background-color $fastTransition;
    -moz-transition:  background-color $fastTransition;
    -o-transition:  background-color $fastTransition;
    transition:  background-color $fastTransition;
}

.ms-DetailsRow, .ms-DetailsHeader-cell{
    -webkit-transition: background-color $fastTransition;
    -moz-transition:  background-color $fastTransition;
    -o-transition:  background-color $fastTransition;
    transition:  background-color $fastTransition;
}

.searchBox {
    font-family: $font;
    background-color: color(light-grey);
    color: #091B3D;
    border-radius: $borderRadiusRounder;
    border: solid 1px #ececec;
    height: 40px;
    padding-left: 0px;

    .ms-SearchBox {
        border: 0;
        background-color: transparent;
        margin-left: calc(#{$gutterXSmall} + #{$gutterTiny});
    }
}

.ms-CommandBar {
    margin-bottom: 14px;
    border-bottom: none;
    height: $commandBarHeight;
    padding: 0;
    margin-bottom: $gutterXXLarge;

    .ms-Button{
        -webkit-transition: background-color $fastTransition;
        -moz-transition:  background-color $fastTransition;
        -o-transition:  background-color $fastTransition;
        transition:  background-color $fastTransition;
    }

    .searchBox {
        margin: 0px;
        .ms-SearchBox {
            padding: 0;
        }
    }
    // .ms-SearchBox {
    //     margin: 0;
        // background-color: #FFF;
        // border: 0;
        // border-radius: $borderRadius;
        // padding: 0 $gutterSmall;

        // &.is-active {
        //     border: none;
        // }
    //}

    .ms-OverflowSet-item {
        border: 0;
        border-radius: $borderRadiusSharper;
        height: $commandBarHeight;

        .ms-CommandBarItem-link {
            padding: 0 $gutterMedium;
        }

        button {
            border-radius: $borderRadiusSharp;
        }

        .ms-Button-menuIcon {
            margin-left: $gutterTiny;
            color: color(light-blue);
        }

        &:last-child {
            margin-right: 0 !important;
        }
    }
}

.ms-CommandBar-secondaryCommand {
    .ms-OverflowSet-item {
        margin-left: $gutterSmall;
    }
}

.CommandBar-GrowSearch {
    .ms-CommandBar {
        .ms-OverflowSet-item {
            &:first-child {
                flex-grow: 1;

                .SpintrSearch {
                    // max-width: 440px;
                    width: 100%;
                    min-width: 200px;
                }

                .ms-SearchBox {
                    width: 100%;
                }
            }
        }
    }
}

.ms-Pivot {
    .ms-Callout-container {
        z-index: 1;
    }
}

.tinymce{
    .tox-tinymce{
        border-color: color("mid-grey");
        border-radius: $borderRadius;
    }
}
.ms-TextField-field{
    border-radius: $borderRadius;
}

$totalRigthPanelWidth: $rightPanelWidth + $gutterLarge;

.FormFooterBar {
    position: fixed;
    left: $spintrSidebarMaxWidth;
    right: 0px;
    bottom: 0px;

    &.top-bar {
        bottom: auto;
        top: 0;
        background-color: #fff !important;
        height: $spintrHeaderHeight;
        display: flex;
        flex-direction: column;
        justify-content: center;

        div[role="menu"] {
            padding-left: $gutterXXLarge !important;
            padding-right: $gutterXXLarge !important;
            padding-top: $gutterMedium !important;
            padding-bottom: $gutterMedium !important;
        }
    }

    button {
        font-family: $font;
    }
}

.VisageSideBarHidden {
    .FormFooterBar {
        left: 0;
    }
}

@media screen and (max-width: 1550px) {
    .FormFooterBar {
        left: $spintrSidebarMinWidth;
    }
}

.FormFooterBar{
    .ms-Button{
        -webkit-transition: background-color $fastTransition;
        -moz-transition:  background-color $fastTransition;
        -o-transition:  background-color $fastTransition;
        transition:  background-color $fastTransition;
    }
}

.ms-DetailsList-contentWrapper {
    .ms-DetailsRow-fields {
        font-size: 12px;
    }
    .ms-DetailsRow-cell {
    }
}

.ms-DetailsList-contentWrapper .ms-DetailsRow-fields {
    line-height: 2.375em;
}

.dateInput {
    padding-left: 2.5em;
    font-size: 12px;
}

.SpintrDatePicker {
    .label {
        display: flex;
        flex-direction: row;
        align-items: center;

        .ms-TooltipHost {
            margin-left: $gutterXSmall;
        }
    }

    .react-datepicker__close-icon{
        height: auto;
        top: 1.1em;
        right: 1em;
    }
    .react-datepicker-wrapper {
        > .react-datepicker__input-container {
            input {
                min-width: 200px;
            }

            .react-datepicker__close-icon::after {
                background-color: #909090;
            }
        }
    }

    .react-datepicker__portal {
        .react-datepicker__current-month,
        .react-datepicker-time__header {
            font-size: 0.944rem;
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            width: 1.7rem;
            line-height: 1.7rem;
        }

        .react-datepicker__navigation {
            border-width: 0.45rem;
        }
    }
}

.SpintrDatePicker.time-only {
    .react-datepicker-wrapper {
        > .react-datepicker__input-container {
            input {
                background-color: transparent;
                min-width: 40px !important;
                width: 40px;
            }
        }
    }
}

i.fa-calendar {
    position: absolute;
    top: 1em;
    left: 1em;
}

.ms-Button--primary, .ms-Button--default{
    -webkit-transition: background-color $fastTransition;
    -moz-transition:  background-color $fastTransition;
    -o-transition:  background-color $fastTransition;
    transition:  background-color $fastTransition;
}



.react-datepicker-popper {
    // needs to be higher than tinymce toolbar for calendar event popup form
    z-index: 2;
}

.isSmallViewMode {
    .spintr-modal {
        .ms-Dialog-main {
            max-width: 100%;
        }
    }
}
// .ms-BasePicker-text {
//     min-height: 40px !important;
// }

.ms-ComboBox-container {
    .ms-ComboBox {
        height: 48px;
        padding-left: $gutterMedium;
        padding-right: 42px;
        &:after {
            border: 1px solid color("mid-grey");
            line-height: 2.5em;
            font-size: 12px;
            display: flex;
            align-items: center;
            border-radius: $borderRadius;
        }

        input {
            height: 48px;
            line-height: 2.5em;
            font-size: 12px;
        }

        button {
            height: 48px;

            &:hover {
                background-color: #FFF;
            }
        }
    }
}

.ms-Dropdown {
    min-height: 48px;
    width: 100%;
    min-width: 200px;
    border-radius: $borderRadius;
    span {
        border-color: color("mid-grey");
        min-height: 48px;
        line-height: 2.5em;
        font-size: 12px;
        display: flex;
        align-items: center;
        border-radius: $borderRadius;
        padding: 0 $gutterSmall 0 $gutterSmall;
    }

    .ms-Dropdown-title {
        padding-right: $gutterXXXLarge;
        display: block;
        line-height: 48px;
    }

    &:focus::after{
        border-radius: $borderRadius;
    }
}

.dropdown-title, .dropdown-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.dropdown-option {
        column-gap: $gutterSmall;
    }

    .status-ball {
        margin: 0 $gutterTiny;
    }
}

.ms-Dropdown-optionText{
    padding-left: 4px;
}

.fullWidthForm {
    max-width: 750px;
    margin: 0 auto;

    .control {
        .css-40 {
            max-width: 550px;
        }
    }
}
.ms-Label {
    font-size: 12px;
    color: color(mid-grey);
    font-weight: 550;
    line-height: 1em;
    margin-bottom: 8px;
    margin-top:8px;
    padding: 0;
}
.linkFGColor {
    color: color("light-blue");
}

.PivotItemWithActionMenu {
    .actionmenu-component {
        margin-left: $gutterXSmall;
    }
}

.general-row-break {
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    white-space: unset !important;
}

.raw-row-break {
    word-break: break-all;
}

.ms-Button {
    border-radius: $borderRadiusSharper;
}

.ms-ContextualMenu-Callout {
    border-radius: $borderRadius;
}

.ms-ContextualMenu-link {
    padding-left: 8px;
}

@keyframes pulse {
    from {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    50% {
        transform: scale(1.05);
        -webkit-transform: scale(1.1);
    }
    75% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    to {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

@keyframes rotate {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(90deg); }
}

.icon-animation {
    &.animation-pulse:hover {
        .Visage2Icon, svg, i {
            animation-name: pulse;
            animation-duration: .5s;
        }
    }

    &.animation-rotate:hover {
        .Visage2Icon, svg, i {
            animation-name: rotate;
            animation-duration: .5s;
        }
    }
}