@import "src/style/variables";

.StartPageBuilderColumn {
    flex: auto 0 0;
    border: 1px dashed color("neutralBlue");
    padding: 1px;
    margin-bottom: -1px;
    margin-right: -1px;

    &:hover:not(.selected) {
        opacity: 0.75;
    }

    &.selected {
        background-color: color("blueHighlight");
        border: 2px dashed rgba(color("neutralBlue"), 0.75);
        padding: 0;

        .StartPageBuilderColumn-cell {
            &:not(:last-child) {
                border-bottom-color: rgba(color("neutralBlue"), 0.75);
            }
        }
    }

    .visage-box-shadow {
        box-shadow: 0px 10px 74px rgba(0, 0, 0, 0.1);
    }

    .builder-drop-area {
        .empty-placeholder {
            background-color: color("blueHighlight");
            padding: $gutterSmall;
            margin: $gutterLarge;
            width: calc(100% - #{$gutterLarge * 2} - #{$gutterSmall * 2});

            .text-wrapper {
                border: 1px dashed color("neutralBlue");
                padding: $gutterMedium;
                text-align: center;
            }
        }
    }

    .StartPageBuilderColumn-cell {
        border-bottom: 2px dashed transparent;
        padding: $gutterMedium $gutterMedium ($gutterMedium - 2px);
    }
}

.StartPageBuilderRow:last-child .StartPageBuilderColumn {
    border-bottom-width: 1px;

    &.selected {
        border-bottom-width: 2px;
    }
}