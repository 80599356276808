@import "src/style/variables.scss";

.calendar-event-popup {
    width: 650px !important;
}

.calendar-event-popup-view {
    .data-box {
        margin-bottom: $gutterSmall;

        .left {
            width: 7.5em;
        }

        .right {
            width: calc(100% - 13em);
        }

        .left,
        .right {
            display: inline-block;
        }
    }

    .content {
        margin-bottom: $gutterSmall;
    }

    .attendees {
        margin-bottom: $gutterSmall;
    }
}
