@import "src/style/variables.scss";
@import "src/style/common.scss";

.SystemStatusResource {
    border-radius: $borderRadiusRound;
    padding: $gutterMedium;
    border: 1px solid color(borderColor);
    max-width: 450px;

    .SystemStatusResourceInfo {
        // min-width: 240px;
        .ms-Persona-details {
            padding-right: 0;

            .title-row {
                > .label-component {
                    flex: 1 1 auto;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                > .SystemStatusTypePill {
                    flex: 0 0 auto;
                }
            }
        }
    }

    .footer {
        align-items: center;
        column-gap: $gutterMedium;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: $gutterMedium;

        > .cta {
            flex: 1 1 calc(100% - 24px);
            display: flex;
        }

        .footer-button {
            height: 24px;
            width: 24px;
            border-radius: 24px;
            background-color: $spintrGrayLighter;
            display: flex;
            flex: 0 0 24px;
            align-items: center;
            align-content: center;
            justify-content: center;
        }

        &.buttons {
            justify-content: flex-end;
        }
    }
}