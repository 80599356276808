@import "src/style/variables";

.AppPageComponentGroup {
    display: flex;
    flex-direction: column;
    gap: $gutterMedium;
    
    > div {
        flex-grow: 1;
        flex-shrink: 1;
    }
    
    &.horizontal {
        flex-direction: row;
        gap: $gutterXXLarge;
    }
}

@media only screen and (max-width: $vmTabletLandscape) {
    .AppPageComponentGroup {
        &.horizontal {
            flex-direction: column;
        }

        > * {
            width: 100% !important;
        }
    }
}