@import "src/style/variables";
@import "src/search/variables";

$searchBarHeight: 40px;
$headerSearchMaxWidth: 400px;
$headerSearchMinWidth: 200px;
$resultSidebarWidth: 288px;

.HeaderSearchWrapper {
    display: flex;
    flex-direction: row;
    column-gap: $gutterMedium;
    width: 100%;

    .AssistantButton {
        min-width: 150px;
        border-radius: 30px;
        height: $searchBarHeight;

        .assistant-wrapper {
            cursor: pointer;
            flex: 1 1 132px;
            transition: all 0.2s;
            height: $searchBarHeight;
        }
    }

    > .AssistantButton {
        cursor: pointer;

        .unstyled-button {
            pointer-events: none;
        }
    }
}

.AssistantButton {
    &.closed {
        min-width: 0px !important;
        flex-grow: 0;
        padding-right: $gutterMedium;

        .assistant-wrapper {
            display: flex;
            flex-wrap: nowrap;

            .AssistantPartInner {
                display: flex;
                flex-wrap: nowrap;

                .assistant-cta {
                    > span {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

.AssistantPartInner {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: $searchBarHeight;
    width: 100%;

    .assistant-cta-wrapper {
        flex-grow: 1;
    }

    .assistant-cta {
        flex-grow: 1;

        > span {
            > span {
                font-weight: 600;
            }
        }

        &.has-subjects {
            cursor: pointer;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    .assistant-icon {
        margin: 5px;
        height: 28px;
        margin-right: $gutterSmall;
    }

    .close-button {
        margin-right: 17px;

        i {
            font-size: 12px;
        }
    }
}

.HeaderSearch {
    ul,
    ol,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &.active {
        &.search-mode {
            .search-bar {
                .input-container {
                    .assistant-wrapper {
                        flex-basis: 50px;

                        .assistant-cta {
                            display: none;
                        }
                    }

                    .wrap {
                        flex-basis: calc(100% - 50px);
                    }
                }
            }
        }

        &.assistant-mode {
            .search-bar {
                .input-container {
                    .assistant-wrapper {
                        flex-basis: calc(100% - 50px);
                    }

                    .Visage2Icon {
                        display: none;
                    }

                    .wrap {
                        padding-top: 0px;
                        flex-basis: 50px;

                        input {
                            display: none;
                        }

                        .icon {
                            margin: 6px 15px 16px 15px;
                            position: relative;
                            right: -1px;
                            top: -1px;
                        }
                    }
                }
            }
        }
    }

    .search-bar {
        .clear-button {
            position: relative;
            top: 1px;
        }

        .input-container {
            display: flex;
            flex-direction: row;
        }

        .icon {
            margin-top: 7px;
            margin-left: 6px;
            height: 36px;
            width: 36px;
            border-radius: 36px;
            text-align: center;
            margin-right: $gutterSmall;

            > * {
                line-height: 36px;
                margin-right: 0px;
                top: auto;
                color: #FFF;
            }
        }

        .wrap {
            flex-grow: 1;

            .toggle-search-mode {
                border-radius: $borderRadius;
                padding: $gutterTiny $gutterSmall $gutterTiny $gutterSmall;
                position: absolute;
                right: 10px;
                top: 10px;


                .search-mode-text, .toggle-search-icon {
                    line-height: 20px;
                    vertical-align: middle;
                }

                .toggle-search-icon {
                    font-size: 12px;
                    transform: scaleX(-1);
                    margin-right: $gutterXSmall;
                }

                .search-mode-text {
                    display: inline-block;
                }
            }

            .query-input {
                background-color: transparent;
                border: 0 none;
                color: color("dark-grey");
                font-size: 14px;
                font-family: $font;
                font-weight: 500;
                padding: 0;
                width: 100%;
                outline: 0 !important;
                top: 1px;

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: color("dark-grey");
                    opacity: 1; /* Firefox */
                }
                
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: color("dark-grey");
                }
                
                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: color("dark-grey");
                }
            }

            .type-selector {
                display: none;
                position: absolute;
                right: 0;
                top: -7px;

                .ms-Dropdown {
                    border: 0 none;

                    .ms-Dropdown-title {
                        background-color: transparent;
                        border: 0 none;
                    }
                }
            }

            .search-mode-button {
                position: absolute;
                right: 5px;
                top: 0px;
            }
        }

        .icon {
            svg {
                color: color("mid-grey");
                margin-top: 2px;
                scale: 1.9;
            }
        }
    }

    .assistant-popdown {
        left: 0;
        position: absolute;
        top: $searchBarHeight + $gutterXXLarge + ($gutterLarge * 2);
        z-index: $spintrOverlayContentZIndex;
    }

    &.active {
        max-width: 650px;

        .header-search-overlay {
            bottom: 0;
            height: 100%;
            opacity: 0.3;
            transition: opacity 0.3s;
        }

    }

    &:not(.assistant-enabled) {
        .search-bar {
            .icon {
                margin-left: $gutterMedium !important;
            }
        }

        .query-input {
            width: calc(100% - 44px) !important;
            position: relative;
            top: -1px;
        }
    }
}

.VisageSidebarMode3 {
    .HeaderSearch {
        &.modal-mode {
            .search-bar {
                max-width: 600px;
            }
        }
    }
}

.search-callout {
    .popdown {
        &.hidden {
            border-top: 0 none;
            height: 0;
        }

        .error, .empty-set {
            cursor: default;
            padding: $gutterXXLarge;
            text-align: center;
            box-sizing: border-box;
        }

        .results {
            .hits .empty-set {
                display: inline-block;
                vertical-align: top;
                width: 100%;
            }

            .hits {
                max-height: 75vh;
                overflow-y: scroll;
                padding: 0 $gutterMedium;
            }

            &.has-sidebar {
                .hits .empty-set {
                    width: calc(100% - (#{$resultSidebarWidth}));
                }
            }
        }

        .results {
            .pivot-compact-style {
                margin: $gutterMedium $gutterMedium $gutterLarge $gutterMedium;
            }

            .sidebar {
                > div {
                    .heading {
                        height: 12px;
                        margin-bottom: $gutterSmall;

                        span {
                            text-transform: uppercase;
                            font-weight: 550;
                            color: color("mid-grey");
                            letter-spacing: 1px;
                            line-height: 12px;
                            display: block;
                            font-size: 12px;
                        }
                    }

                    &:not(:first-child) {
                        .heading {
                            margin-top: $gutterMedium;
                        }
                    }
                }
            }

            .sidebar,
            .content-wrapper {
                box-sizing: border-box;
                display: inline-block;
                vertical-align: top;
            }

            .content-wrapper {
                width: 100%;

                ul {
                    li {
                        &:last-child {
                            > a {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }

            &.has-sidebar {
                .sidebar {
                    width: $resultSidebarWidth;
                    padding-left: $gutterLarge;
                    border-left: 1px solid #EDEDED;
                    margin-left: -1px;

                    ul {
                        li {
                            margin-bottom: $gutterSmall;
                            cursor: pointer;

                            span.type {
                                color: color("mid-grey");
                                font-weight: 550;
                                font-size: 12px;
                                line-height: 14px;
                                margin: 0;
                                text-transform: uppercase;
                                margin-bottom: $gutterTiny;
                            }

                            &:last-child {
                                margin-bottom: 0px;
                            }

                            &:hover {
                                opacity: 0.75;
                            }
                        }
                    }

                    .recommended {
                        ul {
                            li {
                                margin-bottom: $gutterSmall;

                                &:last-child {
                                    margin-bottom: 0px;
                                }

                                .document {
                                    background-color: color(light-grey);
                                    padding: $gutterSmall;
                                    border-radius: $borderRadius;

                                    > div {
                                        span {
                                            &:not(:first-child) {
                                                margin-bottom: -6px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .related {
                        margin-bottom: calc(#{$gutterSmall} * -1);

                        ul {
                            li {
                                display: inline-block;
                                margin-right: $gutterSmall;
                                margin-bottom: $gutterSmall !important;
                                border-radius: $borderRadius;
                                background-color: color(light-grey);

                                div {
                                    padding: 0px $gutterXSmall;

                                    div {
                                        color: color(dark-grey);
                                        font-weight: 550;
                                        font-size: 12px;
                                    }
                                }

                                &:hover {
                                    opacity: 0.75;
                                }
                            }
                        }
                    }
                }

                .content-wrapper {
                    width: calc(100% - (#{$resultSidebarWidth}));
                    padding-right: $gutterLarge;
                    border-right: 1px solid #EDEDED;
                }
            }

            .footer {
                margin: $gutterMedium;
                
                .count {
                    display: inline-block;
                    width: calc(50% - 1px);

                    > a {
                        display: block;
                        padding: $gutterXSmall $gutterSmall;

                        > * {
                            display: inline-block;
                            line-height: 1.5em;
                            vertical-align: middle;
                        }

                        > i {
                            margin-right: $gutterTiny;
                        }
                    }

                    &:first-child {
                        text-align: right;
                        border-right: 1px solid color("smoke");
                    }

                    &:only-child {
                        border-right: 0 none;
                        text-align: center;
                        width: 100%;
                    }

                    &.office365-count {
                        > a {
                            color: color("office365");
                        }
                    }
                }
            }
        }

        ul,
        ol,
        li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }
}