@import "src/common.scss";
@import "../plannerVariables.scss";

.TimelineGroup {
    border-bottom: 1px solid $spintrBlueLighter;
    box-sizing: border-box;
    left: 0;
    position: absolute;
    right: 0;

    .TimelineGroup-axisLabel {
        align-items: center;
        background-color: $spintrWhite;
        border-bottom: 1px solid #F1F2FC;
        box-sizing: border-box;
        display: flex;
        left: 0;
        padding: 0 $spacingXXLarge;
        position: sticky;
        z-index: map-get($plannerStrata, rowLabel);

        .TimelineGroup-axisButton {
            align-items: center;
            display: flex;
            flex: 0 0 100%;
            justify-content: space-between;
        }

        .TimelineGroup-axisIcon {
            transform: rotate(180deg);
            transition: transform 0.15s ease-in-out;
        }
    }

    .TimelineGroup-viewMore {
        display: flex;
        width: 100%;
        position: absolute;

        .TimelineGroup-viewMore-button {
            align-items: center;
            background-color: $spintrWhite;
            border-bottom: 1px solid #F1F2FC;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            gap: $spacingTiny;
            justify-content: flex-start;
            left: 0;
            padding: $spacingMedium ($spacingLarge * 2);
            position: sticky;
            top: 0;
            z-index: map-get($plannerStrata, rowLabel);

            .TimelineGroup-viewMore-icon {
                transform: rotate(180deg);
                transition: transform 0.15s ease-in-out;
            }
        }
    }

    &.showingAll {
        .TimelineGroup-viewMore-button {
            .TimelineGroup-viewMore-icon {
                transform: rotate(0deg);
            }
        }
    }

    &.expanded {
        .TimelineGroup-axisLabel {
            .TimelineGroup-axisIcon {
                transform: rotate(0deg);
            }
        }
    }
}