@import "src/style/variables.scss";

.InformationFeedEntry {
    background-color: #FFF;
    border-radius: $borderRadiusSharp;
    padding: $gutterMedium;
    display: flex;
    flex-direction: column;

    :hover .actionmenu-component {
        opacity: 1;
    }

    position: relative;

    .entry-title {
        position: relative;
        margin-bottom: $gutterTiny;

        a {
            color: color(visageGray);
            max-width: 100%;
            display: inline-block;
        }
    }

    .entry-preamble {
        > a {
            color: color(visageGray);

            .label-component {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                display: block;
            }
        }
    }

    .entry-meta {
        margin-bottom: $gutterTiny;

        .date-readtime-separator {
            margin-left: 2px;
            margin-right: 2px;
        }
    }

    .entry-lead {
        a {
            color: color(mid-grey);
            max-width: calc(100% - 24px);
        }
    }

    .actionmenu-component {
        position: absolute;
        display: inline-block;
        opacity: 0;
        right: 0px;
        top: 0px;
    }

    .entry-image {
        width: 100% !important;
        display: block;
        height: auto;
        width: 100%;
        overflow: hidden;
        position: relative;
        background-position: center center;
        background-size: cover;
        border-radius: $borderRadiusSharp;
        margin-bottom: $gutterMedium;

        &.fixed-height {
            height: 126px;
        }

        img {
            width: 100% !important;
            border-radius: $borderRadiusSharp;
            display: block;
        }
    }

    .entry-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .entry-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .entry-content-inner {
                flex-grow: 1;
            }

            .entry-metadata {
                position: relative;

                .InformationFeedEntry {
                    background-color: transparent;
                }
            }

            .InformationFeedEntryLink {
                display: inline-block;
            }

            .entry-category {
                text-transform: uppercase;
            }

            .entry-title {
                h4 {
                    line-height: 24px;
                    margin: 0px;
                }
            }

            .entry-lead {
                margin-top: $gutterTiny;
            }
        }

        &.spotlight {

            .like-count,
            .comment-counter,
            .label-component,
            .label-component a {
                color: #FFF;
            }

            .LikeContainer:not(.has-liked),
            .Commentors {
                svg {
                    g {
                        stroke: #FFF;
                    }
                }
            }
        }
    }

    .entry-content-image {
        .entry-image {
            width: 100% !important;
        }
    }

    .entry-interactions {
        .InteractionsBar {
            border-top: 1px solid color(borderColor);
            padding-top: $gutterMedium;
        }
    }
}

.template3 .InformationFeedEntry {}