@import "src/common.scss";

$imageSize: 40px;

.ProductSuggestionsEntry {
    align-items: center;
    border: 1px solid $spintrGrayLighter;
    border-radius: $brRounder;
    display: flex;
    gap: $spacingMedium;
    padding: $spacingMedium;

    &:not(:first-child) {
        margin-top: $spacingSmall;
    }

    .logo {
        align-items: center;
        background-color: $spintrWhite;
        border-radius: $brSharp;
        display: flex;
        height: $imageSize;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: $imageSize;

        img {
            max-width: 100%;
            object-fit: contain;
        }
    }

    .text-content {
        flex: 1 1 0;
    }

    .actions {
        .dismiss-button {
            i {
                color: $spintrGray;
            }
        }
    }
}