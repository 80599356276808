@import "src/style/variables.scss";

.SystemStatus {
    border-radius: $borderRadiusRound;
    padding: $gutterSmall;
    border: 1px solid color(borderColor);
    display: flex;
    flex-direction: row;
    gap: $gutterMedium;
    flex-wrap: wrap;

    .spacer {
        flex-grow: 1;
    }

    .segment {
        display: flex;
        flex-direction: column;
        row-gap: $gutterMedium;

        &:nth-child(1) {
            width: calc((100% - (#{$gutterMedium} * 2)) * 0.25);
        }

        &:nth-child(2) {
            width: calc((100% - (#{$gutterMedium} * 2)) * 0.4);
            flex-grow: 1;
        }

        &:nth-child(3) {
            width: calc((100% - (#{$gutterMedium} * 2)) * 0.35);
            max-width: 250px;
        }

        .SystemStatusResourceInfo {
            // min-width: 240px;
            .ms-Persona-details {
                padding-right: 0;

                .title-row {
                    > .label-component {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
    
                    > .SystemStatusTypePill {
                        flex: 0 0 auto;
                    }
                }
            }
        }

        .footer-button {
            display: flex;
        }

        .info {
            display: flex;
            flex-direction: column;
            row-gap: $gutterTiny;

            > div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                
                > * {
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    .SystemStatus {
        flex-direction: column;

        .segment {
            width: 100% !important;
            max-width: 100% !important;
        }
    }
}
