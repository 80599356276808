@import "src/style/variables";

.Composer {
    margin: 0 $gutterSmall;
    padding: $gutterSmall 0;
    border-top: 1px solid color(visage2LightGray2);

    .row {
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;
        align-items: flex-end;

        > * {
            min-height: 52px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        > .inner {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            //border-radius: $borderRadiusRounder;
            border-radius: $borderRadiusRound;
            border: 1px solid color(visage2LightGray2);
            padding: $gutterSmall;
            column-gap: $gutterXSmall;
            row-gap: $gutterSmall;
            width: 0;

            .inner-row {
                display: flex;
                flex-direction: row;
                column-gap: $gutterXSmall;
            }

            .wrapper {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .footer-attachments-selector {
                border-top: 1px solid color(visage2LightGray2);
                padding-top: $gutterSmall;

                .ComposerAttachmentSelector {
                    justify-content: flex-end;
                }
            }
    
            .attachments {
                display: flex;
                flex-direction: row;
                gap: $gutterSmall;
                border-top: 1px solid color(visage2LightGray2);
                padding-top: $gutterSmall;
                flex-wrap: wrap;
            }
        }

        .send-button {
            height: 36px;
            width: 36px;
            border-radius: 36px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            background-color: color(visageMidBlueBackground);

            &:hover {
                opacity: 0.75;
            }

            .SpintrLoader {
                height: auto;
                width: auto;
                padding: 0px;
                margin: 0px;
            }

            > * {
                pointer-events: none;
            }
        }
    }
    
    &.expanded {
        .row {
            .inner {
                .inner-row {
                    flex-direction: column !important;

                    .wrapper {
                        .text-input {
                            min-height: 100px;
                        }
                    }

                    .ComposerAttachmentSelector {
                        justify-content: flex-end;
                        border-top: 1px solid color(borderColor);
                        padding-top: $gutterSmall;
                    }
                }
            }
        }
    }

    &.is-loading {
        pointer-events: none;
    }
}   