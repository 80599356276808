@import "src/style/variables.scss";

.ActivityLogView {
    .feedItem {
        margin-bottom: $gutterSmall;

        .feedItem-left, .feedItem-right {
            display: inline-block;
            vertical-align: middle;
        }

        .feedItem-left {
            margin-right: $gutterSmall;
        }
    }
}
