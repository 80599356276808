@import "src/style/variables.scss";

$indicatorSize: 8px;
$indicatorHeight: 19px;

.slider {
    position: relative;
    
    .slider-indicator {
        display: flex;
        border-radius: $borderRadius;
        height: $indicatorHeight;
        line-height: $indicatorHeight;
        padding: 0 $gutterXLarge $gutterXLarge;
        width: auto;
        
        .pause-resume {
            color: color("mid-grey");
        }

        .indicator-dot {
            background-color: rgba(255, 255, 255, 0.4);
            border-radius: $indicatorSize;
            display: inline-block;
            height: $indicatorSize;
            margin: 5px 6px 5px 0;
            width: $indicatorSize;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                background-color: #FFF;
            }
        }
    }

    .slider-item {
        position: absolute;
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
        opacity: 0;
        
        &.active {
            transform: translateZ(0);
            opacity: 1;
        }

        &:not(.active) {
            pointer-events: none;
        }
    }

    &.slider-relative {
        .slider-item {
            position: relative;
        }
    }
    .back-next{
        display: flex;
        align-items: center;
        .back{
            opacity: 80%;
            margin-left: $gutterXXLarge;
        }
        .next{
            margin-left: $gutterXSmall;
        }
}
}
