@import "src/style/variables";

.MemoHeader {
    display: flex;
    flex-direction: column;
    row-gap: $gutterSmall;

    .top-row {
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;
        align-items: center;
        border-bottom: 1px solid color(borderColor);
        padding-bottom: $gutterSmall;
    }
}
