@import "src/common.scss";

.PlannerItemFormDrawer {
    .PlannerItemFormDrawer-body { 
        background: linear-gradient(180deg, rgba(255,255,255,0) 90%, rgba(0,0,0,0.05) 100%);
        position: relative;
    }
    
    .PlannerItemFormDrawer-footer {
        background-color: $spintrWhite;
        justify-content: space-between;

        .PlannerItemFormDrawer-saveActions {
            display: flex;
            gap: $spacingLarge;

            .PlannerItemFormDrawer-button {
                border-radius: $brNormal !important;
            }

            .PlannerItemFormDrawer-draftButton {
                background-color: $spintrWhite;
                color: $spintrContentNormal;
                border-color: $spintrGray;
            }
        }
    }
}