@import "src/style/variables";

.ms-Persona {
    &.initialsGreyBackground {
        .ms-Persona-initials {
            background-color: color("light-grey") !important;

            span {
                color: color("dark-grey");
            }
        }
    }

    &.hideImage {
        .ms-Persona-coin {
            display: none;
        }
        .ms-Persona-details {
            padding-left: 0;
        }
    }

    &.initialsIconInsteadOfInitials {
        .ms-Persona-initials {
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .ms-Persona-presence {
        .ms-Persona-presenceIcon {
            display: none;
        }
    }
}
