@import "src/style/variables.scss";

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
}

.SocialPostTypeContent {
    border-radius: $borderRadius;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: whitesmoke;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 100%;

        > div {
            &:not(.confetti) {
                z-index: 1;
            }
        }
    }

    .edit-controls {
        .close-button {
            position: absolute;
            top: $gutterMedium;
            right: $gutterMedium;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            background-color: rgba($color: #FFFFFF, $alpha: 0.9);
            border-radius: 16px;
        }

        > .unstyled-button:hover{
            background-color: #FFF;
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: $gutterSmall;
        flex-grow: 1;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 100%;
        z-index: 1;
    }

    &:not(.editMode) {
        margin-top: $gutterMedium;
    }
}

.SocialPostTypeContentInput {
    input {
        background-color: transparent;
        color: #FFF;
        border: 0;
        outline: 0;
        font-size: 14px;
        text-align: center;
        line-height: 1.5em;
        border: 1px solid rgba($color: #FFFFFF, $alpha: 0.5);
        border-radius: $borderRadius;
        padding: $gutterXSmall $gutterSmall;
        // min-width: 50px;

        @include placeholder {
            color: #FFF;
            opacity: 1;
            min-width: min-content;
        }
    }

    .hidden-value {
        position: absolute;
        opacity: 0;
        height: 0px;
        overflow: hidden;

        > * {
            font-size: 14px;
        }
    }

    &.SocialPostTypeContentInput-big {
        .hidden-value {
            > * {
                font-size: 18px;
            }
        }

        input {
            font-size: 18px;
            padding: $gutterXSmall $gutterSmall;
        }
    }
}

.SocialPostType-8 {
    .icon-wrapper {
        background-color: #FFF;
        height: 60px;
        width: 60px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-bottom: $gutterMedium;
    }

    > div {
        .label-component {
            &:not(:last-child) {
                margin-bottom: -10px;
            }
        }
    }
}

.SocialPostType-9, .SocialPostType-8 {
    min-height: 240px;
}

.SocialPostType-9, .SocialPostType-7 {
    .confetti {
        position: absolute;
        width: 100%;
        top: 0px;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        justify-content: center;
        -webkit-animation: loaderFadeIn 1s;
        -moz-animation: loaderFadeIn 1s;
        -ms-animation: loaderFadeIn 1s;
        -o-animation: loaderFadeIn 1s;
        animation: loaderFadeIn 1s;
    }

    .users {
        z-index: 1;
        margin-top: $gutterMedium;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        column-gap: $gutterXSmall;
    }

    // .ms-Persona-imageArea {
    //     border: 3px solid #FFF;
    // }
    
    .user-count-many {
        .users {
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;

            > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                align-content: center;
                justify-content: center;
            }

            .images {
                margin-bottom: $gutterMedium;
                height: 42px;
                display: inline-block;

                > * {
                    display: inline-block;
                    vertical-align: top;
                    height: 42px !important;
                    
                    &:not(:first-child) {
                        margin-left: -10px;
                    }
                }
            }
        }
    }

    .user-count-2 {
        .users {
            width: 100%;

            .user {
                display: flex;
                flex-direction: row;
                column-gap: $gutterSmall;
                align-items: center;
                width: 50%;

                &:first-child {
                    justify-content: flex-end;
                }
            }
        }
    }
}

.add-button-big {
    margin-top: $gutterMedium;

    > div {
        padding: $gutterXSmall $gutterSmall;
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;
        background-color: rgba($color: #FFFFFF, $alpha: 0.2);
        border: 1px solid #FFF;
        border-radius: $borderRadiusSharper;

        .Visage2Icon {
            position: relative;
            top: 2px;
            margin-left: -5px;
        }
    }
}

.add-button-small, .decoration-button-small {
    position: absolute;

    > div {
        border-radius: $borderRadiusSharper;
        background-color: rgba($color: #000000, $alpha: 0.2);
        padding: $gutterXSmall;
        display: inline-block;
        position: relative;
        z-index: 3;
    }

    &:hover {
        opacity: 0.75;
    }
}

.add-button-small {
    right: $gutterMedium;
    bottom: $gutterMedium;
}

.decoration-button-small {
    left: $gutterMedium;
    top: $gutterMedium;
    z-index: 1;
}

.add-button-big, .add-button-small, .decoration-button-small {
    &:hover {
        opacity: 0.75;
    }
}