@import "src/style/variables";

.AssistantMessage {
    display: flex;
    gap: $gutterSmall;
    margin-bottom: $gutterMedium;

    &.user {
        flex-direction: row-reverse;
        text-align: right;

        .text {
            .text-wrapper {
                background-color: color("visageMidBlue");
                border-radius: $borderRadius 0 $borderRadius $borderRadius;
                color: #FFF;
            }
        }
    }

    .image {
        flex: 0 0 40px;

        img {
            border-radius: 100%;
            display: block;
            height: 40px;
            overflow: hidden;
            width: 40px;
        }
    }

    .text {
        flex: 1 1 calc(100% - 40px);
        margin-left: 50px;
        margin-right: 0px;

        .text-wrapper {
            border: 1px solid transparent;
            border-radius: 16px;
            display: inline-block;
            position: relative;

            .message-text {
                padding: 8px;

                sub {
                    font-size: 8px;
                    margin-left: 2px;
                }
            }

            .message-text {
                position: absolute;
                top: 0px;
                left: 0px;

                span {
                    line-height: 18px;
                }

                .reference {
                    height: 16px;
                    width: 16px;
                    border-radius: 16px;
                    background-color: #FFF;
                    display: inline-block;
                    text-align: center;
                    margin-left: $gutterTiny;
                    margin-right: $gutterTiny;
                    position: relative;
                    top: 1px;

                    > span {
                        line-height: 16px;
                        font-size: 10px;
                        display: inline-block;
                        position: relative;
                        top: -1px;
                    }
                }
            }

            .message-text-fake {
                position: relative;
                pointer-events: none;
                opacity: 0;
                cursor: default;
            }

            .citations {
                padding: 0 $gutterSmall $gutterSmall;

                ol {
                    display: flex;
                    flex-direction: row;
                    column-gap: $gutterSmall;
                    row-gap: $gutterSmall;
                    flex-wrap: wrap;
                    height: 71px;
                }
                
                .citation {
                    width: 175px;
                    position: relative;
                    -webkit-animation: loaderFadeIn 0.5s;
                    -moz-animation: loaderFadeIn 0.5s;
                    -ms-animation: loaderFadeIn 0.5s;
                    -o-animation: loaderFadeIn 0.5s;
                    animation: loaderFadeIn 0.5s;

                    .name {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100%;
                    }

                    .content {
                        display: block;
                        border-radius: 12px;
                        background-color: rgba(255, 255, 255, 0.8);
                        width: 100%;
                        padding: $gutterSmall;
                        box-sizing: border-box;

                        .citation-footer {
                            display: flex;
                            flex-direction: row;
                            align-content: center;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: $gutterXSmall;

                            .VisageIcon {
                                height: 20px;
                            }

                            .index {
                                display: flex;
                                height: 20px;
                                width: 20px;
                                background-color: #f4f4f5;
                                color: color(dark-grey);
                                border-radius: 20px;
                                align-items: center;
                                align-content: center;
                                justify-content: center;

                                span {
                                    margin-top: 1px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.assistant {
        .text {
            margin-left: 0px;
            margin-right: 50px;

            .text-wrapper {
                background-color: color("visageMidBlueBackground");
                border-radius: 0 $borderRadius $borderRadius $borderRadius;

                .message-text {
                    padding: $gutterMedium $gutterSmall $gutterSmall;
                }
            }
        }
    }

    &.inactive {
        opacity: 0.5;
    }
}