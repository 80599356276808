@import "src/style/variables";

@media screen and (max-width: 1023px) {
    .UberContentView {
        .contentSection {
            > div {
                width: 100% !important;
                margin-right: 0px !important;
                margin-bottom: $gutterMedium;
            }
        }
    }
}