@import "src/style/variables.scss";

.Birthdays {
    .widget-header {
        margin: 8px 0 8px 0;
    }
    .no-birthdays {
        text-align: center;
    }

    .user {
        cursor: pointer;
        margin-bottom: $gutterSmall;
    }

    .show {
        cursor: pointer;
        text-align: center;
    }

    .congrats-input {
        min-height: 100px !important;
    }
    .textNoBirthday{
        background-color: #FFF;
        padding: $gutterMedium;
        text-align: center;
        border-radius: $borderRadius;
    }
}
