@import "src/style/variables";

.AcademyCourseSidebarFinalExam {
    border: 1px solid color(borderColor);
    border-radius: $borderRadius;
    padding: $gutterMedium;

    .top-row {
        display: flex;
        flex-direction: row;
        column-gap: $gutterSmall;
        align-items: center;

        .circle {
            width: $circleLarge;
            height: $circleLarge;
            border-radius: $circleLarge;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;

            &.done {
                background-color: #D9FFED;
            }
        }

        .info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .meta {
                display: flex;
                flex-direction: row;
                column-gap: $gutterSmall;

                > div {
                    display: flex;
                    flex-direction: row;
                    column-gap: $gutterXSmall;
                }
            }
        }
    }

    .chapter-content {
        margin-top: $gutterMedium;
        display: flex;
        flex-direction: column;
        row-gap: $gutterXSmall;
    }
}

