@import "src/style/variables";

.SocialComposerExternalFile {
    margin: $gutterSmall 0 $gutterMedium;

    .file-stage {
        align-items: center;
        background-color: #EFF0F2;
        border-radius: $borderRadius;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 250px;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 100%;

        i {
            background-color: #DDDFE4;
            color: #566176;
            border-radius: 100%;
            display: block;
            height: 40px;
            line-height: 44px;
            margin-bottom: $gutterXSmall;
            text-align: center;
            width: 40px;
        }
    }
}