@import "src/style/variables.scss";

.FileUpload {
    .button {
        position: relative;
        
        .button-text {
            z-index: 1;
        }

        .file-selector {
            bottom: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
        }
    }

    .file-list {
        list-style: none;
        margin: 0;
        padding: 0;

        .file-entry {
            > img, .text {
                display: inline-block;
                vertical-align: middle;
            }

            > img {
                margin-right: $gutterXSmall;
            }
        }
    }
}