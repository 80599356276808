@import "src/style/variables";

$closedWidth: 66px;
$openedWidth: 410px + $closedWidth;

#StartPageBuilderMenu {
    bottom: 0;
    display: flex;
    left: 0;
    min-height: calc(100vh - 76px);
    position: fixed;
    top: 76px;
    transition: width 0.25s ease-in-out;
    width: $closedWidth;
    z-index: 5;

    .menu {
        background-color: #FFF;
        height: 100%;
        flex: $closedWidth 0 0;
        flex-shrink: 0;
        border-top: 1px solid color("borderColor");
        box-shadow: 0px 4px 14px rgba(111, 111, 111, 0.15);
        padding-top: $gutterXSmall * 1.25;
        width: $closedWidth;
        z-index: 3;

        .menu-section {
            border-bottom: 1px solid color("borderColor");
            list-style: none;
            margin: 0;
            padding: $gutterSmall 0;

            .menu-item {
                >.unstyled-button {
                    &:hover, &.active {
                        background-color: #edf3ff;
                    }
                }
            }

            &:last-child {
                border-bottom: 0 none;
            }

            .menu-item {
                height: 40px;
                width: 100%;
                line-height: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                [role="button"]:hover {
                    .Visage2Icon {
                        animation-name: pulse;
                        animation-duration: .5s;
                    }
                }

                .unstyled-button {
                    width: 32px;
                    height: 32px;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    #StartPageBuilderDrawer {
        flex: ($openedWidth - $closedWidth) 0 0;
        height: 100%;
        width: ($openedWidth - $closedWidth);
        z-index: 2;
    }
}

.drawer-open #StartPageBuilderMenu {
    width: $openedWidth;
}