@import "src/common.scss";

.Drawer {
    z-index: $ziDrawer;
    inset: 0;
    position: fixed;

    > [role="dialog"] {
        > [role="document"] {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            pointer-events: auto;
            position: fixed;
            width: 100%;
        }
    }

    .Drawer-overlay {
        background-color: $spintrContentDark;
        inset: 0px;
        position: absolute;
        opacity: 0.2;
        transition: opacity 0.267s ease;
        user-select: none;
    }

    .Drawer-content {
        background-color: $spintrWhite;
        bottom: 0;
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        transition: all 2s ease;

        @include DrawerShadow;
    }
}
