@import "src/style/variables";

.teaser-box-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $gutterSmall;
    margin-bottom: $gutterSmall;
    position: relative;

    .teaser-box-wrapper {
        box-sizing: border-box;
        min-width: 160px;
        flex: 1 1 calc(100% / 3 - (2 * $gutterMedium));
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        background-color: #FFF;
    }

    &.blogs {
        .teaser-box-wrapper{
            min-width: 200px;
        }
    }

    .MarketplaceWidget {
        height: 100%;
    }
}

.blogs-only {
    background-color: #FFF;
    border-radius: $borderRadius;
    margin-bottom: $gutterXXLarge;

    .teaser-box-container {
        margin-bottom: 0px !important;
    }
}

@media screen and (min-width: $vmTabletPortrait) {
    .teaser-box-container {
        &.blogs {
            &.teaser-box-count-1 {
                .blog-teaser-box-content {
                    .TextAndImages {
                        display: block;

                        > * {
                            display: inline-block;
                            vertical-align: top;
                            width: 50%;
                        }

                        .BlogPostImageWrapper {
                            margin-right: $gutterLarge;
                            width: calc(50% - #{$gutterLarge});
                        }

                        .BlogPostImage {
                            margin-bottom: 0px;
                        }

                        .text {
                            .BlogPostText {
                                -webkit-line-clamp: 2;
                            }
                        }
                    }
                }
            }
        }
    }
}
