@import "src/style/variables";

.ComposerMemoTitle {
    .ms-TextField-fieldGroup {
        height: 24px;
    }

    .ms-TextField-field {
        padding: 0px;
        font-size: 16px;
        font-weight: 550;
        border-radius: 0px;
    }
}
