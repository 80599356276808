@import "src/style/variables";

.operatingInfoBox {
    display: flex;
    flex-direction: column;
    margin-right: 0px;

    .headerOperatingInfoBox {
        display: flex;
        flex-direction: row;
        margin: 10px;
        margin-left: 0;
        border-bottom: 1px solid color("dusk-grey");
        width: 100%;
        
        h4{
            margin-bottom: $gutterLarge;
        }
    }
}
