@import "src/style/variables.scss";

#ProductDetailsView {
    .view-stack {
        flex-direction: row-reverse;
        gap: $gutterSmall;

        .product-posts {
            flex: 1 1 30%;
        }

        .product-details {
            flex: 1 1 70%;
        }
    }

    .product-stack {
        background-color: color("spintrWhite");
        border-radius: $borderRadius;
        padding: $gutterMedium;
    }

    .product-information {
        gap: $gutterXXLarge;

        .information-list {
            display: flex;
            flex-direction: column;
            gap: $gutterXSmall;
            margin-bottom: $gutterMedium;

            .information-item {
                align-items: center;
                display: flex;
                flex-direction: row;

                .information-term, .information-data {
                    flex: 1 1 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 0;
                }

                .information-term {
                    color: color("spintrGrey");
                }

                &.description {
                    align-items: flex-start;
                    flex-direction: column;
                    justify-content: flex-start;

                    .information-term, .information-data {
                        flex: 1 1 100%;
                        overflow-wrap: break-word; 
                        word-wrap: break-word;
                        white-space: normal; 
                        width: 100%; 
                    }
                }

                .status-label {
                    background-color: color("visageMidBlueBackground");
                    border-radius: $borderRadiusSharp;
                    cursor: default;
                    display: inline-block;
                    padding: $gutterTiny $gutterXSmall;
                    user-select: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    #ProductDetailsView {
        .view-stack {
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 1023px) {
    #ProductDetailsView {
        .view-stack {
            flex-direction: column;
        }
    }
}