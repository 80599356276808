@import "src/style/variables";

.SupplierView {
    position: relative;
    min-height: calc(100vh - 104px);

    .SupplierHeader {
        margin-bottom: $gutterXXLarge;
        background-color: #fff;
        border-radius: $borderRadius;
        padding: $gutterXXLarge;

        .SupplierHeader-Image {
            margin-bottom: $gutterXXLarge;

            img {
                border-radius: $borderRadius;
            }
        }

        .SupplierHeader-Content {
            display: flex;
            flex-direction: row;
            column-gap: $gutterMedium;
            align-items: center;

            .left {
                .icon {
                    height: 100px;
                    width: 100px;
                    border-radius: 100px;
                    overflow: hidden;
                }
            }
        }
    }

    .SupplierView-Info, .SupplierView-ContactPerson {
        padding: $gutterXXLarge;
    }

    .ms-CommandBar {
        margin-top: 0px;
    }

    .SupplierTags {
        margin-top: $gutterSmall;
        display: flex;
        flex-wrap: wrap;
        row-gap: $gutterMedium;

        .SupplierTag {
            margin-right: $gutterMedium;
            padding: $gutterXSmall $gutterSmall;
        }
    }

    .Supplier-ActionMenuWrapper {
        position: absolute;
        top: 0;
        right: 0;
    }

    .role-group {
        margin-bottom: $gutterMedium;

        .group-heading {
            margin-bottom: $gutterSmall;
        }
    }

    .people-in-role {
        display: grid;
        column-gap: $gutterXXLarge;
        row-gap: $gutterXXLarge;
        grid-template-columns: repeat(auto-fit, minmax(337px, 1fr));

        .SupplierView-ContactPerson {
            background-color: #fff;
            border-radius: $borderRadius;
            box-sizing: border-box;
            position: relative;

            .actionmenu-component {
                position: absolute;
                top: 0;
                right: 0;
            }

            .data {
                position: relative;
                width: 100%;

                .name {
                    margin-bottom: 2px;
                    width: calc(100% - 32px);
                }

                .subText {
                    margin-bottom: 2px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .role {
                    margin: $gutterXSmall 0;
                }
            }
        }
    }

    .SupplierView-Info-Header {
        padding-top: 4px;
        margin-bottom: $gutterSmall;
    }

    .ms-Pivot {
        margin-top: $gutterLarge;
        margin-bottom: 0px;
        height: 50px;
    }

    .SupplierView-Pivot {
        .ms-Pivot {
            margin-bottom: 0;
        }
    }

    .SupplierView-Info {
        background-color: #fff;
        border-radius: $borderRadius;
    }

    .SupplierView-Info {
        .SupplierView-Info-Header {
            margin-bottom: $gutterLarge;
        }

        .SupplierView-Info-Column {
            display: inline-block;
            vertical-align: top;
            width: 25%;

            .SupplierView-Info-Row {
                margin-bottom: $gutterSmall;
                margin-right: $gutterXSmall;

                &.website {
                    word-break: break-all;
                }
            }
        }
    }

    .SupplierView-FilesCategory {
        .SupplierView-FilesCategory-Files {
            margin-top: $gutterXSmall;

            .SupplierView-FilesCategory-File {
                width: calc(33% - #{$gutterSmall});
                display: inline-block;
                vertical-align: top;
                margin-right: $gutterSmall;
                margin-bottom: $gutterSmall;
            }
        }
    }

    .SupplierView-Tabs {
        margin-top: $gutterMedium;
        border: 1px solid color("dusk-grey");
        padding: $gutterSmall;
    }

    .ExternalFile .office-file .details .file-name .text {
        min-height: 42px;
    }

    .SupplierUploadButton {
        margin-bottom: $gutterMedium;
    }

    .SupplierUser {
        margin-top: $gutterSmall;

        // .ms-Persona-primaryText {
        //     font-size: 16px;
        // }

        // .ms-Persona-secondaryText {
        //     font-size: 14px;
        // }
    }

    .SupplierView-Info-Grid {
        margin-top: $gutterSmall;
        display: grid;
        row-gap: 22px;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        > div {
            display: inline-block;
        }
    }

    .SupplierView-Info-Category {
        &:not(:first-child) {
            margin-top: 36px;
        }
    }
}

.SupplierView-AddContact {
    .SupplierView-Image {
        width: 128px;
        margin: 0 auto;
        border-radius: $borderRadius;
        margin-bottom: $gutterMedium;

        .pageHeaderImage,
        .pageHeaderImageDeleteOverlay {
            border-radius: $borderRadius;
        }
    }
}

.supplier-message-modal {
    .messageInfo {
        margin-top: $gutterSmall;
        margin-bottom: $gutterSmall;
    }
}

.suppliers-instructions-text {
    white-space: pre-line;
}

.supplier-file-modal {
    .SpintrDatePicker {
        > div {
            width: 100%;
        }
    }
}

@media screen and (max-width: $vmTabletPortrait) {
    .SupplierView {
        .SupplierView-Info-Grid {
            grid-template-columns: 1fr;
        }
    }
}
