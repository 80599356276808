@import "src/style/variables.scss";

.PageCalendar {
    .event {
        display: flex;
        flex-direction: column;
        border: 2px solid color("light-grey");
        margin-bottom: 16px;
        text-align: center;
        width: 100%;
        border-radius: 5px;

        &.threeColumns.error {
            display: flex;
            flex-direction: column;
        }

        &.error {
            // padding: 40px;
            // font-size: 16px;
            // margin-top: 20px;
            justify-content: center;
            align-content:center;
            text-align: center;
            align-items: center;
            
            .eventError {
                align-self: center;
                margin: $gutterSmall;
            }
        }

        &.threeColumns
            .errorIcon {
                margin-top: $gutterSmall;
        }

        &:hover {
            cursor: pointer;
            background-color: color("light-grey");
        }

        .seatsAndAttendingButton {
            flex-shrink: 0;
        }

        &.oneColumn,
        &.twoColumns {
            flex-direction: row;
            .eventContent {
                flex-direction: row;
                display: flex;
                align-items: center;
                align-self: center;
            }
            .timeAndTitle {
                text-align: left;
            }
        }

        &.oneColumn {
            .seatsAndAttendingButton {
                display: flex;
                align-items: center;
            }
            .attendingButton {
                margin-left: $gutterLarge;
            }
        }

        &.twoColumns {
            .eventContent {
                align-items: flex-start;
                flex-direction: column;
            }
            .seatsAndAttendingButton {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                justify-content: space-between;

                .seats {
                    text-align: left;
                }
                .attendingButton {
                    flex-shrink: 0;
                    margin-left: $gutterSmall;
                }
            }
        }

        &.threeColumns {
            .attendingButton {
                margin-top: $gutterMedium;
            }
        }

        .date {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: $gutterSmall;
            font-size: 12px;
            font-weight: bold;
            background-color: color("light-grey");
            flex-shrink: 0;
        }

        .eventContent {
            padding: $gutterSmall;
            padding-left: $gutterMedium;
            flex-grow: 1;
            word-break: break-word;
            overflow-wrap: anywhere; // Test this

            .timeAndTitle {
                flex-grow: 1;
            }
        }

        .attendingButton {
        }
    }
}
