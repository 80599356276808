@import "src/style/variables";

.AcademyCourseView {
    .contentWIthInfoPanelLeft {
        width: calc(100% - 424px - 24px) !important;

        &.noInfoPanel {
            padding: $gutterMedium !important;
            width: 100% !important;
        }
    }

    .contentWIthInfoPanelRight {
        width: 424px !important;
    }

    .AcademyImage {
        margin-bottom: $gutterMedium;
    }

    > .pageHeader {
        margin-bottom: $gutterXSmall;
    }
}
