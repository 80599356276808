@import "src/style/common.scss";

.Podium {
    .bars {
        > div {
            max-width: calc(100% / 3);
        }

        .second,
        .first,
        .third {
            .user {
                .image {
                    position: relative;
                    width: 44px;
                    margin: 0 auto $spacingSmall auto;

                    .ms-Persona {
                        flex-direction: column;
                    }

                    .medal {
                        position: absolute;
                        bottom: -3px;
                        right: -9px;
                        font-size: 20px;
                    }
                }

                .label-component {
                    text-align: center;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }

        .bar {
            position: relative;
            margin-top: $spacingMedium;

            .label-component {
                width: 52px;
                height: 52px;
                border-radius: 50%;
                position: absolute;
                top: 30px;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
                line-height: 52px;
            }
        }
    }

    .second {
        .bar {
            height: 200px;
            background-color: $spintrYellowLight;
            border-radius: $brRounder 0 0 $brRounder;

            .label-component {
                color: $spintrYellow;
                background-color: $spintrYellowLighter;
            }
        }
    }

    .first {
        .bar {
            height: 334px;
            background-color: $spintrBlueLight;
            border-radius: $brRounder $brRounder 0 0;

            .label-component {
                color: $spintrBlue;
                background-color: $spintrBlueLighter;
            }
        }
    }

    .third {
        .bar {
            height: 125px;
            background-color: $spintrPinkLight;
            border-radius: 0 $brRounder $brRounder 0;

            .label-component {
                color: $spintrPink;
                background-color: $spintrPinkLighter;
            }
        }
    }
}
