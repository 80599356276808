@import "src/style/variables";

.TeaserBoxSelectionPopup {
    .teaser-box-content {
        cursor: pointer;

        &:hover {
            border-color: color("dark-grey");
            -webkit-transition: border-color $fastTransition;
            -moz-transition: border-color $fastTransition;
            -o-transition: border-color $fastTransition;
            transition: border-color $fastTransition;
        }
    }
}

.spintr-modal .ms-Dialog-main.TeaserBoxSelectionPopup-container {
    max-width: 582px;
    max-height: 700px;
    width: 100%;
}