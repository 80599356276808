@import "src/common.scss";

.DrawerFooter {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    padding: $spacingXXLarge;
    max-width: 100%;
    width: 100%;
}