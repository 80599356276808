@import "src/style/variables";

$topBarHeight: 110px;
$responsiveTopBarHeight: 64px;
$chatTabComposerHeight: 43px;
$headerHeight: 64px;

#NewConversationView {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px - (12px * 2));
    background: #FFF;
    border-radius: 12px;

    .header {
        background-color: #FFF;
        border-bottom: 1px solid color("borderColor");
        border-width: 0 1px 1px;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        min-height: $headerHeight;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 10;

        > div {
            max-width: 100%;
        }

        .ms-BasePicker-text {
            padding: ($gutterMedium - 1px) $gutterSmall;
        }

        .ms-BasePicker-text {
            border: 0 none;
        }
    }

    .ChatTabContent {
        height: 100%;
        width: 100%;

        .ChatTabMessageContainer {
            height: calc((100 * var(--vh, 1vh)) - #{110px + $headerHeight + $chatTabComposerHeight});

            .ScrollableAreaContainer {
                height: calc((100 * var(--vh, 1vh)) - #{110px + $headerHeight + $chatTabComposerHeight}) !important;

                .ScrollableAreaContent {
                    padding: 0 $gutterMedium;
                }
            }
        }
    }
}

@media screen and (max-width: $vmPhoneLandscape) {
    #NewConversationView {
        .header {
            top: $responsiveTopBarHeight;
        }

        .ChatTabContent {
            height: calc((100 * var(--vh, 1vh)) - #{$responsiveTopBarHeight});
            width: 100%;
    
            .ChatTabMessageContainer {
                height: calc((100 * var(--vh, 1vh)) - #{$responsiveTopBarHeight + $chatTabComposerHeight});
    
                .ScrollableAreaContainer {
                    height: calc((100 * var(--vh, 1vh)) - #{$responsiveTopBarHeight + $chatTabComposerHeight}) !important;
    
                    .ScrollableAreaContent {
                        padding: 0 $gutterMedium;
                    }
                }
            }
        }
    }
}