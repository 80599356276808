.Sidebar-Todos {
    margin-bottom: 16px;
    .widget-header {
            margin: 8px 0 8px 0;
    }
    .Todos-Header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .actionmenu-component {
            margin-left: auto;
            opacity: 0;
        }
    }
    &:hover .actionmenu-component {
        opacity: 1;
    }

    .ms-DetailsRow-cell {
        align-self: center;
    }
}
