@use "sass:math";
@import "src/marketplace/variables";
@import "src/style/variables";

#MarketplaceWidgetFormView {
    .view-header {
        @include headerWithImage;
    }

    .view-body {
        margin: $gutterXLarge auto;
        max-width: 1100px;

        .main-content {
            padding-bottom: $gutterXXXLarge;
        }


        .tabs {
            flex: 1 1 calc(100% - 256px);
            margin-right: $gutterXLarge;

            textarea {
                max-width: 100%;
            }
        }

        .appearance {
            flex: 0 0 256px;
        }

        .MarketplaceWidgetResource {
            align-items: baseline;
        }

        .resource {
            align-items: center;
        }

        .resource, .MarketplaceWidgetResource {
            .request-type {
                flex: 0 0 120px;
                padding-right: 10px;
            }

            .resource-input {
                flex: 0 1 calc(100% - 128px - #{$gutterMedium});
                margin-right: $gutterMedium;
            }

            .button-wrap {
                flex: 1 1 128px;

                .fetch-data-button {
                    min-height: 40px;
                }
            }
        }

        .dsl-area {
            .dslInput {
                textarea {
                    font-family: "Consolas", "Lucida Sans Console", monospace;
                }
            }

            .dslExpression, .result {
                flex: 0 0 50%;
            }

            .dslExpression {
                margin-right: math.div($gutterMedium, 2);
            }

            .result {
                margin-left: math.div($gutterMedium, 2);
            }
        }
    }
}