@use "sass:math";
@import "src/common.scss";
@import "../plannerVariables.scss";

$axisToggleSize: 32px;

.Timeline {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px - 12px - 12px - 24px - 38px - 16px);
    overflow: hidden;
    z-index: map-get($plannerStrata, backplate);

    .Timeline-roadmap-wrapper {
        flex: 1 1 auto;
        position: relative;
    
        .Timeline-roadmap {
            .timeline-wrapper {
                @include AbsoluteFullsize;
                overflow: hidden;
    
                .timeline {
                    @include AbsoluteFullsize;
                    display: flex;
                    overflow: scroll;
    
                    .timeline-rows-wrapper {
                        display: flex;
                        flex: 1 1 auto;
                        flex-direction: column;
                        height: fit-content;
                        min-height: 100%;
                        position: relative;
                        width: fit-content;

                        .Timeline-axisBorder-wrapper, .Timeline-axisToggle-wrapper {
                            @include AbsoluteFullsize;
                            pointer-events: none;
                            width: 100%;
                            z-index: map-get($plannerStrata, header);

                            .Timeline-axisToggle {
                                align-items: center;
                                background-color: $spintrWhite;
                                border-radius: 100%;
                                cursor: pointer;
                                display: flex;
                                height: $axisToggleSize;
                                justify-content: center;
                                left: 20px;
                                position: sticky;
                                pointer-events: all;
                                top: math.div($timelineHeaderHeight, 2) - math.div($axisToggleSize, 2);
                                transition: transform 0.2s ease-in-out;
                                width: $axisToggleSize;
                                z-index: map-get($plannerStrata, headerButton);

                                &.minimized {
                                    transform: scaleX(-1);
                                }
                            }

                            .Timeline-axisBorder {
                                border-left: 1px solid $spintrBlueLighter;
                                border-right: 1px solid $spintrGray;
                                border-top-left-radius: $brNormal;
                                box-sizing: border-box;
                                height: 100%;
                                left: 0;
                                transition: width 0.2s ease-in-out;
                                position: sticky;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $vmTabletPortrait) {
    .Timeline {
        height: calc(100vh - 63px - 76px - 12px - 36px - 16px);
        margin-right: -$spacingMedium;
        margin-bottom: -$spacingMedium;
    }
}