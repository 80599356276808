@mixin AbsoluteFullsize {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

$timelineHeaderHeight: 68px;
$timelineItemHeight: 44px;

$plannerStrata: (
    backplate: 1,

    itemGuide: 2,
    item: 3,
    itemBackground: 4,
    itemContent: 5,

    todayLine: 6,
    
    axisRail: 7,
    
    itemHandle: 8,

    rowLabel: 9,
    rowLabelButton: 11,

    header: 12,
    headerButton: 15,
);