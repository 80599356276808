@import "src/style/variables.scss";

.PopoutFrame {
    // border: 16px solid color("borderColor2");
    // border-top: 24px solid color("borderColor2");
    padding: 16px;
    box-shadow: 0px 12px 74px rgba(157, 160, 166, 0.15);
    border-radius: 16px;
    background-color: color("borderColor2");

    .PopoutFrame-header {
        background-color: color("borderColor2");
        width: 100%;
        height: 100%;
        display: flex;
        height: 24px;
        margin-bottom: $gutterTiny;

        > * {
            display: inline-block;
            vertical-align: top;
        }

        > .unstyled-button {
            margin-right: $gutterXSmall;
            height: 20px;

            .Visage2Icon {
                display: block;
                height: 20px;
                margin-top: 2px;

                svg {
                    display: block;
                }
            }
        }


        .PopoutFrame-title {
            padding-top: calc($gutterXXLarge - 24px);
            flex-grow: 1;  
            font-size: 14px !important;
            font-weight: 600 !important;
            line-height: 24px !important;
            height: 24px;
            width: calc(100% - 56px);
        }
    }

    .PopoutFrame-content-inner {
        border-radius: 16px;
        border: 1px solid #ebebeb;
        background-color: white;
        z-index: -1;
    }
}

.SpintrStaticLinksPopout {
    position: absolute;
    right: -1px;
    top: 74px;
    background: color("borderColor2");
    z-index: $spintrOverlayContentZIndex;
    border-radius: $borderRadius;
    width: 400px;

    &.SpintrStaticLinksPopout-extraWide {
        width: 686px;
    }

    &.alternativePosition {
        right: 150px;
    }

    .Scrollable-content {
        max-height: 75vh;
    }

    .SpintrStaticLinksCallout-footer {
        text-align: center;

        > div {
            padding: $gutterSmall;
            padding-bottom: 0;
        }

        .label-component {
            &:hover {
                opacity: 0.75;
            }
        }
    }

    .FormSection {
        padding: $gutterMedium;
        padding-left: $gutterLarge;
        padding-right: $gutterLarge;
        padding-top: 0;
        padding-bottom: 0;
    }

    .CreateWizardContentWrapper {
        max-height: 35vw;
        overflow-y: scroll;
    }
}

.SpintrStaticLinksCallout-header {
    padding: $gutterMedium;
    padding-left: $gutterLarge;
    position: relative;
}

.SpintrStaticLinksCallout-header-icons {
    position: absolute;
    right: $gutterMedium;
    top: $gutterMedium;

    > * {
        display: inline-block;
        vertical-align: middle;
    }

    > .SpintrStaticLinksCallout-header-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-left: $gutterXSmall;
        text-align: center;
        border: 1px solid color("smoke");

        > i {
            line-height: 30px;
        }

        &:hover {
            background-color: color("light-grey");
        }

        &.primaryBGColor {
            border: 0;

            > i {
                color: #FFF;
            }

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .actionmenu-component {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid color("smoke");

        .Visage2Icon {
            line-height: 30px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            padding: 0px;
            margin-top: 3px;
            margin-left: 3px;
        }

        &:hover {
            background-color: color("light-grey");
        }
    }
}

@media screen and (max-width: 1550px) {
    .SpintrStaticLinksPopout {
        top: 74px;
    }
}