@import "src/style/variables";

.GroupContentView {
    background-color: #FFF;
    padding-top: $gutterSmall;
    border-radius: $borderRadius;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin: $gutterSmall;
    margin-top: 0px;
    width: calc(100% - (#{$gutterSmall} * 2));

    &.feed {
        background-color: transparent;
        padding: 0px;

        > * {
            margin: 0 auto;
        }
    }

    &.no-padding {
        padding: 0px;
    }
}

.GroupView-content {
    .visage-box-shadow {
        box-shadow: none;
        border: 1px solid color(borderColor);
    }
}

.isSmallViewMode {
    .groupWrap {
        width: 100% !important;
    }

    .GroupMenu {
        display: block;

        button {
            height: 42px;
        }
    }

    .ChatMessage .ChatMessage-right {
        .ChatMessage-message-wrapper {
            .ChatMessage-inner {
                width: calc(100% - 60px);
            }

            .ChatMessage-right-info {
                width: 60px;
            }
        }

        .ChatMessage-replies-wrapper {
            .ChatMessage-replies {
                .ChatMessage-reply-right {
                    .ChatMessage-inner {
                        width: calc(100% - 40px);
                    }

                    .ChatMessage-right-info {
                        width: 40px;
                    }
                }
            }
        }
    }

    .GroupPlanningView {
        .Planning-Category {
            margin: $gutterMedium;
        }

        .Planning-AddCategoryButton {
            margin: $gutterMedium;

            .Planning-AddCategory {
                box-sizing: initial;
                margin-bottom: $gutterLarge;
            }
        }
    }
}

.GroupMenu {
    display: flex;
    height: 100%;

    [role="toolbar"] {
        font-weight: 500;
        max-width: 100%;

        .ms-Callout-container {
            z-index: 5; // higher than numbers in calendar
        }
    }

    button {
        height: 76px;
    }

    .ms-Callout-container {
        button {
            height: auto;
        }
    }

    .ms-Pivot {
        .ms-Pivot-text {
            font-family: $secondaryFont;
        }
        margin-bottom: 0;
    }
}

.GroupHeader-actions {
    display: flex;
    align-items: center;
    column-gap: $gutterSmall;

    > a {
        height: 36px !important;
    }

    // .GroupHeader-action {
    //     margin-left: $gutterSmall;
    //     width: 32px;
    //     border: 1px solid color(borderColor);
    //     border-radius: $borderRadiusSharp;
    // }
}

.group-content-view-spacing {
    padding: $gutterMedium;
    box-sizing: border-box;
}

.groupWrap {
    overflow: hidden;
}

.GroupHistoryView {
    .GroupHistoryView-inner {
        padding: $gutterSmall;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: $gutterSmall;
        box-sizing: border-box;

        .unstyled-button {
            width: 100%;
        }

        h5 {
            text-transform: uppercase;
            font-size: 10px;
            color: color("dark-grey");
        }
        .GroupHistoryViewItem {
            /*padding: $gutterXSmall $gutterSmall;*/

            .GroupHistoryViewItem-left {
                display: inline-block;
                margin-right: $gutterSmall;
                vertical-align: top;
            }

            .GroupHistoryViewItem-right {
                display: inline-block;
                width: calc(100% - 48px);
                vertical-align: middle;
                h3 {
                    font-family: $secondaryFont;
                    margin: 0;
                    font-weight: 400;
                    font-size: 14px;
                }
                p {
                    margin: 2px 0 0 0;
                    font-weight: 400;
                    font-size: 12px;
                }
            }

            .GroupHistoryViewItem-inner {
                background: #fff;
                -webkit-transition: background-color $fastTransition;
                -moz-transition: background-color $fastTransition;
                -o-transition: background-color $fastTransition;
                transition: background-color $fastTransition;

                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }
}

.GroupFeedView, .GroupPageView {
    .SpintrSocialFeed {
        margin-top: $gutterMedium;

        .SocialPostContainer,
        .SocialPostShimmer {
            background-color: #fff; // TODO: borde kanske vara mellanrum mellan varje post?
        }
    }
}
