@import "src/style/variables";

.AcademyCoursePreview {
    .AcademyImage {
        margin-bottom: $gutterMedium;
    }

    .pageHeader {
        margin-bottom: $gutterXSmall;
    }

    .AcademyCourseCategories {
        margin-bottom: $gutterMedium;
    }

    .AcademyCourseMetaDataRow {
        margin-bottom: $gutterMedium;
    }

    .AcademyCoursePreview-footer {
        margin-top: $gutterLarge;
        display: flex;
        flex-direction: column;
        row-gap: $gutterMedium;

        .AcademyCourseProgress {
            min-width: 300px;
        }

        > div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            column-gap: $gutterMedium;

            .cancel-button {
                background-color: color(salmon);
                border-color: color(salmon);
            }
        }
    }
}

.isSmallViewMode {
    .AcademyCoursePreview {
        .AcademyCourseMetaDataRow {
            margin-bottom: 0;
        }
    }
}