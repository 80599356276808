@import "src/style/variables";

.WidgetSelectionPopup {
    .MarketplaceWidget {
        cursor: pointer;

        &:hover {
            border-color: color("dark-grey");
            -webkit-transition: border-color $fastTransition;
            -moz-transition: border-color $fastTransition;
            -o-transition: border-color $fastTransition;
            transition: border-color $fastTransition;
        }
    }
}

.spintr-modal .ms-Dialog-main.WidgetSelectionPopup-container {
    max-width: 848px;
}