@import "src/common.scss";

.TagItem {
    background-color: $spintrGrayLighter;
    border-radius: $brNormal;
    color: $spintrGrayDark;
    padding: ($spacingTiny / 2) ($spacingTiny * 1.5);
    white-space: nowrap;

    .Text {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}