@import "src/common.scss";

$mainMaxWidth: 1080px;

$sidebarMaxWidth: 319px;
$sidebarMinWidth: 250px;

$viewMaxWidth: $mainMaxWidth + $spacingSmall + $sidebarMaxWidth;

#WikiListView {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: $viewMaxWidth;
    width: 100%;

    .WikiListView-stack {
        gap: $spacingSmall;
    }

    .WikiListView-main, .WikiListView-sidebar {
        background-color: $spintrWhite;
        border-radius: $brRounder;
        box-sizing: border-box;
        flex: 1 1 auto;
        padding: $mainContentBackplatePadding;
    }

    .WikiListView-main {
        max-width: $mainMaxWidth;
        width: 100%;

        header.WikiListView-header {
            margin-bottom: $spacingLarge;
            position: relative;

            .open-drawer-button {
                position: absolute;
                right: 0;
                top: $spacingTiny;
            }
        }

        section.WikiListView-prioritized {
            margin-bottom: $spacingLarge;
        }
    }
}

.WikiListView-sidebar {
    max-width: $sidebarMaxWidth;
    min-width: $sidebarMinWidth;

    .sidebar-box {
        margin-bottom: $spacingMedium;

        &.pins-box svg {
            rotate: 135deg;
        }
    }
}

@media only screen and (max-width: $vmTabletLandscape) {
    #WikiListView {
        .WikiListView-main {
            .PrioritizedWikiList ol {
                flex-wrap: nowrap;
                overflow-x: scroll;
            }
        }
    }
}