.SpintrSvgBar{
    text{
        color : rgba(0,0,0,.5)
    }
}

.BarChartWidget, .LineChartWidget {
    margin-top: -35px;
    margin-right: -20px;
    margin-left: -5px;
    margin-bottom: -5px;
    min-height: 150px;
    position: relative;
}