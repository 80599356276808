@import "src/style/variables";

.TinyForm-wrapper {
    margin-bottom: $gutterSmall;
    .ms-ComboBox::after{
        border-radius: $borderRadius;
    }
   .ms-ComboBox-CaretDown-button{
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
   }
    .TinyForm-info {
        background-color: color("light-grey");
        padding: $gutterSmall;
        margin-bottom: $gutterSmall;

        .ms-Button {
            margin-top: $gutterSmall;
        }
    }

    .question .ms-Label {
        font-weight: 550 !important;
    }

    .TinyForm-part {
        margin-bottom: $gutterSmall;

        .surveyHeader {
            font-weight: 550;
        }

        .TinyForm-part-input-wrapper {
            .ms-TextField {
                width: 100%;
                max-width: none;
            }

            .ms-Checkbox {
                margin-top: 0px;
            }

            .ms-Rating-star {
                margin-top: calc(#{$gutterTiny} * -1);
            }


            .file-loader {
                width: 100px;
            }
        }
    }
}