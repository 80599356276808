@import "src/style/variables";

$shortcutImageSize: 30px;

.CreateWizardModal {
    h1:first-of-type {
        margin-top: 0em;
    }

    padding: $gutterLarge;
    max-width: 580px;
}

.office-shortcuts {
    border-top: 1px solid #EBEBEB;

    .image-and-text {
        .image {
            height: $shortcutImageSize;
            position: relative;
            width: $shortcutImageSize;
            margin-right: $gutterSmall;
            padding: $gutterXSmall;
            padding-left: 0px;

            img {
                height: $shortcutImageSize;
                width: $shortcutImageSize;
            }

            .image-plus {
                border-radius: 100%;
                height: 15px;
                position: absolute;
                right: 0px;
                top: 27px;
                width: 15px;
                padding: 0 !important;
                background-color: #FFF;

                svg {
                    vertical-align: top;
                }
            }
        }
    }
}

.CreateContent {
    padding: $gutterMedium;

    .link {
        display: block;
        width: 100%;
        text-decoration: none;
        color: color(mid-grey);
        margin-bottom: $gutterSmall;

        .createWizardShortcut{
            display: flex;
            flex-direction: row;
            column-gap: $gutterSmall;

            .text {
                width: calc(100% - #{$gutterSmall} - 20px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:hover {
                opacity: 0.75;
            }
        }

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .lf-player-container {
        height: 24px !important;
        width: 24px !important;
        transition: all 0.2s;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;
    }

    .AnimatedIcon {
        height: 24px;
        display: block;

        > svg {
            vertical-align: top;

            * {
                stroke: color(mid-grey);
            }
        }
    }

    .linkIconDocuments {
        font-size: 28px;
        height: 28px;
        width: 28px;
        margin: 0 10px;
        vertical-align: middle;
        text-align: center;
    }

    .documentLink {
        color: unset !important;
    }

    .link {
        &.note {
            .Visage2Icon {
                margin-left: 2px;
                margin-top: 4px;
                margin-right: calc(#{$gutterSmall} - 2px);
            }
        }
    }
}
