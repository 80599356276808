@import "src/style/variables";

.PayrollView {
    .buttons-row {
        .control {
            display: flex;
            flex-direction: row;
            column-gap: $gutterMedium;
        }
    }
}

.PayrollHome {
    .filter-wrapper {
        position: absolute;
        top: calc(#{$gutterXLarge} + 40px);
        right: $gutterXXLarge;

        .filter {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: $gutterMedium;
            row-gap: $gutterMedium;

            .ms-Dropdown {
                width: 155px !important;
                min-width: 155px !important;
            }
        }
    }

    .data {
        margin-top: $gutterXLarge;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: $gutterXLarge;
        row-gap: $gutterXLarge;

        > * {
            width: calc(50% - (#{$gutterXLarge} / 2));
            box-sizing: border-box;
            padding: $gutterMedium;
            border-radius: $borderRadius;
            border: 1px solid color(borderColor);

            .label-component {
                margin-bottom: $gutterSmall;
            }
        }
    }
}

.payroll-filter-dropdown-callout {
    width: 300px !important;
}

@media screen and (max-width: 1350px) {
    .PayrollHome {
        .filter-wrapper {
            position: relative;
            top: 0px;
            right: auto;
        }
    }
}

@media screen and (max-width: 1023px) {
    .PayrollHome {
        .data {
            > * {
                width: 100%;
            }
        }
    }
}