@import "src/style/variables";
@import "../Composer";

#InlineComposer {
    .opener {
        background-color: #FFFFFF;
        border-radius: $borderRadius;
        cursor: pointer;
        user-select: none;

        .top {
            line-height: 24px;
            padding: $gutterMedium;

            .actionmenu-component {
                margin-left: $gutterXSmall;
            }

            .user, .text-placeholder, .actionmenu-component {
                display: inline-block;
                vertical-align: middle;
            }

            .actionmenu-component {
                min-width: auto;
                .Visage2Icon-arrow-down-1 {
                    position: relative;
                    top: 3px;
                    width: 14px;

                    svg {
                        height: 14px;
                        width: 14px;
                    }
                }
            }

            .text-placeholder {
                max-width: calc(100% - #{$circleMedium} - #{$gutterSmall});
                margin-left: $gutterXSmall;

                > div {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        &.hideUser {
            .top {
                .text-placeholder {
                    max-width: 100%;
                    margin-left: 0;
                }
            }
        }

        .bottom {
            .types {
                @include type-selector;
            }
        }
    }

    .type-selector {
        padding: $gutterMedium;
        padding-top: 0px;
    }
}

@media screen and (max-width: 1640px) {
    #InlineComposer {
        .type-selector {
            .type-selector-type {
                column-gap: $gutterXSmall;

                .icon-wrapper {
                    width: auto;
                    background-color: transparent;
                }
            }
        }
    }
}