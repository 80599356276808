@import "src/style/variables.scss";

.translate-button {
    .unstyled-button {
        font-weight: 400;
        font-size: 12px;

        &:hover .label-component {
            color: color('light-blue');
        }

        &:hover .label-component.fc-white {
            color: color('dusk-grey');
        }
    }

    .ms-TooltipHost {
        margin-top: -2px;
    }
}