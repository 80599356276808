@import "src/style/variables.scss";

.UpdateReloadBanner-Wrapper {
    background-color: color(light-grey);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: $gutterSmall $gutterSmall 0px $gutterSmall;
    border-radius: $borderRadius;

    > * {
        padding: $gutterMedium;
        z-index: 1;

        &:first-child {
            flex-grow: 1;

            > * {
                &:first-child {
                    margin-bottom: 3px;
                }
            }
        }
    }
}