@import "src/style/variables";
@import "src/style/common.scss";

.AcademyLeaderboardCategory {
    margin-bottom: $gutterLarge;

    > .label-component {
        &:first-child {
            margin-bottom: $spacingLarge;
        }
    }

    > .ms-Stack {
        border: 1px solid #d9d9d9;
        border-radius: $brRounder;
        padding: $spacingMedium;

        > div {
            width: 100%;
        }
    }

    .spintr-list {
        .medal {
            font-size: 16px;
            margin-right: $spacingSmall;
        }
    }

    &.has-podium {
        .spintr-list {
            border: 1px solid #d9d9d9;
            border-radius: $brRounder;
            padding: $spacingMedium;
            box-sizing: border-box;
        }
    }
}
