@import "src/style/variables";

$iconWidth: 14px;
$rowHeight: 34px;
$unreadHeight: 17px;

.HiddenChatTab {
    padding: $gutterXSmall $gutterSmall;
    position: relative;
    width: 200px;

    .name, .close-button {
        display: inline-block;
    }

    .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        width: calc(100% - #{$iconWidth + $gutterSmall});
    }

    .unread-count {
        line-height: 0.625em;
        position: absolute;
        right: ($gutterSmall * 2) + $iconWidth;
        top: floor(($rowHeight - $unreadHeight) / 2);

        span {
            background-color: #f1173f;
            border: 1px solid #FFF;
            border-radius: $borderRadius;
            color: #FFF;
            display: inline-block;
            line-height: 0.938em;
            text-align: center;
            vertical-align: middle;
            width: 15px;
        }
    }

    .close-button {
        line-height: 1.250em;
        position: absolute;
        right: $gutterSmall;
        width: $iconWidth;
    }
}