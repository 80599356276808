@import "src/style/variables";

.TinyFolder-MissingRights {
    background-color: color("light-grey");
    padding: $gutterSmall;
    margin-bottom: $gutterSmall;
    border-radius: $borderRadiusSharper;
}

.TinyFolder-wrapper {
    .TinyFolder-header {
        display: flex;
    }
    
    .TinyFolder-header-icon, .TinyFolder-header-text {
        display: inline-block;
        vertical-align: middle;
    }

    .TinyFolder-header-icon {
        margin-right: $gutterXSmall;
    }

    .TinyFolder-content {
        margin-top: $gutterXSmall;
        background-color: color("light-grey");
    }
}