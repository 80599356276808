@import "src/style/variables.scss";

.RightColumnTags {
    position: relative;
.widget-header {
        margin: 8px 0 8px 0;
    }
    .RightColumnTags-content {
        background-color: #FFF;
        border-radius: $borderRadius;

        .RightColumnTags-tag {
            font-size: 14px;
            display: inline-block;
        }

        .eachTagContainer {
            display: flex;
            height: auto;
            border-bottom: 1px solid #8a8886;
            justify-content: center;
            align-items: center;
            align-content: center;
            max-height: 500px;
            width: 100%;
            border-bottom: none;
            margin-bottom: 2px;

            .eachTag {
                display: flex;
                height: auto;
                justify-content: center;
                align-items: center;
                align-content: center;
                width: 100%;
                padding: 0 $gutterMedium 0 $gutterMedium;
                border-bottom: none;
                -webkit-transition: background-color $fastTransition;
                -moz-transition:  background-color $fastTransition;
                -o-transition:  background-color $fastTransition;
                transition:  background-color $fastTransition;
                &:hover{
                    background-color: color("light-grey");
                }
                .text {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    font-size: 14px;
                    cursor: pointer;
                    padding-top: $gutterMedium;
                    padding-bottom: 13px;
                    a {
                        color: color(dark-grey);
                        line-height: 1.125em;
                    }
                }

                .icon {
                    margin-top: 10px;
                    margin-bottom: 5px;
                    
                    a {
                        font-size: 16px;
                        color: color(dark-grey);
                        line-height: 1.125em;
                    }
                }
            }
        }

        .RightColumnTags-footer {

            margin-top: 1px;
            text-align: center;
            
            a{
                padding: $gutterMedium 0;
                width: 100%;
                display: inline-block;
                -webkit-transition: background-color $fastTransition;
                -moz-transition:  background-color $fastTransition;
                -o-transition:  background-color $fastTransition;
                transition:  background-color $fastTransition;
                &:hover{
                    background-color: color(light-grey);
                }
            }
        }
    }
}
