@import "src/style/variables";

.SocialComposerVideo {
    margin-top: $gutterSmall;

    .video-stage {
        position: relative;

        .thumbnail {
            background-color: #000;
            border-radius: $borderRadius;
            height: 300px;
            overflow: hidden;
            width: 100%;

            img {
                max-width: 100%;
                max-height: 300px;
                margin: 0 auto;
                overflow: hidden;
            }
        }

        .remove-button {
            color: #FFF;
            height: 24px;
            position: absolute;
            right: $gutterXLarge;
            top: $gutterXLarge;
            width: 24px;
        }
    }
}

@media screen and (max-width: 1023px) {
    .SocialComposerVideo {
        width: 100%;

        .video-stage .thumbnail {
            height: 200px;
            
            img {
                max-height: 200px;
            }
        }
    }
}
