@import "src/style/variables";

.MessageObjects {
    .ExternalFile-loader {
        min-height: 173px;
        min-width: 257px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    .ExternalFile {
        .image-preview {
            img {
                height: 100px;
            }
        }
    }

    .ImageCore-wrapper {
        .ImageCore {
            animation: none;
        }

        img {
            object-fit: cover;
            width: 100px;
            height: 74px;
            border-radius: $borderRadius;
            display: block;
        }
    }
}

.file-attachment {
    max-width: 200px;
    border-radius: $borderRadius;
    border: 1px solid color(borderColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: $gutterXSmall;
    padding: $gutterSmall;
    position: relative;
    height: fit-content;
    background-color: #FFF;

    .label-component {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
}