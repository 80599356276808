@import "src/style/variables";

.attachments {
    .unstyled-button {
        > .Visage2Icon {
            pointer-events: none;

            > * {
                pointer-events: none;
            }
        }
    }
}
