@import "src/marketplace/variables";
@import "src/style/variables";

.MarketplaceList {
    display: flex;
    flex-flow: row wrap;
    gap: $gutterLarge;
    justify-content: flex-start;

    .list-item {
        flex: 0 0 $catalogItemWidth;
        margin-bottom: $gutterSmall;
    }
}
