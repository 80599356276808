@import "src/style/variables";

.AcademyTestFormQuestion {
    margin-bottom: $gutterMedium;
    border-radius: $borderRadius;
    border: 1px solid color(borderColor);
    overflow: hidden;

    .top-row {
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;
        position: relative;
        background-color: color(visageMidBlueBackground);
        padding: $gutterMedium;
        align-items: center;

        .question-order {
            background-color: #FFF;
            border-radius: $borderRadiusSharper;
            display: flex;
            flex-direction: row;
            column-gap: $gutterTiny;
            margin-right: $gutterXSmall;

            > div {
                padding: $gutterXSmall;

                &:first-child {
                    padding-right: 0px;
                }
                &:last-child {
                    padding-left: 0px;
                }
                &:hover {
                    opacity: 0.75;
                }
            }
        }

        > .label-component {
            flex-grow: 1;
        }
    }

    .content {
        padding: $gutterMedium;
    }

    .ms-Dropdown-label {
        margin-top: 0px;
    }

    .alternatives {
        margin-top: $gutterSmall;
        margin-bottom: 0px;

        .alternative {
            display: flex;
            flex-direction: row;
            column-gap: $gutterXSmall;
            align-items: center;
            min-height: 36px;

            .textFieldWrapper {
                flex-grow: 1;

                .ms-TextField {
                    flex-grow: 1;
                    .ms-TextField-fieldGroup {
                        height: auto;
                        min-height: auto;
                        
                        .ms-TextField-field {
                            padding: 0px;
                            border-radius: 0px;

                            &:focus {
                                outline-width: 0px;
                            }
                        }
                    }
                }
            }

            .unstyled-button {
                z-index: 1;
            }
        }
    }

    .SmallCreateButton {
        margin-top: $gutterXSmall;
    }
}
