@import "src/style/variables.scss";

.submenu-component {
    padding-right: 12px;

    &.hidden {
        margin-bottom: $gutterLarge;
    }
    
    .ms-Nav-navItem {
        button {
            background-color: transparent;

            i {
                &:after {
                    border-left-width: 0;
                }
            }
        }
    }

    .searchBox {
        margin-left: 0px;
        margin-right: 0px;
    }

    .ms-Nav-chevronButton {
        right: 6px;
        left: auto;
        color: color("dark-grey");

        &:after {
            border-left-width: 0;
        }
    }

    .row {
        box-sizing: border-box;
        color: #000;
        height: auto;        

        > div {
            margin: 0 4px;
            display: flex;
            justify-content: space-between;

            > i {
                margin-right: $gutterSmall;
            }

            > span {
                display: flex;
                word-break: normal;
                overflow-wrap: break-word;
                line-height: 1.5em !important;
                padding: 10px;
                width: 100%;
                height: auto;
                color: color("dark-grey") !important;
                justify-content: flex-end;

                &:first-of-type {
                    flex-grow: 1;
                    flex-basis: 0%;
                    justify-content: flex-start;
                }

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .create{
        &:hover{
            text-decoration: none;
        }
    }

    nav{
        font-family: $font;
    }
}
