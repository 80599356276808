@import "src/style/variables.scss";

.FooterCreateButton {
    .round-button {
        &:hover {
            background-color: #787CDD !important;

            > div {
                color: #FFF !important;
            }

            .Visage2Icon {
                svg {
                    path {
                        fill: #FFF !important;
                    }
                }
            }
        }
    }
}