@import "src/style/variables";

.AcademyCourseProgress {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: $gutterSmall;

    .AcademyCourseProgress-meter {
        flex-grow: 1;
        height: 8px;
        background-color: color(visageMidBlueBackground);
        border-radius: 8px;

        .AcademyCourseProgress-meter-progress {
            height: 8px;
            border-radius: 8px;
            background-color: color(visageMidBlue);
        }
    }

    &.completed {
        .AcademyCourseProgress-meter-progress {
            background-color: color(green);
        }
    }
}
