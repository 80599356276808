@import "src/style/variables";

.zoltorContainer{
    .teaser-box{
        border: 1px solid;
        border-color: color("dusk-grey");
    }
}

.dialogSidebar {
    display: flex;
    justify-content: center;
}
.dialogItemsContainer {
    height: 100%;
    width: 100%;
}

.sidebarPopup {
    height: 100%;
    width: 100%;
    display: flex;
    font-family: sans-serif;
    text-align: center;
    align-items: flex-start;
    justify-content: flex-start;
}

.react-beatiful-dnd-copy ~ li {
    transform: none !important;
}

.react-beatiful-dnd-copy ~ [data-rbd-placeholder-context-id] {
    display: none !important;
}

.pickabeleItemsClass {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    width: 100%;
    max-height: 350px;
    margin-right: 15px;
    padding: 10px;
    border: 1px solid #aaa;
    overflow: hidden;
    overflow-y: scroll;
    min-height: 350px;
    .itemContainer {
        height: fit-content;
    }
}

.sidebarBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-left: 50px;
    padding: 10px;
    border: 2px solid#777;
    max-height: 350px;
    overflow-y: scroll;
    min-height: 350px;
}

.pickableItemsContainer {
    width: 100%;
    max-height: 350px;
}

.sidebarItemsContainer {
    width: 40%;
    min-width: 300px;
    min-height: 350px;
    max-height: 350px;
}

.sidebarInfoContainer {
    border: 1px solid #aaa;
    border-radius: $borderRadius;
    padding: 10px;
    margin-top: 5px;
    display: flex;
    .actionMenuPosition {
        display: flex;
        width: 20%;
        flex-direction: row-reverse;
        align-items: center;
    }
}

.sidebarPopup{
    font-family: $font !important;
}

.textContains {
    font-size: 12px;
    color: #616770;
}

.eachSidebarWidgets + .eachSidebarWidgets::before {
    display: inline-block;
    white-space: pre;
    content: ", ";
}

.placementDiv {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: $gutterMedium;

    .label {
        margin: 0px;
        margin-bottom: 10px;
    }

    .label2 {
        margin: 0px;
        margin-bottom: 10px;
        margin-left: 20px;
    }

    .eachContextDiv {
        border: 1px solid #aaa;
        border-radius: $borderRadius;
        padding: $gutterSmall;
        margin-bottom: $gutterXSmall;
        cursor: pointer;
    }

    .childEachContextDiv {
        border: 1px solid #aaa;
        border-radius: $borderRadius;
        padding: 10px;
        cursor: pointer;
        display: flex;
        margin-left: $gutterMedium;
    }

    .sidebarsDiv {
        border: 1px solid #aaa;
        border-radius: $borderRadius;
        padding: $gutterSmall;
        cursor: pointer;
        margin-left: $gutterMedium;
        margin-bottom: $gutterXSmall;
        position: relative;

        .icon {
            position: absolute;
            right: $gutterSmall;
            top: $gutterSmall;
            margin-top: -3px;
        }
    }
}


.dropDown{
    width: 95px;
    font-family: $font !important;
}

.zoltorContainer {
    border-color: 1px solid color("dusk-grey");
    font-family: $font;

    .teaser-box {
        border: 1px solid !important;
        border-color: color("dusk-grey") !important;
    }
    
    .teaser-box-content:active {
        background: color("dusk-grey");
        border: 1px solid !important;
        border-color: color("mid-grey") !important;
    }
    .teaser-box:active {

        .text{
        color: color("light-grey") !important;
        }
    }
}

.AdminSidebarEditor {
    .AdminSidebarEditor-forms{
        width: 325px;
    }
    .AdminSidebarEditor-editor {
        border: 1px solid color("dusk-grey");
        padding: $gutterSmall;
        margin-bottom: $gutterMedium;
        width: 300px;
    }
}

.AdminSidebarEditor-editor-segment {
    background-color: color("light-grey");
    cursor: grab;
    text-align: center;
    margin-bottom: $gutterSmall;

    .AdminSidebarEditor-editor-segment-text {
        display: inline-block;
        padding: $gutterSmall;
        padding-top: $gutterXLarge;
        padding-bottom: $gutterXLarge;
    }
}

.AdminSidebarItemSelector {
    padding: $gutterMedium;
    max-width: 428px;

    .ModuleSelectorHeader {
        display: flex;
        flex: 1 1 auto;
    }

    .AdminSidebarItemSelector-item {
        display: inline-block;
        width: 137px;
        height: 137px;
        vertical-align: top;
        margin: $gutterMedium;

        .AdminSidebarItemSelector-item-button {
            display: block;
            width: 100%;
            height: 100%;
            border: 1px solid color("dusk-grey");

            .AdminSidebarItemSelector-item-button-inner {
                display: inline-block;
                box-sizing: border-box;
                text-align: center;
            }
        }
    }
}

.AdminSidebarItemSelector-teaserBoxSelector {
    max-width: 601px;

    .AdminSidebarItemSelector-tb {
        display: inline-block;
        vertical-align: top;
        margin: $gutterSmall;
        width: 225px;
        min-height: 150px;
        cursor: pointer;

        .teaser-box-content {
            cursor: pointer;
        }
    }
}