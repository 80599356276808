@import "src/style/variables.scss";

.media-folder-edit-view {
    .title {
        margin-bottom: $gutterXSmall;
    }

    .ms-BasePicker {
        margin-top: $gutterXSmall;
    }
}

.isSmallViewMode {
    .media-folder-edit-view {
        width: auto;
    }
}
