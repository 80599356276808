@import "src/style/variables";

.AudioPlayer {
    border: 1px solid color(visageMidBlue);
    background-color: #F1F2FC;
    border-radius: 60px;
    padding: $gutterTiny;
    padding-left: $gutterSmall;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    column-gap: $gutterXSmall;
    width: 0px;

    .info-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .round-button {
        .recording {
            width: 12px;
            height: 12px;
            border-radius: 12px;
            background-color: #FC5371;
        }

        &.disabled {
            .recording {
                background-color: color(visage2LightGray2);
            }
        }
    }

    .time {
        width: 26px;
    }

    audio {
        display: none;
    }

    .wave {
        flex-grow: 1;
    }

    .round-button {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background-color: #FFF;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        z-index: 1;
    }
}

.Message {
    &.right {
        .bubble {
            .AudioPlayer {
                border: 0;
            }
        }
    }
}