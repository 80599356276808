@import "src/style/variables";
.onboarding-popup-component {
    .page-number {
        opacity: 0.9;
        text-transform: uppercase;
        color: color("dark-grey");
        text-align: center;
    }

    img {
        max-width: 636px;
    }
    button{
        margin-top: $gutterSmall;
    }
    .FormControl{
        margin-top: $gutterMedium;
    }
}
