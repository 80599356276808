@import "src/style/variables";

.AcademyLeaderboard {
    .category {
        margin-bottom: $gutterLarge;
    }

    .ms-Stack {
        > div {
            width: 100%;
        }
    }
}
