@import "src/style/variables.scss";

.toDoContent{
    h4 {
        margin-bottom: $gutterSmall;
    }

    .ms-DetailsHeader{
        padding-top:0;
    }
    .ms-DetailsRow-cell{
        font-size: 14px;
        padding-top: $gutterXSmall;
        padding-bottom: $gutterXSmall;
    }
    .active{
        font-size: 14px;
    }
    .listGroup{
        border: 1px solid color("dusk-grey");
        padding: 0px 12px 0px 12px;
        margin-top:8px;
    }
}