.SplitImage {
    .left, .rightTop, .rightBottom {
      box-sizing: border-box;
      float: left;
      overflow: hidden;
    }

    .left {
        border-right: 1px solid #fff;
    }

    .rightTop {
        border-bottom: 1px solid #fff;
    }

    .empty {
        background-color: rgba(0,0,0,0.05);
        color: #666;
        text-align: center;
        text-transform: uppercase;
    }

    &.circle {
        border-radius: 100%;
        overflow: hidden;
    }
}