@import "src/style/variables.scss";

.drop-zone-container {
    .image-size-recommendation {
        font-style: italic;
    }

    .drop-zone {
        font-size: 16px;

        .text {
            color: color("light-blue")
        }

        text-align: center;
        padding: $gutterXLarge;
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        border-color: color("dusk-grey");

        .uploadIcon {
            margin-right: $gutterLarge;
            display: inline-block;
            position: relative;
            top: 5px;
        }

        &:hover {
            background-color: color("light-grey");
            -webkit-transition: background-color $fastTransition;
            -moz-transition: background-color $fastTransition;
            -o-transition: background-color $fastTransition;
            transition: background-color $fastTransition;

        }

        &.is-dragging-file-over {
            border-style: dashed;
            border-width: 1px;
            margin: -1px;

            background-color: rgba(11, 80, 128, 0.8);
            border: 6px solid #0C97F4;
        }

        .image-size-recommendation {
            p {
                font-weight: 500;
                padding-top: $gutterXSmall;
            }
        }
    }
}