@import "src/style/variables";

.SearchResultObjectHit {
    align-items: center;
    border: 1px solid #ECECEC;
    display: block;
    padding: $gutterMedium;
    margin-bottom: $gutterSmall;
    border-radius: $borderRadius;
    background-color: white;

    &:hover {
        border: 1px solid #F2F2F2;
        box-shadow: 0px 12px 74px rgba(157, 160, 166, 0.15);

        .visit-action {
            opacity: 1;
        }
    }

    .image {
        height: 55px;
        width: 80px;
        margin-right: $gutterSmall;
        text-align: center;
        
        .hit-image {
            border-radius: $borderRadiusSharper;
            height: 55px !important;
            width: 80px !important;
        }

        .hit-icon {
            color: color("dark-grey");
            line-height: 2.5em;
            font-size: 20px;
            text-align: center;
        }
    }

    &.type1 {
        > * {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .user-image {
        width: 71px;
        height: 71px;
        display: inline-block;
        margin-right: $gutterMedium;
    }

    .ms-Persona-image {
        width: 71px !important;
        height: 71px !important;
    }

    .ms-Persona-initials {
        width: 71px !important;
        height: 71px !important;

        > span {
            font-size: 30px;
            margin-top: 18px;
            display: inline-block;
        }
    }

    .content {
        .name {
            margin-top: -3px;

            > span, > i, > img, > .Visage2Icon {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px !important;
            }

            > i, > img, > .Visage2Icon {
                margin-right: calc(#{$gutterXSmall} + #{$gutterTiny});
            }

            > .Visage2Icon {
                height: 20px;
                margin-top: -1px;
            }
        }

        .description, .user-title{
            margin-top: $gutterXSmall;

            > * {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box !important;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }

        .type {
            margin-top: calc(#{$gutterSmall} - 4px);

            > div {
                font-size: 12px;
                font-weight: 500;
            }
        }

        .no-title {
            > div {
                font-size: 12px;
                font-weight: 500;
            }
        }

        .path {
            margin: 6px 0px;
            padding-left: 0px;

            .path-link {
                display: inline-block;
                vertical-align: middle;
                margin-right: $gutterXSmall;
                color: color("light-blue");

                &:last-child:after {
                    content: "";
                    display: none;
                }
            }
            
            .path-link:after {
                content: "\e76c";
                display: inline-block;
                font-family: "FabricMDL2Icons";
                font-size: 8px;
                margin-left: $gutterXSmall;
                vertical-align: middle;
            }
        }
    }

    &.has-image {
        .content {
            width: calc(100% - 200px);
        }

        .image, .content {
            display: inline-block;
            vertical-align: top;
        }

        &.type1 {
            .content {
                margin-top: 10px;

                .user-title {
                    margin-top: 4px;
                }
            }
        }
    }

    .visit-action {
        color: color("mid-grey");
        margin-left: $gutterSmall;
        opacity: 0;

        i {
            font-size: 12px;
        }
    }
}
