@import "src/style/variables";

.ListWidget {
    text-align: left;
    margin: 0 -16px;
    overflow-y: scroll;
    max-height: 96px;

    .list {
        margin: 0 16px;

        .list-item {
            border-bottom: 1px solid color(borderColor);
            display: flex;
            margin-bottom: $gutterXSmall;
            padding-bottom: $gutterXSmall;

            &:last-child {
                margin-bottom: 0px;
                border-bottom: 0 none;
            }

            .text, .value {
                margin-bottom: 0;
                font-size: 10px;
            }

            .text {
                flex: 1 1 60%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre;
            }

            .value {
                flex: 1 1 40%;
                text-align: right;
            }
        }
    }
}