@import "src/style/variables";

.WidgetConfiguration {
    .widget-list {
        display: flex;
        flex-flow: row wrap;
        gap: $gutterLarge;
        justify-content: flex-start;

        .widget {
            cursor: pointer;
            flex: 0 0 256px;

            .heading {
                margin-bottom: $gutterXSmall;
            }

            .MarketplaceWidget {
                &:hover{
                    border-color: color("dark-grey");
                    -webkit-transition: border-color $fastTransition;
                    -moz-transition: border-color $fastTransition;
                    -o-transition: border-color $fastTransition;
                    transition: border-color $fastTransition;
                }
            }
        }
    }

    .configuration-body {
        padding-bottom: $gutterMedium;
    }

    .settings {
        display: flex;
        gap: $gutterXXXLarge;
        justify-content: flex-start;

        .left {
            flex: 1 1;
        }

        .right {
            flex: 0 0 256px;
        }
    }
}