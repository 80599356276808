@import "src/style/variables";

.AppPagePoweredBy {
    text-align: center;

    .powered-by {
        margin-bottom: $gutterSmall;
        text-transform: uppercase;
    }

    img {
        margin: 0 auto;
        max-width: 140px;
    }
}