@import "src/style/variables";

.ComposerAttachmentAudio {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    column-gap: $gutterSmall;
    align-items: center;
}
