@import "src/style/variables";

.AcademyTestView {
    border: 1px solid color(borderColor);
    border-radius: $borderRadius;

    .pageHeader {
        border-bottom: 1px solid color(borderColor);
        padding: $gutterMedium;
        margin-bottom: 0px;
    }

    .FormSection {
        margin-bottom: 0px !important;

        .questions {
            display: flex;
            flex-direction: column;
        }
    }

    .test-footer {
        padding: $gutterMedium;
    }

    &.locked {
        padding: $gutterMedium;
        display: flex;
        flex-direction: row;
        column-gap: $gutterSmall;
        align-items: center;
    }
}
