@import "src/style/variables";

.actionmenu-component {
    position: relative;
    height: 24px;
    min-width: 24px;
    display: inline-block;

    >span,
    >i {
        display: inline-block;
        vertical-align: middle;
    }

    // > span {
    //     margin-right: $gutterXSmall;
    // }

    i {
        color: color("dark-grey");
        fill: color("mid-grey");
        stroke-width: 15 !important;
        padding: 0 4px 0 4px;
        -webkit-transition: background-color $fastTransition;
        -moz-transition: background-color $fastTransition;
        -o-transition: background-color $fastTransition;
        transition: background-color $fastTransition;
    }

    &__toggler {
        display: inline-block;
        line-height: 0;
        outline: 0;
    }

    &__menu {
        min-width: 9em;
    }

    &.hover-effect {
        i {
            &:hover {
                background-color: color("light-grey");
                border-radius: $borderRadiusSharper;
            }
        }
    }
}

.StandardActionMenu {
    max-width: 24px;
    max-height: 24px;
}

.ActionMenu-FluentIcon {
    height: 20px;
    margin-left: -2px !important;
    margin-right: -2px !important;
    margin-top: -1px !important;

    >span {
        display: block;
        height: 20px;

        >svg {
            display: block;
            height: 20px;
        }
    }
}