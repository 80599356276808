@import "src/style/variables";

$chatTabComposerHeight: 43px;
$returnHeaderHeight: 64px;

#ConversationView {
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$spintrHeaderHeight} - (#{$gutterSmall} * 2));
    background: #FFF;
    border-radius: $borderRadius;

    .header {
        border-bottom: 1px solid color("borderColor");
        box-sizing: border-box;
        display: flex;
        line-height: 31px;
        padding: $gutterSmall;
        padding-left: $gutterSmall;
        padding-right: $gutterSmall;

        .conversation-info {
            display: flex;

            .image {
                flex: 0 0 31px;
                margin-right: $gutterSmall;
            }

            .text {
                flex: 1 1;

                .title {
                    line-height: 31px;
                }
            }
        }
    }

    .ChatTabContent {
        background-color: initial;
        height: calc(100% - #{$returnHeaderHeight});

        .message-container {
            height: calc(100% - #{$chatTabComposerHeight});
        }

        .ChatTabMessageContainer {
            height: 100%;
            
            .ScrollableAreaContainer {
                height: 100% !important;

                .ScrollableAreaContent {
                    padding: 0 $gutterMedium;
                }
            }
        }
    }
}

@media screen and (max-width: $vmPhoneLandscape) {
    #ConversationView {
        height: calc(100vh - 64px - (#{$gutterSmall} * 2) - 76px);

        .ChatTabContent {
            height: calc((100 * var(--vh, 1vh)) - #{$responsiveHeaderHeight + $returnHeaderHeight});
    
            .ChatTabMessageContainer {
                height: calc((100 * var(--vh, 1vh)) - #{$responsiveHeaderHeight + $returnHeaderHeight + $chatTabComposerHeight});
    
                .ScrollableAreaContainer {
                    height: calc((100 * var(--vh, 1vh)) - #{$responsiveHeaderHeight + $returnHeaderHeight + $chatTabComposerHeight}) !important;

                    .ScrollableAreaContent {
                        padding: 0;
                    }
                }
            }
        }
    }
}