@import "src/common.scss";

.ProductListColumn {
    .ProductsListColumn-products {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $spacingMedium;
        margin: 0;
        padding: 0;

        .product {
            flex: 1 0 360px;
            list-style: none;
            max-width: 100%;
        }
    }
}