@import "src/style/variables.scss";

.media-image-view {
    display: flex;
    flex-direction: column;
    position: relative;

    > .item {
        display: flex;
        position: relative;
        margin-bottom: $gutterMedium;
        align-self: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 300px;

        &:after {
            // Hax to make flex with min-height work in IE 11
            content: "";
            min-height: inherit;
            font-size: 0;
        }

        > .arrow {
            position: absolute;
            display: flex;
            background-color: rgba(0, 0, 0, 0.25);
            border-radius: 100%;

            > a {
                align-self: center;
                padding: $gutterTiny;
            }

            &.left {
                left: 0;
                margin-left: $gutterMedium;
            }

            &.right {
                right: 0;
                margin-right: $gutterMedium;
            }
        }

        i {
            color: #fff;
            filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 1));
        }

        > img,
        video {
            display: block;
            max-width: 100%;
            border-radius: $borderRadius;
            margin: 0 auto;
            flex-shrink: 0;
            align-self: center;
        }

        // .info {
        //     position: absolute;
        //     bottom: 0;
        //     width: 100%;
        //     box-sizing: border-box;
        //     border-radius: 0 0 4px 4px;
        //     background: linear-gradient(360deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);

        //     .displayName {
        //         margin-bottom: $gutterXSmall;
        //     }

        //     .sub-info {
        //         display: flex;
        //         justify-content: space-between;
        //     }
        // }
    }

    .media-image-info {
        .info-displayName-wrapper {
            position: relative;

            .displayName {
                margin-bottom: $gutterMedium;
                padding-bottom: $gutterMedium;
                border-bottom: 1px solid color("dusk-grey");
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .actionmenu-component {
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        .items {
            width: calc(100% - 300px);
            display: inline-block;

            .item {
                display: inline-block;
                width: calc(100% / 3);
                margin-bottom: $gutterMedium;
                vertical-align: top;

                .ms-TextField,
                .FormSelect {
                    max-width: 80%;
                }
            }

            &.fullWidth {
                width: 100%;
            }
        }

        .download-box {
            display: inline-block;
            width: 300px;
            vertical-align: top;

            a {
                div {
                    line-height: 1.875em;
                }
            }
        }
    }
    .InteractionsBar{
        margin: $gutterSmall 0px;
    }
    .CommentCreator{
        .comment-content{
            margin-top: 4px;
        }
    }
}

.isSmallViewMode {
    .media-image-view {
        > .info {
            .items {
                .item {
                    width: 100%;
                }
            }
        }

        .breadcrumbs-container {
            width: calc(100% - 30px);
        }

        .actionmenu-component {
            top: 12px; // breadcrumbs margin-top
        }
    }
}
