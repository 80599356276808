@import "src/common.scss";

.WikiSection {
    margin-bottom: $spacingLarge;

    .WikiSection-header {
        margin-bottom: $spacingMedium;
        position: relative;

        .action-menu-wrapper {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}