/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
.fs-big {
  font-family: "Eloquia", sans-serif;
  font-size: 28px !important;
  font-weight: 700 !important;
}

.fs-h1, h1 {
  font-family: "Eloquia", sans-serif;
  font-size: 28px;
  font-weight: 550;
  line-height: 1.5em;
}

.fs-h2, h2 {
  font-family: "Eloquia", sans-serif;
  font-size: 24px;
  font-weight: 550;
  line-height: 1.5em;
}

.fs-h3, h3 {
  font-family: "Eloquia", sans-serif;
  font-size: 20px;
  font-weight: 550;
  line-height: 1.5em;
}

.fs-h4, .SystemStatusView .left .header .ms-Persona .ms-Persona-primaryText, h4 {
  font-family: "Eloquia", sans-serif;
  font-size: 18px;
  font-weight: 550;
  line-height: 1.25em;
}

.fs-h5, h5 {
  font-family: "Eloquia", sans-serif;
  font-size: 16px;
  font-weight: 550;
  line-height: 1.25em;
}

.fs-h6, h6 {
  font-family: "Eloquia", sans-serif;
  font-size: 14px;
  font-weight: 550;
  line-height: 1.25em;
}

.fs-h7 {
  font-family: "Eloquia", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25em;
}

.fs-body-1 {
  font-family: "Eloquia", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-body-2 {
  font-family: "Eloquia", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-body-3 {
  font-family: "Eloquia", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.25em;
}

.fs-body-4 {
  font-family: "Eloquia", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-body-5 {
  font-family: "Eloquia", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-small-1 {
  font-family: "Eloquia", sans-serif;
  font-size: 10px;
  font-weight: 550;
  line-height: 1em;
}

.fs-small-2 {
  font-family: "Eloquia", sans-serif;
  font-size: 10px;
  font-weight: 550;
  line-height: 11px;
}

.fw-extra-bold {
  font-weight: 900;
}

.fw-bold {
  font-weight: 700;
}

.fw-semi-bold {
  font-weight: 600;
}

.fw-medium, .SystemStatusView .left .header .ms-Persona .ms-Persona-primaryText {
  font-weight: 550;
}

.fw-regular {
  font-weight: 400;
}

.fw-normal {
  font-weight: normal;
}

.fw-light {
  font-weight: 300;
}

.label-component {
  letter-spacing: -0.2px;
}
.label-component.uppercase {
  text-transform: uppercase;
}
.label-component.subline-letter-spacing {
  letter-spacing: 1px;
}
.label-component.centerText {
  text-align: center;
}
.label-component.italic {
  font-style: italic;
}
.label-component.underline {
  text-decoration: underline;
}
.label-component.line-through {
  text-decoration: line-through;
}

.fc-black {
  color: #000;
}

.fc-dark-grey {
  color: #22234A;
}

.fc-mid-grey {
  color: #475267;
}

.fc-grey {
  color: #727E94;
}

.fc-dusk-grey {
  color: #d5d5d5;
}

.fc-light-grey {
  color: #F8F8FF;
}

.fc-light-blue {
  color: #787CDD;
}

.fc-red {
  color: #EB0014;
}

.fc-salmon {
  color: #FC5371;
}

.fc-smoke {
  color: #EAEAEA;
}

.fc-white {
  color: #FFF;
}

.fc-office365 {
  color: #D83B01;
}

.fc-green {
  color: #1F7F35;
}

.fc-spintrGreen {
  color: #00A578;
}

.fc-outline {
  color: #488dc8;
}

.fc-yellow {
  color: #ADA000;
}

.fc-orange {
  color: #FFAE2C;
}

.fc-darkOrange {
  color: #A86800;
}

.fc-disabled {
  color: #a19f9d;
}

.fc-visageGray {
  color: #091B3D;
}

.fc-visageGray2 {
  color: #363853;
}

.fc-visageGray3 {
  color: #6D7588;
}

.fc-visageGray6 {
  color: #F7F7F9;
}

.fc-neutralBlue {
  color: #2772F0;
}

.fc-borderColor {
  color: #F4F4F5;
}

.fc-borderColor2 {
  color: #F7F8FA;
}

.fc-bookmarkColor {
  color: #F8F9FF;
}

.fc-blueHighlight {
  color: #ECE9FE;
}

.fc-visageMidBlue {
  color: #787CDD;
}

.fc-visageMidBlueBackground {
  color: #F1F2FC;
}

.fc-visage2LightGray {
  color: #F4F4F5;
}

.fc-visage2LightGray2 {
  color: #ECF0F3;
}

.fc-spintrWhite {
  color: #FFFFFF;
}

.fc-spintrGrey {
  color: #727E94;
}

.fc-primaryContent {
  color: #4E4F6C;
}

.fc-backgroundColor {
  color: #F0F0F0;
}

.fc-lightRed {
  color: #FDEBED;
}

.fc-lightYellow {
  color: #FDF7EA;
}

@media screen and (max-width: 1024px) {
  .fs-h1, h1 {
    font-size: 22px;
  }

  .fs-h2, h2 {
    font-size: 20px;
  }

  .fs-h3, h3 {
    font-size: 18px;
  }

  .fs-h4, .SystemStatusView .left .header .ms-Persona .ms-Persona-primaryText, h4 {
    font-size: 16px;
  }

  .fs-h5, h5 {
    font-size: 14px;
  }

  .fs-h6, h6 {
    font-size: 12px;
  }
}
.SystemStatusView .right {
  background-color: #fff;
  border-radius: 12px;
}
.SystemStatusView .left .header .pageHeader {
  border-bottom: 1px solid #F4F4F5;
  margin-bottom: 12px;
  padding-bottom: 12px;
}
.SystemStatusView .left .header .ms-Persona {
  flex-shrink: 0;
}
.SystemStatusView .left .header .ms-Persona .ms-Persona-details {
  padding-right: 0;
}
.SystemStatusView .left .header .SpintrSearch {
  margin: 0;
}
.SystemStatusView .left .header .SpintrSearch .ms-SearchBox {
  min-width: 125px;
}
.SystemStatusView .left .header .system-info-button {
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  align-self: center;
  padding: 8px;
}
.SystemStatusView .left .header .system-info-button .Visage2Icon {
  line-height: 1;
}
.SystemStatusView .left .header #InlineComposer {
  width: 0px;
  height: 0px;
  overflow: hidden;
  position: absolute;
  left: 100vw;
}
.SystemStatusView .left #SocialFeedContainer {
  margin-bottom: 0px;
}
.SystemStatusView .left #SocialFeedContainer .SocialPostContainer:last-child {
  margin-bottom: 0px;
}
.SystemStatusView .left .visage-box-shadow {
  box-shadow: none;
}
.SystemStatusView .left > .ms-Stack > .ms-Stack-inner {
  flex-wrap: nowrap !important;
}
.SystemStatusView .left > .ms-Stack > .ms-Stack-inner > div {
  box-sizing: border-box;
}
.SystemStatusView .left > .ms-Stack > .ms-Stack-inner > div.feed {
  width: 100%;
}
.SystemStatusView .left > .ms-Stack > .ms-Stack-inner > div.feed #SocialFeedContainer .infinite-scroll-component {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.SystemStatusView .left > .ms-Stack > .ms-Stack-inner > div.feed #SocialFeedContainer .infinite-scroll-component .SocialPostContainer {
  max-width: 440px;
  width: 100%;
  box-sizing: border-box;
  align-self: flex-end;
}
.SystemStatusView .left > .ms-Stack > .ms-Stack-inner > div.feed #SocialFeedContainer .infinite-scroll-component .SocialPostContainer.is-system-status {
  align-self: flex-start;
}
.SystemStatusView .right {
  max-width: 300px;
}
.SystemStatusView .right .system-info .header {
  margin-bottom: 12px;
}
.SystemStatusView .right .system-info .content > div:not(:last-child) {
  margin-bottom: 16px;
}
.SystemStatusView .right .system-info .content .title {
  margin-bottom: 6px;
}
.SystemStatusView .right .system-info .content .resource-description {
  white-space: pre-wrap;
}
.SystemStatusView .right .system-info .content .openTimes .days {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.SystemStatusView .right .system-info .content .openTimes .days .openTimeDay {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
}
.SystemStatusView .right .system-info .content .openTimes .days .openTimeDay > div > div {
  display: flex;
  align-items: center;
}
.SystemStatusView .right .system-info .content .content-box {
  background-color: #F4F4F5;
  border-radius: 12px;
  padding: 12px;
}
.SystemStatusView .right .system-info .content .owners {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.SystemStatusView .right .system-info .content .archive .unstyled-button {
  width: 100%;
  margin-top: 6px;
  padding: 6px 12px;
  border-radius: 12px;
  box-sizing: border-box;
}
.SystemStatusView .right .system-info .content .archive .unstyled-button.active, .SystemStatusView .right .system-info .content .archive .unstyled-button:hover {
  background-color: #F1F2FC;
}
.SystemStatusView .right .system-info .content .faq {
  border-radius: 12px;
  border: 1px solid #F4F4F5;
}
.SystemStatusView .right .system-info .content .faq > div {
  padding: 9px 12px;
}
.SystemStatusView .right .system-info .content .faq > div:not(:last-child) {
  border-bottom: 1px solid #F4F4F5;
}
.SystemStatusView .right .system-info .content .faq > div .unstyled-button {
  width: 100%;
}
.SystemStatusView .right .system-info .content .faq > div .unstyled-button .Visage2Icon {
  margin: 0;
}
.SystemStatusView .right .system-info .content .faq > div .answer {
  margin-top: 6px;
}
.SystemStatusView .right .system-info .content .faq > div.expanded {
  background-color: #F4F4F5;
}
.SystemStatusView .contentWithInfoPanel .contentWIthInfoPanelLeft .SpintrSocialFeed .social-post-feed {
  margin-top: 0;
}
.SystemStatusView .contentWithInfoPanel .contentWIthInfoPanelRight .archive .unstyled-button {
  width: 100%;
  margin-top: 12px;
}
.SystemStatusView .contentWithInfoPanel .contentWIthInfoPanelRight .archive .unstyled-button .count {
  padding: 4px;
  border-radius: 8px;
  background-color: #F4F4F5;
  min-height: 24px;
  min-width: 24px;
  box-sizing: border-box;
}

.isSmallViewMode .SystemStatusView .left,
.isSmallViewMode .SystemStatusView .right {
  max-width: 100%;
}
.isSmallViewMode .SystemStatusView .right {
  margin-bottom: 24px;
}
.isSmallViewMode .SystemStatusView .pageHeader .pageHeader-title {
  max-width: 100%;
}
.isSmallViewMode .SystemStatusView .pageHeader .pageHeader-title .pageHeader-title-left {
  flex: 1 1 calc(100% - 36px - 12px);
  max-width: calc(100% - 36px - 12px);
}
.isSmallViewMode .SystemStatusView .pageHeader .pageHeader-title .pageHeader-title-left .ms-Persona {
  max-width: calc(100% - 26px);
}
.isSmallViewMode .SystemStatusView .pageHeader .pageHeader-title .pageHeader-title-left .ms-Persona-details {
  padding-right: 12px;
}
.isSmallViewMode .SystemStatusView .pageHeader .pageHeader-title .pageHeader-title-left .ms-Persona-details .ms-Persona-primaryText {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.isSmallViewMode .SystemStatusView .pageHeader .pageHeader-title .pageHeader-title-right {
  flex: 0 0 36px;
}