@import "src/style/variables";

.ms-Overlay {
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0.3;
}

.ms-Panel-main {
    border-radius: $borderRadiusRound 0 0 $borderRadiusRound;

    .visage-box-shadow {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 24px;
    }

    .teaser-box-container {
        padding-bottom: 24px;
        border-bottom: 1px solid color(visage2LightGray);
    }

    .ms-Panel-content {
        padding-left: 24px;
        padding-right: 24px;
        margin-top: $gutterSmall;

        .teaser-box-inner {
            min-height: 88px;
        }
    }

    .ms-Panel-navigation {
        justify-content: space-between;
        align-items: center;
        padding-top: $gutterMedium;
    }

    .ms-Panel-closeButton {
        padding: 0;
        margin-right: 17px;
    }

    .DrawerHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0 0 $gutterXXLarge;
    }

    .ms-Panel-commands {
        margin-top: 0;
    }

    .ShortcutsIcon {
        margin-right: $gutterXSmall;
    }
}

.group-popout {
    overflow: hidden;

    .ms-Panel-content {
        padding: 0px !important;
        height: calc(100vh - 52px);
        overflow: hidden;

        .GroupHistoryView {
            height: calc(100vh - 52px);
        }
    }
}

@media screen and (min-width: $vmPhonePortrait) {
    .ms-Panel-main:has(.isBigView) {
        width: 568px;
    }

    .ms-Panel-main:has(.row-layout) {
        width: 640px;
    }
}
