@import "src/style/variables.scss";

.EditableContentSectionsRow-IframeForm {
    .textField {
        max-width: none;
    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
    .ms-BasePicker {
        background: white;
        border-radius: 12px;
    }
}

.teaserbox-textpage{
  .visage-box-shadow{
    box-shadow: none !important;
  } 
}

.columnTypeSelectorModal {
  .typeSelectorContainer {
    column-count: 2;
    padding-top: $gutterSmall;
  }

}

.contentSectionItem {
    background-color: color("visageMidBlueBackground");
    height: 150px;
    border-radius: $borderRadius;
}

.EditableContentSections {
    .redactorInlineFolder,
    .redactorSingleFile,
    .redactorQa,
    .redactorAcademy {
        background-color: #e3e5e5;
        border: 1px solid #d0d0d0;
        color: #333333;
        display: inline-block;
        margin: 0 4px;
        padding: 2px;
    }

    img {
        max-width: 100%;
    }

    .AcademyTestFormQuestion {
        border: 0;
        border-radius: 0;

        .content {
            padding: 0;

            .alternative {
                padding: 0 $gutterXSmall;
                border-radius: $borderRadius;
                background-color: #fff;

                margin-bottom: $gutterXSmall;
                border: 1px solid color("mid-grey");
            }
        }
    }
  
    .columnTypeSelector {
        max-height: 320px;
        border-radius: 30px;
        overflow-y: scroll;
        background-color: #fff;
        position: absolute;
        z-index: 2;
        cursor: default;
        top: calc(50% - 150px);
        width: 450px;
        &::-webkit-scrollbar {
          background: transparent; 
          width: 0px;
        }
        .columnContainer {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            // overflow: hidden;
            padding: 6px 6px 6px;
        }
        .columnContainer.smallViewMode {
          column-count: 2;
        
    }
    .columnTypeSelector.smallViewMode {
      width: 300px;
      left: 10px;
    }
    
    .columnType {
    /* don't grow, don't shrink, width */
        flex: 1;
        flex-basis: 20%;
        height: 73px;
        
      .columnTypeInner {
          padding: $gutterSmall;
          &:hover{
            background-color: color("light-grey");
            border-radius: 30px;
          }
      }
      &:hover{
        background-color: color("light-grey");
        border-radius: 30px;
      }
    }
  
  .teaser-box {
    width: 100%;
  }
  
  .expandableFieldHeadlineLabel, .expandableFieldContentLabel {
    margin-top: $gutterSmall;
    margin-bottom: $gutterSmall;
  }
}
.WidthTypeSelector-items {
  &:hover {
     border-radius: 100px;
    background-color: #F4F4F5;
  }
}

    .content-row {
        margin-bottom: $gutterMedium;
        display: flex;
        flex-direction: row;
        column-gap: $gutterMedium;

        &.column-count-1 {
            .content-column {
                width: 100%;
            }
        }

        &.column-count-2 {
            .content-column {
                width: 50%;
            }
        }

        &.column-count-3 {
            .content-column {
                width: 33%;
            }
        }
    }
}