@import "src/style/variables";
.profile-view {
    position: relative;

    .label-and-menu {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .favorite {
        display: flex;
        justify-content: space-between;
    }
    a {
        color: color("light-blue");
    }
    .summary {
        position: relative;

        .row {
            display: flex;
            border: 1px solid color("dusk-grey");

            .left-column {
                flex: 1 1 50%;
                padding: $gutterMedium;
                padding-top: 0;
            }

            .right-column {
                border-left: none;
                padding: $gutterMedium;
                padding-top: 0;
            }
        }

        h3 {
            font-size: 16px;
            font-weight: 400;
        }
        h4 {
            color: color("dark-grey");
            font-size: 10px;
            font-weight: 550;
            line-height: 1em;
            margin-bottom: 4px;
            text-transform: uppercase;
        }

        .ms-Button {
            top: 10px;
            right: 10px;
            position: absolute;
        }
    }

    .feed-and-tabs {
        .socialFeed, .tabs {
            display: inline-block;
            vertical-align: top;
        }

        .socialFeed {
            width: $socialFeedWidth;
        }

        .tabs {
            background-color: #FFF;
            padding: $gutterXXLarge;
            box-sizing: border-box;
            width: calc(100% - #{$socialFeedWidth} - #{$gutterXXLarge});
            margin-left: $gutterXXLarge;
            border-radius: $borderRadius;

            &.hide-feed {
                margin-left: 0;
                width: 100%;
            }

            .ms-Pivot {
                margin-bottom: $gutterXXLarge;
            }

            .education,
            .employers,
            .competences,
            .interests,
            .family {
                border: 1px solid color("dusk-grey");
                border-radius: $borderRadius;
                margin: $gutterXSmall 0 $gutterSmall 0;
                padding: $gutterSmall;
                .ms-DetailsHeader {
                    padding-top: 0;
                }
            }

            .CompetenceView {
                padding: 0 $gutterMedium $gutterMedium;

                i {
                    &:hover {
                        cursor: pointer;
                    }
                }

                h1 {
                    margin-top: 0;
                }

                .competenceModal {
                    display: none;
                }
            }

            .favorites {
                padding-left: 16px;
                border: 1px solid color("dusk-grey");
                padding: $gutterMedium;
            }
            .informationContent {
                padding: $gutterMedium;
                .ms-Button {
                    margin-top: $gutterSmall;
                }
                h4 {
                    margin-bottom: 0;
                    margin-left: $gutterXSmall;
                }
            }
        }
    }

    .content-list {
        list-style-type: none;
        padding-left: 0;
        margin-top: 0;
    }

    .latest-images {
        margin-top: $gutterMedium;
        padding: $gutterMedium;
        padding-top: 0;
        border: 1px solid color("dusk-grey");

        .images {
            img {
                height: 108px;
                overflow: hidden;
                padding: 3px;
                width: auto;
                margin-right: $gutterXSmall;
            }
        }
    }

    .images-tab {
        img {
            height: 108px;
            overflow: hidden;
            padding: 3px;
            width: auto;
        }
    }

    .header {
        position: relative;
        display: flex;
        align-items: center;
        height: 175px;
        margin-bottom: $gutterXXLarge;

        .actionmenu-component {
            position: absolute;
            top: 10px;
            right: 16px;
            z-index: 7;
            background-color: rgba(255, 255, 255, 0.3);
        }

        .user-info {
            overflow: hidden;

            .ms-Persona {
                margin-left: 2em;
                z-index: 7;
                height: 100px !important;

                .ms-Persona-secondaryText {
                    white-space: initial;
                }
            }
        }

        .artwork {
            bottom: 0;
            left: 0;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 5;

            // .overlay {
            //     background: rgb(255, 255, 255);
            //     background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 50%, transparent 100%);
            //     height: 100%;
            //     position: absolute;
            //     width: 100% !important;
            //     z-index: 6;
            // }
            img {
                min-height: 100%;
                min-width: 100%;
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: $borderRadius;
            }
            .overlay {
                border-radius: $borderRadius;
                background-color: initial !important;
                background-image: -ms-linear-gradient(
                    left,
                    rgba(255, 255, 255, 0.8) 0%,
                    rgba(255, 255, 255, 0.6) 40%,
                    transparent 100%
                );
                background-image: -moz-linear-gradient(
                    left,
                    rgba(255, 250, 250, 0.8) 0%,
                    rgba(255, 255, 255, 0.6) 40%,
                    transparent 100%
                );
                background-image: -o-linear-gradient(
                    left,
                    rgba(255, 255, 255, 0.8) 0%,
                    rgba(255, 255, 255, 0.6) 40%,
                    transparent 100%
                );
                background-image: -webkit-gradient(
                    linear,
                    left top,
                    right top,
                    color-stop(0%, rgba(255, 255, 255, 0.8)),
                    color-stop(40%, rgba(255, 255, 255, 0.6)),
                    color-stop(100%, transparent)
                );
                background-image: -webkit-linear-gradient(
                    left,
                    rgba(255, 255, 255, 0.8) 0%,
                    rgba(255, 255, 255, 0.6) 40%,
                    transparent 100%
                );
                background-image: linear-gradient(
                    to right,
                    rgba(255, 255, 255, 0.8) 0%,
                    rgba(255, 255, 255, 0.6) 40%,
                    transparent 100%
                );
                height: 100%;
                position: absolute;
                width: 100% !important;
                z-index: 10;
            }
        }
    }

    .SpintrSocialFeed .social-post-feed {
        margin-top: $gutterXXLarge;
    }
}

.isSmallViewMode {
    .profile-view {
        .header {
            .ms-Persona {
                margin-left: $gutterMedium;
            }
        }

        .summary {
            .row {
                flex-direction: column;

                .left-column {
                    padding-bottom: 0;
                }
            }
        }
    }

    .ms-Pivot {
        .ms-Callout-container {
            z-index: 2; // higher than feed composer
        }
    }
}
