@import "src/style/variables.scss";

.ContentWithUnreadIndicator {
    position: relative;

    .UnreadIndicatorWrapper {
        position: absolute;
    }
}

.UnreadIndicator {
    background-color: #FC5371;
    border-radius: 100px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    .label-component {
        font-family: "Eloquia" !important;
        position: relative;
        top: -1px;
    }
}
