@import "src/style/variables.scss";

.InformationFeedContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: $gutterMedium;
    row-gap: $gutterMedium;

    .information-feed-empty {
        padding: $gutterSmall $gutterLarge $gutterSmall $gutterLarge;
    }

    .information-feed-entry {
        flex-basis: calc((100% - (#{$gutterMedium}) * 2) / 3);
        width: calc((100% - (#{$gutterMedium}) * 2) / 3);
        min-width: 230px;
        flex-shrink: 1;
        flex-grow: 1;

        >div {
            height: 100%;
            box-sizing: border-box;
        }
    }

    .information-feed-entry.latest {
        flex-basis: calc((100% - #{$gutterMedium}) / 1.5);
        width: calc((100% - #{$gutterMedium}) / 1.5);

        .entry-image {
            &.fixed-height {
                height: 140px;
            }
        }
    }

    &.template1 {
        .information-feed-entry {
            flex-basis: calc((100% - #{$gutterMedium * 2}) / 3);
            width: calc((100% - #{$gutterMedium * 2}) / 3);

            // 2nd row, 50%
            &:nth-child(2),
            &:nth-child(3) {
                flex-basis: calc((100% - #{$gutterMedium}) / 2);
                width: calc((100% - #{$gutterMedium}) / 2);

                .entry-image {
                    height: 200px;
                }
            }

            .entry-image {
                height: 183px;
            }

            // Full row article
            &.latest {
                flex-basis: calc((100% - #{$gutterMedium}));
                height: 350px;
                width: calc((100% - #{$gutterMedium}));
                position: relative;

                .entry-image,
                .entry-content-image {
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 0;

                    border-radius: $borderRadius;
                    height: 350px;
                }

                .gradient-overlay {
                    border-radius: $borderRadius;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, #000000 100%);
                    bottom: 0;
                    height: 350px;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 1;
                }

                .entry-wrapper {
                    position: absolute;
                    z-index: 2;
                    bottom: 0;
                    left: 0;

                    .mini-title {
                        background-color: initial;
                    }
                }

                .entry-content-text {
                    >div:not(.entry-content-image) {
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }
    }

    &.template2 {
        .entry-image {
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
            height: 183px;
        }

        .information-feed-entry {
            flex-basis: calc((100% - #{$gutterMedium * 2}) / 3);
            width: calc((100% - #{$gutterMedium * 2}) / 3);

            &.latest {
                flex-basis: calc((100% - #{$gutterMedium}) / 1.5);
                width: calc((100% - #{$gutterMedium}) / 1.5);
            }
        }
    }

    &.template3 {
        .information-feed-entry {
            .InformationFeedEntry {
                display: flex;

                .entry-content-image {
                    flex-basis: 28%;
                    flex-grow: 0;
                    flex-shrink: 0;

                    .entry-image {
                        height: 183px;
                        border-bottom-left-radius: $borderRadius;
                        border-top-right-radius: 0;
                    }
                }

                .entry-wrapper {
                    flex: 72% 1 1;
                    display: block;
                    overflow: hidden;
                }
            }

            flex-basis: calc((100% - #{$gutterMedium}));
            width: calc((100% - #{$gutterMedium}));
        }
    }
}
