@import "src/style/variables";

.WidgetLibrary {
    .empty-library {
        padding-top: $gutterXXXLarge;
        text-align: center;
    }

    .app-list {
        .WidgetLibraryAppCategory {
            margin-bottom: $gutterMedium * 2;

            .heading {
                margin-bottom: $gutterMedium;

                .text, .create-button {
                    display: inline-block;
                    vertical-align: middle;
                }

                .create-button {
                    margin-left: $gutterXSmall;
                    height: 30px;
                    line-height: 30px;
                }
            }

            .widget-list {
                display: flex;
                flex-flow: row wrap;
                gap: $gutterLarge;
                justify-content: flex-start;

                .WidgetLibraryConfiguration {
                    flex: 0 0 255px;
                    position: relative;

                    .MarketplaceWidget {
                        width: 254px;
                    }

                    .information {
                        .name {
                            .label-component {
                                width: calc(100% - 20px);
                            }
                        }
                    }

                    &.clickable {
                        cursor: pointer;
                    }

                    > .options {
                        position: absolute;
                        right: 12px;
                        top: 12px;
                    }
                }
            }
        }
    }
}