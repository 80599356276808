@import "src/style/variables";

#ResponsiveProfile {
    z-index: $spintrHeaderZIndex + 1;

    .overlay {
        background-color: rgba(0, 0, 0, 0.3);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $spintrHeaderZIndex + 2;
    }

    .profile, .profile .header, .profile .body {
        background-color: #FFF;
        right: 0;
        max-width: 90%;
        position: fixed;
        top: 0;
        transition: right ease-in-out 0.2s;
        width: 335px;
    }

    &.hidden {
        .overlay {
            bottom: 100%;
        }

        .profile, .profile .header, .profile .body {
            right: -335px;
        }
    }

    .profile {
        bottom: 0;
        z-index: $spintrHeaderZIndex + 3;

        .header {
            border-bottom: 1px solid color("smoke");
            box-sizing: border-box;
            display: flex;
            height: 64px;
            line-height: 32px;
            padding: $gutterMedium;

            .user {
                flex: 1 1 calc(100% - #{23px + $gutterMedium});
            }

            .close-button {
                flex: 0 0 23px;
                margin-left: $gutterMedium;
                align-self: center;
            }
        }

        .body {
            bottom: 0;
            overflow: scroll;
            top: 64px;

            ul,
            li {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            .categories {
                .category {
                    padding-bottom: $gutterSmall;

                    .heading {
                        padding: 0 $gutterMedium;
                    }

                    .items {
                        .item {
                            a {
                                color: #000;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: $gutterSmall $gutterMedium;
                                position: relative;

                                i {
                                    position: absolute;
                                    right: $gutterMedium;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}