@use "sass:math";

/* Spacings */
$spacingTiny:       4px;
$spacingSmall:      8px;
$spacingMedium:     12px;
$spacingLarge:      16px;
$spacingXLarge:     20px;
$spacingXXLarge:    24px;

/* Border Radii */
$brSharp:           4px;
$brNormal:          8px;
$brRounder:         12px;
$brVeryRound:       30px;
$brCircular:        50%;

/* Font Name */
$primaryFont:       Eloquia,sans-serif;

/* Font Sizes */
$fsCaption:         12px;
$fsBodySmall:       14px;
$fsBodyMedium:      16px;
$fsBodyLarge:       18px;
$fsHeadingSmall:    20px;
$fsHeadingLarge:    28px;
$fsHeadingExtraLarge: 32px;

/* Font Weights */
$fwRegular:         400;
$fwMedium:          550;
$fwSemibold:        600;
$fwBold:            700;

/* Line Heights */
$lhNarrow:          math.div(4, 3.5);   // 1.1428
$lhLow:             1.2;
$lhNormal:          math.div(4, 3);     // 1.3333
$lhHigh:            math.div(5, 3.5);   // 1.4285
$lhHigher:          1.5;

/* Letter Spacing */
$lsDense:           -0.2px;
$lsNormal:          0.28px;
$lsWide:            1px;


/* Z-Index */
$ziTmpFix:        999100;
$ziDrawer:           900 + $ziTmpFix;
$ziModal:           1000 + $ziTmpFix;

/* Curves */
$curveEasyEase:     cubic-bezier(0.33, 0, 0.67, 1);

/* Responsive Breakpoints */
$vmTabletPortrait: 768px;
$vmPhoneLandscape: 960px;
$vmPhonePortrait: 480px;
$vmTabletLandscape: 1024px;

/* Defaults */ 
$mainContentBackplatePadding: $spacingLarge;
