@import "src/style/variables.scss";

.CalendarEventRecurringSelector {
    .rec-info-text {
        margin-top: $gutterSmall;
    }
}

.CalendarEventRecurringSelector-dialog {
    .ms-Modal-scrollableContent {
        max-width: 100%;
    }

    .weekDaySelector {
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;

        >* {
            height: 30px;
            width: 30px;
            border-radius: 30px;
            text-align: center;

            >.label-component {
                line-height: 33px;
            }

            &.selected {
                background-color: color(visageGray3);

                >.label-component {
                    color: #FFF;
                }

                &:hover {
                    opacity: 0.75;
                }
            }

            &:not(.selected) {
                &:hover {
                    background-color: color(light-grey);
                }
            }
        }
    }

    .ms-ChoiceField-wrapper {
        width: 100%;
    }

    .ChoiceGroup-option-inline {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gutterSmall;
        margin-bottom: $gutterXSmall;
        .ms-Dropdown{
            min-width: 188px;
        }

        .ms-ChoiceField-field {
            flex-grow: 1;
            min-width: 100px;
        }
    }

    .inlineChildren {
        .spinButton {
            max-width: calc(100% - 100px - #{$gutterSmall});
        }
    }
    .spinButton{
        :disabled{
            border-radius: $borderRadius !important;
        }
        .ms-Button{
            height: 50%;
            vertical-align: middle;
            &:hover{
                border-radius: $borderRadius;
            }
        }
    }
}