.ScrollableArea {
    direction: ltr;
    height: 100%;
    overflow: hidden;
    position: relative;

    .contentBefore:before, .contentAfter:after {
        content: ' ';
        display: block;
        height: 2px;
        position: absolute;
        width: 100%;
        z-index: 99;
    }

    .contentBefore:before {
        top: 0;
    }

    .contentAfter:after {
        bottom: 0;
    }
    
    > .ScrollableAreaWrap {
        height: 100%;
        outline: none;
        margin-right: -30px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-right: 30px;
        position: relative;

        > .ScrollableAreaBody {
            direction: ltr;
            position: relative;
        }
    }
}
