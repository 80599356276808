@import "src/style/variables.scss";

.teaser-box {
    width: 100%;
    height: 100%;
    border-radius: $borderRadius;
    min-height: 88px;

    .teaser-box-inner {
        display: flex;
        box-sizing: border-box;
        border-radius: $borderRadius;
        width: 100%;
        height: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: $gutterLarge;
        min-height: 130px;
        background-size: cover;
        background-repeat: no-repeat;

        .teaser-box-icon-wrapper {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            
            &.has-icon-bg {
                height: 55px;
                width: 55px;
                border-radius: 55px;
                margin-bottom: $gutterSmall;

                svg {
                    margin-top: calc(50% - 12px);
                }
            }
        }

        .teaser-box-image {
            max-width: 70px;
        }

        .sub-text {
            margin-top: $gutterXSmall;
        }
    }
    
    .blog-teaser-box-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        box-sizing: border-box;
        background-color: #F1F2FC;
        border-radius: $borderRadius;

        .InformationFeedEntry {
            padding: 0px;
            border: 0;
            background-color: #F1F2FC;
        }

        .BlogTitle {
            margin-bottom: 0;
            margin-top: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex-shrink: 0;
        }

        .BlogPostText {
            margin-bottom: 2px;
        }
        
        .BlogPostHeadline {
            margin: 0px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            flex-shrink: 0;
        }

        .text {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
        }

        .TextAndImages {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow: hidden;

            .BlogPostText {
                overflow: hidden;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                margin-top: $gutterXSmall;
            }

            .ms-Persona {
                margin-top: $gutterSmall;
            }

            .BlogPostImage {
                margin-bottom: $gutterLarge;
                line-height: 0;
                position: relative;
                padding: 33.9% 0 0 0;
                display: block;
                height: auto;
                overflow: hidden;
                border-radius: 16px;

                img {
                    max-height: 100%;
                    display: block;
                    max-width: 100%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }

        .socialBlock {
            margin-top: $gutterMedium;
        }
    }

    &.hasImage {
        .TextAndImages {
            .text {
                .BlogPostText {
                    -webkit-line-clamp: 3;
                }
            }
        }
    }

    .fullWidth {
        width: 100%;

        .TextAndImages {
            .text {
                .BlogPostText {
                    -webkit-line-clamp: 4;
                }
            }
        }

        &.hasImage {
            .InformationFeedEntry {
                display: flex;
                flex-direction: row;
                max-width: 100%;
                column-gap: $gutterMedium;

                > * {
                    flex-basis: 50%;
                }

                .entry-content-image  {
                    img {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    &.with-border {
        border: 1px solid color(smoke);
    }

    &.is-selectable {
        &:hover {
            border: 1px solid color(dark-grey);
        }
    }

    &.is-selected {
        border: 1px solid color(dark-grey);
    }
}