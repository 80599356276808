@import "src/style/variables.scss";

.GroupHeaderInformation {
    display: flex;
    flex-direction: row;
    column-gap: $gutterSmall;
    align-items: center;
    padding: $gutterSmall;

    .GroupHeaderInformation-inner {
        display: flex;
        flex-direction: row;
        column-gap: $gutterSmall;
        align-items: center;
        position: relative;

        .text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .upper {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: $gutterXSmall;
        }
    }

    .divider {
        width: 1px;
        height: 16px;
        background-color: color(borderColor);
    }

    .members {
        display: flex;
        flex-direction: row;
        margin-left: 5px;
        
        > * {
            margin-left: -5px;

            .ms-Persona {
                border: 2px solid #FFF;
                border-radius: 20px;
                height: auto !important;
            }
        }

        .plus {
            height: 20px;
            width: 20px;
            border-radius: 20px;
            background-color: color(visageMidBlueBackground);
            border: 2px solid #FFF;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            z-index: 1;

            .label-component {
                font-size: 8px !important; 
            }
        }
    }

    .add-member {
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;
        align-items: center;
    }
}

@media only screen and (max-width: $vmTabletLandscape) {
    .GroupHeaderInformation {
        .members, .add-member, .divider {
            display: none;
        }
    }
}
