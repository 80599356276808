@import "src/common.scss";

@media screen and (max-width: $vmTabletPortrait) {
    .PlannerItemForm {
        .date-range {
            flex-direction: column;
            gap: $spacingSmall;
        }
    }
}

.PlannerItemForm{
    .PlannerItemForms-infoBlock {
        display: flex;
        align-items: center;
        gap: $spacingMedium;
        border: 1px solid $spintrGrayLight;
        border-radius: $brRounder;
        margin-bottom: $spacingXLarge;
        padding-left: $spacingSmall;
        padding-right: $spacingMedium; 
        padding-top: $spacingMedium;
        padding-bottom: $spacingMedium;
    }
    
    .title-url-container {
        display: flex;
        flex-direction: column;
        gap: $spacingSmall;
        flex-grow: 1;
    }
    
    .delete-icon {
        margin-left: auto;
        margin-top: -55px;
        align-self: center;
        cursor: pointer;
    }
    
    .PlannerItemDetails-content-property {
        align-items: flex-start;
        display: flex;
        align-items: center;
    }
    
    .add-link-button {
        display: flex;
        align-items: center;
        padding: 0;
        cursor: pointer;
    }
    
    .add-link-label {
        font-weight: 400;
        line-height: 1.5em;
        font-size: 12px;
        margin-right: 8px;
        cursor: pointer;
    }

    .Label-form{
        font-weight: 400;
        line-height: 2em;
        font-size: 12px;
    }
}