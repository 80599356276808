@import "src/style/variables.scss";

.Tabs {
    width: 100%;
    .Tabs-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        overflow: hidden;

        .tab-wrapper {
            .tab {
                display: flex;
                flex-direction: row;
                padding: $gutterXSmall $gutterSmall;
                column-gap: $gutterXSmall;
                border-radius: $borderRadiusSharp;
                align-items: center;
                height: 24px;
        
                .Visage2Icon {
                    display: none;
                }
        
                .label-component {
                    white-space: nowrap;
                }
    
                &.active {
                    background-color: color(visageMidBlueBackground);
        
                    .label-component {
                        color: color(visageMidBlue);
                    }
        
                    .Visage2Icon {
                        display: block;
        
                        svg {
                            path {
                                fill: color(visageMidBlue);
                            }
                        }
                    }
                }
        
                &:hover {
                    opacity: 0.75;
                }
            }
            &:first-child {
                .tab {
                    padding-left: 0px;

                    &.active {
                        padding-left: $gutterSmall;
                    }
                }
            }
        }
    
        &.calculating {
            .tab {
                opacity: 0;
            }
        }
    }
}
