@import "src/style/variables";

.SuppliersView {
    .suppliersFilter {
        border: 1px solid color("dusk-grey");
        padding: $gutterXSmall;
        margin-bottom: $gutterMedium;
        display: inline-block;
    }

    .SupplierUser {
        display: inline-block;
        margin-top: 2px;
        margin-right: $gutterXSmall;
        vertical-align: top;
    }
}
