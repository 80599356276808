@import "src/style/variables";

.SocialFeedFilter {
    margin-top: $gutterXXLarge;
}

// .SocialFeedFilter {
//     margin-bottom: $gutterSmall;
//     display: inline-block;
//     margin-left: $gutterMedium;
//     height: 25px;

//     .actionmenu-component {
//         > i {
//             font-size: 10px;
//             &:hover{
//                 background-color: #fff;
//             }
//         }
//     }
// }

.ms-ContextualMenu-list {
    .SocialFeedFilter-checkbox {
        padding: $gutterXSmall;

        i {
            color: #FFF;
        }
    }

    .SocialFeedFilter-active-item {
        background-color: color("light-grey");
        border-left: 2px solid color("light-blue");
        
        .ms-ContextualMenu-link {
            margin-left: -2px;

            &:hover {
                background-color: transparent;
            }
        }
    }
}