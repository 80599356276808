@import "src/style/variables.scss";

.CommentsContainer {
    .CommentsContainer-load-more {
        margin-top: $gutterMedium;
        cursor: pointer;
    }

    .comments-wrapper {
        .comment-wrapper {
            margin-bottom: 2px;
        }
    }
}

.SocialPostContainer {
    > .socialBlock {
        &.noCommentForm {
            margin-bottom: -14px;

            .FeedInteractionsBar-buttons {
                border-bottom: none;
            }
        }
    }
}

.socialBlock {
    margin-top: $gutterMedium;

    &:not(.enableReactions) {
        .CommentsContainer {
            border-radius: $borderRadius;
        }
    }

    .Comment {
        .socialBlock {
            margin-top: 0px;
        }
    }
}