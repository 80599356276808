@use "sass:math";
@import "src/style/variables";

$animationDuration: 1s;

.AssistantTypingLoader {
    .loader-image {
        position: relative;
        margin-bottom: 6px;

        .chat-dots {
            left: 12px;
            position: absolute;
            top: 9px;

            rect {
                animation: assistant-typing-move-dot 1.5s infinite ease-in-out;

                &:nth-child(1) {
                    animation-delay: 0s;
                }

                &:nth-child(2) {
                    animation-delay: $animationDuration * 0.15;
                }

                &:nth-child(3) {
                    animation-delay: $animationDuration * 0.30;
                }
            }
        }

        .css-chat-loader {
            .large-bubble, .medium-bubble, .small-bubble {
                background-color: color(visage2LightGray);
            }

            .large-bubble {
                height: 31px;
                width: 50px;
                border-radius: 16px;
                margin-left: 4px;
                position: relative;
            }

            .medium-bubble {
                height: 10px;
                width: 10px;
                border-radius: 10px;
                margin-top: -10px;
                margin-left: 3px;
            }

            .small-bubble {
                height: 5px;
                width: 5px;
                border-radius: 5px;
                margin-top: -2px;
            }
        }
    }
}

@keyframes assistant-typing-move-dot {
    0%, 20% { transform: translateY(0); }
    10% { transform: translateY(-4px); }
}