.resourceImage {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textNewResource {
    color: white;
}

.ResourceWeeklyView-reserve {
    padding: 1em;
}
.ResourceWeeklyView-reservation {
    padding: 1em;

    .ms-Button {
        margin: 1em;
    }
}

.ResourceBookingView {
    .rct-item {
        z-index: 20 !important;
    }
}
.ResourceWeeklyView {
    .pageHeader {
        margin-bottom: 20px;
        margin-top: 20px;
        
        .pageHeader-title-left {
            max-width: calc(100% - (24px));
        }
        .ms-Persona-secondaryText {
            white-space: wrap;
        }
        .ms-Persona{
            height: auto !important;
            align-items: flex-start;
        }
    }
    .rct-item {
        z-index: 20 !important;
    }
}

.ResourceEditView {
    .interval {
        .timeselect :first-child {
            margin-right: 2px;
        }
    }

    .description {
        .ms-TextField-field {
            min-height: 80px;
        }
    }

    .pageHeaderImageWrapper {
        width: 200px;
        height: 200px;
        // border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        margin-top: 1em;
        margin-bottom: 1em;
    }
}
