@import "src/style/variables.scss";

.PrioItems {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: $gutterXXXLarge;

    .PrioItem {
        width: 20%;
        padding-left: $gutterMedium;
        padding-right: $gutterMedium;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        margin-bottom: $gutterXLarge;

        .PrioItem-Inner {
            max-width: 200px;
            margin: 0 auto;

            .prioItemTitle {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            }
        }

        .ms-Persona-initials {
            background-color: #FFF;
        }

        .description {
            margin-top: -6px;
        }

        .PrioItem-Icon {
            display: inline-block;
            vertical-align: top;
        }
    }
}

@media screen and (max-width: $vmTabletLandscape) {
    .PrioItems {
        .PrioItem {
            width: 33% !important;
        }
    }
}

@media screen and (max-width: 700px) {
    .PrioItems {
        .PrioItem {
            width: 50% !important;
        }
    }
}
