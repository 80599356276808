@import "src/common.scss";

.PlannerFileList {
    border: 1px solid $spintrGrayLight;
    border-radius: $brRounder;
    margin-bottom: $spacingXLarge;
    padding: $spacingMedium;

    .PlannerFileList-header, .PlannerFileList-text {
        margin-bottom: $spacingMedium;
    }

    .PlannerFileList-text {
        white-space: pre-wrap;
    }

    .PlannerFileList-files {
        display: flex;
        flex-direction: column;
        gap: $spacingSmall;

        .PlannerFileList-file {
            background-color: map-get($spintrColors, grayLight);
            border-radius: $brRounder;
            display: flex;
            gap: $spacingSmall;
            padding: $spacingSmall;
            width: 100%;
    
            .image {
                align-items: center;
                display: flex;
                flex: 0 0 32px;
                justify-content: center;
            }
    
            .information {
                flex: 1 1 auto;
            }
    
            .actions {
                align-items: center;
                display: flex;
                flex: 0 0 32px;
                justify-content: center;
            }
        }
    }
}