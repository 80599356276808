@import "src/style/variables.scss";

.TwoColumnListBox {
    background-color: #FFF;
    padding: $gutterXLarge;
    text-align: left;
    border-radius: $borderRadius;

    .TwoColumnListBox-items {
        .TwoColumnListBox-item {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            position: relative;
            border-radius: $borderRadius;

            .TwoColumnListBox-item-inner {
                padding: $gutterSmall;
            }
            //padding: $gutterSmall;
            -webkit-transition: $bgColorTransition;
            -moz-transition: $bgColorTransition;
            -o-transition: $bgColorTransition;
            transition: $bgColorTransition;

            &:hover {
                background-color: color(light-grey);
            }

            a {
                max-width: calc(100% - 50px);
                display: block;
            }

            &.TwoColumnListBox-item-deleted {
                .ms-Persona-primaryText,
                .ms-Persona-secondaryText {
                    text-decoration: line-through;
                }
            }

            .actionmenu-component {
                position: absolute;
                right: $gutterMedium;
                top: 17px;
                opacity: 0;

                &.open {
                    opacity: 1;
                }
            }

            &:hover {
                .actionmenu-component {
                    opacity: 1 !important;
                }
            }
        }
    }
}

.ms-Fabric--isFocusVisible {
    .TwoColumnListBox-item {
        .actionmenu-component {
            opacity: 1 !important;
        }
    }
}

@media screen and (max-width: $vmTabletLandscape) {
    .TwoColumnListBox-item {
        .actionmenu-component {
            opacity: 1 !important;
        }
    }
}

@media screen and (max-width: $vmTabletPortrait) {
    .TwoColumnListBox-item {
        width: 100% !important;
    }
}