@import "src/style/variables.scss";

.PageActionButton {
    display: flex;
    cursor: pointer;
    margin: $gutterSmall;
    margin-left: 0px;
    margin-right: $gutterXXLarge;
    
    .icon {
        margin-right: $gutterXSmall;
        font-size: 18px;
        align-self: center;
    }
}