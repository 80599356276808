@import "src/style/variables";

.wrapper {
    position: relative;
}


#top-right-actionmenu {
    position: absolute;
    right: 47px;
    top: 53px;
}

.label {
    //margin-top: 1em; 
}

.button {
    max-width: 500px;
    position: relative;
    text-align: right;
}

.OperatinginfoList {
    .Visage2Icon {
        margin-top: $gutterLarge;
    }
}