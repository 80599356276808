@import "src/style/variables";

$colorSize: 72px;

.SocialComposerFrame {
    &:not(.selector) {
        margin: $gutterMedium 0 $gutterLarge;
    }

    .stage-wrapper {
        position: relative;

        .remove-button {
            color: #FFF;
            height: 20px;
            position: absolute;
            right: $gutterMedium;
            top: $gutterMedium;
            width: 20px;
            z-index: 1;

            .Visage2Icon {
                svg {
                    * {
                        fill: #FFF;
                    }
                }
            }
        }

        .stage {
            align-items: center;
            background-color: #000;
            border-radius: $borderRadius;
            color: #FFF;
            display: flex;
            font-size: 20px;
            line-height: 1.750em;
            height: 240px;
            justify-content: center;

            .SocialComposerTextInput {
                max-width: 280px;
                width: 100%;
                .public-DraftEditorPlaceholder-root {
                    color: #FFF !important;
                }
            }
        }

        .options {
            height: 30px;
            left: $gutterMedium;
            position: absolute;
            top: 200px;

            .list {
                list-style: none;
                margin: 0;
                padding: 0;

                .item {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: $gutterXSmall;

                    .option {
                        color: #FFF;
                        display: inline-block;
                        vertical-align: middle;

                        &.color {
                            // Reset styling...
                            font-size: inherit;
                            color: inherit;
                            margin-bottom: 0;
                            height: auto;
                            width: auto;
                            position: static;
                            right: inherit;
                            border: 0 none;
                            border-radius: 0;
                        }

                        svg {
                            path {
                                fill: #FFF !important;
                            }
                        }
                    }
                }
            }
        }
    }

    &.popdown-open {
        .stage-wrapper {
            .stage {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .popdown {
        padding: $gutterMedium;
        box-sizing: border-box;
        position: relative;
        transition: height 1s linear;

        &.none {
            border: 0 none;
            height: 0;
            padding: 0;
        }
        
        &.color {
            border-radius: 0;
            color: inherit;
            display: block;
            font-size: inherit;
            height: auto;
            margin-bottom: 0;
            right: auto;
            width: auto;

            .list {
                list-style: none;
                margin: 0 (-$gutterMedium) 0 0;
                padding: 0;

                .item {
                    display: inline-block;
                    margin-right: $gutterMedium;
                    vertical-align: middle;

                    .color-wrapper {
                        // border: 3px solid transparent;
                        display: inline-block;
                        height: $colorSize;
                        width: $colorSize;

                        .color {
                            border: 1px solid #ebdddc;
                            border-radius: 0;
                            cursor: pointer;
                            display: inline-block;
                            height: 100%;
                            position: static;
                            width: 100%;
                        }
                    }

                    &.active {
                        .color-wrapper {
                            border-color: color("dusk-grey");
                        }
                    }
                }
            }
        }

        .fonts {
            .list {
                list-style: none;
                margin: 0;
                padding: 0;
                text-align: center;

                .item {
                    margin-bottom: $gutterSmall;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .sample {
                        color: color("dark-grey");
                        display: inline-block;
                        overflow: hidden;
                        user-select: none;
                        text-overflow: ellipsis;
                        white-space: pre;
                        width: 100%;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1023px) {
    .SocialComposerFrame {
        width: 100%;
    }
}