@import "src/style/variables.scss";

.FormFooterBar {
    height: $spintrResponsiveFooterHeight;
    left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .menu, .FormFooterBar-right {
        display: flex;
        padding: 0 $gutterMedium;
        flex-direction: row;
        column-gap: $gutterMedium;

        .action-menu-wrapper {
            display: flex;
            align-items: center;
        }
    }

    .FormFooterBar-left {
        .ms-Breadcrumb {
            margin-bottom: 0px;
        }
    }

    .ms-TooltipHost {
        display: inline-block;
    }
}