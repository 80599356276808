@import "src/common.scss";

.PlannerItemDetail {
    .PlannerItemDetail-textBlock {
        margin-bottom: $spacingXLarge;
        white-space: pre-wrap;
    }

    .PlannerItemDetail-infoBlock {
        border: 1px solid $spintrGrayLight;
        border-radius: $brRounder;
        margin-bottom: $spacingXLarge;
        padding: $spacingMedium;

        .PlannerItemDetail-infoBlock-heading {
            margin-bottom: $spacingMedium;
        }

        .PlannerItemDetail-infoBlock-content {
            display: flex;
            flex-direction: column;
            gap: $spacingSmall;

            .PlannerItemDetail-infoBlock-property {
                align-items: center;
                display: flex;
                flex-direction: row;

                .PlannerItemDetail-infoBlock-property-label,
                .PlannerItemDetail-infoBlock-property-value {
                    flex: 1 1 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 0;
                }

                .PlannerItemDetail-infoBlock-property-value {
                    overflow: auto;
                    text-overflow: initial;
                    white-space: pre-wrap;

                    &.user {
                        .ms-Image {
                            margin-right: $spacingSmall;
                        }

                        .ms-Persona-details {
                            padding: $spacingSmall;
                        }

                        .ms-Persona-primaryText {
                            font-size: $fsBodySmall;
                            color: $spintrContentNormal;
                            font-weight: $fwRegular;
                        }
                    }
                }

                .PlannerItemDetail-infoBlock-property-label {
                    align-items: start;
                    display: flex;
                    gap: $spacingSmall;
                }
            }
        }
    }

    .PlannerItemDetail-advancedField {
        border-bottom: 1px solid $spintrGrayLight;
        margin-bottom: $spacingSmall;
        padding-bottom: $spacingSmall;

        &:last-child {
            border-bottom: 0 none;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .PlannerItemDetail-advancedField-content {
            margin-top: $spacingTiny;
        }
    }
}