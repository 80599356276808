@import "src/common.scss";
@import "../plannerVariables.scss";

.TimelineHeader {
    background-color: $spintrGrayLighter;
    border-radius: $brRounder $brRounder 0 0;
    box-sizing: border-box;
    height: $timelineHeaderHeight;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: map-get($plannerStrata, header);

    .TimelineHeader-row {
        display: flex;
        height: $timelineHeaderHeight;
        position: relative;

        .TimelineHeader-cell {
            background-color: $spintrWhite;
            align-items: center;
            display: flex;
            height: $timelineHeaderHeight;
            flex: 0 0 auto;
            justify-content: center;
            left: 0;
            position: sticky;
            z-index: map-get($plannerStrata, header);

            .TimelineHeader-axis-label {
                align-items: center;
                background-color: $spintrGrayLighter;
                border-right: 1px solid $spintrGray;
                border-top-left-radius: $brRounder;
                box-sizing: border-box;
                display: flex;
                height: 100%;
                justify-content: start;
                padding: 0 $spacingXXLarge;
                user-select: none;
                width: 100%;
            }
        }

        .TimelineHeader-date-labels-container {
            box-sizing: border-box;
            height: $timelineHeaderHeight;
            position: relative;
            z-index: map-get($plannerStrata, axisRail);

            .TimelineHeader-date-labels {
                height: 100%;
                position: relative;
                width: 100%;
            }
        }
    }
}
