@import "src/style/variables.scss";

#SocialComposerHeader {
    .header-bar {
        padding: $gutterMedium $gutterMedium $gutterMedium;
        position: relative;
        border-bottom: 1px solid color("smoke");

        .close-button {
            position: absolute;
            right: $gutterMedium;
            top: $gutterMedium;
        }

        .text {
            .back-button, .header-text {
                display: inline-block;
                vertical-align: middle;
            }

            .back-button {
                margin-right: $gutterXSmall;

                svg {
                    position: relative;
                    top: 3px;
                }
            }
        }
    }
}