/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
#MarketplaceCatalogView .view-header {
  align-items: center;
  display: flex;
  height: 90px;
  flex-shrink: 0;
  justify-content: space-between;
}
#MarketplaceCatalogView .view-header .header-text {
  flex: 0 1 70%;
}
#MarketplaceCatalogView .view-header .header-text h2 {
  margin: 0;
  padding: 0;
}
#MarketplaceCatalogView .view-header .search-bar {
  flex: 1 0 30%;
}
#MarketplaceCatalogView .view-body {
  padding: 24px;
  margin: 0 auto;
}
#MarketplaceCatalogView .view-body .app-grid-view {
  display: inline-block;
  margin: 0 auto;
  padding: 0 20px;
  vertical-align: top;
}