@import "src/style/variables";

.PageFormView {
    .container {
        background-color: color("mid-grey");
        padding: $gutterXSmall;
        color: white;
        
        .ms-Button {
            margin-left: $gutterXSmall;
        }
        
        .adminBar {
            justify-content: space-between;
            display: flex;
            .left {
                padding-top: 5px;
                padding-left: $gutterXSmall;
            }
        }

        .userBar {
            padding-left: $gutterXSmall;
        }
    }

    .PageFormView-preview {
        .pageHeaderImageWrapper {
            margin-bottom: $gutterXXLarge;
        }
    }

    .PageFormView-CustomTemplates {

        .PageFormView-CustomTemplate {
            display: inline-block;
            vertical-align: top;

            .PageFormView-CustomTemplateInfo {
                max-width: 100px;
                margin-left: 2px;
            }
        }
    }
    .ms-TextField-wrapper{
        //max-width: 500px;
    }

    .rights {
        position: relative;

        .locked {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.7;
            z-index: 1;
            background-color: #fff;
        }

        .locked-text {
            position: absolute;
            border: 1px solid color("dusk-grey");
            background-color: #fff;
            left: 25%;
            top: 50%;
            width: 50%;
            padding: $gutterLarge;
            z-index: 1;
            text-align: center;
        }
    }

    .SplitForm {
        .additional-settings {
            .ExpandableFormField {
                .pageInfoPanel {
                    padding: 0 !important;
                }
            }
        }
    }
}

.page-form-view-reject-modal {
    .ms-Button {
        margin-top: $gutterXSmall;
        margin-right: $gutterXSmall;
    }
}
