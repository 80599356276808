@use "sass:math";
@import "src/common.scss";

.PlannerCommandBar {
    .PlannerCommandBar-bar {
        [role="menubar"] {
            margin-bottom: $spacingLarge;
        }

        .CommandBar-search {
            background-color: $spintrGrayLighter;
            border-radius: $brRounder;
            max-width: 478px;
            
            input {
                color: $spintrContentNormal;
            }
        }

        .commandBarButton {
            border-radius: $brNormal;
        }
    }

    .viewMode-switch {
        border-radius: $brRounder;
        background-color: $spintrGrayLighter;
        display: flex;
        gap: math.div($spacingTiny, 2);
        padding: math.div($spacingTiny, 2);

        .switch-button {
            align-items: center;
            background-color: $spintrGrayLighter;
            border: 1px solid $spintrGrayLighter;
            border-radius: $brNormal;
            display: flex;
            height: 32px;
            justify-content: center;
            transition: 150ms all ease-in;
            width: 32px;

            &.active {
                background-color: $spintrWhite;
                border-color: $spintrGrayLight;
            }
        }
    }
}