@import "src/style/variables";

.CalendarEventPopupForm {
    .event-image img {
        width: 100%;
    }

    .remove-image-button {
        margin-top: $gutterSmall;
    }
    .ms-MessageBar {
        margin-bottom: $gutterMedium;
    }
}