@import "src/style/variables";

.SocialImage {
    border-radius: $borderRadius;
    overflow: hidden;

    .videoViewCount {
        margin-top: $gutterMedium;
    }
    .image-stage {
        //margin-bottom: 12px;
        width: 100%;
        .image-link {
            //background-color: #000;
            border-radius: $borderRadius;
            cursor: pointer;
            display: inline-block;
            line-height: normal;
            max-height: 238px;
            overflow: hidden;   
            position: relative;  
            text-align: center; 
            vertical-align: top;
            width: 100%;

            .ProgressIndicator {
                background-color: #FFF;
            }
        }

        .image-black-bars {
            background-size: cover;
            bottom: 0;
            background-color: color("dark-grey");
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        img {
            background-color: #FFF;
            max-height: 238px;
            object-fit: cover;
            width: 100%;
        }
        .maximize-icon{
            position: absolute;
            z-index: 1;
            right: $gutterSmall;
            top: $gutterSmall;
            pointer-events: none;
            background-color: rgba(0, 0, 0, 0.2);
            padding: 3px;
            border-radius: 6px;
        }
        .status {
            color: #FFF;
            padding: $gutterMedium;
            text-align: center;
        }
    }

    .azure-video-item {
        .video {
            overflow: hidden;
            border-radius: $borderRadius;

            .video-js .vjs-tech {
                position: relative;
            }
        }

        .video-js {
            max-height: 238px;
        }

        .vjs-big-play-centered {
            .vjs-big-play-button {
                &:before {
                    color: #FFF !important;
                    text-shadow: none !important;
                }
            }
        }
    }
}