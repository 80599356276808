@import "src/style/variables.scss";

$commentActionMenuWidth: 24px;
$commentBackgroundColor: #f4f4f5;
$commentBorderRadius: 16px;

.Edit-Comment {
    background-color: color("light-grey");
}

.Comment {
    position: relative;

    .comment-inner {
        padding: $gutterXLarge 0 0;

        .commentor-image {
            margin-right: $gutterSmall;

            > div {
                > div {
                    margin-top: 4px;
                }
            }
        }

        .comment-first-row {
            display: flex;
            flex-direction: row;

            .comment-content {
                flex-grow: 1;

                .comment-content-first-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }
        }

        .commentor-image,
        .comment-content {
            display: inline-block;
            vertical-align: top;
        }

        .comment-content {
            position: relative;
            word-wrap: break-word;
            width: calc(100% - 68px);

            .comment-content-inner {
                background-color: $commentBackgroundColor;
                border-radius: $borderRadius;
                display: inline-block;
                max-width: calc(100% - #{($gutterMedium - 2px) * 2});
                padding: $gutterSmall;
                width: auto;
                min-width: 200px;

                .visage2-feed-icon {
                    margin-bottom: $gutterSmall;
                }
                
                .commentor {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: $gutterXSmall;

                    .commentor-name {
                        line-height: 18px;
                    }

                    > .label-component {
                        display: flex;
                        flex-grow: 1;
                        justify-content: flex-end;
                    }
                }

                .comment-image-wrapper {
                    margin-top: $gutterSmall;

                    .ImageCore-wrapper {
                        vertical-align: top;
                    }

                    img {
                        border-radius: $borderRadius;
                        max-width: 100%;
                        overflow: hidden;
                    }
                }
            }

            .FeedInteractionsBar-info {
                padding-top: $gutterSmall;
            }

            .actionmenu-component {
                opacity: 0;
                top: calc(50% - 26px);
                margin-left: $gutterXSmall;
            }
    
            .actionmenu-component &.active {
                opacity: 1;
            }
    
            .actionmenu-component.open {
                opacity: 1;
            }
        }

        .child-comments {
            margin-top: $gutterXSmall;
            margin-left: 31px;
            // margin-right: 13px;
            border-radius: $borderRadius;

            .socialBlock {
                .CommentsContainer-load-more {
                    margin-left: 8px;
                    margin-bottom: $gutterSmall;
                }

                .CommentsContainer {
                    margin-top: 0;
                    background-color: #FFF;

                    .CommentsContainer-load-more {
                        background-color: #FFF;
                    }

                    .comments-wrapper {
                        .comment-wrapper {
                            margin-bottom: 0;

                            .Edit-Comment {
                                background-color: #FFF;

                                .CommentCreator {
                                    background-color: #FFF;

                                    .commentor-image {
                                        display: none;
                                    }

                                    .comment-content {
                                        width: calc(100% - 30px);
                                    }

                                    .SocialComposerImages {
                                        margin-left: 0;
                                    }
                                }
                            }

                            .Comment {
                                .comment-inner {
                                    padding: $gutterSmall 0 0 $gutterSmall;

                                    .comment-content {
                                        width: calc(100% - 32px - #{$gutterSmall});

                                        .Commentors {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        .comment-creator {
                            .CommentCreator {
                                > div {
                                    // .commentor-content {
                                    //     vertical-align: 2px;
                                    // }

                                    .comment-content {
                                        width: calc(100% - 43px);
                                        margin-top: 1px;
                                    }
                                }

                                // .CommentForm-add-image-button {
                                //     bottom: $gutterXSmall;
                                //     right: 36px;
                                // }

                                // .CommentForm-send-button {
                                //     bottom: $gutterXSmall;
                                //     right: $gutterSmall;
                                // }

                                .SocialComposerImages {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }

            .comment-creator {
                margin-left: 12px;
            }
        }
    }

    .comment-content {
        &:hover {
            > .comment-content-first-row {
                > .actionmenu-component {
                    opacity: 1;
                }
            }
        }
    }
}
