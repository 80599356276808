@import "./style/variables.scss";

html,
body,
p {
    margin: 0;
    padding: 0;
}

html.overflow-hidden {
    overflow: hidden;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: color("light-blue");
}

html,
body {
    font-family: "Eloquia", sans-serif;
    scroll-behavior: smooth;
}

body {
    background-color: color(visage2LightGray2);
    color: color(dark-grey);

    /*
   * Make sure body is atleast 100% of screen height, needed
   * to prevent ui bug with Fluent UI dialog background
   */
    min-height: 100vh;
    overflow-y: scroll;
}

input,
textarea {
    font-family: $font, sans-serif;
}

[role="button"] {
    cursor: pointer;
}

svg.icon-component {
    display: inline-block !important;
    vertical-align: middle;
}

.public-DraftEditorPlaceholder-root {
    color: color("mid-grey") !important;
}

.initialLoader {
    >div {
        top: calc(50vh - 38px);
    }
}

.VisageContent {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    overflow: hidden;
    max-width: 100vw;
}

.appWrap {
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    .SpintrSidebar {
        width: $spintrSidebarMaxWidth;
    }

    >.mainWrap {
        width: calc(100% - #{$spintrSidebarMaxWidth});
    }

    >.mainWrap>.SpintrHeader {
        width: calc(100% - #{2 * $gutterLarge} - #{$spintrSidebarMaxWidth});
    }

    /*
    No!
    > .mainWrap.isSmallViewMode {
        width: 100%;
    }
    */

    .appInner {
        display: flex;
        flex-direction: row;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        background-color: #F0F0F0;
    }

    &.hasFormFooterBar {
        .NotificationCardContainer {
            display: none;
        }
    }
}

.header-gradient-border {
    background: linear-gradient(white, white) padding-box,
              linear-gradient(135deg, rgba(34, 35, 75, 0.2), rgba(34, 35, 75, 0.12)) border-box;
    border-radius: $borderRadius;
    border: 1px solid rgba(255, 255, 255, 0.08);
    position: relative;

    > .gradient {
        border-radius: $borderRadius;
        background-color: rgba(34, 35, 74, 0.04);
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

.ms-Callout {
    max-width: 100%;

    &.system-status-tooltip {
        max-width: 360px;
        box-sizing: border-box;

        .SocialPostContentItem {
            margin-top: $gutterXSmall;
        }

        .ms-Callout-main {
            border-radius: 0;
        }
    }
}

.underline-wrapper {
    a {
        text-decoration: underline;
    }
}

.underline {
    text-decoration: underline;
}

[role="tablist"] {
    .ms-Callout {
        max-width: initial;
    }
}

.ms-BasePicker {
    ::placeholder{
        font-size: 12px;
        color: color("mid-grey");
    }
    // min-height: 48px;
    .ms-BasePicker-input{
        min-height: 35px;
        font-size: 12px;
        align-self: center;

        &:focus {
            outline-width: 0px;
        }
    }
    .ms-BasePicker-text {
        border-color: color("mid-grey");
        border-radius: $borderRadius;
        padding: $gutterXSmall;
        font-size: 12px;
        .ms-TagItem {
            align-self: center;
            min-height: 40px;
            border-radius: $borderRadius;
            .ms-TagItem-text{
                align-self: center;
                font-size: 12px;
            }
            .ms-Button{
                border-radius: $borderRadiusSharp;
                height: 40px;
            }

            .ms-Persona {
                min-height: 40px;
            }
        }
    }
}

.ms-BasePicker-text:after {
    border-radius: $borderRadius; 
    }

@media screen and (max-width: 1500px) {
    .appWrap {
        .SpintrSidebar {
            width: $spintrSidebarMinWidth;
        }

        >.mainWrap {
            width: calc(100% - #{$spintrSidebarMinWidth});
        }

        >.mainWrap>.SpintrHeader {
            width: calc(100% - #{2 * $gutterLarge} - #{$spintrSidebarMinWidth});
        }
    }
}

@media screen and (max-width: 1023px) {
    .appWrap {
        padding-top: 64px;

        >.mainWrap {
            width: 100% !important;

            >.contentWrap {
                height: calc(100vh - #{$responsiveHeaderHeight});
                height: calc((100 * var(--vh, 1vh)) - #{$responsiveHeaderHeight});
            }
        }
    }
}

.appWrap {
    &.whiteBackground {
        .VisageSidebarContainer {
            .VisageSidebar {
                margin: 0px;
                left: 0px;
                top: 0px;
                border-radius: 0px;
            }

            .faker {
                width: $spintrSidebarMaxWidth;
                left: 0px;
                bottom: 0px;
                border-radius: 0px;
            }
        }

        .contentWrap {
            background-color: #FFFFFF;
        }
    }
}

.contentWrap {
    min-height: calc(100vh - #{$spintrHeaderHeight});
    position: relative;

    &.grayBackground {
        background-color: #E8E8E8;
    }
}
.contentWrap:has(.apply-padding) {
    .EmergencyAlertView-container {
        padding-top: 96px;
    }
}
.contentWrap:has(.EmergencyAlertView-container) {
    .main-content {
        margin-top: 12px !important;
    }
}
.site-max-width {
    max-width: 1600px;
    margin: 0;

    >div {
        max-width: 1300px;
        margin: 0 auto;
    }
}

.site-document-width {
    max-width: 1600px;
    margin: 0;

    >div {
        max-width: 1170px;
        margin: 0 auto;
    }
}

.site-narrow-form-width {
    max-width: 1600px;
    margin: 0;

    >div {
        max-width: 750px;
        margin: 0 auto;
    }
}

.site-max-width-with-padding {
    max-width: calc(1260px + 40px);
    margin: 0 auto;
}

.site-max-width-with-padding2 {
    max-width: calc(1260px);
    padding: $gutterMedium;
    margin: 0 auto;
}

.ms-MessageBar {
    border-radius: $borderRadius;
}

.page-margin-bottom {
    margin-bottom: 70px;
}

.ms-SearchBox {
    &::after {
        display: none !important;
    }
}

.ms-SearchBox-iconContainer {
    transform: scaleX(-1);
    margin-right: 1px;
    margin-left: -4px;

    i {
        color: color("mid-grey");
    }
}

.border-radius {
    border-radius: $borderRadius;
}

.form-item-width {
    width: 100%;
    // max-width: 500px;
}

.no-user-select {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
}

/* * * * * * * * * *
 * Gutter classes  *
 * * * * * * * * * */

.gutter-s {
    padding: $gutterSmall;
}

.gutter-m {
    padding: $gutterMedium;
}

.gutter-l {
    padding: $gutterLarge;
}

.gutter-xl {
    padding: $gutterXLarge;
}

.gutter-xxl {
    padding: $gutterXXXLarge;
}

/* * * * * * * * * *
 *    Box shadow   *
 * * * * * * * * * */

.box-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.09);
}

.pop-box-shadow {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.visage-box-shadow {
    //box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.02), inset 0 0 0 1px rgba(0, 0, 0, 0.075);
    box-shadow: 0px 10px 74px rgba(197, 236, 235, 0.3);
}

.visage2-box-shadow {
    box-shadow: 0px 10px 30px 0px rgba(11, 17, 34, 0.05);
}

.visage-box-border {
    border: 1px solid color(borderColor);
}

.box-shadow-and-border {
    border-width: 1px;
    border-style: solid;
    border-color: color("light-grey");
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.09);
}

.box-shadow-and-border-only-border-bottom {
    border: 0;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: color("light-grey");
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.09);
}

.ms-Pivot {
    margin-bottom: 1em;
}

.hasFormFooterBar {
    padding-bottom: $formFooterBarContentPaddingBottom !important;
}

.removeDateButton {
    margin-top: $gutterXSmall;
}

.tiny-modal {

    // if tiny is in a modal
    .tox-tinymce-aux {
        z-index: 1000000 !important;
    }
}

.close-button {
    &.standard-position {
        position: absolute;
        top: $gutterLarge;
        right: $gutterLarge;
    }
}

.required-field-text-replica {
    font-size: 12px;
    color: rgb(164, 38, 44);
}

.required-field-border-tinymce {
    .tox-tinymce {
        border: 1px solid rgb(164, 38, 44);
    }
}

.interactive-text {
    >p {
        display: inline;
    }
}

a {
    >.ms-Breadcrumb-item {
        cursor: pointer;

        >div {
            cursor: pointer;
        }
    }
}

.SpintrDatePicker {
    .fa-calendar {
        //margin-top: -4px;
    }
}

.SpintrUser-autoheight {
    .ms-Persona {
        height: auto !important;

        .ms-Persona-primaryText {
            white-space: normal !important;
        }
    }
}

.ms-ContextualMenu-itemText {
    font-size: 12px;
}

.tiny-at-search-result {
    max-width: 288px;
}

.video-js {

    .vjs-play-progress,
    .vjs-volume-level,
    .vjs-icon-circle,
    .vjs-seek-to-live-control,
    .vjs-icon-placeholder {
        font-family: VideoJS !important;
    }
}

.content-separator {
    margin-top: $gutterXXLarge;
    margin-bottom: $gutterXXLarge;
    padding: 0 !important;

    &:before {
        background-color: color(smoke);
    }
}

.ms-Breadcrumb {
    margin: 0;
    margin-bottom: $gutterSmall;

    .ms-Breadcrumb-item:last-child {
        font-size: 12px;
        font-style: normal;
        font-weight: 550;
        letter-spacing: 0px;
        text-align: left;
        color: color("visageMidBlue") !important;
    }

    .ms-Breadcrumb-listItem {
        padding: 0;

        a,
        span {
            line-height: normal;
            padding: 0;
            color: color("mid-grey");
        }

        .ms-Breadcrumb-itemLink {
            line-height: normal;
            padding: 0;
            font-size: 12px;

            &:hover {
                background-color: transparent;

                >div {
                    color: color("visageMidBlue");
                }
            }
        }

        &:last-child {
            a {
                >div {
                    color: color("visageMidBlue") !important;
                    font-weight: 550;
                }
            }
        }

        &:not(:last-child) {
            a {
                >div {
                    color: color("mid-grey");
                }
            }
        }
    }

    .ms-Breadcrumb-chevron {
        margin-left: $gutterXSmall;
        margin-right: $gutterXSmall;
        font-size: 10px;
    }
}

.validationFailed {
    .ms-BasePicker-text {
        border: 1px solid rgb(164, 38, 44) !important;
    }
}

.SpintrUser-initialsIcon {
    svg {
        display: block;
    }
}

.zoomCoinOnHover {

    .SpintrUser-initialsIcon,
    .ms-Persona-imageArea img,
    .ms-Persona-initials span {
        -webkit-transition: height 0.2s, width 0.2s, font-size 0.2s;
        -moz-transition: height 0.2s, width 0.2s, font-size 0.2s;
        -o-transition: height 0.2s, width 0.2s, font-size 0.2s;
        transition: height 0.2s, width 0.2s, font-size 0.2s;
    }

    &:hover {

        .SpintrUser-initialsIcon,
        .ms-Persona-initials span {
            font-size: 108%;
        }

        .ms-Persona-imageArea img {
            height: 120%;
            width: 120%;
        }
    }
}

.categoriesAndTime {
    display: flex;
    align-items: center;
    margin-bottom: $gutterXXLarge;

    .PageActionButton {
        i {
            color: color("mid-grey") !important;

            span {
                display: flex;
            }
        }

        .calendarLabel {
            font-weight: 550;
        }

    }

    .categories {
        display: flex;
    }

    .time {
        >* {
            display: inline-block;
            vertical-align: middle;

            &.label-component {
                vertical-align: -1px;
            }
        }

        .Visage2Icon {
            margin-right: $gutterXSmall;
        }
    }
}

.mediumLineHeight {
    line-height: 1.25em;
}

.smallerButton {
    min-width: 0px !important;
    height: $gutterXLarge;
    font-size: 12px !important;
}

.bookmark-domain {
    margin-bottom: $gutterXSmall;
    font-size: 12px;
}

.bookmark-title-text {
    display: block;
    font-weight: 600;
    line-height: 1.188em;
    margin: 0;
    font-size: 14px;
}

.ms-Persona-image {
    >img {
        top: auto;
        left: auto;
        transform: none;
    }
}

.infinite-scroll-component {
    overflow-x: unset !important;
}

.Changelog {
    * {
        font-weight: 550;
        font-size: 10px !important;
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
}

.ms-Callout,
.ms-Dialog-main,
.ms-Callout-beakCurtain,
.ms-Callout-main {
    border-radius: $borderRadius;
}

.ms-OverflowSet-item>button {
    font-size: 16px;

    .ms-Button-textContainer,
    .ms-Button-label {
        font-weight: 550 !important;

        >span {
            margin: 0px;
        }
    }
}

.ms-Button {
    height: 36px;

    .ms-Button-label {
        font-size: 12px;
        font-weight: 550;
    }
}

.ms-OverflowSet-item {
    .ms-Button {
        &:hover {
            opacity: 0.75;
        }

        .ms-Button-flexContainer {
            column-gap: $gutterXSmall;

            i {
                margin-left: 0px;
                margin-right: 0px;
            }

            .ms-layer {
                display: none;
            }
        }

        &.commandBarAddButton {
            color: #FFF;
            background-color: color(spintrGreen);

            .ms-Button-menuIcon {
                background-color: color(spintrGreen);
            }

            i {
                color: #fff;
            }
        }

        &:not(.commandBarAddButton) {
            border: 1px solid color(borderColor);
            color: color(dark-grey);
        }

        &:not(.commandBarAddButton) i {
            color: color(dark-grey) !important;
        }
    }
}

.ms-OverflowSet-item>i {
    margin-top: 2px;
}

.SpintrOverlay {
    background-color: #000;
    bottom: 100%;
    height: 0;
    left: 0;
    opacity: 0.3;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: $spintrOverlayZIndex;

    &.SpintrStaticLinksPopoutMode {
        height: 150vh;
        width: 150vw;
        top: 0px;
        left: calc(-50vw);
        right: 0px;
        bottom: 0px;
    }
}

.unreadDot {
    height: 10px;
    width: 10px;
    background-color: color("light-blue");
    border-radius: 50%;
    margin-left: 10px;
    align-self: center;
}

.PagePadding {
    padding: $gutterMedium;
    padding-left: $gutterLarge;
    padding-right: $gutterLarge;
}

.PagePaddingWithoutPaddingBottom {
    padding: $gutterMedium;
    padding-left: $gutterLarge;
    padding-right: $gutterLarge;
    padding-bottom: 0;
}

.pivot-compact-style {

    //height: 30px;
    .ms-Pivot {
        height: 30px;

        .ms-Pivot-link {
            height: 30px;
            line-height: 30px;

            >* {
                font-size: 12px;
                font-weight: 550;
                letter-spacing: 1px;
                line-height: 30px;
                text-transform: uppercase;
            }
        }

        >span,
        .ms-Pivot-overflowMenuButton {
            height: 30px;
            line-height: 30px;
        }
    }

    button {
        &:not(.is-selected) {
            span {
                color: color(mid-grey);
            }
        }

        &:hover {
            background-color: transparent;
            opacity: 0.75;
        }
    }
}

.pivot-filter-style {
    .ms-Pivot {
        //margin-top: -3px;
        margin-bottom: -5px;
        line-height: 19px;
        white-space: unset;

        .ms-Pivot-link {
            background-color: color("smoke");
            height: 20px;
            line-height: 20px;
            border-radius: $borderRadiusRounder;
            margin-bottom: $gutterXSmall;
            margin-right: $gutterXSmall;

            &:hover {
                background-color: color("light-grey");
            }

            >span {
                color: color("dark-grey");
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin-top: -1px;
            }

            &:after {
                display: none;
            }

            &:before {
                display: none;
            }

            &.is-selected {

                i,
                span {
                    fill: #FFF !important;
                    color: #FFF !important;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }

        >span,
        .ms-Pivot-overflowMenuButton {
            height: 20px;
            line-height: 20px;
            top: 7px;
        }

        .ms-Pivot-text {
            line-height: 20px
        }
    }

    &.spacing-top {
        padding-top: $gutterMedium;
    }

    &.spacing-bottom {
        padding-bottom: $gutterMedium;
    }
}

.empty-sidebar-list {
    box-sizing: border-box;
    margin: 128px auto;
    padding: 0 $gutterXXXLarge;
    text-align: center;
    width: 100%;

    .icon {
        display: inline-block;
        font-size: 40px;
        margin-bottom: $gutterLarge;
    }
}

.UsersListPopup {
    .ms-Pivot {
        margin-top: -16px;

        .ms-Button {
            vertical-align: middle;
        }
    }
}

.ms-Dialog-inner {
    padding: 0 $gutterXLarge $gutterXLarge;
}

.reactionPivotItem {
    display: flex;

    img {
        display: block;
        margin-top: 13px;
        height: 16px;
        width: 16px;
        margin-right: $gutterTiny;
    }

    .label-component {
        margin-left: $gutterTiny;
        line-height: 44px;
        margin-top: 1px;
    }
}

.start-page-flexible-padding {
    padding: $gutterMedium;
}

.ms-Persona-secondaryText {
    margin-top: 2px;
}

.ModularPage {
    .ModularPageHeader {
        padding: $gutterXLarge;
        border-radius: $borderRadius;
        margin-bottom: $gutterSmall;
        background-color: #FFF;
    }

    &.withCommandBar {
        .ModularPageHeader {
            margin-bottom: 0px;
            padding-bottom: 0px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        &.notSpintrList {
            .ModularPageHeader {
                margin-bottom: $gutterXLarge;
                padding-bottom: $gutterXLarge;
                border-bottom-left-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;

                .ms-CommandBar {
                    margin-bottom: 0px;
                }
            }
        }

        .spintr-list-command-bar {
            margin-bottom: $gutterXXLarge;
            margin-top: calc(#{$gutterMedium} * -1);
            padding: $gutterXLarge;
            background-color: #FFF;
            border-bottom-left-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;

            .ms-CommandBar {
                margin-bottom: 0px;
                margin-top: 0px;
            }
        }
    }

    .pageHeader {
        margin-bottom: 0px;
    }
}

.bottomPageInfoPanel {
    margin-bottom: calc(#{$gutterMedium} * -1) !important;
}

.PublisherAndActions {
    display: flex;
    align-items: center;
    margin-top: $gutterXXLarge;
    margin-bottom: calc(#{$gutterSmall} * -1);

    .UserHovercard-wrapper {
        flex-grow: 1;
    }

    .actions {
        display: flex;
    }

    .socialBlock {
        margin-top: 0 !important;
    }
}

.MAC {
    .VisageFilterButton {
        .VisageFilterButton-left {
            >* {
                line-height: 33px !important;
            }
        }
    }
}

#background-canvas {
    position: absolute;
    top: -4000px;
    left: -4000px;
    display: none;
}

.isSmallViewMode {
    .actionmenu-component {
        opacity: 1 !important;
    }

    .contentWrap {
        min-height: calc(100vh - #{$spintrResponsiveHeaderHeight} - 100px);
    }
}

.DraftEditor-root {
    * {
        -webkit-user-select: text;
    }
}

.displayChildrenAsBlock {
    >* {
        display: block !important;
    }
}

.image-blur {
    background-size: cover;
    background-position: center;
    bottom: 0;
    filter: blur(25px) brightness(140%);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.image-blur-front {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.ms-DatePicker {
    .ms-TextField-fieldGroup {
        // .ms-TextField-field {
        //     line-height: 38px;
        // }

        i {
            line-height: 24px;
        }
    }
}

.marketplace-tooltip {
    padding: 6px 9px;

    .ms-Callout-beak,
    .ms-Callout-beakCurtain,
    .ms-Callout-main {
        background-color: #3F4692;

        >* {
            color: white;
        }
    }
}

.horizontal-dates {
    >* {
        display: inline-block;
        vertical-align: top;

        &:first-child {
            margin-right: $gutterMedium;
        }
    }
}

.horizontal-form-controls {
    display: flex;
    flex-direction: row;
    column-gap: $gutterMedium;

    > * {
        flex-grow: 1;
    }
}

.info-bar {
    padding: $gutterSmall;
    background-color: color(light-grey);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: $borderRadiusSharper;

    > :first-child {
        flex-grow: 1;
    }

    &.with-bottom-spacing {
        margin-bottom: $gutterMedium;
    }
}

.spinButton {
    >div {
        height: 48px;
        border-radius: $borderRadius;

        &:after {
            border-radius: $borderRadius;
            border: 1px solid color("mid-grey");
        }
    }

    .ms-Button {
        height: 50% !important;
    }
}

.visage2-feed-icon {
    position: relative;
    display: inline-block !important;
    margin-bottom: $gutterMedium;

    .visage2-icon-wrapper {
        border-radius: 8px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: $gutterXSmall;
        column-gap: $gutterXSmall;

        .Visage2Icon {
            svg {
                display: block;
            }
        }
    }
}

.close-button-circle {
    height: 32px !important;
    width: 32px !important;
    border-radius: 16px !important;
    background-color: #FFF !important;
    display: flex !important;
    align-items: center !important;
    align-content: center !important;
    justify-content: center !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .Visage2Icon {
        height: 28px !important;
        width: 28px !important;

        > div {
            height: 28px !important;
            width: 28px !important;

            svg {
                height: 28px !important;
                width: 28px !important;

                path {
                    &:first-child {
                        display: none;
                    }
                }
            }
        }
    }
}

.centered-flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.active-option-items-item {
    span {
        font-weight: 600;
    }
}

.ms-ChoiceFieldGroup {
    margin-top: $gutterMedium;

    .ms-ChoiceField {
        margin-top: $gutterSmall;

        &:first-child {
            margin-top: 0px;
        }

        .ms-ChoiceField-field {
            &::before {
                border-color: #A7A7B5;
            }
        }
    }

    .ms-ChoiceFieldLabel {
        color: #4E4F6C;
    }
}
.ConversationsPanel-subline {
    margin: $gutterMedium;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: $gutterXSmall;
}

@import "src/style/Shared.scss";