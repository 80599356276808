.ResponsiveBlock {
  position: relative;
}
.ResponsiveBlock > .sensor {
  background: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.ResponsiveBlock > .sensor .sensorNode {
  border: 0 none;
  display: block;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  width: 100%;
}
