@import "src/style/variables";

.divWithHoverActions {
    position: relative;
    outline-style: solid;
    outline-width: 0px;
    outline-color: color(dusk-grey);
    outline-offset: $gutterXSmall;
    border-radius: $borderRadius;

    .divWithHoverActionsContainer {
        position: absolute;
        right: $gutterSmall;
        top: -22px;
        display: none;

        .divWithHoverActionsActionsGroup {
            display: inline-block;
            border-radius: $borderRadius;
        }

        .divWithHoverActionsActionsGroup:not(:last-child) {
            margin-right: $gutterXSmall;
        }

        .divWithHoverActionsAction {
            display: inline-block;
            background-color: color("light-grey");
            cursor: pointer;
            vertical-align: top;
            z-index: 1;
            position: relative;
            height: 26px;
            border-radius: $borderRadius;
            margin-bottom: 2px;
            .Visage2Icon { pointer-events: none; }

            .Visage2Icon { pointer-events: none; }

            > .ms-TooltipHost {
                display: block;
                text-align: center;
            }

            .divWithHoverActionsActionButton {
                padding: 4px 6px;
                display: inline-block;
                border-radius: $borderRadius;
            }

            .divWithHoverActionsActionReaction {
                line-height: 1.125em;
            }

            .divWithHoverActionsActionChildren {
                position: absolute;
                background-color: color("light-grey");

                .divWithHoverActionsActionChild {
                    width: 100px;

                    &:hover {
                        background-color: color("dusk-grey");
                    }

                    .divWithHoverActionsActionChildInner {
                        >div {
                            display: inline-block;
                            vertical-align: middle;
                        }

                        .divWithHoverActionsActionChildText {
                            margin-left: $gutterXSmall;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }

    &:hover,
    &.isHoveringTooltip {
        &.displayBorder {
            outline-width: 1px;
        }

        &.displayActions {
            .divWithHoverActionsContainer {
                display: block;
            }
        }
    }

    &.displayActionsAtBottom {
        .divWithHoverActionsContainer {
            top: auto;
            bottom: -12px;
        }
    }

    &.displayActionsVertically {
        .divWithHoverActionsContainer {
            left: -41px;
            top: -7px;
            right: auto;
            padding-right: 10px; // some padding to allow easier hover

            .divWithHoverActionsAction {
                display: block;
                line-height: 1;
            }

            .divWithHoverActionsActionsGroup {
                display: block;
            }

            .divWithHoverActionsActionsGroup:not(:last-child) {
                margin-bottom: $gutterXSmall;
                margin-right: 0;
            }
        }
    }
}

.ms-Fabric--isFocusVisible {
    .divWithHoverActionsContainer {
        display: block !important;
    }
}