@import "src/style/variables";

.HighlightedShortcuts {
    padding: 0 $gutterSmall;
    .link {
        display: flex;
        align-items: center;
    }
    .SpintrUser-initialsIcon{
        svg path {
            fill: #FFFFFF !important;
        }
    }
    .shortcut {
        min-width: 25%;
        margin-bottom: $gutterSmall;

        .ms-Persona-initials {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 0;
        }

        .shortcut-inner {
            color: #091B3D;
            font-size: 12px;
            margin-left: $gutterXSmall;
            width: 100%;
         
        }
    }

    .form-wrap {
        .shortcut-inner {
            pointer-events: none;
        }
    }
}