@import "src/style/variables";

.FloatingFooterBar {
    background-color: color("light-grey");
    bottom: 0px;
    left: $spintrSidebarMaxWidth;
    position: fixed;
    right: 0px;
    z-index: 2;
}

@media screen and (max-width: 1550px) {
    .FloatingFooterBar {
        left: $spintrSidebarMinWidth;
    }
}