@import "src/style/variables.scss";

.VisageSidebarLines {
    position: absolute;
    overflow: hidden;
    left: 6px;
    top: 2px;

    .VisageSidebarLine {
        height: 38px;
        width: 10px;
        position: relative;
        
        .VisageSidebarLine-left {
            border-left: 1px solid color(mid-grey);
            position: absolute;
            height: 100%;
        }

        .VisageSidebarLine-circle {
            height: 16px;
            width: 16px;
            border-radius: 16px;
            border-bottom: 1px solid color(mid-grey);
            transform: rotate(45deg);
            position: absolute;
            top: 0px;
        }

        .VisageSidebarLine-bottom {
            height: 10px;
            width: 5px;
            border-bottom: 1px solid color(mid-grey);
            position: absolute;
            left: 8px;
            top: 6px;
        }

        &.isLast {
            .VisageSidebarLine-left {   
                height: 12px;
            }
        }
    }
}

.VisageSidebar-menuItem-children {
    .VisageSidebar-menuItem-children {
        .VisageSidebarLines {
            left: 10px;
        }
    }
}

@media screen and (max-width: 1550px) {
    .VisageSidebarLines {
        left: 0px;
    }

    .VisageSidebar-menuItem-children {
        .VisageSidebar-menuItem-children {
            .VisageSidebarLines {
                left: 5px;
            }
        }
    }
}