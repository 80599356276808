@import "src/style/variables";

#SearchResultView {
    .results-view {
        .general-results {
            width: 100%;
            display: inline-block;
            vertical-align: top;

            .heading {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                .office365-switch {
                    flex-grow: 1;
                    text-align: right;
                }
            }

            .load-more {
                display: block;
                padding: $gutterLarge 0;
                text-align: center;
            }

            .general-list.office365 {
                .search-hit {
                    align-items: center;
                    border-bottom: 1px solid color("smoke");
                    display: flex;
                    padding: $gutterSmall;
                
                    &:hover {
                        background-color: color("light-grey");
                    }
                
                    .image {
                        flex-shrink: 0;
                        height: 50px;
                        margin-right: $gutterSmall;
                        text-align: center;
                        width: 50px;
                        
                        .hit-image {
                            border-radius: $borderRadius;
                        }
                
                        .hit-icon {
                            color: color("dark-grey");
                            line-height: 3.125em;
                            font-size: 20px;
                            text-align: center;
                        }
                    }
                
                    .content {
                
                        .name{
                            span{
                                font-weight: 500;
                                font-size: 16px;
                            }
                        }
                
                        .description{
                            span{
                                font-size: 14px;
                            }
                        }
                        flex-grow: 1;
                
                        .name, .type, .description, .path {
                            color: color("dark-grey");
                            display: block;
                            line-height: 1;
                        }
                
                        .type {
                            text-transform: uppercase;
                            margin-top: 3px;
                            margin-bottom: 3px;
                            span{
                                font-weight: 600 !important;
                            }
                        }
                
                        .path {
                            margin-top: 6px;
                            .path-link {
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: $gutterXSmall;
                
                                &:after {
                                    content: "\e76c";
                                    display: inline-block;
                                    font-family: "FabricMDL2Icons";
                                    font-size: 8px;
                                    margin-left: $gutterXSmall;
                                    vertical-align: middle;
                                }
                                &:last-child:after {
                                    content: "";
                                    display: none;
                                }
                            }
                        }
                    }
                
                    .visit-action {
                        color: color("dark-grey");
                        flex-shrink: 0;
                        margin-left: $gutterSmall;
                    }
                }
            }
        }
    }
}