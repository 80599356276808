@import "src/style/variables.scss";

// .rbc-agenda-view {
//     margin-right: -20px !important;
// }

.SpintrAgenda.group-view {
    margin-right: -27px;
    max-width: 110%;
    height: 100%;

    .rbc-agenda-empty {
        background-color: white;
        width: calc(100% - 27px);
    }

    .agenda-item {
        border: 0 !important;
        margin-right: $gutterSmall;
        flex: 1 0 32%;
        max-width: 32%;
        max-height: 450px;
    }
}

.SpintrAgenda {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-top: $gutterSmall;

    .rbc-agenda-empty {
        background-color: white;
        width: 100%;
        text-align: center;
        padding: 10em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .agenda-row {
        display: flex;
        flex-wrap: wrap;
        column-gap: $gutterMedium;
        // row-gap: $gutterMedium;
    }

    .date-label {
        display: flex;
        margin-bottom: $gutterSmall;
    }
    .agenda-item {
        border: 2px solid #f4f4f5;
        border-radius: 12px;
        margin-bottom: $gutterMedium;
        padding: $gutterMedium;
        flex: 0 1 calc(33% - #{$gutterXSmall} - 2px);
        min-width: 273px;
        min-height: 390px;

        .top {
            border-radius: 12px;
            height: 150px;
            background-color: #555;
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;

            .gradient-overlay {
                display: flex;
                border-radius: 12px;
                align-items: flex-end;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.53237044817927175) 0%, rgba(0, 0, 0, 0) 100%);
                padding: $gutterLarge;
            }

            &.with-image {
            }

            .date-box {
                text-align: center;
            }
        }

        .middle {
            padding: $gutterMedium 0;
            cursor: pointer;
            .title {
                margin: $gutterTiny 0;
            }
            .date-span {
                display: flex;
                flex-direction: column;
            }
            .description {
                margin-top: $gutterSmall;
                p {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .agenda-separator {
            padding: 10px 0px;
        }

        .bottom {
            .attending-buttons {
                display: flex;
                justify-content: space-evenly;

                .ms-Button-flexContainer {
                    flex-direction: row-reverse;
                }
            }
        }
    }
}
