@import "src/style/variables";

.SocialComposerTarget {
    display: inline-block;
    border-radius: $borderRadiusSharper;
    padding-right: 4px;
    transition: background-color 0.3s;
    margin-bottom: -3px;

    .actionmenu-component {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        column-gap: $gutterXSmall;
        width: auto;

        > i {
            font-size: 10px;
        }

        .Visage2Icon-arrow-down-1 {
            position: relative;
            top: 3px;
            
            svg {
                height: 14px;
                width: 14px;
            }
        }

        .ms-layer {
            margin-right: calc(#{$gutterXSmall} * -1);
        }
    }

    &:hover {
        opacity: 0.75;
    }
}

.ms-ContextualMenu-list {
    .SocialComposerTarget-checkbox {
        padding: $gutterXSmall;
    }

    .SocialComposerTarget-active-item {
        background-color: color("light-grey");
        border-left: 2px solid color("light-blue");
        
        .ms-ContextualMenu-link {
            margin-left: -2px;

            &:hover {
                background-color: transparent;
            }
        }
    }
}

.SocialComposerTarget-type-menu {
    ul {
        li {
            &:first-child {
                .ms-ContextualMenu-linkContent {
                    i {
                        display: none;
                    }

                    span {
                        margin-left: 0px !important;
                    }
                }
            }
        }
    }
}