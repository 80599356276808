@import "src/style/variables";

.SpintrCoachmark {
    .ms-TeachingBubble-header {
        .ms-TeachingBubble-headline {
            font-size: 18px;
        }
    }

    .ms-TeachingBubble-bodycontent {
        padding: $gutterMedium;
    }

    .ms-TeachingBubble-footer {
        .ms-Button--primary {
            border: 0;
            background-color: #FFF;
        }
    }
}
