@import "src/common.scss";
@import "../plannerVariables.scss";

.TimelineBarGroup {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    min-width: 3px;
    position: absolute;
    user-select: none;
    z-index: map-get($plannerStrata, item);

    .TimelineBarGroup-inner {
        border-radius: $brNormal;
        bottom: 0;
        left: 0;
        padding: $spacingTiny $spacingMedium;
        position: absolute;
        right: 0;
        top: 0;
        z-index: map-get($plannerStrata, itemBackground);

        .TimelineBarGroup-label-top {
            align-items: center;
            display: flex;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
            width: 100%;
            z-index: map-get($plannerStrata, itemContent);
        }

        .TimelineBarGroup-label-bottom {
            align-items: center;
            display: flex;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
            width: 100%;
            z-index: map-get($plannerStrata, itemContent);
        }
    }
}