@import "src/style/variables";

.SocialComposerText.banshee-suggestion {
    background-color: #F9F9F9;
    border-radius: $borderRadius;
    margin-bottom: $gutterMedium;
    padding: $gutterSmall;

    > div {
        display: block;

        > div[role="button"] {
            display: inline;
            cursor: pointer;
            color: color("light-blue");
            font-weight: bold;
        }
    }
}

.SocialComposerText.bookmark {
    margin-top: $gutterXSmall;
    background-color: #FFF;

    .image, .text {
        display: inline-block;
        vertical-align: top;
    }

    .image {
        max-width: 35%;
        margin-right: $gutterSmall;

        img {
            border-radius: $borderRadius;
            width: 100%;
            display: block;
        }
    }

    .frame-wrapper {
        height: 0;
        position: relative;
        padding: $gutterXLarge 0 52.75%;

        &.spotify {
            height: 80px;
            padding: 0;
        }

        iframe {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .text {
        max-width: calc(65% - #{$gutterSmall});
        overflow-wrap: break-word;
        font-size: 14px;
        line-height: 1.5em;
        color: color("dark-grey");

        .title {
            display: block;
            font-weight: 600;
            line-height: 1.188em;
            margin: 0;
        }

        .address {
            margin-top: $gutterTiny;
            display: block;
        }
    }
}

.bookmark-stage  {
    position: relative;

    .bookmark-actions {
        position: absolute;
        width: 100%;
        top: 0px;
        padding: $gutterSmall;
        box-sizing: border-box;

        .group {
            background-color: color(visageGray6);
            border-radius: $borderRadiusSharper;
            display: inline-block;
            height: 22px;
            width: 44px;

            &.right {
                position: absolute;
                top: $gutterSmall;
                right: $gutterSmall;
                width: 22px;
            }

            .action {
                height: 22px;
                width: 22px;
                display: inline-block;
                text-align: center;
                position: relative;

                i {
                    font-size: 8px;
                    line-height: 22px;
                    position: absolute;
                    height: 22px;
                    width: 22px;
                    left: 0px;
                    top: 0px;
                    color: color(dark-grey);
                }

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}