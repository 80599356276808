@import "src/style/variables.scss";

.WikiArticleListView {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;

    .TwoColumnListBoxWrapper {
        margin-top: $gutterXXXLarge;
    }
}
