@import "src/style/variables";

.PageView {
    .PageView_expired,
    .PageView_notPublished {
        background-color: color("light-grey");
        padding: $gutterSmall;
        margin-bottom: $gutterSmall;
        display: inline-block;
    }

    .InteractionsBar {
        margin-bottom: $gutterSmall;
    }

    // .pageHeaderImageWrapper.appMode {
    //     width: calc(100vw + 1px) !important;
    //     margin-left: -$gutterMedium;
    //     margin-right: -$gutterMedium;
    //     margin-top: 12px;
    //     margin-bottom: 0px;
    //     max-width: 1300px !important;
    // }

    .contentSection {
        .teaser-box {
            width: 100%;
          }
    }

    .pageHeaderImageWrapperWrapper {
        margin-bottom: $gutterXXLarge;
    }

}
