@import "src/style/variables.scss";

.type-content-confetti {
    height: 100%;
    position: absolute;
    width: 100%;

    .confetti {
        z-index: 2;
        overflow: hidden;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        -webkit-animation: loaderFadeIn 1s;
        -moz-animation: loaderFadeIn 1s;
        -ms-animation: loaderFadeIn 1s;
        -o-animation: loaderFadeIn 1s;
        animation: loaderFadeIn 1s;
        pointer-events: none;
        position: relative;
    }

    .emojis {
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: 2;
        -webkit-animation: loaderFadeIn 1s;
        -moz-animation: loaderFadeIn 1s;
        -ms-animation: loaderFadeIn 1s;
        -o-animation: loaderFadeIn 1s;
        animation: loaderFadeIn 1s;
        position: relative;
    }

    .emoji-left {
        overflow: hidden;
        z-index: 2;
        position: absolute;
        left: -10px;
        //bottom: -8px;
        bottom: 0px;

        svg {
            height: 70px;
            width: 70px;
            transform: scaleX(-1);
        }
    }

    .emoji-right {
        overflow: hidden;
        z-index: 2;
        position: absolute;
        right: -20px;
        //top: -10px;
        bottom: 0px;
        height: 100px;
        width: 100px;
    }
}

.confetti {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    position: relative;
}

.confetti-piece {
    position: absolute;
    width: 3px;
    height: 7px;
    top: 0;
    opacity: 0;
    animation: confettiAnimation 1000ms infinite ease-out;
}

.confetti-piece:nth-child(even) {
    z-index: 1;
}

.confetti-piece:nth-child(4n) {
    width: 5px;
    height: 12px;
    animation-duration: 2000ms;
}

.confetti-piece:nth-child(3n) {
    width: 3px;
    height: 10px;
    animation-duration: 3500ms;
    animation-delay: 3000ms;
}

@keyframes confettiAnimation {
    from {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    to {
        transform: translateY(240px);
    }
}