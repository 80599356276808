@import "src/style/variables.scss";

.Select {
    > select {
        border: 1px solid color("dusk-grey");
        border-radius: $borderRadius;
        box-sizing: content-box;
        max-width: 500px;
        padding: 6px 8px;
        width: 100%;
    }
}