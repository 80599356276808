@import "src/style/variables";

.SmallCreateButton {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    column-gap: $gutterXSmall;
    align-items: center;

    &:hover {
        opacity: 0.75;
    }
}
