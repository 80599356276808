@import "src/style/variables";

.ComposerMemoTodoList {
    display: flex;
    flex-direction: column;

    .items {
        display: flex;
        flex-direction: column;
        row-gap: $gutterXSmall;
        margin-bottom: $gutterSmall;
    }

    .add-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gutterXSmall;
        height: 20px;
        border-top: 1px solid color(borderColor);
        padding-top: $gutterSmall;
    }
}
