@import "src/style/variables.scss";
.CenteredPageHeader {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;

    h1 {
        margin-bottom: $gutterXSmall;
        margin-top: $gutterSmall;
    }
    
    .CenteredPageHeader-Icon {
        display: inline-block;

        .ms-Persona-initials {
            background-color: #FFF;
        }
    }
}