@import "src/style/variables.scss";

.CenteredAddButton {
    .CenteredAddButton-button {
        padding: 8px 14px 8px 18px;
        background-color: #FFF;
        border-width: 1px;
        border-color: color(visageMidBlue);
        border-style: solid;
        display: flex;
        border-radius: 30px;
        align-items: center;
        cursor: pointer;
        margin-bottom: 2px;
        align-items: center;
        column-gap: 4px;

        &:hover {
            background-color: color(visageMidBlue);
            
            .label-component {
                color: #FFF;
            }

            .Visage2Icon {
                svg {
                    path {
                        fill: #FFF;
                    }
                }
            }
        }
    }
}