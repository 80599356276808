@import "src/style/variables.scss";

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
}

$height: 38px;
$columnGap: $gutterXSmall;
$searchIconWidth: 16px;
$clearIconWidth: 14px;

.SpintrSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FFF;
    border-radius: $borderRadiusSharp;
    border: 1px solid color(borderColor);
    height: 36px;
    box-sizing: border-box;
    padding: 0 $gutterSmall;
    column-gap: $columnGap;

    .Visage2Icon, .Visage2Icon {
        svg {
            * {
                transition: all 0.5s;
            }
        }
    }

    input {
        flex-grow: 1;
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        max-width: calc(100% - $columnGap - $searchIconWidth);
    }

    &.expandable {
        padding: 0;
        width: 36px;

        input {
            display: none;
            min-width: 0px;
        }

        &:not(.hasValue) {
            cursor: pointer;
        }

        &.expanded {
            position: absolute;
            width: 100%;
            right: 0px;
            z-index: 1;
            padding: 0 $gutterSmall;
            transition: width 0.25s;

            input {
                display: block;
            }
        }
    }

    &.hasValue {
        input {
            max-width: calc(100% - calc(#{$columnGap} * 2) - $searchIconWidth - $clearIconWidth);
        }
    }
}

.ms-SearchBox-clearButton {
    &:hover {
        .ms-Button {
            background-color: transparent;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .main-content-wrapper {
        .SpintrSearch {
            &.expandable {
                &.expanded {
                    position: relative;
                    max-width: 200px;
                }
            }
        }
    }
}