@import "src/style/variables.scss";

.CalypsoButton {
    display: flex !important;
    flex-direction: row;
    height: 36px;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-radius: $borderRadiusSharp;
    box-sizing: border-box;
    border: 1px solid color(borderColor);
    padding: 0 $gutterSmall;
    column-gap: $gutterXSmall;
    white-space: pre;
    min-width: 36px;

    &:not(.expandable) {
        &:hover {
            opacity: 0.75;
        }
    }

    &.primaryBGColor {
        border: none;

        &.add-button {
            .icon-wrapper {
                background-color: rgba(255, 255, 255, 0.1);
                height: 20px;
                width: 20px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;

                .Visage2Icon-add {
                    margin: 0px;
                }
            }
        }
    }

    &.icon-only {
        width: 36px;
    }

    &.reversed {
        flex-direction: row-reverse;
    }

    .Visage2Icon-more-square {
        svg {
            top: -8px;
            left: -9px;
        }
    }
}
