@import "src/style/variables";

.AcademyCourseSidebarMeta {
    display: flex;
    flex-direction: column;
    row-gap: $gutterXSmall;

    .AcademyCourseSidebarMetaRow {
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;
        align-items: center;

        .AcademyCourseSidebarMetaRow-value {
            flex-grow: 1;
            justify-content: flex-end;
            display: flex;
        }
    }
}