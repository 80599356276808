@import "src/style/variables.scss";

.SpintrStaticLinks {
    display: flex;
    -webkit-animation: loaderFadeIn 1s;
    -moz-animation: loaderFadeIn 1s;
    -ms-animation: loaderFadeIn 1s;
    -o-animation: loaderFadeIn 1s;
    animation: loaderFadeIn 1s;
    flex-direction: row;
    align-items: center;

    > .unstyled-button, .SpintrUserMenu {
        margin-left: $gutterSmall;
    }

    .NotificationsChatShortcuts {
        display: flex;

        > .unstyled-button {
            vertical-align: top;
        }
    }
    
    .shortcutsButtonWide {
        .ms-TooltipHost {
            display: flex;
            flex-direction: row;
            align-items: center;
            
            .label-component {
                margin: 0 $gutterSmall;
            }
        }
    }

    .shortcutsButtonWide:hover svg {
        animation-name: pulse;
        animation-duration: .5s;
    }

    .shortcutsButtonWide:hover {
        opacity: 0.75;
    }

    .shortcutsButtonWide .shortcuts-link {
        margin: 10px;
        margin-right: $gutterMedium;
    }

    .link {
        .ms-TooltipHost {
            width: $circleLarge;
            height: $circleLarge;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;

            .ContentWithUnreadIndicator {
                width: $circleLarge;
                height: $circleLarge;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
            }
        }
    }

    > *, & .NotificationsChatShortcuts > div {
        > .shortcuts-link {

            > .ms-TooltipHost {
                display: flex;

                .gridIcon {
                    margin-right: $gutterSmall;
                }

                .chevronIcon {
                    margin-left: $gutterSmall;
                }
            }
        }

        > .link, .unstyled-button > .link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $circleLarge;
            height: $circleLarge;
            z-index: 1;
            position: relative;

            &.circle {
                border-radius: 50%;

                .gradient {
                    border-radius: 50%;
                    background-color: rgba(34, 35, 74, 0.04);
                    height: 100%;
                    width: 100%;
                }
            }
    
            .link-icon,
            .link-text {
                display: inline-block !important;
                vertical-align: -9px;
                margin-left: 0px;
                -webkit-transition: color $fastTransition, border-color $fastTransition;
                -moz-transition: color $fastTransition, border-color $fastTransition;
                -o-transition: color $fastTransition, border-color $fastTransition;
                transition: color $fastTransition, border-color $fastTransition;
            }
    
            .link-text {
                font-weight: 500;
            }

            &:hover {
                opacity: 0.75;
            }
        }
    }
}



// .link-add {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-right: $gutterSmall;
//     width: $circleLarge;
//     height: $circleLarge;
//     border-radius: 50%;

//     .link-icon,
//     .link-text {
//         display: inline-block !important;
//         vertical-align: -10px;
//         -webkit-transition: color $fastTransition, border-color $fastTransition;
//         -moz-transition: color $fastTransition, border-color $fastTransition;
//         -o-transition: color $fastTransition, border-color $fastTransition;
//         transition: color $fastTransition, border-color $fastTransition;
//     }

//     .link-text {
//         font-weight: 500;
//     }
// }
