@import "src/style/variables.scss";

.InformationFeedModule {
    background-color: #FFF;
    border-radius: $borderRadius;
    margin-bottom: $gutterMedium;

    .tabs {
        .ms-Pivot {
            padding: $gutterLarge;
        }
    }
}