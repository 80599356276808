@import "src/style/variables.scss";
@import "src/office365/mixins.scss";

.ExternalFile-error-message-wrapper {
  background-color: #FFF;
  border: 1px solid color("dusk-grey");
  border-radius: $borderRadius;

  .ExternalFile-error-message {
    padding: $gutterSmall;
  }

  .spintr-list-empty-list-label {
    background-color: transparent !important;
  }
}

.ExternalFile {
    text-align: left !important;
    background-color: #FFF;
    border-radius: $borderRadius;
    min-width: 200px;

    .external-file {
        @include office365file;
    }

    .office-file {
        border: 1px solid color("borderColor");
        border-radius: $borderRadius;
        cursor: pointer;
        position: relative;

        .details {
            margin: $gutterMedium $gutterSmall;
            position: relative;

            .file-name {
                display: flex;
                flex-direction: row;
                column-gap: $gutterXSmall;
                margin-bottom: $gutterSmall;

                .text {
                    display: inline-block;
                    font-weight: 600;
                    max-width: 100%;
                    word-wrap: break-word;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .date {
                bottom: 0;
                position: absolute;
                right: 0;
            }

            .date, .author {
                color: color("mid-grey");
            }
        }

        .hover-buttons {
            background-color: #FFF;
            border: 1px solid color("smoke");
            bottom: -13px;
            display: none;
            height: 25px;
            position: absolute;
            right: $gutterSmall;
            z-index: 1;

            .hover-button {
                display: inline-block;
                height: 25px;
                text-align: center;
                width: 25px;
            }
        }

        &:hover {
            .hover-buttons {
                display: inline-block;
            }
        }
    }
}
