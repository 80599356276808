@use "sass:math";
@import "../../style/variables.scss";

.SpintrStartView {
    max-width: 1400px;
    margin: 0 auto;
    column-gap: $gutterSmall;

    &.no-social-feed {
        max-width: 100%;

        .startModules {
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;

            .module-row {
                .module-cell {
                    &.cell-width3 {
                        margin: 0 auto;
                        // max-width: $modulesAreaMaxWidth;
                    }
                }
            }
        }
    }
}

.SpintrStartView > .socialFeed,
.SpintrStartView > .startModules,
.SpintrStartView > .zoltor {
    display: inline-block;
    vertical-align: top;
}

.SpintrStartView > .socialFeed {
    width: $socialFeedWidth;
    min-width: $socialFeedMinWidth;
}

.SpintrStartView > .startModules {
    width: calc(100% - #{$socialFeedWidth} - ((#{$gutterXXXLarge} * 2) + 2px));
    max-width: $modulesAreaMaxWidth;

    .module-row {
        display: flex;
        flex-wrap: wrap;
        vertical-align: top;

        &.margin-bottom {
            margin-bottom: $gutterXXLarge;
        }

        .module-cell {
            display: block;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            position: relative;
            width: 100%;
            -webkit-box-flex: 1;
            justify-content: space-between;
            min-width: 0;

            // &.cell-width2 {
            //     flex: 1 0 calc(65% - #{ceil($gutterXXLarge / 3)});
            // }

            &.cell-width1 {
                padding: 0 ceil($gutterXXLarge / 3);

                max-width: $rightColumnMaxWidth;
                min-width: $rightColumnMinWidth;

                &:first-child {
                    padding-left: 0;
                    padding-right: ceil(math.div($gutterXXLarge, 3)) + floor(math.div($gutterXXLarge, 3));
                }

                &:last-child {
                    padding-left: ceil(math.div($gutterXXLarge, 3)) + floor(math.div($gutterXXLarge, 3));
                    padding-right: 0;
                }

                &:last-child {
                    &:nth-child(2) {
                        padding-left: $gutterXXLarge;
                    }
                }

                &.empty {
                    width: 0;
                    height: 0;
                    padding-left: 0 !important;
                    flex: 0;
                    min-width: 0;
                    max-width: 0;
                    overflow: hidden;
                }
            }

            .right-column-wrapper {
                max-width: $rightColumnMaxWidth;
                width: 100%;
                margin-left: 0;
            }

            .RightColumn {
                .sidebar-container {
                    margin-left: 0;

                    .RightColumn-TeaserBoxes,
                    .operatingInfoBox,
                    .favouriteBox,
                    .birthdayBox {
                        width: 100%;
                        max-width: $rightColumnMaxWidth;
                    }
                }
            }

            &.main {
                background-color: #FFF;
                border-radius: $borderRadius;
                padding: $gutterMedium;
            }
        }
    }
}

.SpintrStartView > .startModules .teaserboxes {
    display: flex;
}

.SpintrStartView {
    display: flex;

    > .startModules {
        flex: 1 1 $modulesAreaMaxWidth
    }
}

.SpintrStartView {
    display: flex;

    > .socialFeed  {
        max-width: $socialFeedWidth;
        flex: 0 2 $socialFeedWidth;
    }

    .socialFeed {
        .SocialPostContainer {
            &.is-system-status {
                margin-bottom: $gutterSmall;
            }
        }
    }

    > .startModules {
        flex: 1 1 $modulesAreaMaxWidth;

        .module-row {
            display: flex;

            .module-cell {
                &.cell-width1 {
                    max-width: none;
                    flex: 0 0 $rightColumnMinWidth;
                }

                &.cell-width2 {
                    flex: 0 1 calc(100% - 1px - #{$gutterXXLarge} - #{$rightColumnMinWidth});
                    max-width: calc(100% - 1px - #{$gutterXXLarge} - #{$rightColumnMinWidth});
                    width: calc(100% - 1px - #{$gutterXXLarge} - #{$rightColumnMinWidth});
                    box-sizing: border-box;
                }
            }
        }
    }
}

@media screen and (min-width: 1700px) {
    .SpintrStartView {
        > .startModules {
            .module-row {
                .module-cell {
                    &.cell-width1 {
                        flex-basis: calc((100% - (#{$gutterXXLarge} * 2)) / 3);

                        > .right-column-wrapper {
                            width: 100%;
                            max-width: none;
                        }
                    }

                    &.cell-width2 {
                        flex-basis: calc((((100% - (#{$gutterXXLarge} * 2)) / 3) * 2) + #{$gutterXXLarge});
                        max-width: calc((((100% - (#{$gutterXXLarge} * 2)) / 3) * 2) + #{$gutterXXLarge});
                        width: calc((((100% - (#{$gutterXXLarge} * 2)) / 3) * 2) + #{$gutterXXLarge});
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}

.SpintrStartView {
    .cell-width2 {
        flex-grow: 1 !important;
        max-width: none !important;
    }
}

.SpintrStartViewResponsivePivot {
    .ms-Pivot {
        height: 44px;
        margin-bottom: $gutterXXLarge;

        > button {
            width: 50%;
            text-align: center;
            margin-right: 0px;

            &:hover {
                background-color: #FFF;
            }
        }
    }
}

@media screen and (max-width: $vmPhoneLandscape) {
    .SpintrStartView{
        > .startModules .module-row .module-cell.cell-width3 #news-spotlight {
            //margin: (-$gutterSmall) (-$gutterSmall) $gutterLarge (-$gutterSmall) !important;
            max-width: none;
        }
    }

}



@media screen and (max-width: 1200px) {
    .startModules {
        .module-row {
            .SocialPostContainer, .opener {
                border: 1px solid color(borderColor);
            }
        }
    }
}