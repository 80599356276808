@import "src/style/variables";

$chatTabWidth: 330px;
$minimizedChatTabWidth: 230px;

.ChatTab {
    border-radius: $borderRadius $borderRadius 0 0;
    box-shadow: 0 0 8px 4px rgba(67, 67, 67, 0.2);
    pointer-events: auto;
    position: relative;
    width: $chatTabWidth;
    display: flex;
    flex-direction: column;
    height: 350px;
    background-color: #FFF;

    &.minimized {
        width: $minimizedChatTabWidth;
        height: auto;
    }
}