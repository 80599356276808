@import "src/style/variables.scss";

.color {
    font-size: 1.4em;
    color: #2a93c2;
    margin-bottom: 0.3em;
}
#TagsViewInner {
    .header {
        margin-bottom: 12px;
    }
}

#TagsView {
    border-radius: borderRadius;

    ul {
        list-style-type: none;
        padding-left: 0.2em;
        padding-bottom: 1em;
        padding-left: 0px;

        li {
            a {
                text-overflow: ellipsis;
                max-width: 100%;
                overflow: hidden;
                display: block;
            }
        }
    }
    .breadcrumbs {
        border-bottom: 1px solid #d1d1d1;
        color: #999999;
        font-size: 0.9em;
        font-weight: 300;
        margin-bottom: 1.67em;
        padding-bottom: 0.6em;

        a {
            color: #999999;
        }
    }

    #TagsViewBody {

        .index-container.columns-enabled.col4 {
            columns: 230px 4;
            -moz-columns: 230px 4;
            -webkit-columns: 230px 4;
        }
        .indexContent {
            padding-top: 1em;
            padding-bottom: 1em;
            .index-list {
                width: 240px;
            }
        }

        .indexHeader {
            margin-bottom: 5px;
        }
        .indexList {
            width: 16em;
            padding-bottom: 1em;
            display: inline-block;
        }


        .itemType {
            color: color("dark-grey");
            font-size: 0.8em;
        }
        .itemName {
        }
    }
}
