.WikiArticleList {
    .SpintrList-cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));

        .ListContentCard {
            min-width: 260px;
            max-width: 100%;

            .ListContentCard-wrapper {
                width: auto;
            }
        }
    }
}
