@import "src/style/variables.scss";

.organisation-invite-view {
    .department-button {
        margin-top: $gutterXSmall;
        .expander {
            width: 15px;
            display: inline-block;
        }
    }

    .input-container {
        position: relative;
        padding: $gutterXSmall 0;

        &:hover {
            .remove-button {
                display: block;
            }
        }

        .remove-button {
            position: absolute;
            display: none;
            right: 5px;
            top: 10px;

            i {
                font-size: 20px;
            }

            &:hover {
                background-color: transparent;
            }
        }
    }
}
