@import "src/style/variables.scss";

.InteractionsBar {
    display: flex;
    flex-direction: row;
    align-content: center;
    height: 16px;
    //justify-content: center;

    > * {
        display: flex;
        flex-direction: row;
        align-content: center;
        //justify-content: center;
        // display: inline-block;
        // vertical-align: top;

        // > * {
        //     display: inline-block !important;
        //     vertical-align: middle;
        // }
    }

    .LikeContainer, .Commentors, .shareButton, .unread-label {
        margin-right: $gutterLarge;
        height: 16px;

        > .unstyled-button {
            height: 16px;
        }

        > :not(.unstyled-button) {
            line-height: 16px;
            height: 16px;
        }

        .like-count-wrapper, .comment-counter, .shareText  {
            margin-left: $gutterXSmall;
            top: -1px;
            position: relative;
            // margin-top: 1px;

            &.shareText {
                margin-left: $gutterXSmall;
                //margin-top: 1px;
            }
        }

        .like-count-wrapper {
            > div {
                display: block;

                > span {
                    display: block;
                }
            }
        }

        .comment-counter {
            > span {
                display: block;
            }
        }
    }

    .LikeContainer {
        .Visage2Icon {
            position: relative;
            top: 0px;
        }
    }

    .Visage2Icon {
        svg {
            display: block;
        }
    }

    .unread-label {
        display: flex;
        flex-direction: row;
        align-items: center;

        .unread-dot {
            height: 6px;
            width: 6px;
            border-radius: 6px;
            margin-right: $gutterXSmall;
        }
    }

    .ms-Icon {
        height: 20px;

        > span {
            height: 20px;
        }
    }
}

.likersHoverCard {
    padding: $gutterXSmall;

    .ms-Persona {
        display: inline-block;
        vertical-align: top;
        margin-right: $gutterXSmall;
    }

    margin-right: -6px;
}

.FeedInteractionsBar-PillButtons {
    display: flex;
}

.FeedInteractionsBar-buttons {
    display: flex;
    justify-content: space-evenly;
    padding: 0 16px;
    border-top: 1px solid color("smoke");
    border-bottom: 1px solid color("smoke");

    .FeedInteractionsBar-button {
        display: inline-block;
        vertical-align: middle;
        flex-grow: 1;
        height: 40px;

        > div {
            height: 40px;
            box-sizing: border-box;

            .lottieWrapper {
                margin-right: 4px;
                margin-left: -10px;

                .lf-player-container {
                    .AnimatedIcon {
                        height: 40px;
                        width: 40px;

                        svg {
                            path {
                                stroke: color(mid-grey);
                            }
                        }
                    }
                }
            }
        }

        > .unstyled-button {
            text-align: center;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;

            > .Visage2Icon, .label-component, img, .lottieWrapper {
                display: inline-block;
                vertical-align: middle;
            }

            img {
                height: 20px;
                width: 20px;
                margin-right: $gutterSmall;
                margin-top: -1px;
            }

            > .Visage2Icon {
                margin-right: $gutterXSmall;

                svg {
                    display: block;
                }
            }

            &.has-liked {
                .label-component {
                    &.LikeType0 {
                        color: #db0916 !important;
                    }
                    &.LikeType5 {
                        color: #db0916 !important;
                    }
                    &.LikeType1 {
                        color: #f7b125 !important;
                    }
                    &.LikeType2 {
                        color: #f7b125 !important;
                    }
                    &.LikeType4 {
                        color: #f7b125 !important;
                    }
                    &.LikeType3 {
                        color: #f7b125 !important;
                    }
                }
            }

            &:hover {
                .label-component {
                    color: color("light-blue");
                }

                > .Visage2Icon {
                    * {
                        fill: color("light-blue");
                    }
                }
            }

            &.animate-like {
                img {
                    animation-name: newReactionAnimation;
                    animation-duration: 1.5s;
                }
            }
        }
    }
}

.smallerReactionButton {

}

.InteractionsBarWrapper {
    &.displayBigButtons {
        .reactions-bar {
            //border-top: 1px solid color("smoke");
        }
    }
}

.FeedInteractionsBar-info {
    display: flex;
    height: 16px;
    padding-top: $gutterXSmall;

    .label-component {
        line-height: 16px;
    }

    .left, .right {
        display: inline-block;
        vertical-align: middle;
        height: 16px;

        > * {
            vertical-align: top;
        }
    }

    // .currentReactions {
    //     height: 20px;

    //     > * {
    //         height: 20px;
    //         vertical-align: top !important;
    //     }
    // }

    .left {
        flex-grow: 1;

        // .smallerReactionButton {
        //     &.has-liked {
        //         .label-component {
        //             &.LikeType0 {
        //                 color: #ff0729 !important;
        //             }
        //             &.LikeType1 {
        //                 color: #0051FF !important;
        //             }
        //             &.LikeType2 {
        //                 color: #0038AC !important;
        //             }
        //             &.LikeType4 {
        //                 color: #FCC801 !important;
        //             }
        //             &.LikeType3 {
        //                 color: #707070 !important;
        //             }
        //         }
        //     }
        // }

        .currentReactions {
            height: 16px;

            > div {
                display: flex;
                flex-direction: row;
                column-gap: $gutterTiny;

                .likeType {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-content: center;
                    justify-content: center;

                    img {
                        height: 16px;
                        width: 16px;
                        margin-top: -1px;
                    }
                }

                .label-component {
                    margin-left: $gutterTiny;
                    margin-top: 1px;
                }
            }

            // > div {
            //     height: 16px;

            //     img {
            //         position: relative;

            //         &:not(:first-child) {
            //             margin-left: -7px;
            //         }
            //     }
            // }

            // > * {
            //     display: inline-block;
            //     vertical-align: top;
                
            //     img {
            //         display: inline-block;
            //         vertical-align: top;
            //         height: 16px;
            //         width: 16px;
            //         margin-right: $gutterTiny;
            //         padding: 4px;
            //         margin-top: -4px;
            //     }
            // }

            // .label-component {
            //     margin-left: $gutterTiny;
            //     line-height: 16px;
            // }
        }
    }

    .right {
        display: flex;
        flex-direction: row;

        .Visage2Icon {
            margin-right: $gutterXSmall;

            svg {
                display: block;
            }
        }
    }

    &.displayBigButtons {
        padding-bottom: $gutterMedium;
    }


    &:not(.displayBigButtons) {
        .currentReactions {
            margin-left: $gutterSmall;

            .label-component {
                margin-left: 0px;
            }
        }

        .interactionsDivider {
            display: inline-block;
            vertical-align: middle;
            width: 3px;
            height: 3px;
            border-radius: 3px;
            margin: 0px 9px;
            margin-top: -9px;
            background-color: color("neutralBlue");
        }

        .commentButtonWrapper {
            display: inline-block;
            height: 16px;
    
            .commentButton {
                margin-left: $gutterXSmall;
                display: inline-block;
                vertical-align: top;
            }
        }

        .date, .date .label-component {
            display: inline-block;
            vertical-align: top;
        }
    }
}

.like-action {
    position: relative;

    .ReactionPicker {
        display: block;
        background-color: #FFF;
        position: absolute;
        border-radius: 36px;
        z-index: 1;
        top: -40px;
        white-space: pre;
        //padding: $gutterTiny;
        left: -70px;
        z-index: 7;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
        //border: 1px solid red;
        height: 36px;

        > .unstyled-button {
            display: inline-block;
            vertical-align: middle;
            height: 36px;
            position: relative;
            text-align: center;

            .lf-player-container {
                height: 36px;
                width: 36px;
                transition: all 0.2s;
            }

            .reaction-tooltip {
                display: none;
                position: absolute;
                top: -38px;
                left: -20px;
                right: -20px;
                text-align: center;
                overflow: visible;

                > .label-component {
                    display: inline-block;
                    border-radius: $borderRadiusRounder;
                    color: #FFF !important;
                    background-color: color("dark-grey");
                    padding: $gutterTiny $gutterXSmall;
                    line-height: normal !important;
                    margin-top: 0px !important;
                }
            }
            
            img {
                height: 40px;
                width: 40px;
                transition: transform 0.25s;
                padding: $gutterTiny;
                margin-right: 0px;
                margin: -5px -6px -3px;
                display: block;

                svg {
                    display: block;
                }
            }
            
            &:first-child {
                margin-left: 6px;
            }

            &:last-child {
                margin-right: 2px;
            }

            &:hover {
                .reaction-tooltip {
                    display: block;
                }

                .lf-player-container {
                    transform: scale(1.20) translateY(-5px);
                    margin-bottom: $gutterXSmall;
                }
            }

            &.LikeType0 {
                img {
                    -webkit-animation: reactionAnimation 0.1s;
                    -moz-animation: reactionAnimation 0.1s;
                    -ms-animation: reactionAnimation 0.1s;
                    -o-animation: reactionAnimation 0.1s;
                    animation: reactionAnimation 0.1s;
                }
            }
            &.LikeType1 {
                img {
                    -webkit-animation: reactionAnimation 0.2s;
                    -moz-animation: reactionAnimation 0.2s;
                    -ms-animation: reactionAnimation 0.2s;
                    -o-animation: reactionAnimation 0.2s;
                    animation: reactionAnimation 0.2s;
                }
            }
            &.LikeType2 {
                img {
                    -webkit-animation: reactionAnimation 0.3s;
                    -moz-animation: reactionAnimation 0.3s;
                    -ms-animation: reactionAnimation 0.3s;
                    -o-animation: reactionAnimation 0.3s;
                    animation: reactionAnimation 0.3s;
                }
            }
            &.LikeType3 {
                img {
                    -webkit-animation: reactionAnimation 0.4s;
                    -moz-animation: reactionAnimation 0.4s;
                    -ms-animation: reactionAnimation 0.4s;
                    -o-animation: reactionAnimation 0.4s;
                    animation: reactionAnimation 0.4s;
                }
            }
            &.LikeType4 {
                img {
                    -webkit-animation: reactionAnimation 0.5s;
                    -moz-animation: reactionAnimation 0.5s;
                    -ms-animation: reactionAnimation 0.5s;
                    -o-animation: reactionAnimation 0.5s;
                    animation: reactionAnimation 0.5s;
                }
            }
        }
    }
}

@keyframes reactionAnimation {
    0% { transform: translateY(0) }
    50% { transform: translateY(-35%) }
    100% { transform: translateY(0) }
}


@keyframes newReactionAnimation {
    from {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
    }
    25% {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
    }
    50% {
        transform: scale(1.25);
        -webkit-transform: scale(1.25);
    }
    75% {
        transform: scale(1.25);
        -webkit-transform: scale(1.25);
    }
    to {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}