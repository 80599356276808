@import "src/common.scss";

.PlannerFilePicker {
    .PlannerFilePicker-dropzone.PlannerFilePicker-simple {
        border: 0 none !important;
        padding: $spacingSmall 0 !important;
        text-align: left !important;
        display: flex;
        flex-direction: row;
        gap: $spacingTiny;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: $spacingTiny;

        &:hover {
            background-color: transparent !important;
        }       
    }

    .PlannerFilePicker-dropzone {
        margin-bottom: $spacingMedium;
    }

    .PlannerFilePicker-files {
        display: flex;
        flex-direction: column;
        gap: $spacingSmall;
    }

    .PlannerFilePicker-file {
        background-color: map-get($spintrColors, grayLight);
        border-radius: $brRounder;
        display: flex;
        gap: $spacingSmall;
        padding: $spacingSmall;
        width: 100%;

        .image {
            align-items: center;
            display: flex;
            flex: 0 0 32px;
            justify-content: center;
        }

        .information {
            flex: 1 1 auto;
        }

        .actions {
            align-items: center;
            display: flex;
            flex: 0 0 32px;
            justify-content: center;
        }
    }
}