@import "src/style/variables";

.SpintrHeaderMenu {
    display: flex;
    flex-direction: row;
    column-gap: $gutterSmall;
    position: relative;
    transition: opacity 1s;

    .menu-item {
        display: flex;
        flex-direction: row;
        gap: $gutterXSmall;
        align-items: center;
        padding: $gutterSmall;
        height: 44px;
        box-sizing: border-box;
        z-index: 1;

        &.active {
            z-index: 41;

            &.search {
                cursor: text;
            }
        }

        .content {
            .search-field {
                width: 169px;
            }
        }
    }

    .indicator {
        height: 42px;
        background-color: rgba(34, 35, 74, 0.04);
        border-radius: $borderRadiusRound;
        position: absolute;
        transition: left 0.25s, width 0.25s, height 0.25s;
        z-index: 40;
    }

    &.is-measuring {
        position: absolute;
        opacity: 0;
    }
    
    &:not(.is-measuring) {
        .menu-item {
            position: relative;
            &:not(.active) {
                .content {
                    display: none;
                }

                &:hover {
                    &::before {
                        content: '';
                        background-color: rgba(34, 35, 74, 0.04);
                        height: 44px;
                        position: absolute;
                        z-index: 0;
                        border-radius: $borderRadiusRound;
                        -webkit-animation: hoverEffectAnimation 0.5s forwards;
                        -moz-animation: hoverEffectAnimation 0.5s forwards;
                        -ms-animation: hoverEffectAnimation 0.5s forwards;
                        -o-animation: hoverEffectAnimation 0.5s forwards;
                        animation: hoverEffectAnimation 0.5s forwards;
                    }
                }
            }
        }
    }
}


@keyframes hoverEffectAnimation {
    0% { width: 0px; left: 22px; height: 0px; }
    100% { width: 100%; left: 0px; height: 42px; }
}