@import "src/style/variables";

.OperatingInfoNotification {
    vertical-align: middle;
    padding-left: 9px;
    padding-bottom: 5px;
    cursor: pointer;

    .SpintrLoader {
        margin-left: $gutterXSmall;
        padding: 0;
        padding-top: 20px;
    }

    .dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
    }

    .dot.nonePlanned {
        background-color: #28a745;
    }

    .dot.planned {
        background-color: #FFAE0040;
    }

    .dot.ongoing {
        background-color: #DB010040;
    }

    .text {
        display: inline-block;
        vertical-align: middle;
    }
}

.operating-info.highlight {
    .operating-info-label {
        display: flex;
    }

    .unread-dot {
        height: 18px;
        width: 18px;
        border-radius: 40px;
        margin-right: 3px;
        margin-left: $gutterSmall;
        background-color: #DB0100;
        align-content: center;
        justify-content: center;
        display: flex;
        font-size: 10px;
        color: white;
        font-size: 14px;
        align-self: center;
    }

    .planned {
        background-color: rgb(255, 231, 178);
        border-radius: $borderRadiusRounder;
    }

    .ongoing {
        background-color:rgb(253, 235, 236);
        border-radius: $borderRadiusRounder;
    }

    .nonePlanned {
        background-color:#FFF;
        border-radius: $borderRadiusRounder;
    }

    .operating-info-icon-wrapper {
        height: 40px;
        padding-left: 10px;

        &.iconHidden {
            display: none;
            padding-left: 0px;
            margin-right: 0px;
            width: $gutterLarge;
        }
    }

    .Visage2IconOperatingInfo {
        padding-left: 5px;
        align-self: center;

        svg {
            vertical-align: text-bottom;
        }
    } 
}

.operating-info {
    padding: 0 !important;
    border-radius: $borderRadiusRounder;
    height: 40px;

    .VisageSidebar-menuItem-wrapper {
        margin: 0px !important;
        height: 40px;

        .VisageSidebar-menuItem {
            padding-right: 0px !important;
            margin-right: 0px !important;
            height: 40px;
            align-items: center;
            display: flex;
        }
    }

    > div {
        > .OperatingInfoNotification {
            vertical-align: top;
        }
    }

    .unstyled-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gutterSmall;
        margin: 0 $gutterMedium;
    }

    .operating-info-icon-wrapper {
        margin-right: $gutterSmall;
        border-radius: 37px;
        width: 37px;
        height: 37px;
        text-align: center;
    }

    .planned {
        .lottieWrapper {
            svg {
                * {
                    stroke: #F5B544;
                }
            }
        }
    }

    .ongoing {
        .lottieWrapper {
            svg {
                * {
                    stroke: #DF2247;
                }
            }
        }
    }

    .nonePlanned {
        .lottieWrapper {
            svg {
                * {
                    stroke: #28a745;
                }
            }
        }
    }

    &:not(.with-plate) {
        .operating-info-icon-wrapper {
            width: 20px;

            .lottieWrapper {
                margin-left: -3px;
            }
        }
    }

    .label-component {
        margin-top: 1px;
    }

    &:hover {
        opacity: 0.75;
    }

    .highlight {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.isSmallViewMode {
    .OperatingInfoNotification {
        .label-component {
            font-size: 11px !important;
        }
    }
}
