@import "src/style/variables";

.WidgetConfigurationPopup {
    .footer-bar {
        box-shadow: initial !important;
        left: 0 !important;
        position: absolute !important;
        right: 0 !important;
        width: 100% !important;
    }
}

.spintr-modal .ms-Dialog-main.WidgetConfigurationPopup-container {
    max-width: 840px;
    width: 100%;
}