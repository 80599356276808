@import "src/style/variables.scss";

.OfflineBanner-Wrapper {
    padding: $gutterSmall $gutterSmall 0px $gutterSmall;

    .OfflineBanner {
        background-color: #E45864;
        margin-left: $gutterXLarge;
        margin-right: $gutterXLarge;
        border-radius: $borderRadius;
        position: relative;
        text-align: center;
        padding: $gutterMedium;
        padding-right: calc(#{$gutterMedium * 2});
        color: #FFF;
        max-width: 1300px;
    
        .removeButton {
            position: absolute;
            right: $gutterSmall;
            top: $gutterSmall;
        }
    }
}