@import "src/style/variables.scss";
@import "../Composer";

.ComposerTypeSelectorBar {
    display: flex;

    .type-list {
        @include type-selector;
        width: calc(100% - 64px);
        
        .ComposerTypeButton {
            height: 46px;

            .Visage2Icon {
                z-index: 1;
            }
        }
    }

    &.single-view {
        .type-list {
            width: 100%;
        }
    }

    .more-button {
        background-color: #E9F1FD;
        border-radius: 50%;
        color: #2772F0;
        flex: 48px 0 0;
        height: 48px;
        line-height: 48px;
        margin-left: $gutterMedium;
        text-align: center;
        width: 48px;
        
        .Visage2Icon {
            display: inline-block;
        }
    }
}

#SocialComposer .composer-wrapper {
    &.image, &.video {
        .ComposerTypeSelectorBar .type-list .ComposerTypeButton {
            &.image, &.video {
                border-color: $imageActiveColor;
            }
        }
    }

    &.file, &.external-file {
        .ComposerTypeSelectorBar .type-list .ComposerTypeButton {
            &.file, &.external-file {
                border-color: $fileActiveColor;
            }
        }
    }

    &.question {
        .ComposerTypeSelectorBar .type-list .ComposerTypeButton.question {
            border-color: $questionActiveColor;
        }
    }

    &.poll {
        .ComposerTypeSelectorBar .type-list .ComposerTypeButton.poll {
            border-color: $pollActiveColor;
        }
    }
}

// @media screen and (max-width: 1509px) {
//     .ComposerTypeSelectorBar {
//         //height: 20px; Removed this because it caused ui issue on start page when small browser window
//         line-height: 1.250em;

//         .ComposerTypeButton {
//             line-height: 1.250em;

//             svg {
//                 line-height: 1.250em;
//                 top: auto;
//                 position: static;
//             }
//         }
//     }
// }

// @media screen and (max-width: 1023px) {
//     .ComposerTypeSelectorBar {
//         display: block;
//         height: 46px;

//         .type-list {
//             align-content: stretch;
//             flex-wrap: nowrap;
//             height: 56px;
//             overflow-x: scroll;

//             .ComposerTypeButton {
//                 white-space: pre;
//                 padding: 0 $gutterSmall;
//                 min-width: auto;
//             }
//         }
//     }
// }
