@import "src/style/variables.scss";

.calendar-event-popup {
    width: 70%;
}

.isSmallViewMode {
    .calendar-event-popup {
        width: initial;

        .date-stack {
            label {
                margin-top: 0;
            }

            .FormControl {
                margin-bottom: 0;

                .SpintrDatePicker {
                    margin-bottom: 0;
                }
            }
        }
    }
}
