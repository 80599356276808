@import "src/common.scss";

$contentBoxMinWidth: 280px;

.ContentMetadataBox {
    border: 1px solid $spintrGrayLight;
    border-radius: $brRounder;
    box-sizing: border-box;
    min-width: $contentBoxMinWidth;
    padding: $spacingMedium;
    width: 0;

    .Reach {
        .label-component {
            color: $spintrContentDark;
            font-size: $fsCaption;
            font-weight: $fwMedium;
            letter-spacing: $lsDense;
            line-height: $lhNormal;
        }
    }

    .link{
        display: block;
    }
}