@import "src/style/variables";

.OperatingInfoSidebarPost {
    background: #fff;
    border-radius: $borderRadius;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 3px solid color("black");
    cursor: pointer;
    margin-top: 1px;
    margin-bottom: $gutterSmall;
    -webkit-transition: background-color $fastTransition;
    -moz-transition:  background-color $fastTransition;
    -o-transition:  background-color $fastTransition;
    transition:  background-color $fastTransition;
    &:last-child{
        margin-bottom: $gutterXSmall;
    }
    &:hover {
        background: color("light-grey");
    }

    &.type1 {
        border-left-color: #DB0100;
    }
    
    &.type3 {
        border-left-color: #FFAE00;
    }

    .wrapper {
        color: inherit;
        display: flex;
        text-decoration: none;
        width: 100%;
    }

    // .icon,
    // .text {
    //     display: inline-block;
    //     vertical-align: middle;
    // }

    // .icon {
    //     margin: 0 $gutterSmall;
    // }

    .icon {
        margin: 0 10px;
        font-size: 22px;
        vertical-align: middle;
        align-self: center;

        .planned {
            color: #FFAE00;
        }

        .ongoing {
            color: #DB0100;
        }

        .completed {
            color: green;
        }
    }

    .text {
        padding: $gutterSmall $gutterSmall $gutterSmall 0;
        width: calc(100% - 48px - #{$gutterSmall});
        line-height: 1em;
        
        h5 {
            margin: 0 0 2px 0;
        }
        .description {
            margin: 0 0 4px 0;
        }
        .name,
        .dates {
            display: block;
        }
        .dates {
            color: color("mid-grey");
        }
    }
}
