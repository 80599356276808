@import "src/style/variables.scss";

.HeaderImportantArticlesButton {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: $gutterSmall;
    background-color: #FFF;
    border-radius: 30px;
    height: 40px;
    align-items: center;
    padding: 0 $gutterSmall;
    margin-right: $gutterSmall;

    .icon-wrapper {
        height: 20px;
        width: 20px;

        > div {
            > div {
                height: 20px;
                svg {
                    height: 20px;
                    width: 20px;
                    margin-top: -1px;
                }
            }
        }
    }

    .label-component {
        white-space: nowrap;
    }

    .Visage2Icon {
        position: relative;
        top: 1px;
    }

    &:hover {
        opacity: 0.75;
    }
}

.HeaderImportantArticlesButton-callout {
    padding: $gutterMedium;
    display: flex;
    flex-direction: column;
    row-gap: $gutterSmall;
    max-width: 500px;

    .HeaderImportantArticlesButton-article {
        border: 1px solid color(borderColor);
        border-radius: $borderRadius;
        padding: $gutterSmall $gutterMedium;
        display: flex;
        flex-direction: row;
        column-gap: $gutterLarge;
        align-items: center;
        align-content: center;
        min-width: 200px;

        > div {
            &:first-child {
                flex-grow: 1;
            }
        }

        &:hover {
            opacity: 0.75;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .HeaderImportantArticlesButton {
        .label-component {
            display: none;
        }
    }
}