@import "src/style/variables.scss";

.ProductActions {
    display: flex;
    gap: $gutterSmall;

    .edit-button {
        background-color: color("visage2LightGray2");
        border-color: color("visage2LightGray2");
        color: color("primaryContent");
    }

    .delete-button {
        background-color: color("spintrWhite");
        border-color: color("visage2LightGray2");
        color: color("primaryContent");
    }
}