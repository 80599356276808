@import "src/style/variables.scss";

.UserHovercard-wrapper {
    display: inline;
}

.UserHovercard {
    width: 300px;

    .UserHovercard-coverArt {
        width: 100%;
        height: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .UserHovercard-personaWrapper {
        padding: $gutterMedium;
    }

    .UserHovercard-contact {
        padding: $gutterMedium;
        padding-top: 0;
        a{
            span{
                font-size: 14px;
            }
            
        }

        .UserHovercard-contact-row {
            color: color("dark-grey");

            > * {
                vertical-align: middle;
            }

            .UserHovercard-contact-row-icon {
                display: inline-block;
                margin-right: $gutterXSmall;
            }
        }
    }

    .UserHovercard-footer {
        padding: $gutterMedium;
        border-top: 1px solid color("light-grey");

        .UserHovercard-footer-divider {
            padding: 0 $gutterXSmall;
        }
    }
}