@import "src/style/variables.scss";

#SocialFeedContainer{
    .load-more{
        padding: 6px 6px;
    }
}

.SocialPostContainer {
    margin-bottom: $gutterSmall;
    background-color: #FFF;
    border-radius: $borderRadius;
    border: 2px solid transparent;
    padding: $gutterMedium - 4px;

    &.border {
        border: 1px solid color(borderColor);
    }

    &.expandable-feed-post {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gutterTiny;

        .SystemStatusSocialPostHeader {
            .SystemStatusTypePill {
                border: none;
                padding: 0px;
            }
        }

        .spacer {
            flex-grow: 1;
        }
    }

    .InteractionsBar{
        padding: $gutterSmall 20px;
        border-bottom: 1px solid color("smoke");
    }

    .comments-wrapper{
        .InteractionsBar{
            padding:0;
        }
    }

    .CommentsContainer{
        .InteractionsBar{
            border-left:none;
            border-right:none;    
            border-bottom:none;  
        }
    }

    .CommentCreator{
        .comment-content{
            padding-bottom: 5px;
            margin-top: 5px;
        }
    }

    .socialBlock {
        &.enableReactions {
            .reactions-bar:empty {
                min-height: $gutterSmall;
            }
        }
    }

    > .socialBlock {
        > div {
            > .FeedInteractionsBar-buttons {
                .ReactionPicker {
                    left: 5px;
                    top: -30px;
                }
            }
        }
    }
}

@media screen and (max-width: $vmPhoneLandscape) {
    .SocialPostContainer{
        .post-wrapper{
            border-left:none;
            border-right:none;
        } 

        .socialBlock {
            .InteractionsBar{
                border-left: none;
                border-right: none;
            }
        }

        &.expandable-feed-post {
            .expand-label {
                display: none;
            }
        }
    }
}