@import "src/common.scss";
@import "src/style/variables.scss";

.RecommendedAudiences{
    margin-top: 20px;
    margin-bottom: 20px;

    .TargetGroupList {
        font-weight: 550 !important;
        margin-top: 6px !important;

        .TargetGroupList-items {
            display: flex;
            flex-direction: row;
            gap: $gutterXSmall;
            flex-wrap: wrap;
            margin-top: $gutterSmall;

            .TargetGroupItem {
                min-width: 0px;

                &.selected {
                    background-color: #f3f2f1;
                }
            }
        }
    }
}