@import "src/style/variables";

.StartPageBuilderRow {
    border: 0px dashed transparent;

    .button-container {
        position: relative;

        .add-before-row {
            align-items: center;
            background-color: #FFF;
            box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            display: flex;
            height: 30px;
            justify-content: center;
            left: calc(50% - 60px);
            padding: 0 $gutterMedium;
            position: absolute;
            top: -15px;
            gap: 4px;
            z-index: 2;

            &:hover {
                opacity: 0.75;

                .Visage2Icon {
                    animation-name: pulse;
                    animation-duration: .5s;
                }
            }

            > span {
                flex: 12px 0 0;
                line-height: 1;
                margin-top: 2px;
            }

            > div {
                flex: 73px 0 0;
            }
        }
    }

    .add-column-button {
        position: relative;

        .button {
            background-color: #FFF;
            box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
            position: absolute;
            top: calc(50% - 15px);
            right: -15px;

            &:hover {
                opacity: 0.75;

                i {
                    animation-name: rotate;
                    animation-duration: .5s;
                }
            }

            border-radius: 100%;
            height: 30px;
            width: 30px;

            display:flex;
            align-items: center;
            justify-content: center;
            z-index: 2;

            i {
                color: color("dark-grey");
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    
    .row-content {
        display: flex;
        min-height: 140px;
    }

    &.empty {
        border-color: color("neutralBlue");
        padding: 1px;
        margin-bottom: -1px;
        margin-right: -1px;
        border-left-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 1px;


        &:first-child {
            border-top-width: 1px;
        }

        &.selected {
            background-color: color("blueHighlight");
            border: 2px dashed color("neutralBlue");
            padding: 0;
        }
    }
}