@import "../../style/variables.scss";

.CalendarEventViewModal {
    .ms-Dialog-main {
        width: 40vw;
    }

    .pageInfoPanel {
        padding-left: 0 !important;
    }
}
.calendar-event-tags {
    display: flex;
    margin-bottom: $gutterSmall;
    
    .calendar-event-tag {
        background-color: color(visage2LightGray);
        border-radius: 16px;
        padding: 4px 12px 4px 12px;
        margin-right: 8px;
    }
}
.calendar-event-view {
    background-color: #fff;
    min-width: 75%;
    .InteractionsBar {
        margin-bottom: $gutterXSmall;
    }
    .close-modal-button {
        align-self: flex-end;
    }

    .headerImage {
        width: 100%;
        max-height: 200px;
        object-fit: cover;
        margin-bottom: $gutterSmall;
        margin-top: 0;
        border-radius: $borderRadiusRound;
    }

    .left {
        //width: 55%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .right {
        width: 45%;
    }

    .right {
        display: inline-block;
        vertical-align: middle;
    }

    .calendar-event-content {
        min-width: 300px;
        width: 100%;
        margin-bottom: $gutterLarge;
    }

    &.isSmallViewMode {
        width: 100%;
        margin-left: 0;
    }

    .print-container {
        p {
            font-size: 12px;
        }
    }
    .attending-buttons {
        display: flex;

        .outlook-button {
            margin-left: $gutterXSmall;
        }

        .ms-Button-flexContainer {
            flex-direction: row-reverse;
        }
    }
}
