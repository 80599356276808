@import "src/style/variables";

.Visage2SocialPoll {
    .poll-alternatives {
        .poll-alternative {
            margin-bottom: $gutterMedium;

            .Visage2SocialPoll-option {
                position: relative;
                border: 1px solid color(borderColor);
                border-radius: $borderRadiusSharper;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: $gutterMedium;
                column-gap: $gutterMedium;
                justify-content: space-between;
        
                > * {
                    z-index: 1;
                    position: relative;
                }
        
                .Visage2SocialPoll-option-bg {
                    background-color: color(visageMidBlueBackground);
                    position: absolute;
                    height: 100%;
                    left: 0px;
                    top: 0px;
                    z-index: 0;
                    -webkit-transition: width 1s ease-in-out;
                    -moz-transition: width 1s ease-in-out;
                    -o-transition: width 1s ease-in-out;
                    transition: width 1s ease-in-out;
                }

                .left, .right {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    column-gap: $gutterMedium;
                }

                &:not(.is-selected) {
                    .Visage2Icon {
                        svg {
                            path {
                                &:last-child {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}
