.ImageCore-wrapper {
  display: block;
}

.ImageCore {
  display: block;
  object-fit: cover;
}

.ImageCore.clickable {
  cursor: pointer;
}