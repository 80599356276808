@import "src/style/variables";

.RunningLabel {
    background-color: #D9FFED;
    border-radius: 8px;
    padding: 0 $gutterXSmall;
    margin-left: $gutterXSmall;
    height: 18px;

    .label-component {
        color: #079455;
    }
}