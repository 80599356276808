@import "src/style/variables";

.PageInfoPanel-attachedFile-download {
  display: block;
  color: #000;
  margin-bottom: $gutterXSmall;
}

.PageInfoPanel-attachedFile {
  .icon-wrapper {
    display: inline-block;
    vertical-align: -5px;
    margin-right: $gutterXSmall;
  }

  .label-component {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 54px);
    word-wrap: break-word;
  }

  button {
    display: inline-block;
    vertical-align: middle;
  }
}

.PageInfoPanel-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  a {
    flex-grow: 1;
  }
}

.pageInfoPanel {
  .header {
    margin-top: 0px;
  }
  
  .moreInfo {
    margin-bottom: $gutterSmall;

    .icon {
      margin-right: $gutterSmall;
    }
  }

  &.wide {
    > * {
      display: inline-block;
      vertical-align: top;
      width: 25%;
      min-width: 100px;
    }

    > .unstyled-button {
      display: block;
    }
  }
}