@import "src/style/variables";

.PopupHeader {
    .top-row {
        padding: $gutterMedium $gutterXLarge $gutterMedium $gutterXLarge;
        display: flex;
        border-bottom: 1px solid #eaeaea;
        margin-bottom: $gutterXLarge;
        align-items: center;
        
        .back-button {
            height: 24px;
            margin-right: $gutterSmall;
        }

        .label-component {
            flex-grow: 1;
            line-height: 24px;
        }

        .close-button {
            margin-left: $gutterSmall;

            &:hover {
                opacity: 0.75;
            }

            i {
                transition: color 0.3s;
                color: color(mid-grey);
                font-size: 12px;
            }

            &:hover {
                i {
                    color: color(dark-grey);
                }
            }
        }
    }

    .sub-text {
        padding: 0 $gutterXLarge $gutterXLarge $gutterXLarge;
    }
}

.dialogWithPopupHeader {
    .ms-Dialog-header, .ms-Dialog-subText {
        display: none;
    }

    .PopupHeader {
        margin: 0 calc(#{$gutterXLarge} * -1);
    }
}

.modalWithPopupHeader {
    .ms-Dialog-header, .ms-Dialog-subText {
        display: none;
    }

    .PopupHeader {
        margin: calc(#{$gutterXLarge} * -1) calc(#{$gutterXLarge} * -1) 0 calc(#{$gutterXLarge} * -1);
    }
}