@import "src/style/variables.scss";
.FormBirthday{
    .FormControl{
        margin-right: $gutterSmall;
    }
    
}
.FormControl {
    margin-bottom: $gutterMedium;
    border-radius: $borderRadius;

    > .label {
        align-items: center;
        display: flex;
        gap: $gutterSmall;
        justify-content: flex-start;
    }

    .ms-TextField-wrapper{
        ::after{
            border-radius: $borderRadius; 
        }
        .ms-TextField-fieldGroup{
            display: flex;
            align-items: center;
            min-height: 48px; // also used in multiline!!
            border-color: color("mid-grey");
            border-radius: $borderRadius; 
            textarea {
                margin-right: 4px;
                margin-bottom: 4px;
            }
        }
        .ms-TextField-field{
            padding: 12px;
            font-size: 12px;
            display: flex;
            align-items: center;
            outline: none;
        }
    }
    .control{
        .CustomInputWrapper{
            border: 1px solid color("mid-grey");
            border-radius: $borderRadius;
            display: flex;
            flex-grow: 1;
            min-height: 47px;
            align-items: center;
            padding-left: 12px;
        }
        .dateInput{
          border: none;
          outline: none;
        }
    }
    .ms-Checkbox{
        margin: 8px 0;
        border-radius: $borderRadius;
        .ms-Checkbox-checkbox{
            border-radius: $borderRadiusSharper;
            border-color: color("mid-grey");
        }
        .ms-Checkbox-text{
            font-size: 12px;
        }
    }

    .ms-TagItem{
        background-color: color("dusk-grey");
        .ms-Persona-initials {
            background-color: color("light-grey");
        }
        .ms-Persona-primaryText{
            color: color("dark-grey");
        }
    }

    &.inlineChildren {
        .control {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: $gutterSmall;
            
        }
    }
    .ms-Toggle-innerContainer{
        align-items: center;
    }

    .control-description {
        margin-bottom: $gutterSmall;
    }
}

.FormControl .spacingTop{
    margin-top: $gutterXSmall;
}

.ms-Checkbox-text, .ms-Label{
    font-family: $font;
}