@import "src/style/variables";

@media screen and (max-width: $vmPhoneLandscape) {
    .OptionsModal {
        .OptionsWrapper {
            background-color: #fff;
            width: 100% !important;
            padding: $gutterSmall;
            box-sizing: border-box;
        }

        .createButtons {
            flex-direction: column;
            width: 100%;
            align-self: center;

            .createButton {
                width: 100% !important;
                margin: $gutterSmall;
            }
        }
    }
}

.OptionsModal {
    .OptionsWrapper {
        background-color: #fff;
        width: 636px;
        padding: $gutterXLarge;
        padding-top: 0px;
    }

    .createButtons {
        display: flex;
        align-items: center;
        column-gap: $gutterXLarge;

        .createButton {
            padding: $gutterXLarge;
            border-width: 1px;
            border-color: color("dusk-grey");
            border-style: solid;
            height: auto;
            flex-grow: 1;
        }
    }
}

.blogs-post-edit-view {
    .pageHeaderImageWrapper {
        width: 100%;
    }

    .tinymce {
        >.ms-Label {
            font-weight: 500;
        }
    }

    .ms-Stack {
        padding-top: 0;
    }

    .textInput {
        margin-top: $gutterSmall;
        margin-bottom: $gutterMedium;
    }

    .side-header {
        color: color("dark-grey");
    }

    .edit {
        margin-top: calc(#{$gutterXSmall} * -1);
    }

    .attached-images {
        display: flex;
        flex-flow: wrap;

        .outer {
            position: relative;
        }

        .overlay {
            display: none;
        }
    }

    .outer:hover .overlay {
        border: 1px solid black;
        opacity: 0.7;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.7;
        top: 0;
    }

    .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
    }

    .images {
        >.control {
            .unstyled-button {
                margin-right: $gutterSmall;
                margin-bottom: $gutterSmall;
            }
        }
    }
}