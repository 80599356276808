@import "src/style/variables.scss";

.organisation-list-view {
    .heading {
        padding: 11px 8px 11px 12px;
        display: flex;
        align-items: center;

        .ms-Persona {
            margin-right: $gutterSmall;
        }
        
        .title {
            color: color("dark-grey");
            flex-grow: 1;
            .organisationTitle {
                margin: 4px 0 0 0;
            }
        }
        
        .icon {
            width: 40px;
            min-width: 40px;
            display: inline-block;
        }
    }
    .list-item {
        display: flex;
        align-items: center;
        padding-left: 52px;

        .ms-Persona {
            margin-right: $gutterSmall;
        }

        .title {
            color: color("dark-grey");
            flex-grow: 1;
            .organisationTitle {
                margin: 4px 0 0 0;
            }
        }
    }
   
}
