@use "sass:math";

@import "src/style/variables";

$dragHandleSize: 40px;
$dragHandleOffset: math.div($dragHandleSize, 2);
$negativeMargin: 0 - $dragHandleOffset;


.StartPageBuilderDivider {
    flex: 0 0 $dragHandleSize;
    margin: 0 $negativeMargin 0 $negativeMargin;
    position: relative;
    user-select: none;
    // renders over header when you scroll
    // z-index: 2;
    cursor: ew-resize;

    &.vertical {
        min-height: $dragHandleSize;
        margin: $negativeMargin 0 $negativeMargin 0;

        &:after {
            border-left: 0;
            border-top: 2px dashed color("neutralBlue");
            bottom: 0;
            left: 0;
            margin-left: 0;
            right: 0;
            top: 50%;
            width: 100%;
        }

        .drag-handle {
            left: calc(50% - $dragHandleOffset);
            top: 0;
        }
    }

    .drag-handle {
        background-color: #FFFFFF;
        display: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: $borderRadiusSharper;
        height: $dragHandleSize;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: calc(50% - $dragHandleOffset);
        width: $dragHandleSize;
        z-index: 4;
    }

    .drop-indicator {
        bottom: 0;
        display: none;
        margin-left: $dragHandleOffset - 1px;
        position: absolute;
        top: 0;
        width: 1px;

        &.current {
            border-left-color: color("visageGray");
        }
    }

    &:after { // Dividing line
        border-left: 2px dashed color("neutralBlue");
        content: ' ';
        display: none;
        margin-left: -1px;
        bottom: 0;
        left: 50%;
        position: absolute;
        right: 50%;
        top: 0;
        width: 1px;
        z-index: 3;
    }

    &:hover, &.dragging {
        .drag-handle {
            display: flex;
        }

        &:after {
            display: block;
        }
    }
}