@import "src/style/variables";

.Message {
    > .message-separator {
        padding: $gutterLarge;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .label-component {
            background-color: #FFF;
            padding: 0px $gutterMedium;
            z-index: 1;
        }

        .line {
            width: calc(100% - (#{$gutterMedium} * 2));
            margin: 0 $gutterMedium;
            height: 0px;
            border-bottom: 1px solid color(visageMidBlue);
            position: absolute;
            top: 50%;
        }
    }

    .inner {
        display: flex;
        flex-direction: column;
        padding: $gutterTiny $gutterSmall;
        row-gap: $gutterXSmall;
    }

    .content {
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;
        position: relative;

        .image-wrapper {
            width: 36px;
        }

        .middle {
            display: flex;
            flex-direction: column;
            row-gap: $gutterXSmall;
            max-width: 100%;
            box-sizing: border-box;
            // flex-grow: 1;
            // width: 0;

            .answer-text {
                display: flex;
            }

            .bubble-wrapper {
                display: flex;
                flex-direction: row;
                column-gap: $gutterSmall;
                max-width: 100%;
                box-sizing: border-box;
        
                .bubble {
                    border-radius: 30px;
                    padding: 9px;
                    max-width: 100%;
                    box-sizing: border-box;
                    position: relative;
                }

                .MessageActions {
                    opacity: 0;
                    pointer-events: none;
                }
            }

            &:hover, &.active {
                .bubble-wrapper {
                    .MessageActions {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }
        }
    }

    &.memo {
        .bubble {
            min-width: 200px;
        }
    }

    &.has-reactions {
        .middle {
            margin-bottom: $gutterMedium;
        }
    }

    &.left {
        .content {
            .bubble-wrapper {
                .bubble {
                    background-color: color(visage2LightGray);
                    border-radius: 4px 20px 20px 4px;
                }
            }
        }
    }

    &.top {
        .inner {
            padding-top: $gutterSmall;
            max-width: 100%;
            box-sizing: border-box;
            
            .content {
                .bubble-wrapper {
                    .bubble {
                        border-top-left-radius: 20px;
                    }
                }
            }
        }
    }

    &.bottom {
        .inner {
            padding-bottom: $gutterSmall;

            .content {
                .bubble-wrapper {
                    .bubble {
                        border-bottom-left-radius: 20px;
                    }
                }
            }
        }
    }

    &.right {
        .content {
            justify-content: flex-end;

            .answer-text {
                align-self: flex-end;
            }
    
            .bubble-wrapper {
                flex-direction: row-reverse;
    
                .bubble {
                    background-color: color(visageMidBlue);
                    color: #FFF;
                    border-radius: 20px 4px 4px 20px;

                    .MemoHeader {
                        .Visage2Icon {
                            svg {
                                path {
                                    fill: #FFF;
                                }
                            }
                        }
                    }

                    a {
                        color: #FFF;
                        font-weight: 550;
                    }

                    .MessageTodoList {
                        .Visage2Icon {
                            svg {
                                path {
                                    fill: #FFF;
                                }
                            }
                        }
                    }

                    .MessageReactions {
                        right: 0px;
                        left: auto;
                    }
                }
    
                .MessageActions {
                    flex-direction: row-reverse;
                }
            }
        }

        &.top {
            .inner {
                padding-top: $gutterSmall;

                .content {
                    .bubble-wrapper {
                        .bubble {
                            border-top-right-radius: 20px;
                        }
                    }
                }
            }
        }
    
        &.bottom {
            .inner {
                padding-bottom: $gutterSmall;

                .content {
                    .bubble-wrapper {
                        .bubble {
                            border-bottom-right-radius: 20px;
                        }
                    }
                }
            }
        }
    }

    &.single-attachment {
        .bubble {
            padding: 0px !important;
            background-color: transparent !important;

            .MessageAttachments, .MessageObjects {
                img {
                    width: 150px;
                    height: 111px;
                }
            }
        }
    }
}
