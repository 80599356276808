@import "src/style/variables";

$footerHeight: 48px;

.StartPageBuilderFooter {
    display: flex;
    height: $footerHeight;
    line-height: $footerHeight;
    padding: $gutterMedium;
    z-index: 4;

    .text {
        line-height: $footerHeight;
    }

    .left, .right {
        flex: 50% 1 1;
    }

    .right {
        text-align: right;

        .cancel-button {
            color: color("neutralBlue");
            margin-right: $gutterXXLarge;
        }
    }

    .left {
        padding: 6px 0;

        .theme-selector {
            .ms-Dropdown-container {
                display: flex;

                .ms-Dropdown-label {
                    color: color("visageGray3");
                    font: 550 14px "Eloquia", sans-serif;
                    line-height: 25px;
                    margin-right: $gutterSmall;
                }

                .ms-Dropdown {
                    min-height: 35px;
                    height: 35px;
                    width: 200px;

                    span {
                        border-color: #D9D9D9;
                        line-height: 35px;
                        min-height: 35px;
                    }

                    .ms-Dropdown-title {
                        color: color("neutralBlue");
                        font-weight: 550;
                        line-height: 35px;
                    }

                    .ms-Dropdown-caretDownWrapper {
                        border-left: 1px solid #D9D9D9;
                        padding-left: 8px;
                        border-radius: 0;
                        height: 31px;
                    }
                }
            }
        }
    }
}
