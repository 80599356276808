@import "src/style/variables";

.FilterButtons {
    display: flex;
    background-color: color(visage2LightGray);
    border-radius: $borderRadius;
    position: relative;

    .tabs {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        z-index: 1;

        .tab {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-grow: 1;
            column-gap: $gutterXSmall;
            padding: $gutterSmall;
            width: 0;
            align-items: center;
        }
    }

    .indicator {
        position: absolute;
        height: 100%;
        transition: left 0.25s;

        .indicator-inner {
            border-radius: $borderRadius;
            background-color: #FFF;
            width: calc(100% - (#{$gutterTiny} * 2));
            height: calc(100% - (#{$gutterTiny} * 2));
            margin: $gutterTiny;
            box-shadow: 0px 2px 4px rgba(16, 24, 40, 0.12);
        }
    }
}
