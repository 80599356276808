@import "src/style/variables.scss";

.RightColumnDashboard {
    > .widget-header {
        margin: 8px 0 8px 0;
    }
    .DashboardCategory-content {
        background-color: #FFF;
        border-radius: $borderRadius;

        .DashboardItem {
            padding: $gutterSmall $gutterMedium;
            border-bottom: 1px solid #FFF;

            &.type1 {
                .PieWrapper {
                    text-align: center;
                    margin-top: $gutterSmall;
                }
            }

            &.type2 {
                .DashboardSubItem {
                    margin-top: $gutterSmall;
                    margin-bottom: $gutterSmall;

                    > .DashboardSubItem-left, >.DashboardSubItem-right {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    > .DashboardSubItem-left {
                        width: calc(100% - 47px);

                        .ms-Persona {
                            height: auto !important;
                            max-height: none !important;
                        }

                        .ms-Persona-secondaryText {
                            white-space: normal;
                            text-overflow: unset;
                            overflow: unset;
                        }
                    }

                    > .DashboardSubItem-right {
                        width: 47px;
                        text-align: right;
                    }
                }
            }

            &.type3 {
                .Numbers {
                    > div, p {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    &.isInHeader {
        max-height: 50px;
        position: relative;
        color: #f6e4c2;
        margin-right: $gutterMedium;
        white-space: nowrap;

        .DashboardCategory {
            .widget-header {
                > * {
                    font-size: 10px;
                    margin: 0px;
                    margin-bottom: 2px;
                }
            }

            .DashboardCategory-content {
                border-radius: 0px;
                background-color: transparent;
                display: flex;
                flex-direction: row;
                column-gap: $gutterMedium;

                .DashboardItem {
                    white-space: nowrap;
                    display: inline-block;
                    padding: 0px;
                    border: 0;

                    .Numbers {
                        .fs-h2 {
                            margin-left: 0px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}