@import "src/style/variables.scss";

$imageSize: 36px;

#ProductsListView {
    .product-image {
        align-items: center;
        border-radius: $borderRadiusSharp;
        display: flex;
        height: $imageSize;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: $imageSize;

        img {
            max-width: 100%;
            object-fit: contain;
        }
    }
}