@import "src/style/variables.scss";

.ScaleTextFontSize {
    position: relative;

    .ScaleTextFontSize-fake {
        position: absolute;
        opacity: 0;
    }
}
