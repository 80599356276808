@import "src/style/variables";

.SimpleFile {
    background-color: color(light-grey);
    padding: $gutterSmall;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: $borderRadiusSharper;

    .icon-wrapper {
        margin-right: $gutterXSmall;
    }

    .label-component {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .remove-button {
        margin-left: $gutterXSmall;
        margin-right: -6px;
    }
}

.SimpleFiles {
    display: flex;
    flex-wrap: wrap;
    row-gap: $gutterSmall;
    column-gap: $gutterSmall;
    margin-bottom: $gutterSmall;
}