@import "src/style/variables";

.module,
.RightColumn-widgets > div {
    margin-top: $gutterMedium;
    padding: $gutterMedium $gutterMedium $gutterMedium 0;
    border: color(visage2LightGray) 1px solid;
    border-radius: $gutterSmall;
}

.row-layout {
    display: flex;
    .shortcuts-column {
        margin-top: $gutterSmall;
    }
}
.separator {
    margin: $gutterSmall $gutterSmall $gutterSmall $gutterSmall;
    border: color(visage2LightGray) 1px solid;
    border-radius: $gutterSmall;
    height: 100%;
}
.topContent {
    background-color: #fff;

    .search {
        display: flex;
        align-items: baseline;

        .addButton {
            color: color("mid-grey");
            margin-left: $gutterMedium;
        }

        .SpintrSearch {
            display: flex;
            width: 100%;
            margin: 0;
        }
    }
}

.collapsable365 {
    margin-bottom: $gutterMedium;

    .collapse-menu {
        padding: $gutterMedium $gutterMedium $gutterMedium 0;
        border: color(visage2LightGray) 1px solid;
        border-radius: $gutterSmall;

        .collapse-menu-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .div-left {
                display: flex;
                align-items: center;
                margin-left: $gutterMedium;
            }

            // &:hover {
            //     background-color: #f2f2f2;
            // }
        }

        .officeShortcuts {
            margin-top: $gutterSmall;

            a {
                padding-top: $gutterXSmall;
                padding-bottom: $gutterXSmall;
                // padding-left: $gutterSmall;
                // padding-right: $gutterSmall;
                margin-left: 8px;
                padding-left: 8px;
                display: flex;
                color: color("dark-grey");
                border-radius: $borderRadiusSharper;

                .nameWrapper {
                    display: inline-block;
                    vertical-align: middle;
                    width: calc(100% - 14px);

                    > div {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }

                .addButton {
                    display: inline-block;
                    background-color: #e4e6eb;
                    width: 20px;
                    height: 20px;
                    border-radius: 100px;
                    text-align: center;
                    align-self: center;

                    svg {
                        width: 11px;
                        fill: #3f4692;
                    }
                }

                &:hover {
                    background-color: #f2f2f2;
                    // color: color("light-blue");

                    // .addButton {
                    //     color: color("light-blue");
                    // }
                }

                .officeShortcutImage {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    margin-right: $gutterSmall;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

.subHeaderLabel {
    margin-bottom: $gutterXSmall;
    margin-top: $gutterTiny;
    margin-left: $gutterMedium;
}

.prioritized {
    .prioritizedShortcut,
    .linkItem {
        display: flex;
        // margin-left: 0;
        border-radius: 5px;
        height: 48px;
        margin-left: 8px;
        padding-left: 8px;

        .ms-Persona-details {
            padding-right: 0;
        }

        .ms-Persona,
        .edit {
            border-radius: $borderRadius;
        }

        .edit {
            align-items: center;
            height: 42px;
            display: flex;
            visibility: hidden;
            background-color: #f2f2f2;
            padding-top: 3px;
            padding-bottom: 0px;
            padding-right: 12px;
            padding-left: 12px;

            #lottie {
                width: 28px;

                path {
                    fill: #727e94 !important;
                    stroke: #727e94 !important;
                }
            }

            &:hover {
                #lottie {
                    path {
                        fill: color("dark-grey") !important;
                        stroke: color("dark-grey") !important;
                    }
                }
            }
        }

        &:hover .edit {
            visibility: visible;
        }

        &:hover .ms-Persona {
            background-color: #f2f2f2;
        }

        &:hover {
            background-color: #f2f2f2;
        }
    }

    .ms-Persona {
        flex-grow: 1;
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

.normalShortcuts {
    margin-top: 12px;

    .normalShortcuts-inner {
        margin-left: 0;

        .nonHighlightedShortcut {
            height: 40px;
            margin-left: 8px;
            padding-left: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .shortcutLink {
                color: color("dark-grey");
                align-self: center;
                width: 100%;

                .label-component {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            > *,
            .edit {
                border-radius: $borderRadiusSharper;
            }

            .edit {
                visibility: hidden;
                align-items: center;
                height: 34px;
                display: flex;
                background-color: #f2f2f2;
                padding-top: 3px;
                padding-bottom: 0px;
                padding-right: 12px;
                padding-left: 12px;

                #lottie {
                    width: 28px;

                    path {
                        fill: #727e94 !important;
                        stroke: #727e94 !important;
                    }
                }

                &:hover {
                    #lottie {
                        path {
                            fill: color("dark-grey") !important;
                            stroke: color("dark-grey") !important;
                        }
                    }
                }
            }

            &:hover .edit {
                visibility: visible;
            }

            &:hover {
                background-color: #f2f2f2;
                border-radius: $borderRadiusSharper;
            }
        }
    }
}

.shortcutsEditMode {
    padding-top: $gutterMedium;
}

.favorites {
    margin-top: 12px;
    padding: $gutterMedium $gutterMedium $gutterMedium 0;
    border: color(visage2LightGray) 1px solid;
    border-radius: $gutterSmall;

    .favorite {
        align-items: center;
        margin-left: 8px;
        padding: $gutterXSmall $gutterXSmall $gutterXSmall 8px;
        border-radius: $borderRadiusSharper;

        .label-component {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        > * {
            display: inline-block;
            vertical-align: middle;
        }

        &:hover {
            background-color: #f2f2f2;
        }

        .link {
            width: calc(100% - 24px - #{$gutterMedium});
            margin-right: $gutterMedium;
        }

        .deleteFavorite {
            visibility: hidden;
            align-items: center;
            height: 24px;
            width: 24px;
            background-color: #f2f2f2;
            border-radius: $borderRadius;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;

            #lottie {
                width: 28px;

                path {
                    fill: #727e94 !important;
                    stroke: #727e94 !important;
                }
            }

            &:hover {
                #lottie {
                    path {
                        fill: color("dark-grey") !important;
                        stroke: color("dark-grey") !important;
                    }
                }
            }

            .lf-player-container {
                margin-top: -6px;
            }
        }

        > .ms-TooltipHost {
            height: 24px;
        }

        &:hover .deleteFavorite {
            visibility: visible;
        }
    }

    .link {
        flex-grow: 1;
        color: color("dark-grey");
        align-self: center;
    }
}

.links {
    .shortcuts-app-link {
        display: block;
        margin-left: 8px;
        padding-left: 8px;

        .link {
            margin: 6px;
        }

        > * {
            display: inline-block;
            vertical-align: middle;
            color: color("dark-grey");

            &:hover {
                background-color: #f2f2f2;
                border-radius: $borderRadiusSharper;
            }
        }
    }
}

@media screen and (min-width: 390px) {
    .column-layout {
            .teaser-box-wrapper {
                flex-grow: 0;
            }
    }
}
