@import "src/style/variables.scss";

$spotlightItemHeight: 289px;

#news-spotlight {
    box-sizing: border-box;
    margin-bottom: $gutterSmall;
    border-radius: $borderRadius;
    background-color: #FFF;
    height: $spotlightItemHeight;

    .left, .right, .play {
        border-radius: 100%;
        position: absolute;
        color: #fff;
        background-color: rgba(160, 160, 160, 0.4);
        text-align: center;
        margin: $gutterMedium;
        bottom: calc((#{$gutterTiny} * 2) + #{$gutterMedium});
        box-sizing: border-box;
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;

        svg {
            display: block;
        }
        
        &:hover {
            opacity: 0.75;
        }
    }

    .play {
        right: calc(((#{$gutterSmall} * 2) + 16px + $gutterSmall) * 2);
    }

    .left {
        right: calc((#{$gutterSmall} * 2) + 16px + $gutterSmall);
    }

    .right {
        right: 0px;
    }

    .index {
        position: absolute;
        top: $gutterMedium;
        left: $gutterMedium;
    }

    .CommentPreview {
        margin-right: 153px;
        height: 16px;
    }
}

.news-spotlight-placeholder {
    background: #EBEDEF;
    border-radius: 20px;
    text-align: center;
    padding: $gutterXXLarge;

    .placeholder-name {
        line-height: 32px;
    }
}