@import "src/style/variables";

.AcademyCourseSidebar {
    .AcademyCourseSidebarSection {
        margin-bottom: $gutterMedium;
        background-color: #fff;
    }
}

.isSmallViewMode {
    .AcademyCourseSidebar {
        margin-top: $gutterMedium;

        .AcademyCourseSidebarSection {
            border: 0;
        }
    }
}