@import "src/style/variables.scss";

.FileBrowser {
    > .header {
        border-bottom: 1px solid #eaeaea;
    }

    .body {
        .ms-DetailsList {
            .ms-DetailsRow {
                .preview-image,
                .icon-wrapper {
                    margin-right: $gutterSmall;
                    vertical-align: middle;
                    color: color("dark-grey");

                    svg {
                        display: block;
                    }
                }

                .preview-image {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-position: center;
                    background-size: center;
                    vertical-align: middle;
                }

                .ms-TextField {
                    display: inline-block;
                    vertical-align: middle;
                }

                .icon-wrapper {
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 2.375em !important;

                    > i {
                        font-size: 18px;
                        margin-top: -2px;
                    }
                }

                a {
                    vertical-align: middle;
                }
                .ms-Checkbox {
                    vertical-align: middle;
                    display: inline-block;
                    visibility: hidden;

                    &.is-checked {
                        visibility: visible;
                    }
                }

                &:hover {
                    .ms-Checkbox {
                        visibility: visible;
                    }
                }
            }
        }

        .spintr-list {
            .error {
                padding: $gutterLarge;
            }
        }
    }
}
