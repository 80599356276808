@import "src/common.scss";

.GroupTemplateDrawer {
    .close-button svg {
        transform: rotate(45deg);
    }

    .GroupTemplateDrawer-body { 
        background: linear-gradient(180deg, rgba(255,255,255,0) 90%, rgba(0,0,0,0.05) 100%);
        position: relative;
    }
    
    .GroupTemplateDrawer-footer {
        background-color: $spintrWhite;
        justify-content: space-between;

        .GroupTemplateDrawer-saveAction {
            display: flex;
            gap: $spacingLarge;

            .GroupTemplateDrawer-button {
                border-radius: $brNormal !important;
            }
        }
    }
}