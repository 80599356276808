@import "src/style/variables.scss";

.ReactionPicker {
    display: block;
    background-color: #FFF;
    position: absolute;
    border-radius: 36px;
    z-index: 1;
    white-space: pre;
    //padding: $gutterTiny;
    z-index: 7;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    //border: 1px solid red;
    height: 36px;

    > .unstyled-button {
        display: inline-block;
        vertical-align: middle;
        height: 36px;
        position: relative;
        text-align: center;

        .lf-player-container {
            height: 36px;
            width: 36px;
            transition: all 0.2s;
        }

        .reaction-tooltip {
            display: none;
            position: absolute;
            top: -38px;
            left: -22px;
            right: -22px;
            text-align: center;
            overflow: visible;

            > .label-component {
                display: inline-block;
                border-radius: $borderRadiusRounder;
                color: #FFF !important;
                background-color: color("dark-grey");
                padding: $gutterTiny $gutterXSmall;
                line-height: normal !important;
                margin-top: 0px !important;
            }
        }
        
        img {
            height: 40px;
            width: 40px;
            transition: transform 0.25s;
            padding: $gutterTiny;
            margin-right: 0px;
            margin: -5px -6px -3px;
            display: block;

            svg {
                display: block;
            }
        }
        
        &:first-child {
            margin-left: 6px;
        }

        &:last-child {
            margin-right: 2px;
        }

        &:hover {
            .reaction-tooltip {
                display: block;
            }

            .lf-player-container {
                transform: scale(1.20) translateY(-5px);
                margin-bottom: $gutterXSmall;
            }
        }

        &.LikeType0 {
            img {
                -webkit-animation: reactionAnimation 0.1s;
                -moz-animation: reactionAnimation 0.1s;
                -ms-animation: reactionAnimation 0.1s;
                -o-animation: reactionAnimation 0.1s;
                animation: reactionAnimation 0.1s;
            }
        }
        &.LikeType1 {
            img {
                -webkit-animation: reactionAnimation 0.2s;
                -moz-animation: reactionAnimation 0.2s;
                -ms-animation: reactionAnimation 0.2s;
                -o-animation: reactionAnimation 0.2s;
                animation: reactionAnimation 0.2s;
            }
        }
        &.LikeType2 {
            img {
                -webkit-animation: reactionAnimation 0.3s;
                -moz-animation: reactionAnimation 0.3s;
                -ms-animation: reactionAnimation 0.3s;
                -o-animation: reactionAnimation 0.3s;
                animation: reactionAnimation 0.3s;
            }
        }
        &.LikeType3 {
            img {
                -webkit-animation: reactionAnimation 0.4s;
                -moz-animation: reactionAnimation 0.4s;
                -ms-animation: reactionAnimation 0.4s;
                -o-animation: reactionAnimation 0.4s;
                animation: reactionAnimation 0.4s;
            }
        }
        &.LikeType4 {
            img {
                -webkit-animation: reactionAnimation 0.5s;
                -moz-animation: reactionAnimation 0.5s;
                -ms-animation: reactionAnimation 0.5s;
                -o-animation: reactionAnimation 0.5s;
                animation: reactionAnimation 0.5s;
            }
        }
    }

    &:not(:hover) {
        &.hidden {
            opacity: 0;
            left: -2000px !important;
            top: -2000px !important;
        }
    }
}

@keyframes reactionAnimation {
    0% { transform: translateY(0) }
    50% { transform: translateY(-35%) }
    100% { transform: translateY(0) }
}