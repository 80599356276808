@import "src/style/variables";

.TimeoutInformation {
    box-sizing: border-box;
    margin: $gutterLarge auto;
    padding: $gutterSmall;
    text-align: center;
    width: 70%;

    .icon {
        font-size: 48px;
        margin-bottom: $gutterLarge;
    }

    .ReloadButton {
        margin-top: $gutterLarge;
    }
}