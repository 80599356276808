@import "src/style/variables";

.VisageGradient {
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: 0;
    left: 0px;
    overflow: hidden;
    -webkit-animation: loaderFadeIn 1s;
    -moz-animation: loaderFadeIn 1s;
    -ms-animation: loaderFadeIn 1s;
    -o-animation: loaderFadeIn 1s;
    animation: loaderFadeIn 1s;
}
