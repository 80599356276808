@import "src/style/variables";

.AppPageBox {
    .box {
        border-radius: 10px;
        color: #FFFFFF;
        display: block;
        padding: $gutterXLarge 0;
        text-align: center;
    }

    div.box {
        cursor: default;
    }

    a.box {
        cursor: pointer;

        &:hover {
            opacity: 0.85;
        }
    }
}
