@import "src/style/variables.scss";

.wiki-structure {
    .draggable-item {
        padding: 8px;
        background-color: color("light-grey");
        margin-bottom: 1px;

        .label-component {
            margin-bottom: 0 !important;
        }

        > * {
            vertical-align: middle;
        }
    }
}
