@import "src/style/variables";

.NewsLayoutSettings {
    .information {
        padding-bottom: $gutterMedium;
    }

    .template-grid {
        max-width: 472px;
        padding-top: $gutterMedium;

        .heading {
            margin-bottom: $gutterSmall;
        }

        .templates {
            display: flex;
            gap: $gutterLarge;
            flex-direction: row;
            flex-wrap: wrap;

            .template {
                flex: 226px 0 0;
                width: 226px;
            }
        }
    }
}