.unstyled-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    outline: none;

    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    &.inline {
      display: inline-block;
    }

    &.disabled {
      cursor: default;
    }
}

.unstyled-button :focus {
  outline: none;
}