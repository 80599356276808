@import "src/style/variables";

.shortcuts {
    position: relative;

    .eachShortcutContainer {
        border-bottom: 1px solid rgb(138, 136, 134);
        justify-content: center;
        align-items: center;
        align-content: center;
        max-width: 260px;
        width: 100%;
        border-bottom: none;
        margin-bottom: 2px;

        span .textTargets {
            display: none;
        }

        .textAudience {
            display: none;
        }

        .textAudience + .textAudience::before {
            display: inline-block;
            white-space: pre;
            content: ", ";
        }

        .textEachShortcut {
            width: 100%;
            font-size: 14px;
            cursor: pointer;

            a {
                color: color(dark-grey);
            }
        }

        .textTargets {
            margin-top: -4px;
        }

        .eachShortcut {
            justify-content: center;
            align-items: center;
            align-content: center;
            max-width: 260px;
            max-height: 500px;
            width: 100%;
            position: relative;
            background: color("light-grey");
            -webkit-transition: background-color $fastTransition;
            -moz-transition:  background-color $fastTransition;
            -o-transition:  background-color $fastTransition;
            transition:  background-color $fastTransition;

            a {
                &:hover{
                    text-decoration: none;
                }
            }

            background: color("light-grey");
            padding: $gutterSmall $gutterMedium;
            border-bottom: none;
            box-sizing: border-box;

            &:hover {
                background: color("dusk-grey");
            }

            .textEachShortcut {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: calc(100% - 20px);

                a {
                    max-width: 100%;
                }
            }

            .button-right {
                position: absolute;
                right: $gutterMedium;
                top: 1px;

                i {
                    color: color(dark-grey);
                }
            }
        }

        .add-shortcut {
            max-width: 240px;
            max-height: 400px;
            margin-bottom: 20px;
        }

        .page-margin-bottom {
            margin-bottom: 20px;
        }
    }

    .actionmenu-component {
        position: absolute;
        top: -$gutterTiny;
        right: $gutterXSmall;
    }
    .textNoShortcut{
        background-color: color("light-grey");
        padding: $gutterMedium;
        text-align: center;
    }
}
