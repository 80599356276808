@import "src/style/variables";

.SpintrHeaderWrapper {
    height: $spintrHeaderHeight;
    display: flex;
    position: relative;

    .SpintrHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        margin: 0px $gutterXXLargeAlt;

        .header-background {
            position: absolute;
            height: calc(100% + #{$borderRadius});
            width: 100%;
            top: 0px;
            left: 0px;
            z-index: -1;
        }

        .logo-wrapper {
            a {
                display: block;
                
                img {
                    display: block;
                    max-height: 58px;
                    max-width: 175px;
                }
            }
        }

        .SpintrHeaderMenu {
            margin: 0 $gutterXXXLarge;
        }

        .spacer {
            flex-grow: 1;
        }

        .externalUser {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .SpintrHeaderWrapper {
        .SpintrHeader {
            .SpintrHeaderMenu {
                margin: 0 $gutterLarge 0 $gutterXXLargeAlt;
            }
        }
    }
}
