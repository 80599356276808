@import "src/style/variables.scss";

.SocialSystemStatus {
    margin-bottom: $gutterXSmall;
    display: flex;
    flex-direction: row;
    column-gap: $gutterMedium;
    row-gap: $gutterXSmall;
    flex-wrap: wrap;

    > div {
        display: flex;
        flex-direction: row;
        column-gap: $gutterXSmall;
        align-items: center;

        .label-component {
            white-space: pre;
        }
    }
}