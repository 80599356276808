@import "src/style/variables";

.AcademyImage {
    border-radius: $borderRadiusSharp;
    overflow: hidden;

    padding-top: 33.3%;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .Visage2Icon {
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
    }
}
