@import "src/style/variables.scss";

.TokenizedInputSuggestion {
    &.selected,
    &:hover {
        .item {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: $borderRadius;
        }
    }

    .item {
        cursor: pointer;
        padding: 4px $gutterXSmall;

        .image,
        .text {
            display: inline-block;
            vertical-align: middle;
        }

        .image {
            margin-right: $gutterXSmall;
        }
    }
}