@import "src/style/variables";

.AcademyCourseSidebarProgressCircle {
    width: $circleLarge;
    height: $circleLarge;
    border-radius: $circleLarge;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: color(visage2LightGray);

    &.done {
        background-color: #D9FFED;
    }

    &.error {
        background-color: #FFD9D9;
    }

    .chart-wrapper {
        height: 16px;
        width: 16px;
    }
}
