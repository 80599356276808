@import "src/common.scss";

.AcademyView {
    .pageHeaderImageWrapper {
        margin-bottom: $spacingLarge;
    }

    .SpintrHelpText {
        margin-bottom: $spacingLarge;
    }
}