@import "src/common.scss";

.PlannerItemDetailDrawer {
    .PlannerItemDetailDrawer-header {
        .PlannerItemDetailDrawer-header-circle {
            border-radius: 50%;
            display: inline-block;
            height: 24px;
            width: 24px;
            margin-right: $spacingMedium;
        }
        .actions {
            display: flex;
            gap: $spacingLarge;
        }
    }

    .PlannerItemDetailDrawer-body {
        padding-top: 0;
    }
}