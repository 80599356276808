@import "src/style/variables";

.AcademyCourseMetaDataRow {
    margin-top: $gutterSmall;
    display: flex;
    flex-direction: row;
    column-gap: $gutterSmall;

    > div {
        display: flex;
        flex-direction: row;
        column-gap: $gutterTiny;
        align-items: center;
    }

    .AcademyCourseCategories {
        margin-bottom: 0px !important;
    }
}
