@import "src/style/variables";

.course-review-modal {
    .ms-Dialog-main {
        padding: 0px;

        .AcademyCourseDoneView {
            position: relative;
            padding-bottom: 0px !important;

            .background {
                position: absolute;
                max-width: 100%;
                height: 100%;

                img {
                    max-width: 100%;
                }

                .type-content-confetti {
                    top: 0px;
                    z-index: 0;
                }
            }

            .content {
                padding: $gutterMedium;
                text-align: center;
                z-index: 1;
                position: relative;

                .image-wrapper {
                    margin-top: $gutterXXLarge;
                    margin-bottom: $gutterXXLarge;

                    .emoji {
                        height: 56px;
                        width: 56px;
                        display: inline-block;

                        >div {
                            svg {
                                width: 56px;
                                height: 56px;
                            }
                        }
                    }

                    &.multiple {
                        .emoji {
                            &:first-child, &:last-child {
                                height: 48px;
                                width: 48px;
                                position: relative;
                                top: $gutterXSmall;

                                >div {
                                    svg {
                                        width: 48px;
                                        height: 48px;
                                    }
                                }
                            }

                            &:nth-child(2) {
                                background-color: #FFF;
                                border-radius: 100%;
                                padding: $gutterTiny;
                                margin-left: -10px;
                                margin-right: -10px;
                                z-index: 1;
                                position: relative;
                            }
                        }
                    }
                }

                .congratulations-title {
                    margin-bottom: $gutterSmall;
                }

                .congratulations-sub {
                    margin-bottom: $gutterXXLarge;
                }

                .FormSection {
                    margin-top: $gutterMedium;
                }
            }
            
            .footer {
                padding: $gutterMedium;
                display: flex;
                flex-direction: row;
                column-gap: $gutterMedium;
                justify-content: flex-end;
            }
        }
    }
}