@import "src/style/variables";

#ResponsiveMenu {
    z-index: $spintrHeaderZIndex + 1;

    .overlay {
        background-color: rgba(0, 0, 0, 0.3);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $spintrHeaderZIndex + 2;
    }

    .menu, .menu > .header, .menu .body {
        background-color: #FFF;
        left: 0;
        max-width: 90%;
        position: fixed;
        top: 0;
        transition: left ease-in-out 0.2s;
        width: 335px;
    }

    &.hidden {
        .overlay {
            bottom: 100%;
        }

        .menu, .menu > .header, .menu .body {
            left: -335px;
        }
    }

    .menu {
        bottom: 0;
        z-index: $spintrHeaderZIndex + 3;

        > .header {
            border-bottom: 1px solid color("smoke");
            box-sizing: border-box;
            display: flex;
            height: 63px;
            line-height: 23px;
            padding: $gutterLarge $gutterMedium;

            .intranet-title {
                flex: 1 1 calc(50% - 12px - #{$gutterSmall / 2});
            }

            .close-button {
                flex: 0 0 23px;
                margin-right: $gutterSmall;
            }

            .operating-info {
                flex: 1 0 141px;
                display: flex;
                height: 20px;
                align-items: center;
                // margin-top: 3px;

                .label-component {
                    // line-height: 30px;
                }
            }
        }

        .body {
            bottom: 0;
            overflow: scroll;
            top: 64px;

            .drawer {
                border-bottom: 1px solid color("smoke");
                padding-top: $gutterLarge;

                .row {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: $gutterLarge;
                    gap: $gutterMedium;
                    flex-wrap: wrap;

                    .column {
                        flex: 3;
                        text-align: center;
                        min-width: 25%;

                        .link {
                            display: block;
                        }

                        .icon, .text {
                            color: color("dark-grey");
                        }

                        .icon {
                            display: inline-block;
                            font-size: 23px;
                            line-height: 30px;
                            padding-bottom: $gutterXSmall;
                            position: relative;

                            .unread {
                                border-radius: 50%;
                                color: #FFF;
                                display: inline-block;
                                height: 16px;
                                font-size: 10px;
                                line-height: 16px;
                                position: absolute;
                                right: -8px;
                                top: -4px;
                                width: 16px;
                            }
                        }
                    }
                }
            }

            .menu-items {
                border-bottom: 1px solid color("smoke");
                padding: $gutterXSmall 0;

                ul,
                li {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
                
                .list {
                    li {
                        a {
                            color: #000;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: $gutterSmall $gutterMedium;
                            position: relative;

                            .chevron-icon {
                                position: absolute;
                                right: $gutterMedium;
                            }
                        }
                    }
                }
            }
        }
    }
}

.MAC {
    #ResponsiveMenu {
        .operating-info {
            .label-component {
                // line-height: 32px !important;
            }
        }
    }
}