@import "src/style/variables.scss";

.newsPreamble {
    margin-bottom: $gutterXXLarge;
    margin-top: $gutterMedium;
    display: block;
}

.NewsArticleView {
    // .pageHeaderImageWrapper.appMode {
    //     width: calc(100vw + 1px) !important;
    //     margin-left: -$gutterMedium;
    //     margin-right: -$gutterMedium;
    //     margin-top: -12px;
    //     margin-bottom: 0px;
    //     max-width: 1300px !important;
    // }

    .headerWrapper {
        margin-top: $gutterMedium !important;
    }

    .pageHeaderImage {
        border-radius: 0px !important;

        >img {
            border-radius: 0px !important;
        }
    }

    .pageInfoPanel.smallViewMode {
        display: block;
    }

    .pageHeader-title {
        .fs-h1 {
            font-size: 48px !important;
            font-weight: 550;
            margin-top: $gutterXXLarge;
            line-height: 57px;
        }
    }

    .pageHeaderImage {
        img {
            width: 100%;
        }
    }

    .translate-button {
        margin-top: 6px;
    }
}