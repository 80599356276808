@import "src/style/variables.scss";

.CommentPreview {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-grow: 1;
    overflow: hidden;

    // .commentor-image, .comment-text {
    //     display: inline-block;
    //     vertical-align: middle;
    // }

    .commentor-image {
        margin-right: calc(#{$gutterXSmall} + #{$gutterTiny});
    }

    .comment-text {
        font-weight: normal;
        display: inline;
    }
}