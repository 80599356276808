@import "src/style/variables.scss";

.SmallCTAButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    border: 1px solid color(borderColor);
    border-radius: $borderRadiusSharp;
    padding: $gutterTiny $gutterXSmall;
    column-gap: $gutterTiny;

    &:hover {
        border-color: #A7A7B5;
    }
}