@import "src/style/variables.scss";

.spotlight-item {
    .InteractionsBar {
        margin: 0;

        .use-white-text {
            .shareButton {
                .label-component {
                    color: #FFF;
                }

                .Visage2Icon {
                    * {
                        fill: #FFF;
                    }
                }
            }
        }
    }

    background-color: color("light-grey");
    color: white;
    display: block;
    height: 289px;
    position: relative;
    border-radius: $borderRadius;
    overflow: hidden;

    .spotlight-item-date {
        margin-bottom: $gutterXSmall;
    }

    .spotlight-item-title {
        margin: 0;
    }

    .background-image {
        height: 100%;
        width: 100%;
        border-radius: $borderRadius;
        position: relative;
    }

    .spotlight-gradient {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
        height: 100%;
        box-sizing: border-box;
        width: 100%;
        border-radius: $borderRadius;
    }

    .spotlight-item-content {
        bottom: $gutterMedium;
        left: $gutterMedium;
        right: $gutterMedium;
        position: absolute;

        .spotlight-item-text {
            &.translationEnabled {
                margin: 4px 0 0 0 !important;
            }
        }

        p {
            margin: 4px 0 $gutterMedium 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .CommentPreview {
            //width: calc(100% - 100px);
            vertical-align: 0px;

            .comment-text {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: calc(100% - 30px);
                display: inline-block;
            }
        }
    }

    &:not(.use-white-text) {
        color: #000 !important;
    }

    .use-white-text {
        .InteractionsBar {
            .LikeContainer {
                &:not(.has-liked) {
                    .Visage2Icon {
                        * {
                            fill: #FFF;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1550px) {
    #news-spotlight {
        height: calc(300px - (#{$gutterMedium} * 2));

        .spotlight-item-title {
            font-size: 16px;
        }

        .spotlight-item-text {
            font-size: 12px;
        }
    }

    .spotlight-item {
        height: calc(300px - (#{$gutterMedium} * 2));
    }
}