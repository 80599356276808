@import "src/style/variables.scss";

.CommentCreator {
    margin-top: $gutterMedium;
    position: relative;

    >div {
        display: flex;
        flex-direction: row;

        .commentor-image {
            margin-right: $gutterSmall;
            margin-top: 8px;

            .ms-Persona-coin {
                margin-top: 0px !important;
            }
        }

        .commentor-right {
            flex-grow: 1;
            min-height: 42px;
            border: 1px solid color(borderColor);
            border-radius: $borderRadius;
            max-width: calc(100% - 40px);

            .commentor-right-top {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                min-height: 42px;

                .commentor-content {
                    flex-grow: 1;
                    padding: 9px $gutterSmall;

                    .CommentForm {
                        margin-top: 1px;
                    }
                }
            }
        }
    }

    .commentor-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .CommentForm-add-image-button,
    .CommentForm-send-button {
        height: 42px;
        width: 32px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-shrink: 0;

        .AnimatedIcon {
            height: 24px;
            display: block;

            >svg {
                vertical-align: top;

                * {
                    stroke: color(mid-grey);
                }
            }
        }

        svg {
            display: block;
        }

        &.CommentForm-send-button {
            margin-right: $gutterXSmall;
        }

        &.CommentForm-add-image-button {
            margin-right: -4px;
        }

        &:hover {
            * {
                stroke: color("light-blue") !important;
            }
        }
    }

    .SocialComposerImages {
        padding: 0px $gutterMedium;

        img {
            max-width: 100%;
            border-radius: $borderRadius;
        }
    }

    .CommentForm-Buttons {
        margin-top: $gutterMedium;
    }
}