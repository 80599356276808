$imagePassiveColor: #F5B54419;
$imageActiveColor: #F5B544;
$imageEffectColor: #fceacb;
$filePassiveColor: #FCB5D433;
$fileActiveColor: #FF5374;
$fileEffectColor: #fde4ef;
$questionPassiveColor: #4B9F4719;
$questionActiveColor: #4B9F47;
$questionEffectColor: #cce3ca;
$pollPassiveColor: #E8ECFD;
$pollActiveColor: #6a75a7;
$pollEffectColor: #d1d9fb;

@mixin type-selector {
    display: flex;
    flex-wrap: wrap;
    gap: $gutterMedium;

    .ComposerTypeButton {
        border-radius: 12px;
        box-sizing: border-box;
        flex: 30% 1 1;
        vertical-align: middle;
        text-align: center;
        min-width: 106px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .label-component {
            margin-left: $gutterSmall;
            z-index: 1;
            line-height: 48px;
        }

        .lottieWrapper {
            position: relative;
            top: 3px;
            width: 40px;
            height: 46px;

            > div {
                height: 46px;

                > div {
                    height: 40px;
                }
            }
        }

        &.image {
            background-color: $imagePassiveColor;

            .lottieWrapper {
                width: 30px;
                margin-left: -6px;
                margin-right: -6px;
                margin-top: -1px;

                * {
                    stroke: $imageActiveColor;
                }
            }

            .animatedBg {
                background-color: $imageEffectColor;
            }
        }

        &.file, &.external-file {
            background-color: $filePassiveColor;

            .lottieWrapper {
                width: 28px;
                margin-left: -7px;
                margin-right: -7px;

                * {
                    stroke: $fileActiveColor;
                }
            }

            .animatedBg {
                background-color: $fileEffectColor;
            }
        }

        &.question {
            background-color: $questionPassiveColor;

            .lottieWrapper {
                * {
                    stroke: $questionActiveColor;
                }
            }

            .animatedBg {
                background-color: $questionEffectColor;
            }
        }

        &.poll {
            background-color: $pollPassiveColor;

            .lottieWrapper {
                margin-left: -13px;
                margin-right: -13px;

                * {
                    stroke: $pollActiveColor;
                }
            }

            .animatedBg {
                background-color: $pollEffectColor;
            }
        }

        .animatedBg {
            position: absolute;
            width: 100%;
            opacity: 0;
            height: 100%;
            z-index: 0;
            border-radius: 12px;
            left: 0px;
            transition-delay: .1s;
            transition-duration: .3s;
            transition-property: opacity;
        }

        &:hover {
            .animatedBg {
                opacity: 1;
            }
        }
    }
}
