@import "./colors.scss";
@import "./variables.scss";

@mixin DefaultFont {
    color:                  $spintrContentDark;
    font-family:            $primaryFont;
    font-feature-settings:  "clig" off, "liga" off; 
    font-size:              $fsBodySmall;
    font-style:             normal;
    font-weight:            $fwRegular;
    line-height:            $lhNormal;
    letter-spacing:         $lsNormal;
}

@mixin TooltipShadow {
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

@mixin DrawerShadow {
    box-shadow: 0 0 8px rgba(0,0,0,0.12), 0 32px 64px rgba(0,0,0,0.14);
}