@import "src/style/variables.scss";

.UserList {
    >.UserList-title {
        margin-bottom: $gutterMedium;
        font-size: 20px;
    }

    .UserList-users {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: $gutterMedium;
        row-gap: $gutterMedium;

        .SpintrCard {
            max-width: 100%;
            min-width: 120px;
            width: 220px;
        }
    }
}
