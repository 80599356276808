@import "src/style/variables.scss";

.file-selector {
    .ms-Dialog-main {
        // width: 550px;
        width: 700px !important;
        max-height: 700px;
    }

    h1 {
        margin: 0;
    }

    .modal-header {
        margin: 0;
        padding: 0 !important;
    }

    .media {
        .title {
            margin-bottom: $gutterXSmall;
        }

        .searchBox {
            margin-left: 0px;
            margin-right: 0px;
        }

        .items {
            margin-left: -$gutterMedium;
            margin-right: -$gutterMedium;

            .item {
                // width: calc(33.2% - 21px);
                width: calc(25.2% - 21px);
                display: inline-block;
                box-sizing: border-box;
                border: 1px solid #ececec;
                border-radius: $borderRadius;
                margin: 8px 0px 0px $gutterMedium;

                overflow: hidden;
                &:hover {
                    border-color: color("dusk-grey");
                    -webkit-transition: border-color $fastTransition;
                    -moz-transition: border-color $fastTransition;
                    -o-transition: border-color $fastTransition;
                    transition: border-color $fastTransition;
                }

                .cover {
                    padding-top: 56.25%;
                    position: relative;
                    background-color: color("light-grey");
                    background-size: cover;

                    svg {
                        position: absolute;
                        top: calc(50% - 12px);
                        margin: 0 auto;
                        left: 0;
                        right: 0;
                    }
                }

                .info {
                    .name {
                        margin-bottom: $gutterXSmall;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        display: flex;

                        span {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            flex-grow: 1;
                            flex-shrink: 1;
                            flex-basis: 0%;
                            padding-left: $gutterXSmall;
                        }

                        svg {
                            display: inline-block !important;
                        }
                    }

                    .sub-info {
                        display: flex;
                        justify-content: space-between;

                        .name,
                        .authorName,
                        .date,
                        .count {
                            max-width: 50%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .name {
                            margin-bottom: $gutterXSmall;
                        }
                    }
                }
            }

            .LoadMoreButtonWrapper {
                margin-top: $gutterMedium;
            }
        }
    }
}

.isSmallViewMode {
    .file-selector {
        .ms-Dialog-main {
            min-width: auto;
            padding-right: 8px;
        }

        .items {
            .item {
                width: calc(48% - 16px);
            }
        }
    }
}
