@import "src/style/variables";

.NewsEditView {
    .headline {
        margin-top: $gutterMedium;
    }

    .pageHeaderImage {
        border-radius: 0px !important;

        > img {
            border-radius: 0px !important;
        }
    }

    .addPreambleText {
        margin-left: $gutterXSmall;
        vertical-align: middle;
    }
    
    .preamble {
        margin-bottom: $gutterSmall;
    }

    .FormControl .preamble-input-field textarea {
        padding-right: 40px;
    }

    .container {
        background-color: color("mid-grey");
        padding: $gutterXSmall;
        color: white;

        .ms-Button {
            margin-left: $gutterXSmall;
        }

        .adminBar {
            justify-content: space-between;
            display: flex;
            .left {
                padding-top: 5px;
                padding-left: $gutterXSmall;
            }
        }

        .userBar {
            padding-left: $gutterXSmall;
        }
    }

    .FormControl {
        .control {
            .send-email-button {
                margin-top: $gutterSmall;
            }
        }
    }

    .ms-TagItem-text {
        align-self: center;
    }
}

.page-form-view-reject-modal {
    .ms-Button {
        margin-top: $gutterXSmall;
        margin-right: $gutterXSmall;
    }
}
