@import "src/style/variables";

.Chat {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    
    .content {
        display: flex;
        flex-grow: 1;
        position: relative;
    }

    .ms-Persona {
        .ms-Image {
            animation-name: none;
        }
    }

    &.compact {
        .ComposerInfo {
            .inner {
                margin-left: 0px;
            }
        }
    }
}