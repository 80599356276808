@import "src/style/variables.scss";

.ExpandableFieldWrapper {
    .ExpandableBox {

        background-color: white;
        margin-bottom: 10px;
        border-radius: $borderRadius;

        .header.isExpanded {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        .header {
            display: flex;
            width: 100%;
            border-radius: 5px;
            color: color(dark-grey);
            align-items: center;
            cursor: pointer;
            box-sizing: border-box;

            .label-component {
                flex-grow: 1;
                padding: $gutterSmall 0px $gutterSmall $gutterMedium;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: uppercase;
                line-height: 24px;
            }

            .expandButton {
                margin: 0 $gutterSmall;
            }

            .ms-Button-icon {
                font-size: 12px;
            }
        }

        .TinyFormattedContent {
            padding: 0 $gutterMedium $gutterSmall $gutterMedium;
            visibility: hidden;
            height: 0;
            overflow: hidden;
            font-size: 12px;

            .unstyled-button {
                display: block;
                
                .ms-Label {
                    font-weight: 400;
                    cursor: pointer;

                    &.activeIsBold {
                        font-weight: bold !important;
                    }
        
                }
            }
            
            &.isExpanded {
                box-sizing: border-box;
                border-top: 0;
                border-bottom-left-radius: $borderRadiusSharper;
                border-bottom-right-radius: $borderRadiusSharper;
                width: 100%;
            }
        }

        .isExpanded {
            transform: translateY(0);
            visibility: visible;
            height: auto;
        }
    }
}