@import "src/style/variables.scss";

.organisation-unit-view {
    .unit-header {
        position: relative;
        height: 215px;
        background-color: #616770;
        background-position: center center;
        background-repeat: no-repeat;
        margin-bottom: $gutterMedium;
        background-size: cover;

        .unit-header-menu-wrapper {
            position: absolute;
            right: $gutterSmall;
            top: $gutterSmall;

            .actionmenu-component {
                color: white;

                i {
                    background-color: color("light-grey");
                    border-radius: $borderRadius;
                    opacity: 0.6;
                }

                &:hover i {
                    background-color: color("dusk-grey");
                    opacity: 1;
                }
            }
        }

        &.with-image {
            background-color: #fff;
        }
        
        .content {
            position: absolute;
            bottom: 0;
            left: $gutterLarge;
            margin-right: $gutterLarge;
            width: calc(100% - #{$gutterLarge} * 2);
            margin-bottom: $gutterLarge;

            h4 {
                margin-top: $gutterXSmall;
                margin-bottom: $gutterXSmall;
            }

            .members {
                .ms-Persona {
                    box-sizing: border-box;

                    .ms-Persona-imageArea {
                        border: 1px solid rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }

        .gradient-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            background: rgb(0,0,0);
            background: -moz-linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(255,255,255,0) 100%);
            background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(255,255,255,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
            background-color: transparent !important;
        }
    }

    .feed {
        width: 35%;

        .impersonationBanner {
            background-color: color("light-grey");
            padding: $gutterSmall;
            margin-bottom: $gutterSmall;
        }
    }

    .right {
        width: 65%;

        .events {
            margin-bottom: $gutterSmall;

            > div {
                width: 50%;
                border: 1px solid color("dusk-grey");
            }

            .date-box {
                width: 40px;
                height: 40px;
                background-color: #dadada;
                border-radius: $borderRadius;
                vertical-align: top;
                display: inline-flex;
                margin-right: $gutterXSmall;
            }

            .event-highlight {
                padding: $gutterMedium;
                .event-header {
                    background-color: #dadada;
                    background-position: center;
                    height: 150px;
                    width: 100%;
                    padding: $gutterSmall;
                    box-sizing: border-box;

                    .date-box {
                        background-color: rgba(0, 0, 0, 0.5);
                        color: #fff;
                    }
                }
                .event-title {
                    margin: $gutterXSmall 0;
                }
            }

            .event-list {
                .header-title {
                    padding: $gutterMedium $gutterMedium 0 $gutterMedium;
                }

                .event {
                    border-bottom: 1px solid color("dusk-grey");
                    padding: $gutterSmall $gutterMedium;

                    .event-info {
                        display: inline-block;
                        width: calc(100% - 40px - #{$gutterXSmall});

                        .title {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                    }
                }

                .load-more {
                    display: block;
                    padding: $gutterXSmall 0;
                    text-align: center;
                }
            }
        }

        .unauthorized {
            border: 1px solid color("dusk-grey");
            padding: $gutterSmall $gutterMedium $gutterSmall $gutterMedium;
        }

        .InformationFeedContainer {
            .information-feed-entry {
                &:last-of-type {
                    border-bottom: 0;
                }
            }
        }
    }

    .content {
        width: 80%;
    }

    .sidebar {
        width: 20%;
        position: relative;

        .sidebar-info-part {
            margin-top: $gutterSmall;

            .sidebar-info-part-content {
                margin-top: $gutterTiny;
                line-height: 1.25em;

                > * {
                    line-height: 1.25em;
                }
            }
        }

        .sidebar-info {
            //border: 1px solid color("dusk-grey");
            background-color: color("light-grey");
            margin-bottom: $gutterSmall;

            .summary {
                position: relative;

                .info {
                    position: relative;
                    padding: $gutterSmall $gutterMedium;
                    word-wrap: break-word;

                    .unit-title {
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    .contact {
                        :after {
                            content: ", ";
                        }
                        :last-child {
                            &:after {
                                content: "";
                            }
                        }
                    }
                }

                .url {
                    text-align: center;
                    margin-top: $gutterXSmall;
                }

                .parent {
                    padding: $gutterMedium;
                    border-top: 1px solid color("dusk-grey");
                }
            }
        }

        .submenu-component {
            width: auto;

            // breaks the expand button
            // .row {
            //     padding: 0 $gutterMedium;
            //     > div {
            //         margin: 0;
            //     }
            // }
        }
    }
}

.isSmallViewMode {
    .organisation-unit-view {
        .feed,
        .sidebar,
        .right,
        .event-list {
            width: 100% !important;
        }

        .event-list {
            .event {
                &:last-of-type {
                    border-bottom: 0;
                }
            }
        }
    }
}
