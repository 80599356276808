@import "src/style/variables";

.SocialStatus {
    display: block;

    .external-file-wrapper {
        margin-top: $gutterSmall;
    }

    .share-wrapper {
        .shared-post {
            margin-top: $gutterSmall;

            .SocialPostContainer{
                margin-bottom: 0px;
                box-shadow: none;
                background-color: color(bookmarkColor);
            }
            .post-wrapper {
                padding: $gutterSmall;
                margin-bottom: 0;

                .SocialPostContainer {
                    .SocialPost {
                        border-radius: $borderRadius;
                        box-shadow: 0;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }

        .shared-content-post {
            padding: $gutterSmall;
            border: 1px solid color("dusk-grey");
            position: relative;

            img {
                max-width: 100%;
            }

            .shared-content-post-title {
                margin: 0;
            }
        }
    }
}