.ms-Fabric--isFocusVisible {
    *:focus{
        outline-style: solid;
        outline-color: #488dc8;
        outline-width: 2px;
        outline-offset: -2px;
    }
}

.ms-Fabric--isFocusHidden {
    a, div {
        &:not(.divWithHoverActions) {
            outline: none;
        }
    }
}