@import "src/style/variables";

.ErrorPage {
    text-align: center;

    .ErrorPage-icon-wrapper {
        display: inline-block;
    }

    .ErrorPage-headline {
        display: inline-block;
        margin-top: $gutterXSmall;
        margin-bottom: $gutterMedium;
    }
    .homepage-button {
        margin-top: $gutterMedium;
    }
}
