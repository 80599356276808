@import "src/style/variables.scss";

.GroupChatView {
    margin: 0px;
}

.ChatMessage-user-name {
    margin-bottom: 3px !important;
}

@media screen and (max-width: $vmPhoneLandscape) {
    .ChatView-empty-group {
        display: flex;
        flex-direction: column;
    }

    .Empty-Group {
        width: 100% !important;
        margin: 0 !important;
        margin-top: 24px !important;
    }
}
