@import "src/style/variables";

.AppPageForm {
    background-color: #FFF;
    border-radius: $borderRadius;
    padding: $gutterMedium;

    .form-header {
        margin-bottom: $gutterMedium;
    }
}