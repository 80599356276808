@import "src/style/variables.scss";

@supports (selector(html:has(body))) {
    .ms-Modal>.ms-Dialog-main:has(.AbscenceView-popup) {
        .ms-Modal-scrollableContent {
            overflow-y: hidden;
        }
    }
}

.AbscenceView-popup-footer {
    .ms-Button {
        padding: 0px;
    }
}

.AbscenceView {
    @supports (selector(html:has(body))) {
        .ms-BasePicker:has(.ms-TagItem) {
            .ms-BasePicker-text {
                padding: 1px !important;
                margin: 0;
                max-width: fit-content;
            }
        }
    }

    .FormControl {
        margin-bottom: 0;
    }

    .ms-CommandBar {
        margin-top: 0;
        margin-bottom: 0;
    }

    .commandBar {
        display: flex;
        flex-direction: row;
        margin-bottom: $gutterMedium;

        .SearchField {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .ms-BasePicker {
        .ms-BasePicker-text {
            padding: 3px !important;
        }
    }

}

.createNewAbsenceIcon {
    >* {
        display: inline-block;
        vertical-align: middle;
    }
}

.AbsenceEditView {
    //width: 500px;
}

.AbsenceTimeline {
    .AbsenceTimeline-header {
        text-align: center;

        .AbsenceTimeline-header-label {
            width: 200px;
        }

        div {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .timeline-header {
        position: sticky;
        width: 100%;
        top: 0px;
        right: 0px;
    }

    .timeline-header, .AbsenceTimeline-Content-Row {
        display: flex;
        flex-direction: row;

        > * {
            min-width: 24px;
            flex-grow: 1;
            flex-basis: 0;
        }
    }

    .AbsenceTimeline-Content {
        margin-top: $gutterLarge;
        padding-bottom: $gutterMedium;
        display: flex;
        flex-direction: row;
        overflow: scroll;
        max-height: 50vh;

        .AbsenceTimeline-Content-left {
            display: inline-block;
            vertical-align: top;
            max-width: 150px;
            margin-right: 24px;

            > div:first-child {
                height: 24px;
            }

            .AbsenceTimeline-Content-Row {
                .label-component {
                    line-height: 24px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .AbsenceTimeline-Content-right {
            display: inline-block;
            vertical-align: top;
            flex-grow: 1;
            position: relative;
            height: fit-content;

            .AbsenceTimeline-Content-Column,
            .AbsenceTimeline-header-date {
                display: inline-block;
                text-align: center;
                height: 24px;
                line-height: 1.500em;
                vertical-align: top;
                box-sizing: border-box;
                white-space: nowrap;
                background-color: #FFF;

                .label-component {
                    line-height: 24px;
                }
            }

            .AbsenceTimeline-header-date {
                border-left: 1px solid transparent;
            }

            .AbsenceTimeline-Content-Row {
                height: 24px;

                &:last-child {
                    .AbsenceTimeline-Content-Column {
                        border-bottom: 1px solid color("dusk-grey");
                    }
                }
            }

            .AbsenceTimeline-Content-Column {
                border-left: 1px solid color("dusk-grey");
                border-top: 1px solid color("dusk-grey");

                &:last-child {
                    border-right: 1px solid color("dusk-grey");
                }

                .AbsenceTimeline-activity {
                    height: 24px;
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    @supports (selector(html:has(body))) {
        .contentWrap:has(.AbscenceView) {
            background-color: #fff;

            .visage-box-shadow:has(.AbscenceView) {
                box-shadow: none;
            }

            &:not(.customDesign) {

                .contentWIthInfoPanelLeft,
                .contentWIthInfoPanelRight {
                    padding: 0px !important;
                }
            }

            .SpintrMainContentContainer .main-content-wrapper.main-content-wrapper-background {
                padding: 0px;
            }
        }
    }
}