@import "src/style/variables";

.AppPageTextInput {
    &.SpintrSearch {
        height: 48px;

        .ms-SearchBox {
            align-self: center;
            background-color: color("light-grey");
            border-radius: 5px;
            border: 0;
            padding: 0 $gutterSmall;
        }
    }
}