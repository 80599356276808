@import "src/style/variables.scss";

.toDoContent{
    .InteractionsBar{
        margin-bottom: $gutterMedium;
    }
    .CommentsContainer{
        .InteractionsBar{
            margin-bottom: 0;
        }  
    }
}