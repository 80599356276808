@import "src/common.scss";

.MetadataField {
    margin-bottom: $spacingSmall;

    .MetadataField-fieldName {
        line-height: normal;
        margin-bottom: $spacingSmall;
    }

    &.singleLine {
        align-items: center;
        margin-bottom: $spacingTiny;
        padding: $spacingTiny 0;

        .MetadataField-fieldName, .MetadataField-fieldValue {
            flex: 1 1 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .MetadataField-fieldName {
            margin-bottom: 0;
        }

        .MetadataField-fieldValue {
            text-align: right;
        }
    }
}