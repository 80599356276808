@import "src/style/variables.scss";

.OpenTimesEditor {
    .row {
        display: flex;
        flex-direction: row;
        column-gap: $gutterSmall;
        border: 1px solid color(borderColor);
        padding: $gutterSmall;
        border-radius: $borderRadius;
        position: relative;
        margin-bottom: $gutterSmall;

        .ms-Dropdown-container {
            width: 25%;
        }

        .ms-Dropdown {
            min-width: 116px;
        }
        
        .SpintrDatePicker {
            .dateInput {
                width: 71px !important;
            }
        }

        > .unstyled-button {
            position: absolute;
            top: $gutterSmall;
            right: $gutterSmall;
        }
    }
}