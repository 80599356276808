@import "src/common.scss";

.TagList {
    .TagList-items {
        display: flex;
        gap: $spacingTiny;
        list-style: none;
        margin: $spacingTiny 0;
        padding: 0;

        li {
            &.item:last-of-type {
                overflow: hidden;
            }
        }
    }

    &.displayAll {
        .TagList-items {
            flex-wrap: wrap;
        }
    }
}