@import "src/common.scss";

$mainMaxWidth: 1080px;

$sidebarMaxWidth: 319px;
$sidebarMinWidth: 250px;

$viewMaxWidth: $mainMaxWidth + $spacingSmall + $sidebarMaxWidth;

#WikiPageView {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: $viewMaxWidth;
    width: 100%;

    .WikiPageView-stack {
        gap: $spacingSmall;
    }

    .WikiPageView-main, .WikiPageView-sidebar {
        background-color: $spintrWhite;
        border-radius: $brRounder;
        box-sizing: border-box;
        flex: 1 1 auto;
        padding: $mainContentBackplatePadding;
    }

    .WikiPageView-main {
        max-width: $mainMaxWidth;
        width: 100%;

        .WikiPageView-breadcrumbs{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        header.WikiPageView-header {
            margin-bottom: $spacingLarge;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .WikiPageView-description{
                margin-top: $spacingSmall;
            }

            .TagList {
                margin-top: $spacingSmall;
            }
            
            .open-drawer-button {
                margin-left: $spacingTiny;
            }
        }

        section.WikiPageView-prioritized {
            margin-bottom: $spacingLarge;
        }
    }
}

.WikiPageView-sidebar {
    max-width: $sidebarMaxWidth;
    min-width: $sidebarMinWidth;

    .sidebar-box {
        margin-bottom: $spacingMedium;

        &.pins-box svg {
            rotate: 135deg;
        }
    }
}