@import "src/style/variables";

.TinyQA-wrapper {
    margin-bottom: $gutterSmall;

    .TinyQA-content {
        border: 1px solid color("dusk-grey");
        margin-top: $gutterSmall;

        .TinyQA-question-wrapper {
            .TinyQA-question-button {
                display: block;
                
                .TinyQA-question {
                    display: flex;
                    padding: $gutterSmall;
                    padding-left: $gutterXSmall;
                    
                    * {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .TinyQA-question-label {
                        align-self: center;
                    }

                    .TinyQA-question-icon {
                        align-self: top;

                        font-size: 12px;
                        margin-right: $gutterSmall;
                        margin-left: $gutterXSmall;
                    }
                }
            }

            .TinyQA-answer {
                padding: $gutterSmall;
                padding-left: 36px;
                background-color: color("light-grey");

                .TinyQA-answered-by {
                    .TinyQA-answered-by-inline, .TinyQA-question-button {
                        display: inline;
                    }
                }
            }
        }
    }
}