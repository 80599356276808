@import "src/style/variables";

$catalogItemWidth: 365px;

$appViewHeaderHeight: 290px;

@mixin adminHeader {
    .view-header {
        align-items: center;
        display: flex;
        height: $spintrHeaderHeight;
        flex-shrink: 0;
        justify-content: space-between;

        .header-text {
            flex: 0 1 70%;

            h2 { margin: 0; padding: 0; }
        }

        .search-bar {
            flex: 1 0 30%;
        }
    }
}


@mixin headerWithImage {
    height: $appViewHeaderHeight;
    position: relative;
    width: 100%;

    .background-image {
        height: $appViewHeaderHeight;
        width: 100%;

        .wrapper {
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
            height: $appViewHeaderHeight;
            width: 100%;
        }
    }

    .header-text {
        bottom: $gutterLarge;
        left: $gutterLarge * 1.5;
        position: absolute;

        .image, .text {
            display: inline-block;
            vertical-align: middle;
        }

        .image {
            border-radius: 100%;
            height: 100px;
            margin-right: $gutterLarge;
            overflow: hidden;
            width: 100px;

            img {
                max-width: 100%;
            }
        }

        .app-title, .category-name {
            display: block;
        }
    }
}