@import "src/style/variables.scss";

.UserEditView {
    .emailAndDomain, .generatePassword {
        margin-bottom: $gutterMedium;
    }

    .ms-Image {
        width: 300px;
        max-height: 300px;
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .GDPR-container{
        border: 1px solid color(borderColor); 
        padding: 16px; 
        border-radius: 8px;
    }
}
