@import "src/style/variables";

$reactionIconSize: 16px;

.MessageReactions {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: calc((#{$gutterSmall} + #{$gutterTiny}) * -1);
    left: $gutterMedium;
    min-width: calc(100% - #{$gutterMedium});
    justify-content: flex-end;

    .reactions {
        display: flex;
        flex-direction: row;
        column-gap: $gutterTiny;
        background-color: color(visageMidBlueBackground);
        border-radius: $borderRadiusSharp;
    
        .reaction {
            display: flex;
            flex-direction: row;
            column-gap: $gutterXSmall;
            padding: $gutterTiny $gutterXSmall;
            align-items: center;
    
            img {
                height: $reactionIconSize;
                width: $reactionIconSize;
            }
        }
    
        &:hover {
            opacity: 0.75;
        }
    }
}
