@import "src/style/variables.scss";

.VisageInformationFeed {
    .content {
        background-color: #FFF;
        border-radius: $borderRadius;
    }

    .LoadMoreButtonWrapper {
        margin-top: $gutterXLarge;
    }

    .important-articles-filter-button {
        display: flex;
        flex-direction: row;
        border-radius: $borderRadiusRounder;
        background-color: #FFF;
        padding: $gutterTiny calc(#{$gutterTiny} * 3);
        height: 30px;
        box-sizing: border-box;
        border: 1px solid color(borderColor);
        align-items: center;
        column-gap: $gutterXSmall;
        margin-right: $gutterSmall;
        transition: background-color 0.2s linear;

        svg {
            position: relative;
            display: block;
            height: 14px !important;
            width: 14px !important;
        }

        &.active {
            border: 1px solid transparent;
        }

        &:hover {
            opacity: 0.75;
        }
    }
}