@import "src/style/variables.scss";

.general-progress-bar-wrapper {
    -webkit-animation: loaderFadeIn 0.5s;
    -moz-animation: loaderFadeIn 0.5s;
    -ms-animation: loaderFadeIn 0.5s;
    -o-animation: loaderFadeIn 0.5s;
    animation: loaderFadeIn 0.5s;
}


@keyframes loaderFadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}