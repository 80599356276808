@import "src/style/variables.scss";

.TaxiFiles {
    width: 100%;
    max-width: 500px;

    .group {
        width: 100%;
        padding: $gutterMedium;
        border-bottom: 1px solid color("borderColor");
        box-sizing: border-box;
    }

    .header {
        background-color: color("visage2LightGray");
        padding: $gutterXSmall $gutterMedium;

        :first-child {
            flex-basis: calc(60% - 26px);
        }
    }

    .month {
        .group {
            text-transform: capitalize;
        }
    }

    .file {
        padding: $gutterMedium;
        border-bottom: 1px solid color("borderColor");

        > .ms-Stack {
            :first-child {
                // flex-basis: calc(60% - 12px);
                overflow-wrap: anywhere;
            }

            :last-child {
                // flex-basis: calc(40% - 12px);
                overflow: hidden;
            }
        }
    }
}
