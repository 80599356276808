@import "src/style/variables";

.ComposerAttachmentGeneral {
    max-width: 200px;
    border-radius: $borderRadius;
    border: 1px solid color(borderColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: $gutterXSmall;
    padding: $gutterSmall;
    position: relative;
    height: fit-content;

    .label-component {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
}
