.SpintrTabs{
    //display: flex;

    .tabs{
      li:not(.active){
          a span{
            color: color("dark-grey");
          }
        
      }
    }

    .defaultTabs {
      list-style: none outside none;
      margin: 0;
      padding: 0;

      > li {
        cursor: pointer;
        display: inline-block;
        font-family: $secondaryFont;
        font-weight: 400;
        //color: #707070;
        font-size: 14px;
        a{
          padding: 5px 0;
          display: inline-block;
          border-bottom: 2px solid transparent;
          &:hover{
            // color: #352e9f !important;
          }
        }

        &.active{
          font-family: $secondaryFont;
          font-weight: bold;
          // color: #352e9f;
          a {
            border-bottom: 2px solid; //#352e9f;
            &:hover{
              // color: #352e9f !important;
  
            }
          }

        }
      }
      >li:not(:last-child) {
        padding-right: $gutterMedium;
      }
    }
}
