@import "src/style/variables";

.CalendarSidebarWidget {
    display: block;
    padding: 1px $gutterSmall 0;
    position: relative;

    .widget-header {
        color: color("dark-grey");
        text-transform: uppercase;
        h4{
            margin-bottom: $gutterSmall;
        }
        .calendar-menu {
            position: absolute;
            right: $gutterSmall;
            top: 9px;
        }
    }

    .inner {
        margin-bottom: $gutterSmall;
    }

    .calendar-event {
        border-left: 3px solid color("light-blue");
        cursor: pointer;
        display: block;
        margin-bottom: $gutterSmall;

        .inner {
            padding: $gutterXSmall $gutterMedium;
            -webkit-transition: background-color $fastTransition;
            -moz-transition:  background-color $fastTransition;
            -o-transition:  background-color $fastTransition;
            transition:  background-color $fastTransition;
            &:hover{
                background-color: color("light-grey");
            }

            .event-date, .text-wrapper, .event-title, .event-description {
                display: block;
            }

            .event-date {
                text-transform: uppercase;
            }

            .event-description {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .empty-list {
        color: color("mid-grey");
        display: block;
        padding: $gutterMedium;
        text-align: center;
    }

    .load-more {
        display: block;
        padding: $gutterSmall;
        text-align: center;
    }

    .create-button {
        border: 1px solid color("dusk-grey");
        margin-top: $gutterLarge;
        -moz-user-select: none;
        -webkit-user-select: none;
        background-color: white;
        text-align: center;
        width: 100%;
        :hover{
            background-color:color("light-grey");
        }

        a {
            color: black;
            display: block;
            padding: $gutterLarge $gutterSmall;
            text-align: center;
            -moz-user-select: none;
            -webkit-user-select: none;
            -webkit-transition: background-color $fastTransition;
            -moz-transition:  background-color $fastTransition;
            -o-transition:  background-color $fastTransition;
            transition:  background-color $fastTransition;
        }

    }
}