@import "src/style/variables.scss";

.TokenizedInputElement {
    background-color: color("light-grey");
    border: 1px solid color("dusk-grey");
    border-radius: $borderRadius;
    cursor: pointer;
    margin-right: 6px;
    margin-bottom: 6px;
    padding: 2px 4px;
    user-select: none;

    &.selected,
    &:hover {
        background-color: color("dusk-grey");
    }

    .item {
        .image, .text {
            display: inline-block;
            vertical-align: middle;
        }

        .image {
            margin-right: $gutterXSmall;
        }
    }

    .item,
    .remove-button {
        display: inline-block;
        vertical-align: middle;
    }

    .remove-button {
        margin-left: 4px;
    }
}