@import "src/style/variables";

.ConversationItemImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: $gutterSmall;

    .images {
        width: $circleMedium;
        height: $circleMedium;
        position: relative;

        .ms-Persona  {
            position: absolute;

            .ms-Persona-imageArea {
                border: 1px solid #FFF;
            }
        }

        &.item-count-2 {
            .ms-Persona {
                &:nth-child(1) {
                    top: 0px;
                    left: 0px;
                }

                &:nth-child(2) {
                    bottom: 0px;
                    right: 0px;
                }
            }
        }

        &.item-count-3 {
            .ms-Persona {
                &:nth-child(1) {
                    top: 0px;
                    left: 0px;
                }

                &:nth-child(2) {
                    top: 0px;
                    right: 0px;
                }

                &:nth-child(3) {
                    bottom: 0px;
                    width: 100%;
                    justify-content: center;
                }
            }
        }
    }
}