@import "src/style/variables";

.ParentMessage {
    .bubble-wrapper {
        .bubble {
            background-color: color(visageMidBlueBackground) !important;
            color: color(grey) !important;
            border-radius: 20px !important;
            padding-bottom: $gutterMedium !important;
            margin-bottom: calc(#{$gutterMedium} * -1) !important;

            .MemoHeader, .MessageTodoList {
                .Visage2Icon {
                    svg {
                        path {
                            fill: color(grey) !important;
                        }
                    }
                }
            }
        }
    }
}
