@import "src/style/variables";

.BlogsCatalogueView,
.BlogsView {
    $baseUnit: 6px;

    .header {
        margin-bottom: 12px;
    }

    .postText {
        flex: 0 100%;
        // margin-left: 24px;
        margin-top: 0px;
        margin-bottom: $gutterMedium;
        position: relative;
    }

    .postText.has-image {
        margin-left: $gutterMedium;
    }
    
    .BlogInList {
        display: flex;
        border-bottom: 1px solid #d5d5d5;
        position: relative;
        padding-bottom: $gutterXSmall;
        margin-top: $gutterMedium;
        &:last-child {
            border-bottom: 0 !important;
        }
        .actionMenuPosition {
            position: absolute;
            right: 0px;
        }

        .blog-title {
            a {
                color: color("dark-grey");
            }
        }

        .postImage {
            width: 165.6px;
            height: 104px;
            background-size: cover !important;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 12px;
            margin-right: 12px;
            
            .gradient {
                width: 100%;
                height: 100%;
                background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #000 100%);
            }
        }
    
        p {
            color: color(mid-grey);
            margin-bottom: 5px;
        }
    }

    .prioritized {
        .items {
            .item {
                display: inline-block;
                width: calc((100% - 12px) / 2);
                position: relative;
                font-size: 15px;
                cursor: pointer;
                margin-bottom: $gutterSmall;
                border-top: 0px;
                .spotlight-item{

                }

                &:nth-child(odd) {
                    margin-right: 6px;
                }

                &:nth-child(even) {
                    margin-left: 6px;
                }

                &:nth-last-child(1):nth-child(odd) {
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 0;
                }

                .fullOverlay {
                    height: 100%;
                    width: 100% !important;
                    position: absolute;
                    background-color: rgba(0, 0, 0, 0.4) !important;
                }

                .spotlight-item {
                    height: 225px !important;

                    .background-image {
                        height: 225px !important;
                    }

                    .spotlight-gradient {
                        height: 225px !important;
                    }
                }

                &.full {
                    width: 100%;
                    margin: 0;

                    .spotlight-item {
                        &.no-image {
                            height: 135px;
                        }
                    }
                }
            }
        }
    }

    .blog {
        margin-top: 20px;
    }

    .feed {
        margin-top: $gutterMedium;
        background-color: #fff;

        .blogPost {
            display: flex;
            border-bottom: 1px solid #d5d5d5;
            margin-bottom: 19px;

            .InteractionsBar {
                margin: 0;
            }

            .post-meta {
                display: flex;
                margin-bottom: $gutterXSmall;
                align-items: center;

                > * {
                    display: inline-block;
                    vertical-align: middle;
                }
                
                .date-readtime-separator {
                    margin-left: 4px;
                    margin-right: 4px;
                }
            }
        }

        .blogPost:hover .actionmenu-component {
            opacity: 1;
        }
        .blogPost:last-of-type {
            border: 0;
            margin-bottom: 0;
        }


        .actionmenu-component {
            position: absolute;
            opacity: 0;
            right: 16px;
        }

        h3 {
            font-family: $secondaryFont;
            font-size: 16px;
            color: #000;
            font-weight: 500;
        }

        .blog-title {
            margin-bottom: 5px;
            a {
                color: color(dark-grey);
                text-transform: uppercase;
            }
        }

        .headline {
            margin: 0;
            margin-top: 5px;
            margin: 5px 0 5px 0;
            a {
                color: color("dark-grey");
            }
        }

        .preamble {
            color: color("mid-grey");
            margin-bottom: 5px;
        }

        .postImage {
            width: 165.6px;
            height: 104px;
            background-size: cover !important;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 19px;

            .gradient {
                width: 100%;
                height: 100%;
                background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.33237044817927175) 0%, rgba(0, 0, 0, 0) 100%);
            }
        }
    }
}

.BlogsView {
    position: relative;

    .actionmenu-component {
        position: absolute;
        right: 0;
        top: 10px;
    }
}
