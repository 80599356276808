@import "src/style/variables";

.MessageActions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: $gutterSmaller;
    column-gap: $gutterXSmall;

    .label-component {
        height: 20px;
        line-height: 20px;
    }

    .Visage2Icon-redo {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    .unstyled-button {
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .actionmenu-component {
        height: 20px;
        width: 20px;
        min-width: 20px;
        margin-right: -3px;

        .Visage2Icon-more-circle {
            transform: rotate(90deg);

            svg {
                height: 24px;
                width: 24px;
                position: relative;
                top: -2px;
                right: 2px;
            }
        }
    }

    > * {
        &:hover {
            opacity: 0.75;
        }
    }

    &.visible {
        opacity: 1 !important;
    }
}
