@import "src/style/variables.scss";

.VisageFilterButtonWrapper {
    height: 30px;
    width: 100%;
    margin-bottom: $gutterMedium;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    > div {
        height: 30px;
    }

    .VisageFilterButtonLabel {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .VisageFilterButton {
        height: 30px;
        border-radius: $borderRadiusSharp;
        
        > * {
            display: inline-block;
            vertical-align: middle;
            height: 30px;
        }

        .VisageFilterButton-left {
            background-color: #FFF;
            border-top-left-radius: $borderRadiusSharp;
                border-bottom-left-radius: $borderRadiusSharp;

            .Visage2Icon {
                color: #FFF;
                font-size: 12px;
                margin-left: calc(#{$gutterXSmall});
                margin-top: 5px;
                margin-right: 4px;
            }
            
            .AnimatedIcon {
                position: relative;
                top: 5px;
                color: #FFF;
                font-size: 12px;
                line-height: 30px;
                margin-left: calc(#{$gutterXSmall} + 1px) !important;
                margin-right: calc(#{$gutterXSmall} + 3px) !important;
                
                svg {
                    width: 18px !important;
                    path {
                        stroke: white;
                    }
                }
            }
 }
.VisageFilterButton-right {
    border-top-right-radius: $borderRadiusSharp;
    border-bottom-right-radius: $borderRadiusSharp;
    background-color: #FFF;
    
    >* {
        line-height: 31px;
        margin-right: 14px;
    }
}

&:hover {
    opacity: 0.75;
}
}
}
