@import "src/common.scss";
@import "../plannerVariables.scss";

.TimelineBar {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    min-width: 3px;
    position: absolute;
    user-select: none;
    z-index: map-get($plannerStrata, item);

    &.hovering.minimized{
        transition: left 0.3s, right 0.3s;
        
        .TimelineBar-inner {
            padding: $spacingTiny $spacingMedium;

            .TimelineBar-label-top, .TimelineBar-label-bottom {
                display: flex;
            }
        }
    }

    &.minimized{
        .TimelineBar-inner {
            padding: 0;

            .TimelineBar-label-top, .TimelineBar-label-bottom {
                display: none;
            }
        }
    }

    &.smallerBar {
        .TimelineBar-inner {
            padding-bottom: 0;
            padding-top: 0;

            .TimelineBar-label-top {
                line-height: 1.6667;
            }
        }
    }

    .TimelineBar-inner {
        border-radius: $brNormal;
        bottom: 0;
        left: 0;
        padding: $spacingTiny $spacingMedium;
        position: absolute;
        right: 0;
        top: 0;
        z-index: map-get($plannerStrata, itemBackground);

        .TimelineBar-label-top {
            align-items: center;
            display: flex;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
            width: 100%;
            z-index: map-get($plannerStrata, itemContent);
        }

        .TimelineBar-label-bottom {
            align-items: center;
            display: flex;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
            width: 100%;
            z-index: map-get($plannerStrata, itemContent);
        }
    }

    .TimelineBar-progressTracker {
        border-radius: 0 $brNormal $brNormal 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        z-index: map-get($plannerStrata, item);
    }

    .TimelineBar-barHandle {
        background-color: rgba(0, 0, 0, 0.3);
        background-clip: content-box;
        border-radius: $brNormal;
        bottom: $spacingTiny;
        cursor: col-resize;
        padding: $spacingTiny;
        position: absolute;
        top: $spacingTiny;
        width: 5px;
        z-index: map-get($plannerStrata, itemHandle);

        &.left {
            left: -10px;
        }

        &.right {
            right: -10px;
        }
    }
}

.TimelineBar-origin {
    align-items: center;
    border-radius: $brNormal;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    min-width: 3px;
    position: absolute;
    z-index: map-get($plannerStrata, itemGuide);
}