@import "src/style/variables";

.StartPageBuilderComponent {
    padding: $gutterSmall;
    border: 2px solid transparent;
    border-radius: $borderRadiusSharper;

    &.selected {
        border-color: rgba(color("neutralBlue"), .75);
    }

    &:empty {
        border: 1px dashed #A4A4A4;
        margin-right: -1px;
        margin-bottom: -1px;
        min-height: 40px;
    }

    .blog-teaser-box .blog-teaser-box-content .BlogPostHeadline {
        white-space: initial;
    }

    .InformationFeedEntry .entry-preamble .label-component {
        white-space: pre-wrap;
        height: 24px;
    }

    #SocialFeedContainer .infinite-scroll-component {
        margin: 0;
        padding: 0;

        .visage-box-shadow {
            box-shadow: none;
        }
    }

    #news-spotlight {
        margin-bottom: 0;
    }

    .teaser-box-container {
        margin-bottom: 0;
    }

    .widget-header {
        h4 {
            margin: 0 0 16px;
        }
    }

    .operatingInfoBox {
        margin-bottom: 0;
    }

    .Birthdays {
        margin-bottom: 0;
    }

    .component-wrapper {
        position: relative;

        > .overlay {
            border-radius: 3px;
            bottom: -$gutterSmall;
            left: -$gutterSmall;
            position: absolute;
            right: -$gutterSmall;
            top: -$gutterSmall;
            z-index: 2;
        }

        > .controls-container {
            background-color: #FFF;
            border-radius: $borderRadiusRounder;
            box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
            padding: 0 $gutterSmall;

            position: absolute;
            left: $gutterSmall;
            top: (0 - $gutterSmall) - 15px;
            z-index: 3;

            .controls {
                display: flex;
                align-items: center;
                gap: $gutterXSmall;
            }

            .control {
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 0;
                height: 30px;
                text-align: center;

                svg {
                    padding: 7px 0;
                }
            }
        }

        .settings-container {
            background-color: #FFF;
            border-radius: $borderRadius;
            box-shadow: -10px 5px 14px rgba(0, 0, 0, 0.07);
            position: absolute;
            left: $gutterMedium;
            top: $gutterSmall;
            z-index: 3;
        }
    }
}