@import "src/style/variables.scss";

.ColorPicker-small {
    .ColorPicker-small-color {
        margin-right: $gutterXSmall;
        border-radius: $gutterMedium;
        border: 1px solid transparent;
        -webkit-transition: border-color $fastTransition;
        -moz-transition:  border-color $fastTransition;
        -o-transition:  border-color $fastTransition;
        transition:  border-color $fastTransition;
        &:hover{
            border: 1px solid color("mid-grey");
        }

        div {
            width: $gutterMedium;
            height: $gutterMedium;
            border-radius: $gutterMedium;
            border: 3px solid #FFF;
        }
    }

    .ColorPicker-small-color-active {
        border: 1px solid black;
    }
}

.ms-ColorPicker-panel {
    padding: 0;
}

.ms-ColorPicker-table {
    .ms-ColorPicker-input {
        padding-right: 0;
    }

    tr {
        td {
            font-weight: 600;

            &:first-child {
                width: 35%;
            }

            &:not(:first-child) {
                text-align: right;
            }
        }
    }

    tbody {
        tr {
            td {
                &:not(:first-child) {
                    input {
                        text-align: right;
                    }
                }
            }
        }
    }

    .ms-TextField-fieldGroup {
        border: 0;
        min-height: 0 !important;
        height: 14px !important;

        input {
            padding: 0;
            height: 14px;
        }
    }
}