@import "src/style/variables.scss";

.ProfilePosition {
    border: 1px solid color("dusk-grey");
    padding: $gutterMedium;

    .ProfilePosition-UserCategory {
        margin-bottom: $gutterSmall;

        .ProfilePosition-User {
            margin-top: $gutterXSmall;
            padding-left: $gutterSmall;
            border-left: 2px solid transparent;

            &.ProfilePosition-Active-User {
                border-left: 2px solid color("light-blue");
            }
        }
    }
}
