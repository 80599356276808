@import "src/common.scss";

.ProductPreviewCard {
    align-items: center;
    border: 1px solid $spintrGrayLight;
    border-radius: $brRounder;
    display: flex;
    flex-direction: row;
    gap: $spacingMedium;
    justify-content: start;
    padding: $spacingMedium;

    .product-image {
        align-items: center;
        border-radius: $brSharp;
        display: flex;
        flex: 0 0 36px;
        height: 36px;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 36px;

        img {
            max-width: 100%;
            object-fit: contain;
        }
    }

    .product-information {
        display: block;
        flex: 1 1 0;

        .product-name, .product-sku {
            display: block;
        }
    }
}