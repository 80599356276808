@import "../../style/variables.scss";

.media-view {
    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $gutterSmall;

        .sort {
            align-self: center;
        }
    }

    .items {
        margin: -$gutterSmall;

        .item {
            width: calc(33.2% - 24px);
            display: inline-block;
            box-sizing: border-box;
            border: 1px solid #ececec;
            border-radius: $borderRadius;
            margin: 12px 12px 8px 12px;
            overflow: hidden;
            position: relative;
            &:hover{
                border-color: color("dusk-grey");
                -webkit-transition: border-color $fastTransition;
                -moz-transition:  border-color $fastTransition;
                -o-transition:  border-color $fastTransition;
                transition:  border-color $fastTransition;
            }

            .actionmenu-component {
                top: $gutterXSmall;
                right: 2px;
                position: absolute;
                color: white;

                i {
                    background-color: color("light-grey");
                    border-radius: $borderRadiusSharper;
                    opacity: 0.6;
                }

                &:hover i {
                    background-color: color("dusk-grey");
                    opacity: 1;
                }
            }

            .cover {
                padding-top: 56.25%;
                position: relative;
                background-color: color("light-grey");
                background-size: cover;

                .Visage2Icon {
                    position: absolute;
                    top: calc(50% - 12px);
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                }
            }

            .info {
                .name {
                    margin-bottom: $gutterXSmall;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    a {
                        span {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            flex-grow: 1;
                            flex-shrink: 1;
                            flex-basis: 0%;
                        }

                        svg {
                            margin-right: $gutterXSmall;
                            display: inline-block !important;
                        }
                    }
                }

                .sub-info {
                    display: flex;
                    justify-content: space-between;

                    .name,
                    .authorName,
                    .date,
                    .count {
                        max-width: 50%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .name {
                        margin-bottom: $gutterXSmall;
                    }
                }
            }
        }
    }

    .drop {
        &.is-dragging-file-over {
            border-style: dashed;
            border-width: 1px;
            margin: -1px;
        }
    }
}

.media-sort ms-ContextualMenu-item.active {
    span {
        font-weight: 400;
    }
}

.isSmallViewMode {
    .media-view {
        .items {
            .item {
                width: calc(50% - 24px);
            }
        }
    }
}

.folder-icon {
    display:inline-block;
    margin-right: $gutterMedium;
}

.folder-icon-name {
    display:inline-block;
}