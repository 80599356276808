@import "src/style/variables";

.AssistantMessages {
    background-color: #FFF;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;

    .conversation-header {
        border-bottom: 1px solid #EAEAEA;
        display: flex;
        padding: $gutterSmall $gutterMedium;

        .assistant-image {
            flex: 0 0 40px;
            height: 45px;
            margin-right: $gutterMedium;
            width: 40px;
        }

        .assistant-name {
            flex: 1 1 calc(100% - 72px - 64px);
            padding: $gutterSmall 0;
        }

        .close-button {
            top: 18px;
        }
    }

    .messages-wrapper {
        height: calc(45 * var(--vh, 10px));
        
        .messages {
            padding: $gutterLarge;
        }
    }

    .response-loader {
        padding-left: $gutterLarge;
    }
}