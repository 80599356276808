@import "src/style/variables.scss";

$highlightedSize: 296px;
$thumbnailSize: 60px;

.ProductImages {
    background-color: color("visage2LightGray");
    border-radius: $borderRadius;
    padding: $gutterSmall;

    .image-stack {
        gap: $gutterMedium;
        justify-content: center;

        .primary-image {
            align-items: center;
            background-color: color("spintrWhite");
            border-radius: $borderRadiusSharp;
            display: flex;
            height: $highlightedSize;
            justify-content: center;
            overflow: hidden;
            position: relative;
            width: $highlightedSize;

            img {
                max-width: 100%;
                object-fit: contain;
            }
        }

        .thumbnails {
            text-align: center;

            .thumbnail-stack {
                gap: $gutterXSmall;
                justify-content: center;

                .thumbnail {
                    align-items: center;
                    background-color: color("spintrWhite");
                    border: 2px solid color("visage2LightGray");
                    border-radius: $borderRadiusSharp;
                    box-sizing: border-box;
                    display: flex;
                    height: $thumbnailSize;
                    justify-content: center;
                    overflow: hidden;
                    position: relative;
                    width: $thumbnailSize;

                    &.highlighted {
                        border-color: color("visageMidBlue");
                    }

                    img {
                        max-width: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}