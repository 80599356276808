@import "src/style/variables";

.AssistantInput {
    background-color: #FFF;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    height: 44px;
    padding: 0 $gutterSmall $gutterSmall;
    
    form {
        display: flex;
        gap: 8px;
        width: 100%;
    }
    
    .input-wrapper {
        border: 1px solid #EAEAEA;
        border-radius: 12px;
        box-sizing: border-box;
        display: block;
        flex: 1 1 calc(100% - 44px - 8px);
        padding: 10px $gutterSmall;
        position: relative;
        width: calc(100% - 44px - 8px);

        input {
            border: 0 none;
            outline: 0 none;
            width: calc(100% - 36px);
        }
    }

    .send-button {
        border-radius: 8px;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); 
        flex: 0 0 44px;
        height: 44px;
        padding: $gutterSmall;
        text-align: center;
        width: 44px;
    }
}