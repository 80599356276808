@import "src/style/variables.scss";

.ActionMenuCategory {
    .title {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        padding: $gutterXSmall $gutterSmall;
        text-align: left;
        text-overflow: ellipsis;
        text-transform: uppercase;
        user-select: none;
        white-space: pre;
        width: 100%;
    }
}