@import "src/style/variables.scss";

@mixin office365file {
    .image-preview {
        background-color: color("light-grey");
        margin-bottom: $gutterMedium;
        max-height: 135px;
        overflow: hidden;
        position: relative;
        text-align: center;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;

        img {
            box-shadow: 0 3px 40px 6px rgba(227, 227, 227, 0.6);
            max-width: 90%;
            position: relative;
            top: 15px;
            vertical-align: middle;
        }
    }
}