@import "src/style/variables.scss";

.StartPageBuilderDrawerItem {
    .component-wrapper {
        &.dragging {
            .FeedPostDrawerItem {
                border: 1px solid var(--primaryColor);
            }
        }

        border: 1px solid white;
        border-radius: $borderRadiusSharper;

        &:hover, &.selected, &.dragging {
            border: 1px solid var(--primaryColor);
        }
    }

    position: relative;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
    border-radius: $borderRadiusSharper;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 130px;
        background-color: #d9d9d9;
        border-radius: $borderRadiusSharper $borderRadiusSharper 0 0;
    }

    .ms-Checkbox {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .info {
        background-color: #fff;
        border-radius: 0 0 $borderRadiusSharper $borderRadiusSharper;
        padding: $gutterSmall $gutterSmall;
    }
}
