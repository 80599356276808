@import "src/style/variables";

.OperatingInfoSidebarWidget {
    display: block;
    position: relative;

    button {
        font-size: 14px;
    }

    .actionmenu-component {
        opacity: 0;
    }

    &:hover .actionmenu-component {
        opacity: 1;
    }
    .textNoOperatingInfo {
        background-color: color(light-grey);
        padding: 16px;
        text-align: center;
    }

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .widget-header {
            margin: 8px 0 8px 0;
        }
    }
}

#StartPageEditView.editing .StartPageBuilderComponent .Sidebar-Todos {
    .ms-DetailsRow {
        width: 100% !important;
    }

    .ms-DetailsRow-fields {
        flex-basis: 100%;
    }

    .ms-DetailsRow-cell {
        width: auto !important;
    }
}