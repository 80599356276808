@import "src/style/variables";

.ComposerAttachmentExternalFile {
    position: relative;

    .ExternalFile {
        pointer-events: none;
    }

    .spintr-list-empty-list-label {
        padding-right: $gutterXXXLarge;
    }

    .close-button {
        position: absolute;
        top: $gutterSmall;
        right: $gutterSmall;
    }
}
