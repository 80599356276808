@use "sass:math";
@import "src/common.scss";

.DataList {
    .ms-CommandBar {
        margin-bottom: $spacingLarge;

        .sort-order-menu {
            border-radius: $brRounder;
        }

        .list-type-switch {
            border-radius: $brRounder;
            background-color: $spintrGrayLighter;
            display: flex;
            gap: math.div($spacingTiny, 2);
            padding: math.div($spacingTiny, 2);
            align-items: center;
            height: 36px;
            box-sizing: border-box;

            .switch-button {
                align-items: center;
                background-color: $spintrGrayLighter;
                border: 1px solid $spintrGrayLighter;
                border-radius: $brNormal;
                display: flex;
                height: 32px;
                justify-content: center;
                transition: 150ms all ease-in;
                width: 32px;

                &.active {
                    background-color: $spintrWhite;
                    border-color: $spintrGrayLight;
                }
            }
        }

        .create-wiki-button {
            border-radius: $brNormal;
        }
    }

    .SpintrList-cards {
        gap: $spacingSmall;
    }
}
