@import "src/style/variables";

.MessageAttachments {
    display: flex;
    flex-direction: row;
    gap: $gutterSmall;
    flex-wrap: wrap;

    img {
        object-fit: cover;
        width: 100px;
        height: 74px;
        border-radius: $borderRadius;
        display: block;
    }

    .AudioPlayer {
        width: 100%;
        min-width: 300px;
        box-sizing: border-box;
    }

    .ImageCore {
        animation: none;
    }
}
