@import "src/common.scss";
@import "../plannerVariables.scss";

.TimelineOverlay {
    @include AbsoluteFullsize;
    
    display: flex;
    pointer-events: none;
    width: 100%;

    .TimelineOverlay-axis {
        background-color: $spintrWhite;
        flex: 0 0 auto;
        left: 0;
        position: sticky;
        z-index: map-get($plannerStrata, itemHandle);
    }

    .TimelineOverlay-chart {
        height: calc(100% - $timelineHeaderHeight);
        flex: 0 0 auto;
        position: relative;
        top: $timelineHeaderHeight;

        .TimelineOverlay-today {
            bottom: 0;
            display: flex;
            pointer-events: none;
            position: absolute;
            top: 0;
            z-index: map-get($plannerStrata, todayLine);

            .TimelineOverlay-todayLine {
                background-image: linear-gradient(to bottom, $spintrContentDark 50%, transparent 50%);
                background-size: 2px 6px; /* Adjust the size to change the dash spacing */
                height: 100%;
                width: 2px;

                .TimelineOverlay-todayArrow {
                    border-left: 4px solid #0000;
                    border-right: 4px solid #0000;
                    border-top: 8px solid $spintrContentDark;
                    margin-left: -3px;
                    position: sticky;
                    top: $timelineHeaderHeight;
                }
            }
        }
    }
}