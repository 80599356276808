@import "src/style/variables";

.ComposerAttachmentImage {
    position: relative;
    height: 74px;

    img {
        height: 74px;
        width: 100px;
        object-fit: cover;
        border-radius: $borderRadius;
        background-color: color(visage2LightGray2);
    }

    .close-button {
        position: absolute;
        bottom: 0px;
        right: 0px;
        background-color: #FFF;
        border-radius: 20px;

        svg {
            path {
                &:first-child {
                    display: none;
                }
            }
        }
    }
}
