@import "src/style/variables.scss";

.CompactTable {
    display: flex;
    flex-direction: column;

    .row {
        display: flex;
        flex-direction: row;

        &.title {
            background-color: color(visage2LightGray);
        }

        &:not(.title) {
            border-bottom: 1px solid color(borderColor);
        }

        .column {
            padding: $gutterXSmall $gutterSmall;
            width: 0;
            flex: 1 1 0;

            .label-component {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}